import gql from "graphql-tag";
const LAUNCH_CAMPAIGN_ORDER_PROGRAMMATIC = gql`
  mutation launchCampaignProgrammatic($data: JSON!) {
    launchCampaignProgrammatic(data: $data) {
      id
      name
    }
  }
`;

export default LAUNCH_CAMPAIGN_ORDER_PROGRAMMATIC;
