import React from "react";
import { Menu, Dropdown, Button, DatePicker, Space } from "antd";
import moment from "moment";
import { DownOutlined, CalendarOutlined } from "@ant-design/icons";
import { isEmpty } from "lodash";
import "../../shared/globalStyling/responsive.css";

const { RangePicker } = DatePicker;

export const ranges = {
  Today: [
    moment()
      .startOf("day")
      .utc(),
    moment()
      .endOf("day")
      .utc(),
  ],
  Yesterday: [
    moment()
      .subtract(1, "days")
      .startOf("day")
      .utc(),
    moment()
      .endOf("day")
      .utc(),
  ],
  "Last 7 Days": [
    moment()
      .subtract(7, "days")
      .startOf("day")
      .utc(),
    moment()
      .endOf("day")
      .utc(),
  ],
  "This Month": [
    moment()
      .startOf("month")
      .startOf("day")
      .utc(),
    moment()
      .endOf("day")
      .utc(),
  ],
  "Last Month": [
    moment()
      .subtract(1, "month")
      .startOf("month")
      .startOf("day")
      .utc(),
    moment()
      .subtract(1, "month")
      .endOf("month")
      .endOf("day")
      .utc(),
  ],
  "Last 30 Days": [
    moment()
      .subtract(30, "days")
      .startOf("day")
      .utc(),
    moment()
      .endOf("day")
      .utc(),
  ],
  "This Quarter": [
    moment()
      .startOf("quarter")
      .startOf("day")
      .utc(),
    moment()
      .endOf("day")
      .utc(),
  ],
  "Last Quarter": [
    moment()
      .subtract(1, "Q")
      .startOf("quarter")
      .startOf("day")
      .utc(),
    moment()
      .subtract(1, "Q")
      .endOf("quarter")
      .endOf("day")
      .utc(),
  ],
  All: [
    moment()
      .subtract(6, "month")
      .startOf("day")
      .utc(),
    moment()
      .endOf("day")
      .utc(),
  ],
  "Custom Range": null,
};

const CustomRangePicker = ({
  value,
  onChange,
  setRangeKey,
  rangeKey,
  loading,
  defaultValue,
}) => {
  // sort them by desc dates

  const onHandleDropdown = e => {
    setRangeKey(e.key);
    handleOnChange(ranges[e.key]);
  };

  const menuItems = Object.entries(ranges).map(r => (
    <Menu.Item key={r[0]} icon={<CalendarOutlined />}>
      {r[0]}
    </Menu.Item>
  ));

  const handleOnChange = v => {
    const dates = !isEmpty(v)
      ? [v[0] ? moment(v[0]) : v[0], v[1] ? moment(v[1]) : v[1]]
      : [];
    onChange(dates);
  };

  return (
    <Space className="ant-space-xs-vertical ant-space-md-horizontal ant-space-md-gap-none ant-space-xs-gap-small">
      <RangePicker
        disabled={loading}
        allowClear={false}
        defaultValue={defaultValue}
        value={value}
        style={{ width: "250px" }}
        onChange={handleOnChange}
        onCalendarChange={handleOnChange}
        disabledDate={current => {
          return current.isBetween(
            moment(new Date())
              .startOf("day")
              .utc(),
            moment(new Date("2039-12-31"))
              .endOf("day")
              .utc()
          );
        }}
      />
      <Dropdown
        trigger="click"
        overlay={<Menu onClick={onHandleDropdown}>{menuItems}</Menu>}
      >
        <Button loading={loading} style={{ textAlign: "left" }}>
          <CalendarOutlined />
          {rangeKey ? rangeKey : "Custom Range"} <DownOutlined />
        </Button>
      </Dropdown>
    </Space>
  );
};

export default CustomRangePicker;
