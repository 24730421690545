export const taskTypes = [
  "Display Campaign",
  "SEO",
  "SEM",
  "Attention Signals",
  "Demograph",
  "Onboarding",
  "Troubleshooting",
  "Sales Support",
  "General Question",
  "Bug Report",
  "Feature Request",
  "Admin Support",
  "OffBoarding",
];

export const subTypes = {
  "Display Campaign": [
    "Update Campaign Name",
    "Update Creative(s)",
    "Update Clickthru URL(s)",
    "Update Audience Segment(s)",
    "Update Geotarget(s)",
    "Update Walk-in Location(s)",
    "Update E-Commerce Attribution",
    "Update Campaign Status",
    "Campaign Budget / Flight Dates",
    "Change Line Item Allocation / Change Flight Dates",
    "Campaign Launch",
    "Report Request",
    "Other",
  ],
  Onboarding: [
    "Domain Approval",
    "Org Creation",
    "Tag Creation / Installation",
    "Whitelabel",
    "Other",
  ],
  "Admin Support": ["Access", "Processing"],
  Troubleshooting: ["Tag Investigation", "Pacing", "Performance", "Other"],
  "Sales Support": ["Request Renewal", "Budget & Strategy", "Other"],
  "Bug Report": ["Major", "Minor"],
  "Feature Request": ["New Feature", "Enhancement"],
  // Add other subtypes as needed
};

export const statusTypes = {
  "Not Started": "NOT_STARTED",
  Ready: "READY",
  "In Progress": "IN_PROGRESS",
  Blocked: "BLOCKED",
  "On Hold": "ON_HOLD",
  Completed: "COMPLETED",
  Monitoring: "MONITORING",
  Duplicate: "DUPLICATE",
  "Input Required": "INPUT_REQUIRED",
  "No Longer Needed": "NO_LONGER_NEEDED",
};

export const clientTypes = {
  Platform: "PLATFORM",
  "Channel Partner": "CHANNEL_PARTNER",
};

export const urgencyTypes = {
  Low: "LOW",
  Medium: "MEDIUM",
  High: "HIGH",
  Critical: "CRITICAL",
};

export const urgencyColors = {
  [urgencyTypes.Low]: "gray",
  [urgencyTypes.Medium]: "yellow",
  [urgencyTypes.High]: "orange",
  [urgencyTypes.Critical]: "red",
};
