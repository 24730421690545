import { countryMap } from "../../../core/utils/constants/constants";
import { transform, isObject } from "lodash";

export const buildGeoTargetPayload = ({ target, orgId }) => {
  const { circles, polygons, location } = target;

  if (location) {
    delete location.id;
    delete location.__typename;
  }

  return {
    circles: {
      create: circles.length
        ? circles.map(circle => {
            const circlePayload = {
              radius: circle.radius,
              coordinate: {
                create: {
                  lat: circle.coordinate.lat,
                  lng: circle.coordinate.lng,
                },
              },
            };

            if (circle.location) {
              circlePayload.location = {
                create: {
                  ...circle.location,
                },
              };
            }
            return circlePayload;
          })
        : [],
    },
    polygons: {
      create: polygons.length
        ? polygons.map(polygon => {
            const polygonPayload = {
              geometry: {
                coordinates: polygon.geometry.coordinates,
              },
            };

            if (polygon.location) {
              polygonPayload.location = {
                create: {
                  ...polygon.location,
                },
              };
            }

            return polygonPayload;
          })
        : [],
    },
    location: {
      create: {
        ...location,
      },
    },
    name: target.name || "",
    start: target.start || null,
    end: target.end || null,
    // orgs: {
    //   connect: {
    //     id: orgId,
    //   },
    // },
    status: "CREATED",
  };
};

export const buildGeoTargetsPayload = ({
  targets = [],
  originalTargets = [],
  orgId,
}) => {
  // Find the targets that were deleted
  const deletedTargets = originalTargets.filter(
    originalTarget =>
      !targets.some(target => target.name === originalTarget.name)
  );

  const newTargets = targets.filter(
    target =>
      !originalTargets.some(
        originalTarget => originalTarget.name === target.name
      )
  );

  const payload = {
    create: newTargets.map(target => buildGeoTargetPayload({ target, orgId })),
  };

  if (deletedTargets.length) {
    payload.delete = deletedTargets.map(target => ({
      id: target.id,
    }));
  }

  return payload;
};

export const transformLocationToGeoTarget = ({ location }) => {
  const {
    name,
    formattedAddress,
    city,
    state,
    zip,
    lat,
    lng,
    street,
    country,
    geoframe,
    org,
    audienceType,
    circles = [],
    polygons = [],
  } = location;

  const geoFrameCircles = geoframe && geoframe.circles ? geoframe.circles : [];
  const geoFramePolygons =
    geoframe && geoframe.polygons ? geoframe.polygons : [];

  const newCircles = geoFrameCircles.length ? geoFrameCircles : circles;
  const newPolygons = geoFramePolygons.length ? geoFramePolygons : polygons;

  return {
    circles: newCircles.map(circle => ({
      ...circle,
      location: {
        name: circle.location ? circle.location.name : name,
        formattedAddress: circle.location
          ? circle.location.formattedAddress
          : formattedAddress,
        street: circle.location ? circle.location.street : street,
        state: circle.location ? circle.location.state : state,
        city: circle.location ? circle.location.city : city,
        zip: circle.location ? circle.location.zip : zip,
        country: circle.location ? circle.location.country : country,
        lat: circle.location ? circle.location.lat : lat,
        lng: circle.location ? circle.location.lng : lng,
        audienceType,
      },
    })),
    polygons: newPolygons.map(polygon => ({
      ...polygon,
      location: {
        name: polygon.location ? polygon.location.name : name,
        formattedAddress: polygon.location
          ? polygon.location.formattedAddress
          : formattedAddress,
        street: polygon.location ? polygon.location.street : street,
        state: polygon.location ? polygon.location.state : state,
        city: polygon.location ? polygon.location.city : city,
        zip: polygon.location ? polygon.location.zip : zip,
        country: polygon.location ? polygon.location.country : country,
        lat: polygon.location ? polygon.location.lat : lat,
        lng: polygon.location ? polygon.location.lng : lng,
        audienceType,
      },
    })),
    location: {
      name: name,
      formattedAddress: formattedAddress,
      street: street,
      state: state,
      city: city,
      zip: zip,
      country: country,
      lat: lat,
      lng: lng,
      audienceType,
    },
    name: location.name,
    start: null,
    end: null,
    org,
  };
};

export const buildAdGroupPayload = ({
  adGroup,
  orgId,
  campaign,
  originalLineItems = [],
}) => {
  let newAdGroupCreatives = [];
  let updatedAdGroupCreatives = [];
  let deletedAdGroupCreatives = [];

  if (adGroup.adGroupCreatives && adGroup.adGroupCreatives.length) {
    newAdGroupCreatives = adGroup.adGroupCreatives.filter(
      creative => !creative.id
    );
    updatedAdGroupCreatives = adGroup.adGroupCreatives.filter(
      creative => creative.id
    );
  }

  const matchedCampaign = originalLineItems
    .filter(
      campaign =>
        campaign.adGroup &&
        campaign.adGroup.adGroupCreatives &&
        campaign.adGroup.adGroupCreatives.length
    )
    .find(c => c.id === campaign.id);

  if (matchedCampaign) {
    deletedAdGroupCreatives = matchedCampaign.adGroup.adGroupCreatives.filter(
      creative =>
        !campaign.adGroup.adGroupCreatives.find(c => c.name === creative.name)
    );
  }

  if (adGroup && adGroup.id) {
    return {
      update: {
        clickthrough: adGroup.clickthrough,
        adGroupCreatives: {
          create: newAdGroupCreatives.map(adGroupCreative => {
            return {
              name: adGroupCreative.name,
              clickthrough: adGroupCreative.clickthrough,
              startDate: adGroupCreative.startDate,
              endDate: adGroupCreative.endDate,
              medias: {
                create: adGroupCreative.medias.map(media => ({
                  fileType: media.fileType,
                  height: media.height,
                  key: media.key,
                  name: media.name,
                  size: media.size,
                  type: media.type,
                  url: media.url,
                  width: media.width,
                })),
              },
            };
          }),
          update: updatedAdGroupCreatives.map(adGroupCreative => {
            return {
              where: {
                id: adGroupCreative.id,
              },
              data: {
                startDate: adGroupCreative.startDate,
                endDate: adGroupCreative.endDate,
                clickthrough: adGroupCreative.clickthrough,
              },
            };
          }),
          delete: deletedAdGroupCreatives.map(adGroupCreative => ({
            id: adGroupCreative.id,
          })),
        },
      },
    };
  } else {
    return {
      create: {
        clickthrough: adGroup.clickthrough,
        adGroupCreatives: {
          create:
            adGroup.adGroupCreatives && adGroup.adGroupCreatives.length
              ? adGroup.adGroupCreatives.map(adGroupCreative => {
                  return {
                    name: adGroupCreative.name,
                    clickthrough: adGroupCreative.clickthrough,
                    startDate: adGroupCreative.startDate,
                    endDate: adGroupCreative.endDate,
                    medias: {
                      create: adGroupCreative.medias.map(media => ({
                        fileType: media.fileType,
                        height: media.height,
                        key: media.key,
                        mediaHasNotes: media.mediaHasNotes,
                        mediaNotes: media.mediaNotes,
                        name: media.name,
                        org: media.org,
                        size: media.size,
                        type: media.type,
                        url: media.url,
                        width: media.width,
                        sharedWith: {
                          connect: {
                            id: orgId,
                          },
                        },
                      })),
                    },
                  };
                })
              : [],
        },
      },
    };
  }
};

export const calculateImpressions = ({ cpm, budget }) => {
  return budget && cpm ? Math.ceil((Number(budget) / Number(cpm)) * 1000) : 0;
};

export const calculateImpressionsByPerecent = ({ cpm, budget, percent }) => {
  return budget && cpm
    ? Math.ceil((Number(budget) / Number(cpm)) * 1000 * percent)
    : 0;
};

export const buildLineItemUpdatePayload = ({
  lineItem,
  originalLineItems,
  selectedAdvertiser,
}) => {
  const {
    targetingRegions,
    targetingLocations,
    targetingCountries,
    audienceType,
  } = lineItem;
  let newTargetingRegions = [];
  let updatedTargetingRegions = [];
  let deletedTargetingRegions = [];
  let newTargetingLocations = [];
  let updatedTargetingLocations = [];
  let deletedTargetingLocations = [];
  let newTargetingCountries = [];
  let deletedTargetingCountries = [];

  switch (lineItem.targetingType) {
    case "REGION":
      newTargetingRegions = targetingRegions.filter(region => !region.id);
      updatedTargetingRegions = targetingRegions.filter(region => region.id);
      deletedTargetingRegions = originalLineItems
        .find(c => c.id === lineItem.id)
        .targetingRegions.filter(
          region => !targetingRegions.find(r => r.name === region.name)
        );

      // Delete all targeting locations
      deletedTargetingLocations = originalLineItems.find(
        c => c.id === lineItem.id
      ).targetingLocations;
      deletedTargetingCountries = originalLineItems.find(
        c => c.id === lineItem.id
      ).targetingCountries;

      break;
    case "LOCAL":
      newTargetingLocations = targetingLocations
        .filter(location => !location.id)
        .map(location => {
          return {
            ...location,
            geoTargets: location.geoTargets.map(target => {
              delete target.id;
              return target;
            }),
          };
        });
      updatedTargetingLocations = targetingLocations.filter(
        location => location.id
      );
      deletedTargetingLocations = originalLineItems
        .find(c => c.id === lineItem.id)
        .targetingLocations.filter(
          location => !targetingLocations.find(l => l.name === location.name)
        );

      // Delete all targeting regions
      deletedTargetingRegions = originalLineItems.find(
        c => c.id === lineItem.id
      ).targetingRegions;
      deletedTargetingCountries = originalLineItems.find(
        c => c.id === lineItem.id
      ).targetingCountries;
      break;

    case "COUNTRY":
      newTargetingCountries = [
        {
          name: countryMap[targetingCountries],
          code: targetingCountries,
        },
      ];

      // Delete all targeting locations
      deletedTargetingLocations = originalLineItems.find(
        c => c.id === lineItem.id
      ).targetingLocations;

      // Delete all targeting regions
      deletedTargetingRegions = originalLineItems.find(
        c => c.id === lineItem.id
      ).targetingRegions;
      // Delete all targeting countries
      deletedTargetingCountries = originalLineItems.find(
        c => c.id === lineItem.id
      ).targetingCountries;
      break;

    default:
      break;
  }

  // Handle audience type
  let newFirstPartyDataAudiences = [];
  let updatedFirstPartyDataAudiences = [];
  let deletedFirstPartyDataAudiences = [];

  switch (audienceType) {
    case "FIRST_PARTY_DATA":
      newFirstPartyDataAudiences = lineItem.firstPartyDataAudiences.filter(
        audience => !audience.id
      );
      updatedFirstPartyDataAudiences = lineItem.firstPartyDataAudiences.filter(
        audience => audience.id
      );
      deletedFirstPartyDataAudiences = originalLineItems
        .find(c => c.id === lineItem.id)
        .firstPartyDataAudiences.filter(
          audience =>
            !lineItem.firstPartyDataAudiences.find(
              a => a.name === audience.name
            )
        );

      break;
    case "ALL_USERS":
      // Delete all first party data audiences
      deletedFirstPartyDataAudiences = originalLineItems.find(
        c => c.id === lineItem.id
      ).firstPartyDataAudiences;

      break;
    default:
      break;
  }

  return {
    where: {
      id: lineItem.id,
    },
    data: {
      name: lineItem.name,
      startDate: lineItem.startDate,
      endDate: lineItem.endDate,
      budgetTotal: Number(lineItem.budgetTotal),
      cpm: Number(lineItem.cpm),
      // Handle Targeting
      targetingType: lineItem.targetingType,
      targetingRegions: {
        create: newTargetingRegions.map(region => ({
          name: region.name,
          regions: {
            set: region.regions.map(region => region),
          },
        })),
        update: updatedTargetingRegions.map(region => ({
          where: {
            id: region.id,
          },
          data: {
            name: region.name,
            regions: {
              set: region.regions.map(region => region),
            },
          },
        })),
        delete: deletedTargetingRegions.map(region => ({
          id: region.id,
        })),
      },
      targetingLocations: {
        create: newTargetingLocations.map(location => ({
          name: location.name,
          type: location.type,
          geoTargets: buildGeoTargetsPayload({
            targets: location.geoTargets,
            originalTargets: [],
            orgId: selectedAdvertiser.id,
          }),
        })),
        update: updatedTargetingLocations.map(location => ({
          where: {
            id: location.id,
          },
          data: {
            name: location.name,
            type: location.type,
            geoTargets: buildGeoTargetsPayload({
              targets: location.geoTargets,
              originalTargets: lineItem.targetingLocations.find(
                l => l.name === location.name
              ).geoTargets,
              orgId: selectedAdvertiser.id,
            }),
          },
        })),
        delete: deletedTargetingLocations.map(location => ({
          id: location.id,
        })),
      },
      targetingCountries: targetingCountries
        ? {
            create: newTargetingCountries.map(country => ({
              name: country.name,
              code: country.code,
            })),
            delete: deletedTargetingCountries.map(country => ({
              id: country.id,
            })),
          }
        : null,
      // Handle Audiences
      audienceType: lineItem.audienceType,
      firstPartyDataAudiences: {
        create: newFirstPartyDataAudiences.map(audience => ({
          name: audience.name,
          activated: audience.activated,
          tag: audience.tag,
          deviceCount: audience.deviceCount,
          matchRate: audience.matchRate,
          isProcessedByStatera: audience.isProcessedByStatera,
          dspSegmentIds: {
            set: audience.dspSegmentIds,
          },
        })),
        update: updatedFirstPartyDataAudiences.map(audience => ({
          where: {
            id: audience.id,
          },
          data: {
            name: audience.name,
            activated: audience.activated,
            tag: audience.tag,
            deviceCount: audience.deviceCount,
            matchRate: audience.matchRate,
            isProcessedByStatera: audience.isProcessedByStatera,
            dspSegmentIds: {
              set: audience.dspSegmentIds,
            },
          },
        })),
        delete: deletedFirstPartyDataAudiences.map(audience => ({
          id: audience.id,
        })),
      },
      // Handle Ad Group
      adGroup: lineItem.adGroup
        ? buildAdGroupPayload({
            adGroup: lineItem.adGroup,
            orgId: selectedAdvertiser.id,
            campaign: lineItem,
            originalLineItems: originalLineItems,
          })
        : null,
    },
  };
};

export const buildLineItemPayload = ({
  lineItem,
  originalLineItems,
  selectedAdvertiser,
}) => {
  const { targetingType } = lineItem;
  const adGroup = lineItem.adGroup;

  const newTargetingCountries = [];

  if (lineItem.targetingCountries) {
    newTargetingCountries.push({
      name: countryMap[lineItem.targetingCountries],
      code: lineItem.targetingCountries,
    });
  }

  return {
    name: lineItem.name,
    startDate: lineItem.startDate,
    endDate: lineItem.endDate,
    budgetTotal: Number(lineItem.budgetTotal),
    cpm: Number(lineItem.cpm),
    targetingType,
    targetingRegions: {
      create:
        targetingType === "REGION"
          ? lineItem.targetingRegions.map(region => ({
              name: region.name,
              regions: {
                set: region.regions.map(region => region),
              },
            }))
          : [],
    },
    targetingCountries: {
      create:
        targetingType === "COUNTRY"
          ? newTargetingCountries.map(country => ({
              name: country.name,
              code: country.code,
            }))
          : [],
    },
    targetingLocations: {
      create:
        targetingType === "LOCAL"
          ? lineItem.targetingLocations.map(location => ({
              name: location.name,
              type: location.type,
              geoTargets: buildGeoTargetsPayload({
                targets: location.geoTargets,
                originalTargets: [],
                orgId: selectedAdvertiser.id,
              }),
            }))
          : [],
    },
    audienceType: lineItem.audienceType,
    firstPartyDataAudiences: {
      create: lineItem.firstPartyDataAudiences.map(audience => ({
        name: audience.name,
        activated: audience.activated,
        tag: audience.tag,
        deviceCount: audience.deviceCount,
        matchRate: audience.matchRate,
        isProcessedByStatera: audience.isProcessedByStatera,
        dspSegmentIds: {
          set: audience.dspSegmentIds,
        },
      })),
    },
    adGroup: adGroup
      ? buildAdGroupPayload({
          adGroup,
          orgId: selectedAdvertiser.id,
          campaign: lineItem,
          originalLineItems: originalLineItems,
        })
      : null,
    adUnits: {
      create: [],
    },
  };
};

export const deleteProperties = ({ object, properties }) => {
  return transform(object, (result, value, key) => {
    if (!properties.includes(key)) {
      // Skip properties in the `properties` array
      // Recursively process nested objects or arrays
      result[key] = isObject(value)
        ? deleteProperties({ object: value, properties })
        : value;
    }
  });
};
