import React from "react";
import { Card, Col, Divider, message, Row, Space, Typography } from "antd";
import { CopyOutlined } from "@ant-design/icons";
const { Text, Paragraph } = Typography;
const DetailsCard = ({ details, type }) => {
  if (type === "impression") {
    details.supportMeasurements = [{ label: "Impressions" }];
  } else if (type === "publisher") {
    details.supportMeasurements = [{ label: "Page Views" }];
  }
  return (
    <Card title={<Text strong>TAG DETAILS</Text>}>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Space direction="vertical" size={0}>
            <Text type="secondary">Tag ID</Text>
            <Space direction="horizontal">
              <Text
                style={{
                  whiteSpace: "normal",
                  wordBreak: "break-word",
                  width: "100%",
                }}
              >
                {details.appId}
              </Text>
              <CopyOutlined
                onClick={() => {
                  navigator.clipboard.writeText(details.appId);
                  message.info("Tag ID copied to clipboard");
                }}
              />
            </Space>
          </Space>
        </Col>
        <Col span={12}>
          <Space direction="vertical" size={0}>
            <Text type="secondary">Advertiser</Text>
            <Text
              style={{
                whiteSpace: "normal",
                wordBreak: "break-word",
                width: "100%",
              }}
            >
              {details.advertiser}
            </Text>
          </Space>
        </Col>
        <Col span={24}>
          <Row align="top">
            {type === "measurement" ? (
              <Col xs={24} sm={12} md={4}>
                <Space direction="vertical" size={0}>
                  <Text type="secondary">Cart Platform</Text>
                  <Text
                    style={{
                      whiteSpace: "normal",
                      wordBreak: "break-word",
                      width: "100%",
                    }}
                  >
                    {details.cart}
                  </Text>
                </Space>
              </Col>
            ) : null}
            {type === "publisher" ? (
              <Col xs={24} sm={12} md={11}>
                <Space direction="vertical" size={0}>
                  <Text type="secondary">Domain</Text>
                  <Text
                    style={{
                      whiteSpace: "normal",
                      wordBreak: "break-word",
                      width: "100%",
                    }}
                  >
                    {details.publisherDomain}
                  </Text>
                </Space>
              </Col>
            ) : null}
            {type === "impression" ? (
              <Col xs={24} sm={12} md={11}>
                <Space direction="vertical" size={0}>
                  <Text type="secondary">Platform</Text>
                  <Text
                    style={{
                      whiteSpace: "normal",
                      wordBreak: "break-word",
                      width: "100%",
                    }}
                  >
                    {details.impressionEnvironments
                      ? details.impressionEnvironments
                      : details.dsp}
                  </Text>
                </Space>
              </Col>
            ) : null}
            <Col xs={0} sm={1} md={1}>
              <Divider type="vertical" style={{ height: "8em" }} />
            </Col>
            <Col xs={24} sm={11} md={10}>
              <Space direction="vertical">
                <Text type="secondary">Supported Measurements</Text>

                <Paragraph>
                  <ul>
                    {details.supportMeasurements.map(measurement => {
                      return (
                        <li key={measurement.label}>
                          <Text>{measurement.label}</Text>
                        </li>
                      );
                    })}
                  </ul>
                </Paragraph>
              </Space>
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  );
};

export default DetailsCard;
