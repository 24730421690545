import React from "react";
import AdvertiserDetailsSignupsLoader from "../../../../../core/components/advertiser/advertiserdetails/signups/AdvertiserDetailsSignupsLoader";
import AdvertiserDetailsSignupsController from "../../../../../core/components/advertiser/advertiserdetails/signups/AdvertiserDetailsSignupsController";
import DetailBar from "../components/DetailBar";
import { Col, Row } from "antd";
import DualAxisChart from "../../../../shared/charts/DualAxisChart";
import { summaryScale } from "../../utils/summaryScale";
import moment from "moment";
import SignUpsTable from "../components/SignupsTable";
import { LoadingPage } from "../../../../shared/LoadingPage";

const SignupsDetailsView = props => {
  return (
    <AdvertiserDetailsSignupsLoader {...props}>
      <AdvertiserDetailsSignupsController>
        <SignUpsTab />
      </AdvertiserDetailsSignupsController>
    </AdvertiserDetailsSignupsLoader>
  );
};

const SignUpsTab = ({
  advertiser,
  reportDataSummary,
  changeDatePicker,
  reportDataSummaryLoading,
}) => {
  const { byDates, signups, summary } = reportDataSummary;
  if (reportDataSummaryLoading) return <LoadingPage />;

  const dropDownOptions = [
    { key: "signups", display: "Sign Ups" },
    { key: "impressions", display: "Impressions" },
  ];

  return (
    <>
      <DetailBar
        {...summary}
        startDate={advertiser.startDate}
        endDate={advertiser.endDate}
        module="SIGNUPS"
      />
      <Row gutter={[16, 16]}>
        <Col xs={24}>
          <DualAxisChart
            byDate={byDates}
            xAxis="date"
            options={dropDownOptions}
            defaultLeftYValue={dropDownOptions[0]}
            defaultRightYValue={dropDownOptions[1]}
            customScale={summaryScale}
            onDatePickerChange={({ data, dates, rangeKey }) => {
              changeDatePicker(dates);
            }}
            has6MonDisables={false}
            allTimeDateRange={[
              moment(advertiser.startDate),
              moment(advertiser.endDate),
            ]}
          />
        </Col>
        <Col xs={24}>
          <SignUpsTable signUpsData={signups ? signups : []} />
        </Col>
      </Row>
    </>
  );
};

export default SignupsDetailsView;
