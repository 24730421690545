import { Button, Card, message, Table } from "antd";
import React from "react";

const TestLogsCard = ({
  logs,
  hasBasketItems,
  hasSignups,
  testTag,
  loading,
  type,
}) => {
  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
  ];

  if (type === "measurement") {
    columns.push({
      title: "Transactions",
      dataIndex: "transactions",
      key: "transactions",
    });

    columns.push({
      title: "Page Views",
      dataIndex: "pageViews",
      key: "pageViews",
    });

    columns.push({
      title: "Transaction Total",
      dataIndex: "total",
      key: "total",
    });

    if (hasBasketItems) {
      columns.push({
        title: "Basket Items",
        dataIndex: "basketItems",
        key: "basketItems",
      });
    }

    if (hasSignups) {
      columns.push({
        title: "Sign Ups",
        dataIndex: "signups",
        key: "signups",
      });
    }
  } else if (type === "publisher") {
    columns.push({
      title: "Page Views",
      dataIndex: "pageViews",
      key: "pageViews",
    });
  } else if (type === "impression") {
    columns.push({
      title: "Impressions",
      dataIndex: "pageViews",
      key: "pageViews",
    });
  }

  return (
    <Card
      title="TEST LOG"
      extra={
        <Button
          shape={"round"}
          loading={loading}
          type="primary"
          onClick={async () => {
            message.info("Testing Signal");
            await testTag();
          }}
        >
          Test Signal
        </Button>
      }
    >
      <Table
        dataSource={logs}
        columns={columns}
        scroll={{ x: "max-content" }}
      />
    </Card>
  );
};

export default TestLogsCard;
