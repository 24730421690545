import React, { useState, useEffect } from "react";
import { Modal, Button, Radio, message } from "antd";

const PaymentMethodModal = ({ visible, onClose, onSubmit, initialAmount }) => {
  const [selectedMethod, setSelectedMethod] = useState(null);
  const [totalAmount, setTotalAmount] = useState(initialAmount);

  useEffect(() => {
    let newTotal = initialAmount;
    if (selectedMethod === "creditCard") {
      newTotal += initialAmount * 0.035; // 3.5% surcharge
    }
    setTotalAmount(newTotal);
  }, [selectedMethod, initialAmount]);

  const handleRadioChange = e => {
    setSelectedMethod(e.target.value);
  };

  const handleSubmit = () => {
    if (selectedMethod) {
      onSubmit(selectedMethod);
      onClose();
    } else {
      message.error("Please select a payment method");
    }
  };

  return (
    <Modal
      title="Select Payment Method"
      visible={visible}
      onCancel={onClose}
      footer={[
        <Button key="cancel" onClick={onClose}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={handleSubmit}>
          Generate Invoice
        </Button>,
      ]}
    >
      <Radio.Group
        onChange={handleRadioChange}
        value={selectedMethod}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "left",
        }}
      >
        <Radio value="creditCard" style={{ marginBottom: "8px" }}>
          Credit Card (<strong>3.5%</strong> surcharge will be added to the
          total bill)
        </Radio>
        <Radio value="bank">Bank (AHC)</Radio>
      </Radio.Group>
      <div
        key="total"
        style={{
          flex: 1,
          textAlign: "right",
          paddingRight: "10px",
          fontSize: "18px",
          fontWeight: "bold",
        }}
      >
        <strong>Total:</strong> ${totalAmount.toFixed(2)}
      </div>
      ,
    </Modal>
  );
};

export default PaymentMethodModal;
