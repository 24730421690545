import React from "react";
import { Tabs } from "antd";
import TaskBoard from "./TaskBoard";
import { PageHeaderLayout, PageContentLayout } from "../../../shared/layout";
import { UnorderedListOutlined } from "@ant-design/icons";
import {
  withRouter,
  useLocation,
  useHistory,
  Switch,
  Route,
} from "react-router-dom";
import UnassignedTasksView from "./UnassignedTasksView";
import { HasAccess } from "@permify/react-role";

const { TabPane } = Tabs;

const Task = () => {
  const location = useLocation();
  const history = useHistory();

  const getActiveKey = () => {
    if (location.pathname.includes("/tasks/unassigned")) return "2";

    return "1";
  };

  const handleTabChange = key => {
    switch (key) {
      case "1":
        history.push("/tasks/main/1");
        break;
      case "2":
        history.push("/tasks/unassigned/1");
        break;
      default:
        history.push("/tasks/main/1"); // Default to Task Board
        break;
    }
  };

  return (
    <>
      <PageHeaderLayout
        title="Tasks"
        titleIcon={
          <UnorderedListOutlined
            style={{
              marginRight: "10px",
              fontSize: 20,
            }}
          />
        }
      />
      <PageContentLayout>
        <HasAccess roles={["PLATFORM"]}>
          <Tabs activeKey={getActiveKey()} onChange={handleTabChange}>
            <TabPane tab="Task Board" key="1" />
            <TabPane tab="Unassigned Tasks" key="2" />
          </Tabs>
          <Switch>
            <Route
              path="/tasks/unassigned/:pageNumber?"
              exact
              component={UnassignedTasksView}
            />
          </Switch>
        </HasAccess>
        <Switch>
          <Route path="/tasks/main/:pageNumber?" exact component={TaskBoard} />
        </Switch>
      </PageContentLayout>
    </>
  );
};

export default withRouter(Task);
