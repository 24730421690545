import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Divider,
  Dropdown,
  Form,
  Input,
  Menu,
  message,
  Select,
  Space,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { useCampaignRequestorContext } from "../../../../core/components/campaignRequestor/useCampaignRequestorContext";
import { useCampaignOrderQueueContext } from "../../../../core/components/campaigns/campaignordersqueue/useCampaignOrderQueueContext";
import CampaignOrderQueueDealIdsDialog from "./CampaignOrderQueueDealIdsDialog";
import { sspPartners } from "../../../../core/utils/constants/constants";
import "../../CampaignRequestor/styles.css";
import { getCampaignOrderErrorMessage } from "../../../../core/utils/constants/campaignOrderErrorType";

const { Text } = Typography;

export const CampaignOrderQueueReviewLaunchSummary = () => {
  const [showDealIdsDialog, setShowDealIdsDialog] = useState(false);
  const [sspOtions, setSspOptions] = useState([]);
  const [showDealIdsLookup, setShowDealIdsLookup] = useState(false);
  const [dspType, setDspType] = useState(null);
  const {
    updateCampaignOrder,
    loadingUpdateCampaignOrder,
    currentCampaignOrder,
  } = useCampaignRequestorContext();
  const {
    launchCampaignOrderProgrammatic,
    loadingLaunchCampaingOrderProgrammatic,
  } = useCampaignOrderQueueContext();

  const history = useHistory();
  const [form] = Form.useForm();

  const handleRemoveDealId = data => {
    const dealIds = form.getFieldValue("dealIds");
    const newDealIds = dealIds.filter(d => d.id !== data.id);
    form.setFieldsValue({ dealIds: newDealIds });
  };

  const handleLaunchManual = async () => {
    // Validate form
    try {
      await form.validateFields();
    } catch (error) {
      message.error(error.errorFields[0].errors[0]);
      return;
    }

    try {
      const values = await form.validateFields();
      const campaignIds = currentCampaignOrder.campaigns.map(
        campaign => campaign.id
      );
      message.loading("Launching campaign...", 0);
      await updateCampaignOrder({
        variables: {
          where: { id: currentCampaignOrder.id },
          data: {
            status: "LIVE",
            campaigns: {
              update: campaignIds.map(campaignId => ({
                where: { id: campaignId },
                data: {
                  demandSidePlatform: values.dspType,
                },
              })),
            },
            dspConfig: {
              dspType: values.dspType,
              dealIds: values.dealIds,
              frequencyCap: values.frequencyCap,
              maxBidCPM: values.maxBidCPM,
              sspType: values.sspType,
            },
          },
        },
      });
      message.destroy();
      message.success("Campaign launched successfully");
      // Redirect to launched campaigns
      history.push("/campaigns/v2/orderqueue/launched");
    } catch (error) {
      message.destroy();
      message.error("Failed to launch campaign");
    }
  };

  const handleLaunchProgrammatic = async () => {
    // Validate form
    try {
      await form.validateFields();
    } catch (error) {
      message.error(error.errorFields[0].errors[0]);
      return;
    }

    try {
      const values = await form.getFieldsValue();
      message.loading(
        "Launching campaign this may take a while, please refresh the page after a few minutes...",
        5
      );

      // TODO: Comment for the meantime LIQUIDM is not yet supported and move the logic on the backend
      // const clientTimezone = Intl.DateTimeFormat()
      //   .resolvedOptions()
      //   .timeZone.includes("America/")
      //   ? Intl.DateTimeFormat().resolvedOptions().timeZone
      //   : TimezonesConfig.default;

      // If DSP is equals to liquidm, then add clientTimezone to values
      // if (values.dspType === "LIQUIDM") {
      //   const { budget, cpm, startDate, endDate } = currentCampaignOrder;
      //   const differenceInDays = moment(endDate).diff(
      //     moment(startDate),
      //     "days"
      //   );
      //   const overall_units =
      //     budget && cpm ? Math.ceil((budget / cpm) * 1000) : 0;
      //   let daily_units = Math.ceil(overall_units / differenceInDays) || 0;

      //   values.clientTimezone = clientTimezone;
      //   values.budget = {
      //     overall_units,
      //     daily_units,
      //     threshold_units: 0,
      //     overall_units_pacing: "none",
      //     overall_cents_pacing: "none",
      //     daily_units_pacing: "optimized",
      //     daily_cents_pacing: "optimized",
      //     overall_cents: null,
      //   };
      // }

      launchCampaignOrderProgrammatic({
        variables: {
          data: {
            id: currentCampaignOrder.id,
            dspConfig: values,
          },
        },
      });
      // Redirect to launched campaigns
      history.push("/campaigns/v2/orderqueue/ready-to-launched");
    } catch (error) {
      message.destroy();
      message.error(getCampaignOrderErrorMessage(error));
    }
  };

  const getStatus = () => {
    if (!currentCampaignOrder) return null;

    if (
      currentCampaignOrder.dspConfig &&
      currentCampaignOrder.dspConfig.status
    ) {
      return currentCampaignOrder.dspConfig.status;
    }

    return currentCampaignOrder.status;
  };

  const displayStatus = () => {
    if (!currentCampaignOrder) return;
    const status = getStatus();

    if (status) {
      switch (status) {
        case "LAUNCHING":
          return <Tag color="orange">Launching</Tag>;
        case "ERROR":
          return (
            <Tooltip title={currentCampaignOrder.dspConfig.error}>
              <Tag color="red">Error</Tag>
            </Tooltip>
          );

        default:
          break;
      }
    }

    if (status === "APPROVED") {
      return "Pending Launch";
    } else if (status === "LIVE_APPROVED") {
      return "Pending Update";
    }
  };

  useEffect(() => {
    if (currentCampaignOrder && currentCampaignOrder.dspConfig) {
      const dealIds = currentCampaignOrder.dspConfig.dealIds;
      if (dealIds && dealIds.length > 0) {
        setShowDealIdsLookup(true);
      }

      form.setFieldsValue({
        dspType: currentCampaignOrder.dspConfig.dspType,
        sspType: currentCampaignOrder.dspConfig.sspType,
        maxBidCPM: currentCampaignOrder.dspConfig.maxBidCPM,
        frequencyCap: currentCampaignOrder.dspConfig.frequencyCap,
        dealIds,
      });
      setDspType(currentCampaignOrder.dspConfig.dspType || null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCampaignOrder]);

  useEffect(() => {
    setSspOptions(
      sspPartners.map(value => ({
        value,
        label: value,
      }))
    );
  }, []);
  return (
    <>
      <Card title="LAUNCH DETAILS">
        <Form form={form} layout="vertical">
          <Space direction="vertical" size="small" style={{ width: "100%" }}>
            <Text>Campaign Status</Text>
            <Text type="secondary">{displayStatus()}</Text>
          </Space>

          <Divider />
          <Space direction="vertical" size={2} style={{ width: "100%" }}>
            <Text>DSP</Text>
            <Text type="secondary">
              <small> Demand-Side Platform </small>
            </Text>
            <Form.Item
              name="dspType"
              rules={[
                {
                  required: true,
                  message: "Please select a DSP",
                },
              ]}
            >
              <Select
                style={{ width: "100%" }}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                onChange={value => {
                  setDspType(value);
                }}
                options={[
                  { value: "NEXXEN", label: "NEXXEN" },
                  { value: "LIQUIDM", label: "LIQUIDM" },
                  { value: "JAMLOOP", label: "JAMLOOP" },
                ]}
              />
            </Form.Item>
            <Text>SSP</Text>
            <Text type="secondary">
              <small> Supply-Side Platform </small>
            </Text>
            <Form.Item
              name="sspType"
              rules={[
                {
                  required: true,
                  message: "Please select a SSP",
                },
              ]}
            >
              <Select
                style={{ width: "100%" }}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                options={sspOtions}
              />
            </Form.Item>
            <Text>Maximum Bid</Text>
            <Text type="secondary">
              <small> Max bid per CPM </small>
            </Text>
            <Form.Item
              name="maxBidCPM"
              rules={[
                {
                  required: true,
                  message: "Please input a maximum bid",
                },
              ]}
            >
              <Input
                addonBefore="$USD"
                type="number"
                style={{ width: "75%" }}
              />
            </Form.Item>
            <Text>Frequency Cap</Text>
            <Text type="secondary">
              <small> Max daily impressions per device </small>
            </Text>
            <Form.Item
              name="frequencyCap"
              rules={[
                { required: true, message: "Please input a frequency cap" },
              ]}
            >
              <Input type="number" style={{ width: "75%" }} />
            </Form.Item>
            <Text>Deal IDs</Text>
            <Text type="secondary">
              <small> Optional: Find Deal IDs to use for this campaign </small>
            </Text>
            {showDealIdsLookup && (
              <Space
                direction="vertical"
                size="small"
                style={{ width: "100%", marginBottom: 4 }}
              >
                {form.getFieldValue("dealIds").map((data, index) => (
                  <Tag
                    closable
                    onClose={() => handleRemoveDealId(data)}
                    key={index}
                    style={{
                      width: "100%",
                      position: "relative",
                    }}
                    className="deal-id-tag"
                  >
                    <span
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        padding: "4px 8px",
                        width: "100%",
                      }}
                    >
                      <span
                        style={{
                          fontSize: 12,
                          fontWeight: 600,
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {data.name}
                      </span>
                      <span
                        style={{
                          fontSize: 12,
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {data.dealId}
                      </span>
                    </span>
                  </Tag>
                ))}
              </Space>
            )}
            <Button
              style={{ width: "100%" }}
              onClick={() => setShowDealIdsDialog(true)}
            >
              Deal ID Lookup
            </Button>
            <Divider />
            {(dspType !== "NEXXEN" || !dspType) && (
              <Button
                style={{ width: "100%" }}
                type="primary"
                disabled={loadingUpdateCampaignOrder || !dspType}
                onClick={() => handleLaunchManual()}
              >
                Launch
              </Button>
            )}
            {dspType && dspType === "NEXXEN" && getStatus() !== "LAUNCHING" && (
              <Dropdown
                trigger={["click"]}
                overlay={
                  <Menu>
                    <Menu.Item onClick={() => handleLaunchManual()}>
                      <span>Manual</span>
                    </Menu.Item>
                    <Menu.Item onClick={() => handleLaunchProgrammatic()}>
                      <span>Programmatic</span>
                    </Menu.Item>
                  </Menu>
                }
              >
                <Button
                  style={{ width: "100%" }}
                  type="primary"
                  disabled={
                    loadingUpdateCampaignOrder ||
                    loadingLaunchCampaingOrderProgrammatic
                  }
                >
                  Launch
                </Button>
              </Dropdown>
            )}
          </Space>

          {/* Create hidden Input for dealIds */}
          <Form.Item name="dealIds">
            <Input type="hidden" />
          </Form.Item>
        </Form>
      </Card>

      {showDealIdsDialog && (
        <CampaignOrderQueueDealIdsDialog
          visible={showDealIdsDialog}
          setVisible={setShowDealIdsDialog}
          selectedDealIds={form.getFieldValue("dealIds")}
          onAddSelections={dealIds => {
            form.setFieldsValue({ dealIds });
            setShowDealIdsLookup(true);
          }}
        />
      )}
    </>
  );
};
