import gql from "graphql-tag";

const GET_TASK = gql`
  query task($where: TaskWhereUniqueInput!) {
    task(where: $where) {
      id
      name
      dueDate

      recurring
      recurrenceType

      # Day of the week for weekly tasks
      weeklyDays

      # Day of the month for monthly tasks
      monthlyType
      dayOfMonth
      dayOfWeek

      #Date of the month for monthly tasks
      dateOfMonth

      # Month of the year for yearly tasks
      yearlyDate
      yearlyMonth

      requestType
      requestSubType
      createdBy {
        id
        name
        username
        avatar {
          id
          key
        }
      }
      createdAt
      recurring
      assigneeOrg {
        id
        name
      }

      assigneeUser {
        id
        name
        username
        avatar {
          id
          key
        }
      }
      assigneeUsers {
        id
        name
        username
        avatar {
          id
          key
        }
      }
      channelPartner {
        id
        name
      }

      advertiser {
        id
        name
      }

      taskDetails

      attachedFiles {
        id
        name
        url
        key
      }
      attachedImages {
        id
        name
        url
        key
      }
      codeSnippet
      status
      relatedCampaigns {
        id
        name
        startDate
        endDate
      }
      attachedComments {
        id
        comment
        createdAt
        createdBy {
          id
          name
          username
          avatar {
            key
          }
        }
        attachedFiles {
          id
          name
          url
          key
        }
        attachedImages {
          id
          name
          url
          key
        }
        codeSnippet
      }
      linkUrl
      urgency
    }
  }
`;

export default GET_TASK;
