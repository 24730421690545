import React, { useCallback, useEffect, useState } from "react";
import {
  Card,
  Space,
  Table,
  Tag,
  Typography,
  Row,
  Col,
  Popover,
  Button,
  message,
} from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import moment from "moment";
import { isEmpty } from "lodash";

import {
  formatCurrency,
  formatNumber2SigFig,
  formatWholeNumber,
  getCTRFromAggregate,
} from "../../../../core/utils/campaigns";
import MiniChart from "../../../shared/MiniChart";
import { useCampaignOrderQueueContext } from "../../../../core/components/campaigns/campaignordersqueue/useCampaignOrderQueueContext";
import { useCampaignRequestorContext } from "../../../../core/components/campaignRequestor/useCampaignRequestorContext";
import CampaignOrderQueueFilters from "./CampaignOrderQueueFilters";

const { Text } = Typography;

const DeletePopover = ({ record, handleDelete }) => {
  const [popoverVisible, setPopoverVisible] = useState(false);

  const handleClose = () => {
    setPopoverVisible(false);
  };

  return (
    <Popover
      content={
        <div>
          <p>Are you sure you want to delete this campaign?</p>
          <Space style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              type="primary"
              onClick={() => {
                handleDelete(record.id);
                handleClose(); // Close the popover after deletion
              }}
            >
              Yes
            </Button>
            <Button onClick={handleClose}>No</Button>
          </Space>
        </div>
      }
      title="Confirm Action"
      trigger="click"
      visible={popoverVisible}
      onVisibleChange={visible => setPopoverVisible(visible)}
    >
      <DeleteOutlined style={{ color: "#ff4d4f", cursor: "pointer" }} />
    </Popover>
  );
};

export const CampaignOrderDraft = () => {
  const {
    campaignOrders,
    loadingCampaignOrdersQueue,
    fetchCampaignOrdersQueue,
    pagination,
    setPagination,
    deleteCampaignOrder,
  } = useCampaignOrderQueueContext();
  const { currentUserOrg } = useCampaignRequestorContext();

  const [filteredOrgId, setFilteredOrgId] = useState(null);

  const handleFetchCampaignOrdersQueue = useCallback(
    ({ skip, first, name = "", orgId, sortField, sortOrder }) => {
      fetchCampaignOrdersQueue({
        variables: {
          where: {
            AND: [
              {
                status_in: ["DRAFT"],
                name_contains: name,
                orgs_some: {
                  OR: [{ id: orgId }, { parentOrg_some: { id: orgId } }],
                },
              },
            ],
          },
          orderBy:
            sortField && sortOrder
              ? `${sortField}_${sortOrder.toUpperCase()}`
              : "updatedAt_DESC",
          skip,
          first,
        },
      });
    }
  );

  const handleTableChange = ({ current, pageSize }, filters, sorter) => {
    const sortField = sorter.field;
    const sortOrder =
      sorter.order === "ascend"
        ? "asc"
        : sorter.order === "descend"
        ? "desc"
        : null;

    setPagination(prev => ({ ...prev, current, pageSize }));
    handleFetchCampaignOrdersQueue({
      skip: (current - 1) * pageSize || 0,
      first: pageSize,
      orgId: filteredOrgId || currentUserOrg.id,
      sortField,
      sortOrder,
    });
  };

  const handleDelete = async id => {
    // Add your delete logic here
    try {
      message.loading({ content: "Deleting campaign order..." });
      await deleteCampaignOrder({
        variables: {
          campaignOrderId: id,
        },
      });
      handleFetchCampaignOrdersQueue({
        skip: 0,
        first: pagination.pageSize,
        orgId: filteredOrgId || currentUserOrg.id,
      });
      message.destroy();
      message.success("Campaign order deleted successfully");
    } catch (error) {
      message.destroy();
      message.error("Failed to delete campaign order");
    }
  };

  useEffect(() => {
    if (currentUserOrg) {
      setFilteredOrgId(currentUserOrg.id);
      handleFetchCampaignOrdersQueue({
        skip: 0,
        first: pagination.pageSize,
        orgId: currentUserOrg.id,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUserOrg]);

  return (
    <Card
      title="DRAFT CAMPAIGNS"
      extra={
        <CampaignOrderQueueFilters
          handleFilterChanged={handleFetchCampaignOrdersQueue}
          setFilteredOrgId={setFilteredOrgId}
          filteredOrgId={filteredOrgId}
        />
      }
    >
      <Table
        columns={[
          {
            title: "Campaign Details",
            dataIndex: "name",
            key: "name",
            sorter: true,
            render: (text, record) => (
              <Space size={4} direction="vertical">
                <Link to={`/campaigns/v2/orderqueue/details/${record.id}`}>
                  {text}
                </Link>
                <Space size={4}>
                  <Tag color="blue">
                    {(record.advertiser && record.advertiser.name) || ""}
                  </Tag>
                </Space>
              </Space>
            ),
          },
          {
            title: "Days Remaining",
            dataIndex: "daysRemaining",
            key: "daysRemaining",
            render: (text, record) => {
              const endDate = new Date(record.endDate);
              const today = new Date();
              if (endDate < today) {
                return <Text>0</Text>;
              }
              const diffTime = Math.abs(endDate - today);
              const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
              return <Text>{diffDays.toString()}</Text>;
            },
          },
          {
            title: "Start Date",
            dataIndex: "startDate",
            key: "startDate",
            sorter: true,
            render: text => moment(text).format("MM/DD/YYYY"),
          },
          {
            title: "End Date",
            key: "endDate",
            dataIndex: "endDate",
            sorter: true,
            render: text => moment(text).format("MM/DD/YYYY"),
          },
          {
            title: "Impressions",
            key: "impressions",
            dataIndex: "impressions",
            render: (text, record) => (
              <MiniChart
                data={
                  record.aggregateData && record.aggregateData.byDate
                    ? record.aggregateData.byDate
                    : []
                }
                yAxisLeft="impressions"
              />
            ),
          },
          {
            title: "Performance",
            key: "performance",
            dataIndex: "performance",
            render: (text, record) => {
              const overAllData =
                record &&
                record.aggregateData &&
                record.aggregateData.overallData;
              return (
                <Space size={2} direction="vertical">
                  <Text>Impressions</Text>
                  <Text type="secondary">
                    {overAllData
                      ? parseInt(overAllData.aggImpressions).toFixed(0)
                      : 0}
                  </Text>
                  <Text>Clicks</Text>
                  <Text type="secondary">
                    {overAllData
                      ? parseInt(overAllData.aggClicks).toFixed(0)
                      : 0}
                  </Text>
                  <Text>
                    CTR:{" "}
                    <Text type="secondary">
                      {getCTRFromAggregate(overAllData ? overAllData : 0)}%
                    </Text>
                  </Text>
                </Space>
              );
            },
          },
          {
            title: "Pacing",
            key: "pacing",
            dataIndex: "pacing",
            render: (text, record) => {
              const {
                cpm,
                budget,
                startDate,
                endDate,
                aggregateData,
                pacingData,
              } = record;
              const chartData = aggregateData && aggregateData.byDate;
              if (!budget || !cpm) {
                return (
                  <Space size={2} direction="vertical">
                    <Text> N/A </Text>
                  </Space>
                );
              }

              let impressionsSpent = 0;
              if (aggregateData && aggregateData.overallData) {
                impressionsSpent = aggregateData.overallData.aggImpressions;
              }

              let daysPast = 0;
              let dailyImpressionsGoal = 0;
              let impressionGoal = 0;
              if (cpm && budget && budget !== 1) {
                impressionGoal = (budget / cpm) * 1000;
              }

              const currentDate = moment()
                .utc() //Convert to UTC
                .startOf("D") //Start of UTC date
                .format(`YYYY-MM-DD`);

              const impressionsleft = impressionGoal - impressionsSpent;

              let todaysImpressions = 0;
              let impressionsUpToToday = 0;
              if (chartData && !isEmpty(chartData)) {
                //Today is in progress - So no need to count today
                chartData.forEach(chart => {
                  const { xAxis, impressions } = chart;
                  const chartDate = moment(xAxis)
                    .utc()
                    .format(`YYYY-MM-DD`);

                  if (chartDate !== currentDate) {
                    impressionsUpToToday = impressionsUpToToday + impressions; //total of impressions up to today
                    daysPast = daysPast + 1;
                  } else {
                    todaysImpressions = impressions;
                  }
                });
              }

              const b = moment(startDate).utc();
              const a = moment(endDate).utc();
              let dayAdjust = 1;
              if (a <= currentDate) {
                //campaign is over
                dayAdjust = 0;
              }

              const daysOfFlight = a.diff(b, "days");
              const daysLeft = daysOfFlight - daysPast + dayAdjust; //include today with date adjust

              if (daysLeft && impressionsleft) {
                dailyImpressionsGoal = impressionsleft / daysLeft;
              } else dailyImpressionsGoal = 0;

              let textColor = "#ff4d4f"; // danger
              if (pacingData && pacingData.pace) {
                switch (true) {
                  case pacingData.pace >= 95:
                    textColor = "#52c41a"; // success
                    break;
                  case pacingData.pace >= 91 && pacingData.pace <= 95:
                    textColor = "#faad14"; // warning
                    break;
                  default:
                    break;
                }
              }

              return (
                <Space size={2} direction="vertical">
                  <Text>
                    Pace:{" "}
                    <Text type="danger">
                      <span style={{ color: textColor }}>
                        {pacingData && pacingData.pace !== null
                          ? formatNumber2SigFig(pacingData.pace)
                          : 0}
                        %
                      </span>
                    </Text>
                  </Text>
                  <Text>
                    Overage:{" "}
                    <Text type="secondary">
                      {`${
                        impressionsleft < 0 ? "Overage: " : "Remaining: "
                      }${formatWholeNumber(impressionsleft)}`}
                    </Text>
                  </Text>
                  <Text>
                    Daily:{" "}
                    <Text type="secondary">
                      {dailyImpressionsGoal
                        ? `Daily: ${formatWholeNumber(dailyImpressionsGoal)}`
                        : "0"}
                    </Text>
                  </Text>
                  <Text>
                    Today:{" "}
                    <Text type="secondary">
                      {todaysImpressions
                        ? `${formatWholeNumber(todaysImpressions)}`
                        : "0"}
                    </Text>
                  </Text>
                </Space>
              );
            },
          },
          {
            title: "Sales",
            key: "sales",
            dataIndex: "sales",
            render: (text, record) => {
              const { aggregateData, cpm } = record;
              let impressions = 0;
              try {
                impressions = aggregateData.overallData.aggImpressions;
              } catch (e) {
                impressions = 0;
              }
              const budgetSpent = cpm * (impressions / 1000);

              return (
                <Row type="flex" justify="start" align="middle">
                  <Col style={{ minWidth: "50px" }}>
                    {!isEmpty(aggregateData) ? (
                      <>
                        <Row type="flex">
                          <Text>{`Orders:‏‏‎ ‎`}</Text>
                          <Text type="secondary">
                            {aggregateData &&
                            aggregateData.overallData &&
                            aggregateData.overallData.aggEvents &&
                            aggregateData.overallData.aggEvents
                              .transactionsTotalCount
                              ? aggregateData.overallData.aggEvents
                                  .transactionsTotalCount
                              : 0}
                          </Text>
                        </Row>
                        <Row type="flex">
                          <Text>{`Rev:‏‏‎ ‎`}</Text>
                          <Text type="secondary">
                            {formatCurrency(
                              aggregateData &&
                                aggregateData.overallData &&
                                aggregateData.overallData.aggEvents &&
                                aggregateData.overallData.aggEvents
                                  .transactionTotalRevenue
                                ? parseFloat(
                                    aggregateData.overallData.aggEvents
                                      .transactionTotalRevenue
                                  )
                                : 0
                            )}
                          </Text>
                        </Row>
                        <Row type="flex">
                          <Text>{`ROAS:‏‏‎ ‎`}</Text>
                          <Text type="secondary">
                            {aggregateData.overallData.aggEvents
                              .transactionTotalRevenue > 0
                              ? budgetSpent && budgetSpent > 0
                                ? (
                                    parseFloat(
                                      aggregateData.overallData.aggEvents
                                        .transactionTotalRevenue / budgetSpent
                                    ) * 100
                                  ).toFixed(2) + "%"
                                : "N/A"
                              : "N/A"}
                          </Text>
                        </Row>
                      </>
                    ) : (
                      <Text> - </Text>
                    )}
                  </Col>
                </Row>
              );
            },
          },
          {
            title: "",
            key: "actions",
            render: (text, record) => {
              return (
                <Space size="middle">
                  <DeletePopover record={record} handleDelete={handleDelete} />
                </Space>
              );
            },
          },
        ]}
        dataSource={campaignOrders}
        loading={loadingCampaignOrdersQueue}
        pagination={pagination}
        onChange={handleTableChange}
      />
    </Card>
  );
};
