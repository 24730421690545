import gql from "graphql-tag";

const CREATE_TASK = gql`
  mutation createTask($data: TaskCreateInput!) {
    createTask(data: $data) {
      id
      name
      createdBy {
        id
        name
        avatar {
          id
          key
        }
      }
      dueDate
      assigneeUser {
        id
        name
      }
      assigneeUsers {
        id
        name
      }
      requestType
      requestSubType
      status
      channelPartner {
        id
        name
      }
      advertiser {
        id
        name
      }
      attachedFiles {
        id
        name
        url
      }
      attachedImages {
        id
        name
        url
      }
      taskDetails
      relatedCampaigns {
        id
        name
        startDate
        endDate
      }
      createdAt
      linkUrl
      urgency
    }
  }
`;

export default CREATE_TASK;
