import React from "react";
import { useMutation, useQuery } from "react-apollo";
import { withRouter } from "react-router-dom";
import GET_CART_PROVIDERS from "../../../GraphQl/Queries/GET_CART_PROVIDER";
import LIST_ORGS_SHARING from "../../../GraphQl/Queries/LIST_ORGS_SHARING";
import { LoadingPage } from "../../../../platform/shared/LoadingPage";
import UPDATE_EVENT_TAG from "../../../GraphQl/Mutations/UPDATE_EVENT_TAG";
import GET_CARTS from "../../../GraphQl/Queries/GET_CARTS";
import EVENTS_TARGET from "../../../GraphQl/Queries/EVENTS_TARGET";
import GET_DSP from "../../../GraphQl/Queries/GET_DSP";
import UPDATE_IMPRESSION_TAG from "../../../GraphQl/Mutations/UPDATE_IMPRESSION_TAG";

//This loader handles all reusable queries for SEO
const TagsManagerLoader = props => {
  const { currentUser, history, match, location } = props;
  const id = match.params.id;
  const currentOrgId = currentUser.role.org.id;

  const searchParams = new URLSearchParams(location.search);
  const source = searchParams.get("source");

  const { data, loading, error } = useQuery(EVENTS_TARGET, {
    variables: {
      where: {
        id,
      },
    },
    fetchPolicy: "no-cache",
  });
  const { data: cartProvidersData } = useQuery(GET_CART_PROVIDERS);

  const { data: orgs, loading: orgsLoading } = useQuery(LIST_ORGS_SHARING, {
    variables: {
      parentid: currentOrgId,
    },
  });

  const { data: carts, loading: cartsLoading } = useQuery(GET_CARTS);

  const [updateEventTag] = useMutation(UPDATE_EVENT_TAG, {
    onCompleted: values => {
      history.push(
        `/tags/details/${values.updateEventTag.id}?source=${source}`
      );
    },
  });

  const [updateImpressionTag] = useMutation(UPDATE_IMPRESSION_TAG, {
    onCompleted: values => {
      history.push(
        `/tags/details/${values.updateImpressionsTag.id}?source=${source}`
      );
    },
  });

  const { data: platforms, loading: dspLoading } = useQuery(GET_DSP);

  if (orgsLoading || cartsLoading || loading || dspLoading)
    return <LoadingPage />;
  return React.cloneElement(props.children, {
    ...props.children.props,
    cartProvidersData,
    orgs,
    currentOrgId,
    carts,
    data,
    loading,
    error,
    updateEventTag,
    updateImpressionTag,
    platforms,
  });
};

export default withRouter(TagsManagerLoader);
