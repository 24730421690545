export const cardBodyStyle = { padding: "0px", height: "650px" };
export const categoryList = {
  titleFontStyle: { fontSize: "17px", fontWeight: "bold" },
  listItemStyle: { padding: "21px 18px 21px 18px" },
  selectedFontStyle: { color: "black", fontSize: "17px" },
  selectedStyle: {
    backgroundColor: "#e6f7ff",
    borderRight: "3px solid #1890ff",
  },
};
export const subCategoryList = {
  listItemStyle: {
    padding: "20 0px 1px 20px",
    border: "0",
  },
  titleFontStyle: { fontSize: "15px" },
  selectedStyle: {
    backgroundColor: "#e6f7ff",
    borderRight: "3px solid #1890ff",
  },
  selectedFontStyle: { color: "black", fontSize: "14px" },
};
export const searchResultList = {
  selectedFontStyle: {
    color: "black",
    fontSize: "14px",
  },
  titleFontStyle: { fontSize: "15px" },
  selectedStyle: {
    backgroundColor: "#e6f7ff",
    borderRight: "3px solid #1890ff",
  },
  listItemStyle: { padding: "20 0px 1px 20px", border: "0" },
};
export const sideBarCardStyle = { height: "100%", minHeight: "80vh" };
export const gaugeStyle = {
  height: "140px",
  marginBottom: "1em",
  position: "relative", // Required for z-index to work
  zIndex: 1,
};
export const sideBarTitleStyle = {
  marginBottom: "5px",
};

export const detailsDivStyle = {
  width: "175px",
  whiteSpace: "wrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
};

export const combinedCardStyle = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-end",
  border: "1px solid black",
  borderLeft: "5px solid #0080ff",
  borderRadius: "6px",
  marginBottom: "0.7em",
  backgroundColor: "#fff",
};

export const combinedCardIndexStyle = {
  textAlign: "center",
  fontWeight: "bold",
  color: "rgba(0, 0, 0, 0.65)",
};

export const tagDemographAudienceTable = {
  width: 200,
  whiteSpace: "nowrap",
  overflow: "auto",
};

export const filterBarRowStyle = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
};

export const filterBarColStyle = {
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  width: "100%",
};

export const searchResultsViewStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginBottom: "1em",
};

export const selectedMetricPanelStyle = {
  cursor: "pointer",
  border: `1px solid black`,
  marginBottom: "0.7em",
  borderRadius: "6px",
  borderLeft: `5px solid #1890ff`,
};
