import gql from "graphql-tag";

const ADVERTISER = gql`
  query advertiser($id: ID!) {
    signupReports: advertiser(where: { id: $id }) {
      id
      reports(
        where: { type: ADVERTISER_SIGNUPS }
        orderBy: createdAt_DESC
        first: 1
      ) {
        id
        key
        type
      }
    }
    advertiser(where: { id: $id }) {
      id
      name
      startDate
      endDate
      budget
      cpm
      transactions
      customers
      revenue
      spend
      roas
      totalImpressions
      creatives {
        id
        creativeID
        name
        url
      }
      reports(
        where: { type: ADVERTISER_TRANSACTIONS }
        orderBy: createdAt_DESC
        first: 1
      ) {
        id
        key
        type
      }
      impressionsTag {
        id
        name
        dsp
        orgs(first: 1) {
          id
        }
      }
      transactionsTag {
        id
        orgs {
          id
        }
      }
      transactionsTags {
        id
        trackMesurements
        eventsTarget {
          id
          name
          orgs {
            id
            name
            website
          }
          eventTags {
            id
            appId
          }
          pageViews
          signups
          transactions
          basketItems
          isAdvertiser
        }
      }
      lineItemIds
      insertionOrderIds
      locations {
        id
        name
        description
        street
        city
        state
        province
        lat
        lng
        formattedAddress
        email
        locationKey
        phone
        contact
        url
        zip
        country
        country
        catalogs {
          id
          name
        }
        hours
        createdAt
        updatedAt
        org {
          id
          name
        }
        geoframe {
          id
          name
          start
          end
          polygons {
            id
            geometry
          }
          circles {
            id
            coordinate {
              lat
              lng
            }
            radius
          }
        }
        transConfig {
          id
          cart
        }
        audienceType
        createdBy {
          name
        }
        updatedBy {
          name
        }
      }
      campaigns {
        id
        name
        insertionOrderId
        lineItemIds
        advertiserId
        impressionsTag {
          id
          name
          dsp
          orgs(first: 1) {
            id
          }
        }
      }
    }
  }
`;

export default ADVERTISER;
