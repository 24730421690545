import gql from "graphql-tag";

const GET_CAMPAIGN_ORDERS_QUEUE = gql`
  query campaignOrdersQueue(
    $where: CampaignOrderWhereInput!
    $first: Int
    $skip: Int
    $after: String
    $before: String
    $orderBy: CampaignOrderOrderByInput
    $last: Int
  ) {
    campaignOrdersConnection: campaignOrdersConnection(
      where: $where
      orderBy: $orderBy
      skip: $skip
      after: $after
      before: $before
      first: $first
      last: $last
    ) {
      pageInfo {
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          name
          status
          startDate
          endDate
          budget
          cpm
          aggregateData {
            id
            overallData {
              id
              aggImpressions
              aggClicks
              aggEvents
            }
            byDate
          }
          pacingData {
            id
            pace
            impressionsLeft
            daysLeft
          }
          updatedAt
          advertiser {
            id
            name
          }
          dspConfig
        }
      }
      aggregate {
        count
      }
    }

    full: campaignOrdersConnection(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

export default GET_CAMPAIGN_ORDERS_QUEUE;
