import React, { useEffect } from "react";
import { useState } from "react";
import {
  Modal,
  Row,
  Col,
  Checkbox,
  Button,
  DatePicker,
  Form,
  Select,
  Radio,
  Space,
  Input,
} from "antd";
import { Option } from "antd/lib/mentions";
import "../styles/DatePickerStyles.css";
import moment from "moment";
import { getNumberWithSuffix } from "../../../../platform/ux/Tasks/utils/task.utils";

export const TaskDatePickerModal = ({
  visible,
  setVisible,
  onClose,
  currentDueDateConfig,
}) => {
  const [isRecurring, setIsRecurring] = useState(false);
  const [recurrenceType, setRecurrenceType] = useState("");
  const [monthlyType, setMonthlyType] = useState("DAY_OF_MONTH");

  const [form] = Form.useForm();

  const handleDateOk = () => {
    setVisible(false);
    onClose(form.getFieldsValue());
  };

  useEffect(() => {
    if (currentDueDateConfig) {
      console.log("currentDueDateConfig", currentDueDateConfig);
      form.setFieldsValue({
        dueDate: moment(currentDueDateConfig.dueDate),
      });

      if (currentDueDateConfig.recurring) {
        form.setFieldsValue({
          recurring: currentDueDateConfig.recurring || false,
          recurrenceType: currentDueDateConfig.recurrenceType,
        });

        switch (currentDueDateConfig.recurrenceType) {
          case "WEEKLY":
            form.setFieldsValue({
              weeklyDays: currentDueDateConfig.weeklyDays,
            });
            break;

          case "MONTHLY":
            form.setFieldsValue({
              monthlyType: currentDueDateConfig.monthlyType,
              dayOfMonth: currentDueDateConfig.dayOfMonth,
              dayOfWeek: currentDueDateConfig.dayOfWeek,
              dateOfMonth: currentDueDateConfig.dateOfMonth,
            });
            setMonthlyType(currentDueDateConfig.monthlyType);

            break;
          case "YEARLY":
            form.setFieldsValue({
              yearlyMonth: currentDueDateConfig.yearlyMonth,
              yearlyDate: currentDueDateConfig.yearlyDate,
            });
            break;

          default:
            break;
        }
        setRecurrenceType(currentDueDateConfig.recurrenceType);
        setIsRecurring(currentDueDateConfig.recurring);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDueDateConfig]);

  return (
    <>
      <Modal
        closable={false}
        visible={visible}
        width={isRecurring ? 490 : 280}
        onCancel={() => setVisible(false)}
        footer={null}
      >
        <Form
          form={form}
          initialValues={{
            recurring: false,
            dueDate: moment(),
          }}
        >
          <div
            style={{
              height: 330,
              width: isRecurring ? 490 : 300,
              display: "flex",
              flexGap: "8px",
            }}
          >
            <div>
              <div style={{ height: 290, width: 300 }}>
                <Form.Item name="dueDate" noStyle>
                  <DatePicker
                    style={{
                      flex: isRecurring ? 1 : "100%",
                      marginRight: isRecurring ? "8px" : "0",
                    }}
                    open={visible}
                    getPopupContainer={trigger => trigger.parentNode}
                    className="task-date-picker"
                  />
                </Form.Item>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                  padding: "8px 12px",
                  width: "100%",
                }}
              >
                <Form.Item name="recurring" valuePropName="checked">
                  <Checkbox
                    onChange={e => {
                      const value = e.target.checked;
                      setIsRecurring(value);
                      if (value) {
                        form.setFieldsValue({ recurrenceType: "DAILY" });
                      } else {
                        form.setFieldsValue({ recurrenceType: "" });
                      }
                    }}
                  >
                    Recurring
                  </Checkbox>
                </Form.Item>
                <Button type="link" onClick={handleDateOk}>
                  Update
                </Button>
              </div>
            </div>

            {/* Recurring config */}
            {isRecurring && (
              <>
                {/* SEPARATOR LINE */}

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    borderLeft: "1px solid #d9d9d9",
                    padding: 16,
                  }}
                  span={9}
                >
                  <span style={{ fontWeight: "bold", fontSize: "16px" }}>
                    Repeats
                  </span>
                  <Row style={{ marginTop: "20px", marginBottom: 16 }}>
                    <Form.Item name="recurrenceType" noStyle>
                      <Select
                        style={{ width: 150 }}
                        onChange={value => {
                          setRecurrenceType(value);
                          // Reset dateOfMonth, dayOfMonth, dayOfWeek, yearlyMonth, yearlyDate
                          form.setFieldsValue({
                            dateOfMonth: null,
                            dayOfMonth: null,
                            dayOfWeek: null,
                            yearlyMonth: null,
                            yearlyDate: null,
                          });

                          switch (value) {
                            case "MONTHLY":
                              form.setFieldsValue({
                                monthlyType: "DAY_OF_MONTH",
                                dayOfMonth: 1,
                                dayOfWeek: "MONDAY",
                              });
                              break;
                            case "YEARLY":
                              form.setFieldsValue({
                                yearlyMonth: "JANUARY",
                                yearlyDate: 1,
                              });
                              break;
                            default:
                              break;
                          }
                        }}
                      >
                        <Option value="DAILY">Daily</Option>
                        <Option value="WEEKLY">Weekly</Option>
                        <Option value="MONTHLY">Monthly</Option>
                        <Option value="YEARLY">Yearly</Option>
                      </Select>
                    </Form.Item>
                  </Row>
                  {recurrenceType === "WEEKLY" && (
                    <Space
                      direction="vertical"
                      size={4}
                      style={{ width: "100%" }}
                    >
                      <div>Repeat weekly on</div>
                      <Form.Item name="weeklyDays" noStyle>
                        <Checkbox.Group
                          options={[
                            { label: "Sunday", value: "SUNDAY" },
                            { label: "Monday", value: "MONDAY" },
                            { label: "Tuesday", value: "TUESDAY" },
                            { label: "Wednesday", value: "WEDNESDAY" },
                            { label: "Thursday", value: "THURSDAY" },
                            { label: "Friday", value: "FRIDAY" },
                            { label: "Saturday", value: "SATURDAY" },
                          ]}
                        ></Checkbox.Group>
                      </Form.Item>
                    </Space>
                  )}
                  {recurrenceType === "MONTHLY" && (
                    <>
                      <Form.Item name="monthlyType" noStyle>
                        <Input type="hidden" />
                      </Form.Item>

                      <Row>
                        <Col span={24}>
                          <span>Repeat monthly on</span>
                          {/* Radio for Day of Month */}
                          <Radio
                            checked={monthlyType === "DAY_OF_MONTH"}
                            onChange={() => {
                              setMonthlyType("DAY_OF_MONTH");
                              form.setFieldsValue({
                                monthlyType: "DAY_OF_MONTH",
                                dayOfMonth: 1,
                                dayOfWeek: "Monday",
                                dateOfMonth: null,
                              });
                            }}
                          >
                            Day of Month
                          </Radio>
                          <Row gutter={16} style={{ marginTop: "10px" }}>
                            <Col span={12}>
                              <Form.Item name="dayOfMonth" noStyle>
                                <Select
                                  style={{ width: "100%" }}
                                  disabled={
                                    form.getFieldValue("monthlyType") !==
                                    "DAY_OF_MONTH"
                                  }
                                >
                                  <Option value={1}>1st</Option>
                                  <Option value={2}>2nd</Option>
                                  <Option value={3}>3rd</Option>
                                  <Option value={4}>4th</Option>
                                  <Option value={5}>5th</Option>
                                </Select>
                              </Form.Item>
                            </Col>
                            <Col span={12}>
                              <Form.Item name="dayOfWeek" noStyle>
                                <Select
                                  disabled={monthlyType !== "DAY_OF_MONTH"}
                                >
                                  <Option value="SUNDAY">Sun</Option>
                                  <Option value="MONDAY">Mon</Option>
                                  <Option value="TUESDAY">Tue</Option>
                                  <Option value="WEDNESDAY">Wed</Option>
                                  <Option value="THURSDAY">Thurs</Option>
                                  <Option value="FRIDAY">Fri</Option>
                                  <Option value="SATURDAY">Sat</Option>
                                </Select>
                              </Form.Item>
                            </Col>
                          </Row>

                          <Row style={{ marginTop: "20px" }}>
                            <Col span={24}>
                              <Radio
                                checked={monthlyType === "DATE_OF_MONTH"}
                                onChange={() => {
                                  setMonthlyType("DATE_OF_MONTH");
                                  form.setFieldsValue({
                                    monthlyType: "DATE_OF_MONTH",
                                    dateOfMonth: 15,
                                    dayOfMonth: null,
                                    dayOfWeek: null,
                                  });
                                }}
                              >
                                Date of Month
                              </Radio>
                            </Col>
                          </Row>

                          <Row style={{ marginTop: "10px" }}>
                            <Form.Item name="dateOfMonth" noStyle>
                              <Select
                                style={{ width: "50%" }}
                                disabled={monthlyType !== "DATE_OF_MONTH"}
                              >
                                {[...Array(31)].map((_, i) => (
                                  <Option key={i} value={i + 1}>
                                    {getNumberWithSuffix(i + 1)}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Row>
                        </Col>
                      </Row>
                    </>
                  )}
                  {recurrenceType === "YEARLY" && (
                    <Row>
                      <Col span={24}>
                        <span>Repeat yearly on</span>
                        <Row gutter={16}>
                          <Col span={12}>
                            <Form.Item name="yearlyMonth" noStyle>
                              <Select style={{ width: "100%" }}>
                                <Option value="JANUARY">Jan</Option>
                                <Option value="FEBRUARY">Feb</Option>
                                <Option value="MARCH">Mar</Option>
                                <Option value="APRIL">Apr</Option>
                                <Option value="MAY">May</Option>
                                <Option value="JUNE">Jun</Option>
                                <Option value="JULY">Jul</Option>
                                <Option value="AUGUST">Aug</Option>
                                <Option value="SEPTEMBER">Sep</Option>
                                <Option value="OCTOBER">Oct</Option>
                                <Option value="NOVEMBER">Nov</Option>
                                <Option value="DECEMBER">Dec</Option>
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item name="yearlyDate" noStyle>
                              <Select style={{ width: "100" }}>
                                {[...Array(31)].map((_, i) => (
                                  <Option key={i} value={i + 1}>
                                    {getNumberWithSuffix(i + 1)}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  )}
                </div>
              </>
            )}
          </div>
        </Form>
      </Modal>
      <style>
        {`
          .ant-modal-body {
            padding: 0;
          }
        `}
      </style>
    </>
  );
};
