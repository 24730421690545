import React from "react";
import { useMutation, useQuery } from "react-apollo";
import { withRouter } from "react-router-dom";
import GET_CART_PROVIDERS from "../../../GraphQl/Queries/GET_CART_PROVIDER";
import LIST_ORGS_SHARING from "../../../GraphQl/Queries/LIST_ORGS_SHARING";
import { LoadingPage } from "../../../../platform/shared/LoadingPage";
import CREATE_EVENT_TAG from "../../../GraphQl/Mutations/CREATE_EVENT_TAG";
import GET_CARTS from "../../../GraphQl/Queries/GET_CARTS";
import { message } from "antd";

//This loader handles all reusable queries for SEO
const TagsManagerLoader = props => {
  const { currentUser, history, location } = props;
  const currentOrgId = currentUser.role.org.id;

  const searchParams = new URLSearchParams(location.search);
  const source = searchParams.get("source");

  const { data: cartProvidersData } = useQuery(GET_CART_PROVIDERS);

  const { data: orgs, loading: orgsLoading } = useQuery(LIST_ORGS_SHARING, {
    variables: {
      parentid: currentOrgId,
    },
  });

  const { data: carts, loading: cartsLoading } = useQuery(GET_CARTS);

  const [createEventTag] = useMutation(CREATE_EVENT_TAG, {
    onCompleted: values => {
      history.push(
        `/tags/details/${values.createEventTag.id}?source=${source}`
      );
    },
    onError: error => {
      console.error("Error creating events target:", error);
      message.error(
        `${error} - Please check your Ad Provider Id on org settings`
      );
    },
  });

  if (orgsLoading || cartsLoading) return <LoadingPage />;
  return React.cloneElement(props.children, {
    ...props.children.props,
    cartProvidersData,
    orgs,
    currentOrgId,
    carts,
    createEventTag,
  });
};

export default withRouter(TagsManagerLoader);
