import React from "react";
import { v4 } from "uuid";

const TagsManagerPublisherController = ({
  orgs,
  currentOrgId,
  createEventTag,
  updateEventTag,
  onSuccess = () => {},
  measurementTags,
  ...props
}) => {
  const advertisers = orgs.orgs.map(org => {
    return { value: org.id, label: org.name };
  });

  const onSubmit = async values => {
    let type = ["PUBLISHER"];
    if (values.isMulti === "yes" || values.isMulti === "exist") {
      type.push("MEASUREMENT");
    }

    if (values.isMulti !== "exist") {
      await createEventTag({
        variables: {
          data: {
            name: values.name,
            orgId: values.advertiser,
            publisherDomain:
              values.domainProtocol + values.domainUrl + values.domainExtension,
            isPublisherDomain: true, // Tag created directly from Tag Publisher Manager will be flag by this
            isEcommerce: false,
            isSignUps: false,
            appId: v4(),
            type,
          },
        },
      });
    } else {
      const existingTag = measurementTags.find(
        m => m.id === values.measurementTag
      );
      updateEventTag({
        variables: {
          data: {
            id: values.measurementTag,
            name: existingTag.name,
            orgId: existingTag.orgs[0].id,
            publisherDomain:
              values.domainProtocol + values.domainUrl + values.domainExtension,
            isPublisherDomain: false, // If tag is not native publisher domain don't mark this
            cart: existingTag.cart,
            isEcommerce: existingTag.isCommerce,
            isSignUps: existingTag.isSignUps,
            domain: existingTag.domain,
            appId: existingTag.eventTags[0].appId[0],
            type,
          },
        },
      });
    }

    onSuccess(values);
  };

  const tags = measurementTags.map(m => {
    return { label: m.name, value: m.id };
  });

  return React.cloneElement(props.children, {
    ...props.children.props,
    currentOrgId,
    advertisers,
    orgs,
    onSubmit,
    measurementTags: tags,
  });
};

export default TagsManagerPublisherController;
