import React, { createContext } from "react";
import { useLazyQuery } from "react-apollo";

// MUTATIONS

// QUERIES
import EVENTS_TARGETS from "../../GraphQl/Queries/EVENTS_TARGETS";
import GET_CARTS from "../../GraphQl/Queries/GET_CARTS";

const TagContext = createContext({});

export const TagProvider = props => {
  const { children } = props;

  const [fetchTags, { data: dataTags, loading: loadingTags }] = useLazyQuery(
    EVENTS_TARGETS,
    {
      fetchPolicy: "no-cache",
    }
  );

  const [fetchCarts, { data: carts, loading: cartsLoading }] = useLazyQuery(
    GET_CARTS,
    {
      fetchPolicy: "no-cache",
    }
  );

  return (
    <TagContext.Provider
      value={{
        fetchTags,
        tags: dataTags ? dataTags.eventsTargets : [],
        loadingTags,
        fetchCarts,
        carts: carts && carts.getCarts ? carts.getCarts : [],
        cartsLoading,
      }}
      {...props}
    >
      {children}
    </TagContext.Provider>
  );
};

export default TagContext;
