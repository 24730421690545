import React from "react";
import { Card, Dropdown, Button, Menu, Typography } from "antd";
import { EllipsisOutlined } from "@ant-design/icons";
import {
  combinedCardStyle,
  combinedCardIndexStyle,
} from "../styling/inline-style";
const { Text } = Typography;

const CombinedCard = ({
  item,
  index,
  handleSelectedMetric,
  removeAudienceMetric,
  selectedCategory,
  titleFontStyle,
  selectedFontStyle,
  separateCombinedItems,
  toggleCombinedDatapointOperator,
}) => {
  const { combineValues, combineCardLogicalOperator } = item;

  const menu = (
    <Menu>
      <Menu.Item
        key="separate"
        onClick={() => {
          separateCombinedItems(item, index);
        }}
      >
        Separate Items
      </Menu.Item>
      <Menu.Item
        key="toggleOperator"
        onClick={() => {
          toggleCombinedDatapointOperator(item);
        }}
      >
        Toggle Operator
      </Menu.Item>
      <Menu.Item
        key="remove"
        onClick={e => {
          removeAudienceMetric({ ...item, index });
        }}
      >
        Remove
      </Menu.Item>
    </Menu>
  );

  return (
    <div style={combinedCardStyle}>
      <Dropdown overlay={menu} trigger={["click"]}>
        <Button
          type="text"
          icon={<EllipsisOutlined />}
          style={{ alignSelf: "flex-end" }}
          onClick={e => e.stopPropagation()} // Prevent the onClick of Card from being triggered
        />
      </Dropdown>

      {combineValues.map((item, index) => {
        const { name, label, value, dataType, categoryName } = item;
        const finaldisplayValue =
          dataType === "boolean" ? (value ? "Yes" : "No") : value;

        return (
          <div key={index}>
            {index > 0 && (
              <div style={combinedCardIndexStyle}>
                {combineCardLogicalOperator || "OR"}
              </div>
            )}

            <Card
              onClick={() => {
                if (typeof handleSelectedMetric === "function") {
                  handleSelectedMetric(item);
                }
              }}
              style={{
                cursor: "pointer",
                borderRadius: "6px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  ...titleFontStyle,
                  ...(selectedCategory && selectedCategory.name === name
                    ? selectedFontStyle
                    : {}),
                }}
              >
                <span>
                  {label}
                  {index > 0 && ` (${combineCardLogicalOperator})`}
                </span>
              </div>
              <div>
                {Array.isArray(finaldisplayValue) ? (
                  <>
                    {finaldisplayValue.map((val, i) => (
                      <p
                        key={`${i}${val.label}`}
                        style={{ marginBottom: "0px" }}
                      >
                        {val.label}
                      </p>
                    ))}
                  </>
                ) : (
                  <>{finaldisplayValue}</>
                )}
              </div>
              <div>
                <Text
                  style={{
                    color: "rgba(0,0,0,0.45",
                    fontSize: "0.9em",
                  }}
                >
                  {categoryName}
                </Text>
              </div>
            </Card>
          </div>
        );
      })}
    </div>
  );
};

export default CombinedCard;
