import React, { useEffect } from "react";
import { Modal, Card, Row, Col, Button, message } from "antd";
import {
  PauseCircleOutlined,
  PlayCircleOutlined,
  ReloadOutlined,
} from "@ant-design/icons";

const AdminModal = ({
  visible,
  setVisible,
  reprocess,
  status,
  updateStatus,
}) => {
  const [currentStatus, setCurrentStatus] = React.useState(status);
  useEffect(() => {
    setCurrentStatus(status);
  }, [status]);
  return (
    <Modal
      title={`Paid Search Admin Tools`}
      visible={visible}
      onCancel={() => setVisible(false)}
      footer={null}
      width={"900px"}
    >
      <Row type="flex" style={{ justifyContent: "center" }} gutter={[16, 16]}>
        <Col xs={12} sm={12} md={11}>
          <Card title="Reprocess Paid Search" style={{ height: "100%" }}>
            <Row type="flex" justify="center" align="middle">
              <ReloadOutlined
                style={{ fontSize: "80px", marginBottom: "20px" }}
              />
              <div style={{ marginBottom: "20px" }}>
                This will reprocess the current Paid Search
              </div>
              <Button
                icon={<ReloadOutlined />}
                type="primary"
                onClick={() => {
                  try {
                    reprocess();
                  } catch (err) {
                    message.err("Failed Reprocessing Paid Search");
                  }
                }}
              >
                Reprocess Paid Search
              </Button>
            </Row>
          </Card>
        </Col>
        <Col xs={12} sm={12} md={11}>
          {currentStatus === "PAUSED" ? (
            <Card title="Pause Paid Search" style={{ height: "100%" }}>
              <Row type="flex" justify="center" align="middle">
                <PlayCircleOutlined
                  style={{ fontSize: "80px", marginBottom: "20px" }}
                />
                <div style={{ marginBottom: "20px" }}>
                  This will resume the processing of this Paid Search Reports
                </div>
                <Button
                  icon={<PlayCircleOutlined />}
                  type="primary"
                  onClick={async () => {
                    try {
                      message.loading("Resuming Paid Search");
                      await updateStatus("ACTIVE");

                      message.success("Paid Search Resumed");
                    } catch (err) {
                      message.err("Failed Reprocessing Paid Search");
                    }
                  }}
                >
                  Resume Paid Search
                </Button>
              </Row>
            </Card>
          ) : (
            <Card title="Pause Paid Search" style={{ height: "100%" }}>
              <Row type="flex" justify="center" align="middle">
                <PauseCircleOutlined
                  style={{ fontSize: "80px", marginBottom: "20px" }}
                />
                <div style={{ marginBottom: "20px" }}>
                  This will stop the processing of this Paid Search Reports
                </div>
                <Button
                  icon={<PauseCircleOutlined />}
                  type="primary"
                  onClick={async () => {
                    try {
                      message.loading("Pausing Paid Search");
                      await updateStatus("PAUSED");

                      message.success("Paid Search Paused");
                    } catch (err) {
                      message.err("Failed Reprocessing Paid Search");
                    }
                  }}
                >
                  Pause Paid Search
                </Button>
              </Row>
            </Card>
          )}
        </Col>
      </Row>
    </Modal>
  );
};

export default AdminModal;
