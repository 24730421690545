import React, { useEffect, useState } from "react";
import { Button, Card, Divider, message, Modal, Space, Typography } from "antd";
import "../../CampaignRequestor/styles.css";
import { useCampaignRequestorContext } from "../../../../core/components/campaignRequestor/useCampaignRequestorContext";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import CampaignOrderQueueReviewNotesDialog from "./CampaignOrderQueueReviewNotesDialog";

const { Text } = Typography;

export const CampaignOrderQueueReviewQueueSummary = ({
  setShowApprovedDialogSuccess,
}) => {
  const {
    updateCampaignOrder,
    loadingUpdateCampaignOrder,
    currentCampaignOrder,
  } = useCampaignRequestorContext();

  const [pendingApproval, setPendingApproval] = useState(0);
  const [approved, setApproved] = useState(0);
  const [needsRevision, setNeedsRevision] = useState(0);
  const [showNotesDialog, setShowNotesDialog] = useState(false);
  const [showRevisionDialogSuccess, setShowRevisionDialogSuccess] = useState(
    false
  );

  const history = useHistory();

  const handleResetNote = async () => {
    try {
      message.loading("Resetting note...", 0);
      await updateCampaignOrder({
        variables: {
          where: {
            id: currentCampaignOrder.id,
          },
          data: {
            reviewNotes: "",
          },
        },
      });
      message.destroy();
      message.success("Note reset successfully");
    } catch (error) {
      message.destroy();
      message.error("Error resetting note");
    }
  };

  const handleRequestRevision = async () => {
    try {
      message.loading("Requesting revision...", 0);
      await updateCampaignOrder({
        variables: {
          where: {
            id: currentCampaignOrder.id,
          },
          data: {
            status: "REJECTED",
          },
        },
      });
      message.destroy();
      setShowRevisionDialogSuccess(true);
    } catch (error) {
      message.destroy();
      message.error("Error requesting revision");
    }
  };

  const handleApprove = async () => {
    try {
      message.loading("Approving...", 0);
      const status =
        currentCampaignOrder.status === "LIVE" ? "LIVE_APPROVED" : "APPROVED";

      await updateCampaignOrder({
        variables: {
          where: {
            id: currentCampaignOrder.id,
          },
          data: {
            status,
          },
        },
      });
      message.destroy();
      setShowApprovedDialogSuccess(true);
    } catch (error) {
      console.log(error);
      message.destroy();
      message.error("Error approving");
    }
  };

  useEffect(() => {
    if (currentCampaignOrder && currentCampaignOrder.campaigns) {
      // get all medias from camapaign.adGroup.adGroupCreatives.medias
      const medias = currentCampaignOrder.campaigns.reduce((acc, campaign) => {
        return [
          ...acc,
          ...(campaign.lineItems
            ? campaign.lineItems.reduce((acc, lineItem) => {
                return [
                  ...acc,
                  ...(lineItem.adGroup && lineItem.adGroup.adGroupCreatives
                    ? lineItem.adGroup.adGroupCreatives.reduce(
                        (acc, adGroupCreative) => {
                          return [...acc, ...adGroupCreative.medias];
                        },
                        []
                      )
                    : []),
                ];
              }, [])
            : []),
        ];
      }, []);

      // get the count of medias that are pending approval
      const pendingApproval = medias.filter(
        media => media.status === "PENDING" || !media.status
      ).length;
      setPendingApproval(pendingApproval);

      // get the count of medias that are approved
      const approved = medias.filter(media => media.status === "APPROVED")
        .length;
      setApproved(approved);

      // get the count of medias that needs revision
      const needsRevision = medias.filter(media => media.status === "REJECTED")
        .length;
      setNeedsRevision(needsRevision);
    }
  }, [currentCampaignOrder]);
  return (
    <>
      <Card title="Review Details" className="campaign-orderqueue-summary">
        {currentCampaignOrder.reviewNotes ? (
          <Space direction="vertical" size="small" style={{ width: "100%" }}>
            <Text>Notes</Text>
            <Text type="secondary">{currentCampaignOrder.reviewNotes}</Text>
            <Space size="small" style={{ width: "100%" }}>
              <Button
                disabled={loadingUpdateCampaignOrder}
                type="link"
                block
                onClick={() => setShowNotesDialog(true)}
              >
                Edit
              </Button>
              <Button
                disabled={loadingUpdateCampaignOrder}
                type="link"
                block
                onClick={handleResetNote}
              >
                Reset
              </Button>
            </Space>
          </Space>
        ) : (
          <Space direction="vertical" size="small" style={{ width: "100%" }}>
            <Text>Campaign Status</Text>
            <Text type="secondary">Pending Review</Text>
          </Space>
        )}

        <Divider />
        <Space direction="vertical" size="small" style={{ width: "100%" }}>
          <Text>Ad Units</Text>
          <Text type="secondary">{pendingApproval} Pending Approval</Text>
          <Text type="secondary">{approved} Approved</Text>
          <Text type="secondary">{needsRevision} Needs Revision</Text>
        </Space>

        <Divider />

        <Space direction="vertical" size="small" style={{ width: "100%" }}>
          {!currentCampaignOrder.reviewNotes && (
            <Button
              disabled={loadingUpdateCampaignOrder}
              style={{ width: "100%" }}
              onClick={() => setShowNotesDialog(true)}
            >
              Add Note
            </Button>
          )}
          <Button
            disabled={loadingUpdateCampaignOrder}
            style={{ width: "100%" }}
            onClick={handleRequestRevision}
          >
            Request Revision
          </Button>
          <Button
            disabled={
              pendingApproval > 0 ||
              needsRevision > 0 ||
              loadingUpdateCampaignOrder
            }
            style={{ width: "100%" }}
            onClick={handleApprove}
          >
            Approve
          </Button>
        </Space>
      </Card>

      {showNotesDialog && (
        <CampaignOrderQueueReviewNotesDialog
          visible={showNotesDialog}
          setVisible={setShowNotesDialog}
        />
      )}

      {showRevisionDialogSuccess && (
        <Modal
          visible={showRevisionDialogSuccess}
          title="REVISIONS REQUESTED"
          onCancel={() => setShowRevisionDialogSuccess(false)}
          footer={[
            <Button
              key="submit"
              type="primary"
              onClick={() => {
                setShowRevisionDialogSuccess(false);
                // Redirect to campaign order queue queued tab
                history.push("/campaigns/v2/orderqueue/queued");
              }}
            >
              Okay
            </Button>,
          ]}
        >
          <Text>Revisions for this campaign have been requested.</Text>
        </Modal>
      )}
    </>
  );
};
