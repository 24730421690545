import React, { useEffect } from "react";
import {
  Modal,
  Form,
  DatePicker,
  Select,
  Button,
  Row,
  Col,
  Typography,
} from "antd";
import { statusTypes } from "../configs/task.config";
import { useTaskContext } from "../../../../core/components/tasks/useTaskContext";
import { useOrgContext } from "../../../../core/components/orgs/useOrgContext";
import { useUserContext } from "../../../../core/components/users/useUserContext";
import { debounce } from "lodash";
import moment from "moment";

const { Option } = Select;
const { Title, Text } = Typography;

const TaskUpdateModal = ({
  visible,
  onCancel,
  onUpdate,
  selected,
  loading,
}) => {
  const [form] = Form.useForm();
  const { currentUser } = useTaskContext();
  const {
    fetchBasicOrgsDetails,
    basicOrgsDetails,
    basicOrgsDetailsLoading,
  } = useOrgContext();
  const {
    fetchBasicUsersDetails,
    basicUsersDetails,
    basicUsersDetailsLoading,
  } = useUserContext();

  const handleUpdate = () => {
    form.validateFields().then(values => {
      const formattedValues = {
        ...values,
        assigneeOrg: values.assigneeOrg,
        assignee: values.assignee,
      };
      onUpdate(formattedValues);
    });
  };

  const fetchBasicOrgs = debounce((name = "") => {
    fetchBasicOrgsDetails({
      variables: {
        first: 10,
        skip: 0,
        where: {
          AND: [
            {
              OR: [
                { parentOrg_some: { id: currentUser.role.org.id } },
                { id: currentUser.role.org.id },
              ],
              name_contains: name,
            },
          ],
          OR: [
            {
              roles_some: {
                org: {
                  level: "PLATFORM",
                },
                roleItems_some: {
                  feature: "TASK_MANAGEMENT",
                },
              },
            },
          ],
        },
      },
    });
  }, 500);

  const fetchBasicUsers = debounce((name = "") => {
    const assigneeOrgId = form.getFieldValue("assigneeOrg");
    fetchBasicUsersDetails({
      variables: {
        first: 10,
        skip: 0,
        where: {
          AND: [
            {
              roles_some: {
                org: {
                  id: assigneeOrgId,
                  level: "PLATFORM",
                },
                roleItems_some: {
                  feature: "TASK_MANAGEMENT",
                },
              },
              name_contains: name,
            },
          ],
        },
      },
    });
  }, 500);

  useEffect(() => {
    if (selected) {
      form.setFieldsValue({
        dueDate: selected.dueDate ? moment(selected.dueDate) : null,
        status: selected.status || "-",
        assigneeOrg: selected.assigneeOrg ? selected.assigneeOrg.id : undefined,
        assignee: selected.assigneeUsers
          ? selected.assigneeUsers.map(user => user.id)
          : [],
      });
    }
  }, [selected, form]);

  return (
    <Modal
      closable
      visible={visible}
      title="UPDATE TASK"
      onCancel={onCancel}
      width={"40%"}
      footer={[
        <Button
          key="update"
          type="primary"
          onClick={handleUpdate}
          disabled={loading}
        >
          Update
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical">
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Row gutter={16}>
              <Col span={8}>
                <Form.Item
                  name="dueDate"
                  label={
                    <Row>
                      <Col span={24}>
                        <Title level={5}>Due Date</Title>
                      </Col>
                      <Col span={24}>
                        <Text type="secondary">Due of task completion</Text>
                      </Col>
                    </Row>
                  }
                  required={false}
                  rules={[
                    { required: true, message: "Please select a due date" },
                  ]}
                >
                  <DatePicker
                    style={{ width: "100%" }}
                    placeholder="Set due date"
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="status"
                  label={
                    <Row>
                      <Col span={24}>
                        <Title level={5}>Status</Title>
                      </Col>
                      <Col span={24}>
                        <Text type="secondary">Task status</Text>
                      </Col>
                    </Row>
                  }
                  required={false}
                  rules={[
                    { required: true, message: "Please select a status" },
                  ]}
                >
                  <Select style={{ width: "100%" }}>
                    {Object.keys(statusTypes).map(status => (
                      <Option key={status} value={statusTypes[status]}>
                        {status}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="assigneeOrg"
                  label={
                    <Row>
                      <Col span={24}>
                        <Title level={5}>Assignee Org</Title>
                      </Col>
                      <Col span={24}>
                        <Text type="secondary">
                          The org that will receive this task
                        </Text>
                      </Col>
                    </Row>
                  }
                  required={false}
                  rules={[
                    {
                      required: true,
                      message: "Please select an assignee org",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Select Assignee Org"
                    loading={basicOrgsDetailsLoading}
                    onFocus={() => fetchBasicOrgs()}
                    onSearch={searchText => fetchBasicOrgs(searchText)}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    filterSort={(optionA, optionB) =>
                      optionA.children
                        .toLowerCase()
                        .localeCompare(optionB.children.toLowerCase())
                    }
                    onChange={() => form.setFieldsValue({ assignee: [] })}
                  >
                    {basicOrgsDetails
                      .concat(
                        selected &&
                          selected.assigneeOrg &&
                          !basicOrgsDetails.some(
                            org => org.id === selected.assigneeOrg.id
                          )
                          ? [selected.assigneeOrg]
                          : []
                      )
                      .map(org => (
                        <Option key={org.id} value={org.id}>
                          {org.name}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="assignee"
                  label={
                    <Row>
                      <Col span={24}>
                        <Title level={5}>Assignee</Title>
                      </Col>
                      <Col span={24}>
                        <Text type="secondary">
                          The person who will receive this task
                        </Text>
                      </Col>
                    </Row>
                  }
                  required={false}
                  rules={[
                    { required: true, message: "Please select an assignee" },
                  ]}
                >
                  <Select
                    mode="multiple"
                    placeholder="Select Assignee"
                    showSearch
                    loading={basicUsersDetailsLoading}
                    onFocus={() => fetchBasicUsers()}
                    onSearch={searchText => fetchBasicUsers(searchText)}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    filterSort={(optionA, optionB) =>
                      optionA.children
                        .toLowerCase()
                        .localeCompare(optionB.children.toLowerCase())
                    }
                    maxTagCount={2}
                  >
                    {basicUsersDetails.map(user => (
                      <Option key={user.id} value={user.id}>
                        {user.name || user.username}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default TaskUpdateModal;
