import React from "react";
import JSZip from "jszip";
import Papa from "papaparse";
import { cloneDeep, isEmpty } from "lodash";
import { saveAs } from "file-saver";
import { Col, Button, Menu, Dropdown, Select, Row } from "antd";
import { DownOutlined, DownloadOutlined } from "@ant-design/icons";
import { withRouter } from "react-router-dom";
import "../styles/styles.css";

const FilterBar = withRouter(props => {
  const {
    data,
    location,
    history,
    setOrderIdVal,
    params,
    dropdownFilter,
    permission,
  } = props;

  const exportData = permission && permission.exportData;

  const keyMap = {
    0: "All Time",
    1: "Last 7 Days",
    2: "Last 15 Days",
    3: "Last 30 Days",
    4: "Last 60 Days",
    5: "Last 90 Days",
  };

  const handleMenuClick = e => {
    const key = keyMap[e.key];

    params.delete("df");
    params.append("df", key);
    history.push(`${location.pathname}?${params.toString()}`);
  };

  const dateFilterMenu = (
    <Menu onClick={handleMenuClick}>
      {Object.entries(keyMap).map(([key, value]) => (
        <Menu.Item key={key}>{value}</Menu.Item>
      ))}
    </Menu>
  );

  const handleChange = s => {
    setOrderIdVal("");
    params.delete("orderId");
    params.delete("search");

    if (s && s.length) {
      s.forEach(item => params.append("search", item));
    }
    history.push(`${location.pathname}?${params.toString()}`);
  };

  const handleExport = () => {
    try {
      const zip = new JSZip();
      const folder = zip.folder("Reports Campaign Basket Item");
      const dupFileData = cloneDeep(data);

      // Configure Papa Parse with proper encoding options
      const csvContent = Papa.unparse(dupFileData, {
        encoding: "UTF-8",
        quotes: true,
        quoteChar: '"',
      });
      const BOM = "\uFEFF";
      const csvContentWithBOM = BOM + csvContent;
      folder.file("Basket Items.csv", csvContentWithBOM, {
        binary: false,
        type: "text/csv;charset=UTF-8",
      });

      zip
        .generateAsync({
          type: "blob",
          compression: "DEFLATE",
          compressionOptions: {
            level: 9,
          },
        })
        .then(
          blob => {
            saveAs(blob, "Campaigns.zip");
          },
          e => {
            console.error("Error exporting CSV:", e);
          }
        );
    } catch (err) {
      console.error("Error exporting CSV:", err);
    }
  };

  return (
    <Row gutter={[8, 8]}>
      <Col id="basket-items-title" span={24}>
        Basket Items
      </Col>
      <Col xs={24} md={12}>
        <Select
          mode="tags"
          allowClear
          style={{ width: "100%" }}
          placeholder="Search multiple basket items by name"
          onChange={handleChange}
          defaultValue={[]}
        />
      </Col>
      <Col xs={24} md={6}>
        <Dropdown overlay={dateFilterMenu}>
          <Button style={{ width: "100%" }}>
            {dropdownFilter || "All Time"} <DownOutlined />
          </Button>
        </Dropdown>
      </Col>
      <Col xs={24} md={6}>
        {exportData && (
          <Button
            style={{ width: "100%" }}
            disabled={isEmpty(data)}
            type="primary"
            block
            onClick={handleExport}
          >
            <DownloadOutlined />
            Export CSV
          </Button>
        )}
      </Col>
    </Row>
  );
});

export default FilterBar;
