import gql from "graphql-tag";

const GENERATE_ATTENTION_SIGNAL_INVOICE = gql`
  mutation generateAttentionSignalInvoice(
    $billingId: String!
    $type: String!
    $orgId: String!
  ) {
    generateAttentionSignalInvoice(
      billingId: $billingId
      type: $type
      orgId: $orgId
    )
  }
`;

export default GENERATE_ATTENTION_SIGNAL_INVOICE;
