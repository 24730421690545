import gql from "graphql-tag";

const UPDATE_EVENT_TAG = gql`
  mutation updateEvents($data: UpdateEventTagInput!) {
    updateEventTag(data: $data) {
      id
      name
    }
  }
`;

export default UPDATE_EVENT_TAG;
