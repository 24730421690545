import { Button, Modal } from "antd";
import React, { useState } from "react";
import { providers } from "../../../../../core/utils/constants/constants";

const GetCartPlatform = cart => {
  const hasNoInstruction = ["no-cart"];
  //Newly created tag
  const selectedCart = providers.find(provider => provider.value === cart);

  //if no cart is found check legacy carts
  if (!selectedCart) {
    const legacyCart = providers.find(provider => provider.legacyCtr === cart);
    if (!legacyCart) {
      return null;
    }
    if (hasNoInstruction.includes(legacyCart.value)) {
      return null;
    }
    return legacyCart;
  } else {
    if (hasNoInstruction.includes(selectedCart.value)) {
      return null;
    }
    return selectedCart;
  }
};

const GenTagInstructionsModal = ({ cart, generatedTag }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  // get shopify generated tag src
  const srcMatch = generatedTag.match(/tag\.src='(.*?)'/);
  const getShopifyGenerated = srcMatch ? srcMatch[1] : null;

  // encode shopify uri
  const encodeUrlShopify = encodeURIComponent(getShopifyGenerated);

  // check if shopify cart then get encoded tag else get other cart encoded tag
  const generatedTagValue =
    cart === "shopify" ? encodeUrlShopify : encodeURIComponent(generatedTag);

  const platform = GetCartPlatform(cart);
  if (!platform) {
    return <></>;
  }
  return (
    <React.Fragment>
      <Modal
        title={"Cart Instructions for " + platform.label + "."}
        width={"50%"}
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        centered
        footer={null}
      >
        <>
          <iframe
            src={`${process.env.REACT_APP_INTERNAL_MICROSERVICE_URL}/api/docs/html?filename=integrations%2F${platform.value}.md&tagsrc="${generatedTagValue}"`}
            title={platform.value}
            width="100%"
            height="800px"
            style={{ border: "none" }}
          />
        </>
      </Modal>
      <Button
        shape="round"
        type="primary"
        onClick={() => setIsModalVisible(true)}
      >
        View Instructions
      </Button>
    </React.Fragment>
  );
};

export default GenTagInstructionsModal;
