import React from "react";
import {
  PrimaryTableRowText,
  StandardTableRowText,
  TableColumnTitle,
} from "../../../../shared/globalStyling/styledText";
import { message, Space, Table, Tag, Typography } from "antd";
import { formatWholeNumberNaN } from "../../../../../core/utils/campaigns";
import { withRouter } from "react-router-dom";
import moment from "moment";

const { Text } = Typography;

const MainTable = ({ tags = [], history, testTag, isAttentionSignals }) => {
  const columns = [
    {
      title: <TableColumnTitle text={"Tag Name"} />,
      key: "name",
      render: record => {
        return <PrimaryTableRowText text={record.name} />;
      },
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: <TableColumnTitle text={"Advertiser"} />,
      key: "advertiser",
      width: "15%",
      render: record => {
        return <StandardTableRowText text={record.advertiser} />;
      },
      sorter: (a, b) => a.advertiser.localeCompare(b.advertiser),
    },
    isAttentionSignals
      ? {
          title: <TableColumnTitle text={"Funnels"} />,
          key: "funnels",
          width: "15%",
          render: record => {
            return <StandardTableRowText text={record.funnels} />;
          },
          sorter: (a, b) => a.measuredEvents.funnels - b.measuredEvents.funnels,
        }
      : {},
    {
      title: <TableColumnTitle text={"Measured Events"} />,
      key: "measuredEvents",
      render: record => {
        const {
          transactions,
          pageViews,
          signups,
          date,
          colors,
          typeColors,
          isSignUps,
        } = record.measuredEvents;

        return (
          <Space style={{ flexWrap: "wrap" }}>
            {pageViews !== undefined && (
              <Tag key={1} color={colors.pageViews}>
                <Space direction="vertical" size={0}>
                  <Text type={typeColors.pageViews}>Page Views</Text>
                  <Text type={typeColors.pageViews} strong>
                    {formatWholeNumberNaN(pageViews)}
                  </Text>
                  <Text type="secondary">
                    {date
                      ? moment(date).format("YYYY/MM/DD - HH:mm")
                      : "Signal Unknown"}
                  </Text>
                </Space>
              </Tag>
            )}
            {transactions !== undefined && (
              <Tag key={2} color={colors.transactions}>
                <Space direction="vertical" size={0}>
                  <Text type={typeColors.transactions}>Transactions</Text>
                  <Text type={typeColors.transactions} strong>
                    {formatWholeNumberNaN(transactions)}
                  </Text>
                  <Text type="secondary">
                    {date
                      ? moment(date).format("YYYY/MM/DD - HH:mm")
                      : "Signal Unknown"}
                  </Text>
                </Space>
              </Tag>
            )}
            {(isSignUps || signups > 0) && (
              <Tag key={3} color={colors.signups}>
                <Space direction="vertical" size={0}>
                  <Text type={typeColors.signups}>Sign-Ups</Text>
                  <Text type={typeColors.signups} strong>
                    {formatWholeNumberNaN(signups)}
                  </Text>
                  <Text type="secondary">
                    {date
                      ? moment(date).format("YYYY/MM/DD - HH:mm")
                      : "Signal Unknown"}
                  </Text>
                </Space>
              </Tag>
            )}
          </Space>
        );
      },
      sorter: (a, b) => a.measuredEvents.pageViews - b.measuredEvents.pageViews,
    },
    {
      title: <TableColumnTitle text={"Tag ID"} />,
      key: "appId",
      render: record => (
        <Space direction="horizontal">
          <Typography.Paragraph
            copyable
            onClick={event => event.stopPropagation()}
          >
            {record.appId}
          </Typography.Paragraph>
        </Space>
      ),
      sorter: (a, b) => a.appId.localeCompare(b.appId),
    },
    {
      title: <TableColumnTitle text={"Action"} />,
      key: "action",
      width: "10%",
      render: record => (
        <Typography.Link
          onClick={event => {
            event.stopPropagation();
            testTag({
              appId: record.appId,
              orgId: record.orgId,
              tagId: record.id,
            });
            message.info(
              `Signal Tag Test for ${record.name} initiated. May take a couple of minutes to complete.`
            );
          }}
        >
          Test Signal
        </Typography.Link>
      ),
    },
  ];

  return (
    <Table
      dataSource={tags}
      columns={columns}
      rowKey={record => record.id}
      onRow={(record, rowIndex) => {
        return {
          onClick: event => {
            console.log(event.type);
            history.push(`/tags/details/${record.id}?source=measurement`);
          },
        };
      }}
      scroll={{ x: "100%" }}
      size="middle"
    />
  );
};

export default withRouter(MainTable);
