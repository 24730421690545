import React, { useState } from "react";
import { Tooltip, Row, Col, Avatar } from "antd";
import { isEmpty } from "lodash";
import { CreativeExpandedCard } from "./CreativeExpandedCard";

// Global Styling
import {
  TableColumnTitle,
  PrimaryTableRowText,
  TooltipText,
} from "../../../../../../shared/globalStyling/styledText";
import { formatWholeNumber } from "../../../../../../../core/utils/campaigns";
import uuid from "uuid";
import SimpleTable from "../../../../../../shared/SimpleTable";
import { CloudOutlined } from "@ant-design/icons";

const CreativeTable = ({ creativeData }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const extraColumn = {
    title: "",
    dataIndex: "creativeUrl",
    key: "creativeUrl",
    width: "70px",
    align: "left",
    render: url => {
      return (
        <Avatar
          shape="square"
          size={50}
          icon={<CloudOutlined />}
          src={url === "" || !url ? false : url}
          style={{ marginLeft: "-16px" }}
        />
      );
    },
  };

  const columns = [
    {
      title: "",
      dataIndex: "color",
      key: "color",
      width: "15px",
      render: text => {
        return (
          <Row
            type="flex"
            justify="center"
            align="middle"
            style={{
              width: "100%",
            }}
          >
            <div
              style={{
                backgroundColor: text,
                height: "15px",
                width: "15px",
                minWidth: "15px",
                borderRadius: "50px",
                marginLeft: "-16px",
              }}
            />
          </Row>
        );
      },
    },
    {
      title: <TableColumnTitle text={"Name"} />,
      dataIndex: "value",
      key: "value",
      width: "250px",
      align: "left",
      footerContent: "Totals + Averages",
      sorter: (a, b) => a.value.localeCompare(b.value),
      render: (text, record) => {
        if (text) {
          return (
            <Row
              type="flex"
              justify="start"
              align="middle"
              style={{
                width: "100%",
              }}
            >
              <Col span={24}>
                {text.length > 45 ? (
                  <Tooltip title={<TooltipText text={text} />}>
                    <span>
                      <PrimaryTableRowText
                        text={text.slice(0, 46).concat("...")}
                      />
                    </span>
                  </Tooltip>
                ) : (
                  <PrimaryTableRowText text={text} />
                )}
              </Col>
            </Row>
          );
        } else {
          return <div />;
        }
      },
    },
    {
      title: <TableColumnTitle text={"Impressions"} />,
      dataIndex: "impressions",
      footerSum: true,
      key: "impressions",
      width: "130px",
      align: "left",
      sorter: (a, b) => a.impressions - b.impressions,
      render: impressions => (
        <PrimaryTableRowText
          text={impressions && formatWholeNumber(impressions)}
        />
      ),
    },
    {
      title: <TableColumnTitle text={"Clicks"} />,
      footerSum: true,
      dataIndex: "clicks",
      key: "clicks",
      width: "100px",
      align: "left",
      sorter: (a, b) => a.clicks - b.clicks,
      render: clicks => (
        <PrimaryTableRowText text={clicks && formatWholeNumber(clicks)} />
      ),
    },
    {
      title: <TableColumnTitle text={"CTR"} />,
      footerContent: "-",
      dataIndex: "ctr",
      key: "ctr",
      width: "80px",
      align: "left",
      sorter: (a, b) => a.ctr - b.ctr,
      render: ctr => <PrimaryTableRowText text={ctr && `${ctr.toFixed(3)}%`} />,
    },
  ];

  if (creativeData && !isEmpty(creativeData)) {
    if (creativeData[0] && creativeData[0].creativeUrl) {
      columns.unshift(extraColumn);
    }
  }

  return (
    <SimpleTable
      footer={creativeData.slice(currentPage * 10 - 10, currentPage * 10)}
      bordered={false}
      columns={columns}
      data={creativeData.map(creative => {
        return {
          ...creative,
          key: `${creative.id}${uuid.v4()}`,
        };
      })}
      pagination={{
        pageSize,
        total: creativeData && creativeData.length,
        current: currentPage,
      }}
      scroll={{ x: 200, y: 400 }}
      onChange={paginationObject => {
        setPageSize(paginationObject.pageSize);
        setCurrentPage(paginationObject.current);
      }}
      expandable={{
        expandedRowRender: record =>
          record && <CreativeExpandedCard record={record} />,
        rowExpandable: record => record.creativeUrl,
      }}
      rowClassName={record => {
        // NOTE: THREEDEE needs to be changed to video. Additional enum needed Also handles Images
        return record.creativeUrl ||
          (record.media && record.media.type) === "THREEDEE"
          ? ""
          : "hide-expand";
      }}
    />
  );
};

export default CreativeTable;
