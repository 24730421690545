import React, { useEffect, useCallback } from "react";
import {
  Card,
  Col,
  Table,
  Input,
  Button,
  Select,
  Row,
  Tag,
  Tooltip,
  Badge,
} from "antd";
import {
  taskTypes,
  clientTypes,
  statusTypes,
  subTypes,
  urgencyTypes,
  urgencyColors,
} from "../configs/task.config";
import { SyncOutlined } from "@ant-design/icons";
import { getNumberWithSuffix } from "../../../../platform/ux/Tasks/utils/task.utils";
import { useTaskContext } from "../../../../core/components/tasks/useTaskContext";
import { withRouter, Link } from "react-router-dom";
import { useUserContext } from "../../../../core/components/users/useUserContext";
import { debounce } from "lodash";
import qs from "qs";
import useSearchParams from "../../../../core/hooks/useSearchParams";
import AvatarS3 from "../../../shared/images/AvatarS3";
import { CloseOutlined } from "@ant-design/icons";
import { startCase } from "lodash";

const { Option, OptGroup } = Select;

const TaskBoard = ({ history }) => {
  const {
    currentUser,
    fetchTasksPaginated,
    task,
    taskBoardPagination,
    setTaskBoardPagination,
    tasksLoading,
  } = useTaskContext();
  const {
    fetchBasicUsersDetails,
    basicUsersDetails,
    basicUsersDetailsLoading,
  } = useUserContext();
  const [searchParams, setSearchParams] = useSearchParams({
    assigneeNames: "",
    requestTypes: "",
    requestSubTypes: "",
    status: "",
    clientTypes: "",
    name: "",
    urgency: "",
  });

  useEffect(() => {
    handleFetchTasksPaginated({
      skip:
        (taskBoardPagination.current - 1) * taskBoardPagination.pageSize || 0,
      first: taskBoardPagination.pageSize,
      ...searchParams,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskBoardPagination.pageSize, taskBoardPagination.current, searchParams]);

  const handleAssigneeFilter = selectedValues => {
    const updatedFilters = {
      ...searchParams,
      assigneeNames: selectedValues.join(","),
    };
    setSearchParams(updatedFilters);
    setTaskBoardPagination(prev => ({ ...prev, current: 1 }));
  };

  const handleTaskTypeFilter = selectedValues => {
    const updatedFilters = {
      ...searchParams,
      requestTypes: selectedValues.join(","),
    };
    setSearchParams(updatedFilters);
    setTaskBoardPagination(prev => ({ ...prev, current: 1 }));
  };

  const handleTaskSubTypeFilter = selectedValues => {
    const updatedFilters = {
      ...searchParams,
      requestSubTypes: selectedValues.join(","),
    };
    setSearchParams(updatedFilters);
    setTaskBoardPagination(prev => ({ ...prev, current: 1 }));
  };

  const handleStatusFilter = selectedValues => {
    const updatedFilters = {
      ...searchParams,
      status: selectedValues.join(","),
    };
    setSearchParams(updatedFilters);
    setTaskBoardPagination(prev => ({ ...prev, current: 1 }));
  };

  const handleClientTypeFilter = selectedValues => {
    const updatedFilters = {
      ...searchParams,
      clientTypes: selectedValues.join(","),
    };
    setSearchParams(updatedFilters);
    setTaskBoardPagination(prev => ({ ...prev, current: 1 }));
  };

  const handleUrgencyFilter = selectedValues => {
    const updatedFilters = {
      ...searchParams,
      urgency: selectedValues.join(","),
    };
    setSearchParams(updatedFilters);
    setTaskBoardPagination(prev => ({ ...prev, current: 1 }));
  };

  const handleFetchTasksPaginated = debounce(
    ({
      skip,
      first,
      sortField = "updatedAt",
      sortOrder = "DESC",
      name = "",
      assigneeNames = "",
      requestTypes = "",
      requestSubTypes = "",
      status = "",
      clientTypes = "",
      urgency = "",
    }) => {
      const assigneeNamesArray = assigneeNames ? assigneeNames.split(",") : [];
      const requestTypesArray = requestTypes ? requestTypes.split(",") : [];
      const requestSubTypesArray = requestSubTypes
        ? requestSubTypes.split(",")
        : [];
      const statusArray = status ? status.split(",") : [];
      const clientTypesArray = clientTypes ? clientTypes.split(",") : [];
      const urgencyArray = urgency ? urgency.split(",") : [];
      fetchTasksPaginated({
        variables: {
          where: {
            AND: [
              { name_contains: name },
              ...(assigneeNamesArray.length
                ? [
                    {
                      OR: [
                        { assigneeUsers_some: { name_in: assigneeNamesArray } },
                        { assigneeUser: { name_in: assigneeNamesArray } },
                      ],
                    },
                  ]
                : []),
              ...(requestTypesArray.length
                ? [{ requestType_in: requestTypesArray }]
                : []),
              ...(requestSubTypesArray.length
                ? [{ requestSubType_in: requestSubTypesArray }]
                : []),
              ...(statusArray.length ? [{ status_in: statusArray }] : []),
              ...(clientTypesArray.length
                ? [
                    {
                      createdBy: {
                        roles_some: { org: { level_in: clientTypesArray } },
                      },
                    },
                  ]
                : []),
              ...(urgencyArray.length ? [{ urgency_in: urgencyArray }] : []),
              {
                OR: [
                  {
                    createdBy: {
                      roles_some: {
                        org: {
                          OR: [
                            {
                              parentOrg_some: {
                                id: currentUser.role.org.id,
                              },
                            },
                            {
                              id: currentUser.role.org.id,
                            },
                          ],
                        },
                      },
                    },
                  },
                ],
              },
            ],
          },
          orderBy: sortField ? `${sortField}_${sortOrder}` : "updatedAt_DESC",
          skip,
          first,
        },
      });
    },
    500
  );

  const fetchBasicUsers = useCallback(
    debounce((name = "") => {
      fetchBasicUsersDetails({
        variables: {
          first: 50,
          skip: 0,
          where: {
            AND: [
              {
                roles_some: {
                  org: { level: "PLATFORM" },
                  roleItems_some: { feature: "TASK_MANAGEMENT" },
                },
                name_contains: name,
              },
            ],
          },
        },
      });
    }, 500),
    [] // empty dependency array ensures this stays the same across renders
  );

  const handleTableChange = (pagination, filters, sorter) => {
    const { current, pageSize } = pagination;
    const sortField = sorter && sorter.field;
    const sortOrder = sorter && sorter.order === "ascend" ? "ASC" : "DESC";

    setTaskBoardPagination(prev => ({ ...prev, current, pageSize }));
    const newQuery = { ...searchParams, sortField, sortOrder };
    const queryString = qs.stringify(newQuery, { addQueryPrefix: true });
    history.push(`/tasks/main/${current}${queryString}`);

    handleFetchTasksPaginated({
      skip: (current - 1) * pageSize || 0,
      first: pageSize,
      sortField,
      sortOrder,
      ...newQuery,
    });
  };

  useEffect(() => {
    if (searchParams.assigneeNames === "") {
      fetchBasicUsers();
    }
  }, [fetchBasicUsers, searchParams.assigneeNames]);

  const handleSearchChange = name => {
    const updatedFilters = { ...searchParams, name };
    setSearchParams(updatedFilters);
    setTaskBoardPagination(prev => ({ ...prev, current: 1 }));
  };

  const level =
    currentUser &&
    currentUser.role &&
    currentUser.role.org &&
    currentUser.role.org.level;

  const columns = [
    {
      title: "Task Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <>
          <Tooltip
            title={record.urgency ? `URGENCY: ${record.urgency}` : undefined}
          >
            <Badge
              dot
              color={urgencyColors[record.urgency] || "white"}
              style={{ marginRight: 8 }}
            />
          </Tooltip>
          <Link
            to={`/tasks/details/${record.id}`}
            style={{ textDecoration: "none" }}
          >
            {text}
          </Link>
        </>
      ),
    },
    {
      title: "Due Date",
      dataIndex: "dueDate",
      key: "dueDate",
      sorter: true,
      render: (dueDate, record) => {
        const isPastDue = new Date(dueDate) < new Date();
        const getTooltipContent = () => (
          <div>
            <div>{record.recurrenceType || "-"}</div>
            {record.weeklyDays && (
              <div>
                {record.weeklyDays
                  .map(day => day.charAt(0) + day.slice(1).toLowerCase())
                  .join(", ")}
              </div>
            )}
            {record.dayOfMonth && record.dayOfWeek && (
              <div>
                {getNumberWithSuffix(record.dayOfMonth)} week of{" "}
                {record.dayOfWeek.charAt(0) +
                  record.dayOfWeek.slice(1).toLowerCase()}
              </div>
            )}
            {record.dateOfMonth && (
              <div>Every {getNumberWithSuffix(record.dateOfMonth)}</div>
            )}
            {record.yearlyMonth && record.yearlyDate && (
              <div>
                Every{" "}
                {record.yearlyMonth.charAt(0) +
                  record.yearlyMonth.slice(1).toLowerCase()}{" "}
                {getNumberWithSuffix(record.yearlyDate)}
              </div>
            )}
          </div>
        );

        return (
          <>
            <span
              style={{
                color: dueDate
                  ? isPastDue
                    ? "#ff4d4f"
                    : "inherit"
                  : "inherit",
              }}
            >
              {dueDate ? new Date(dueDate).toLocaleDateString("en-CA") : "N/A"}
            </span>
            {record.recurring && (
              <Tooltip title={getTooltipContent()}>
                <SyncOutlined style={{ marginLeft: 8, color: "gray" }} />
              </Tooltip>
            )}
          </>
        );
      },
    },
    {
      title: "Assignee",
      dataIndex: "assigneeUsers",
      key: "assigneeUsers",
      render: (assigneeUsers, record) =>
        assigneeUsers && assigneeUsers.length > 0 ? (
          assigneeUsers.map((user, index) => (
            <Tooltip title={startCase(user.name || user.username || "-")}>
              <Col
                key={index}
                style={{
                  display: "inline-flex",
                  alignItems: "center",
                  marginLeft: -5,
                }}
              >
                <AvatarS3
                  imgKey={user.avatar && user.avatar.key}
                  username={user.name || "-"}
                  avatarProps={{ size: 27 }}
                />
              </Col>
            </Tooltip>
          ))
        ) : record.assigneeUser ? (
          <Tooltip
            title={
              startCase(record.assigneeUser && record.assigneeUser.name) || "-"
            }
          >
            <Col
              style={{
                display: "inline-flex",
                alignItems: "center",
                marginLeft: -5,
              }}
            >
              <AvatarS3
                imgKey={
                  record.assigneeUser.avatar && record.assigneeUser.avatar.key
                }
                username={
                  (record.assigneeUser && record.assigneeUser.name) || "-"
                }
                avatarProps={{ size: 27 }}
              />
            </Col>
          </Tooltip>
        ) : (
          "-"
        ),
      onFilter: (value, record) => {
        return (
          record.assigneeUsers &&
          record.assigneeUsers.some(user => user.name.indexOf(value) === 0)
        );
      },
    },
    {
      title: "Request Type",
      dataIndex: "requestType",
      key: "requestType",
      render: (requestType, record) => (
        <>
          {requestType || "N/A"}
          <br />
          <span style={{ color: "gray" }}>{record.requestSubType || ""}</span>
        </>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: status => {
        if (!status) return "N/A";
        const formattedStatus = status
          .split("_")
          .map(word => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ");
        return formattedStatus;
      },
    },
  ];

  if (level === "CHANNEL_PARTNER") {
    columns.push({
      title: "Advertiser",
      dataIndex: "advertiser",
      key: "advertiser",
      render: advertiser =>
        advertiser && advertiser.id ? advertiser.name : "",
    });
  } else {
    columns.push(
      {
        title: "Client Type",
        dataIndex: "clientType",
        key: "clientType",
        render: (_, record) => {
          const orgLevel =
            record.createdBy &&
            record.createdBy.roles &&
            record.createdBy.roles[0] &&
            record.createdBy.roles[0].org &&
            record.createdBy.roles[0].org.level;
          const formattedOrgLevel = orgLevel
            ? orgLevel
                .split("_")
                .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                .join(" ")
            : "N/A";
          return formattedOrgLevel;
        },
      },
      {
        title: "Client",
        dataIndex: "client",
        key: "client",
        render: (_, record) => {
          const orgName =
            record.createdBy &&
            record.createdBy.roles &&
            record.createdBy.roles[0] &&
            record.createdBy.roles[0].org &&
            record.createdBy.roles[0].org.name;
          return (
            <>
              <Tag
                color="geekblue"
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxWidth: "150px",
                }}
              >
                {orgName || "N/A"}
              </Tag>
            </>
          );
        },
      }
    );
  }

  columns.push({
    title: "Created",
    dataIndex: "createdAt",
    key: "createdAt",
    render: (_, record) =>
      record.createdAt ? new Date(record.createdAt).toLocaleDateString() : "",
  });

  return (
    <>
      <Card
        title="TASKS"
        extra={
          <Row gutter={16} align="middle">
            <Row>
              <Button
                style={{ width: "100%", height: "36px", fontSize: "14px" }}
                shape="round"
                type={"default"}
                disabled={
                  !searchParams ||
                  !Object.values(searchParams).some(param => param)
                }
                onClick={() => {
                  setSearchParams({
                    assigneeNames: "",
                    requestTypes: "",
                    requestSubTypes: "",
                    status: "",
                    clientTypes: "",
                    name: "",
                    urgency: "",
                  });
                  setTaskBoardPagination(prev => ({
                    ...prev,
                    current: 1,
                  }));

                  history.push(
                    "/tasks/main/1?assigneeNames=&requestTypes=&requestSubTypes=&status=&clientTypes=&name=&urgency="
                  );
                }}
                icon={<CloseOutlined />}
              >
                Clear
              </Button>
            </Row>
            <Col>
              <Input.Search
                placeholder="Search tasks"
                onSearch={value => handleSearchChange(value)}
                style={{ width: 200 }}
                allowClear
              />
            </Col>
            <Col>
              <Button
                type="primary"
                onClick={() => {
                  history.push(`/tasks/form/`);
                }}
              >
                Create Task
              </Button>
            </Col>
          </Row>
        }
      >
        <Row gutter={[16, 16]} style={{ marginBottom: 16 }} wrap>
          <Col xs={24} sm={12} md={12} lg={8} xl={6}>
            <Row align="middle" gutter={8} wrap={false}>
              <Col flex="110px" style={{ minWidth: 110 }}>
                Assignee:
              </Col>
              <Col flex="auto">
                <Select
                  mode="multiple"
                  showSearch
                  style={{ width: "100%" }}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  filterSort={(optionA, optionB) => {
                    if (optionA.value === "all") return -1;
                    if (optionB.value === "all") return 1;
                    return optionA.children
                      .toLowerCase()
                      .localeCompare(optionB.children.toLowerCase());
                  }}
                  onSearch={searchText => fetchBasicUsers(searchText)} // Fetch filtered data on search
                  loading={basicUsersDetailsLoading}
                  onChange={handleAssigneeFilter} // Trigger fetch when "All" is selected
                  value={
                    searchParams.assigneeNames
                      ? searchParams.assigneeNames.split(",")
                      : []
                  }
                  maxTagCount={1}
                  placeholder="Filter by Name"
                  allowClear
                >
                  {basicUsersDetails.map(user => (
                    <Select.Option key={user.id} value={user.name}>
                      {startCase(user.name || user.username)}
                    </Select.Option>
                  ))}
                </Select>
              </Col>
            </Row>
          </Col>
          <Col xs={24} sm={12} md={12} lg={8} xl={6}>
            <Row align="middle" gutter={8} wrap={false}>
              <Col flex="110px" style={{ minWidth: 110 }}>
                Request Type:
              </Col>
              <Col flex="auto">
                <Select
                  mode="multiple"
                  style={{ width: "100%" }}
                  onChange={handleTaskTypeFilter} // Call the task type filter on change
                  value={
                    searchParams.requestTypes
                      ? searchParams.requestTypes.split(",")
                      : []
                  }
                  maxTagCount={1}
                  placeholder="Filter by Request Type"
                  allowClear
                >
                  {taskTypes.map(type => (
                    <Option key={type} value={type}>
                      {type}
                    </Option>
                  ))}
                </Select>
              </Col>
            </Row>
          </Col>
          <Col xs={24} sm={12} md={12} lg={8} xl={6}>
            <Row align="middle" gutter={8} wrap={false}>
              <Col>Sub Type:</Col>
              <Col flex="auto">
                <Select
                  mode="multiple"
                  style={{ width: "100%" }}
                  onChange={handleTaskSubTypeFilter}
                  value={
                    searchParams.requestSubTypes
                      ? searchParams.requestSubTypes.split(",")
                      : []
                  }
                  maxTagCount={1}
                  placeholder="Filter by Request Subtype"
                  allowClear
                >
                  {Object.entries(subTypes).map(([mainType, subTypeArray]) => (
                    <OptGroup label={mainType} key={mainType}>
                      {subTypeArray.map(subType => (
                        <Option key={`${mainType}-${subType}`} value={subType}>
                          {subType}
                        </Option>
                      ))}
                    </OptGroup>
                  ))}
                </Select>
              </Col>
            </Row>
          </Col>
          <Col xs={24} sm={12} md={12} lg={8} xl={6}>
            <Row align="middle" gutter={8} wrap={false}>
              <Col>Status:</Col>
              <Col flex="auto">
                <Select
                  mode="multiple"
                  style={{ width: "100%" }}
                  onChange={handleStatusFilter}
                  value={
                    searchParams.status ? searchParams.status.split(",") : []
                  }
                  maxTagCount={1}
                  placeholder="Filter by Status"
                  allowClear
                >
                  {Object.keys(statusTypes).map(status => (
                    <Option key={status} value={statusTypes[status]}>
                      {status}
                    </Option>
                  ))}
                </Select>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row gutter={[16, 16]} style={{ marginBottom: 16 }} wrap>
          {level !== "CHANNEL_PARTNER" && (
            <>
              <Col xs={24} sm={12} md={12} lg={8} xl={6}>
                <Row align="middle" gutter={8} wrap={false}>
                  <Col flex="110px" style={{ minWidth: 110 }}>
                    Client Type:
                  </Col>
                  <Col flex="auto">
                    <Select
                      mode="multiple"
                      defaultValue="all"
                      style={{ width: "100%" }}
                      onChange={handleClientTypeFilter}
                      value={
                        searchParams.clientTypes
                          ? searchParams.clientTypes.split(",")
                          : []
                      }
                      maxTagCount={1}
                      placeholder="Filter by Client Type"
                    >
                      {Object.keys(clientTypes).map(type => (
                        <Option key={type} value={clientTypes[type]}>
                          {type}
                        </Option>
                      ))}
                    </Select>
                  </Col>
                </Row>
              </Col>
            </>
          )}
          <Col xs={24} sm={12} md={12} lg={8} xl={6}>
            <Row align="middle" gutter={8} wrap={false}>
              <Col flex="110px" style={{ minWidth: 110 }}>
                Urgency:
              </Col>
              <Col flex="auto">
                <Select
                  mode="multiple"
                  style={{ width: "100%" }}
                  onChange={handleUrgencyFilter}
                  value={
                    searchParams.urgency ? searchParams.urgency.split(",") : []
                  }
                  maxTagCount={1}
                  placeholder="Filter by Urgency"
                  allowClear
                >
                  {Object.keys(urgencyTypes).map(urgency => (
                    <Option key={urgency} value={urgencyTypes[urgency]}>
                      <Badge
                        color={urgencyColors[urgencyTypes[urgency]]}
                        style={{ marginRight: 8 }}
                      />
                      {urgency}
                    </Option>
                  ))}
                </Select>
              </Col>
            </Row>
          </Col>
        </Row>
        <Table
          columns={columns}
          dataSource={task}
          loading={tasksLoading}
          pagination={{
            ...taskBoardPagination,
            showSizeChanger: true,
            hideOnSinglePage: false,
          }}
          onChange={handleTableChange}
          scroll={{ x: 1000 }}
        />
      </Card>
    </>
  );
};

export default withRouter(TaskBoard);
