import gql from "graphql-tag";

const EDITORIAL = gql`
  query Editorial($id: ID!) {
    editorial: editorial(where: { id: $id }) {
      id
      startDate
      endDate
      name
      status
      transactions
      revenue
      customers
      clicks
      domain
      url
      byDate {
        transactions
        revenue
        customers
        clicks
        date
      }
      clicksTag {
        id
        orgs(first: 1) {
          website
        }
      }
      transactionsTag {
        id
      }
      transactionsTags {
        id
        trackMesurements
        eventsTarget {
          id
          name
          orgs {
            id
            name
            website
          }
          eventTags {
            id
            appId
          }
          pageViews
          signups
          transactions
          basketItems
          isAdvertiser
        }
      }
      reports(where: { type: EDITORIAL }, orderBy: createdAt_DESC, first: 1) {
        id
        key
      }
      locations {
        id
        name
        description
        street
        city
        state
        province
        lat
        lng
        formattedAddress
        email
        locationKey
        phone
        contact
        url
        zip
        country
        country
        catalogs {
          id
          name
        }
        hours
        createdAt
        updatedAt
        org {
          id
          name
        }
        geoframe {
          id
          name
          start
          end
          polygons {
            id
            geometry
          }
          circles {
            id
            coordinate {
              lat
              lng
            }
            radius
          }
        }
        transConfig {
          id
          cart
        }
        audienceType
        createdBy {
          name
        }
        updatedBy {
          name
        }
      }
      trackMesurements
    }
    transactionsReport: editorial(where: { id: $id }) {
      id
      reports(where: { type: EDITORIAL }, orderBy: createdAt_DESC, first: 1) {
        id
        key
      }
    }
    pageViewsReport: editorial(where: { id: $id }) {
      id
      reports(
        where: { type: EDITORIAL_PAGEVIEW }
        orderBy: createdAt_DESC
        first: 1
      ) {
        id
        key
      }
    }
    signUpsSummaryReport: editorial(where: { id: $id }) {
      id
      reports(
        where: { type: EDITORIAL_SIGNUPS_SUMMARY }
        orderBy: createdAt_DESC
        first: 1
      ) {
        id
        key
      }
    }
    signUpsListReport: editorial(where: { id: $id }) {
      id
      reports(
        where: { type: EDITORIAL_SIGNUPS }
        orderBy: createdAt_DESC
        first: 1
      ) {
        id
        key
      }
    }
  }
`;

export default EDITORIAL;
