import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import TaskDetailsView from "../components/TaskDetailsView";
import TaskForm from "../components/TaskForm";
import Task from "../components/Task";
import { TaskProvider } from "../../../../core/components/tasks/TaskProvider";
import { OrgProvider } from "../../../../core/components/orgs/OrgProvider";
import { UserProvider } from "../../../../core/components/users/UserProvider";
import { CampaignOrderProvider } from "../../../../core/components/campaingOrder/CampaignOrderProvider";

const TaskManagementRoutes = props => {
  const { path } = props.match;

  return (
    <UserProvider>
      <OrgProvider>
        <CampaignOrderProvider>
          <TaskProvider>
            <Switch>
              <Route
                path={`${path}/main/:pageNumber?`}
                exact
                render={() => <Task />}
              />
              <Route
                path={`${path}/unassigned/:pageNumber?`}
                exact
                render={() => <Task />}
              />
              <Route
                path={`${path}/form/:id?`}
                exact
                render={() => <TaskForm />}
              />
              <Route
                path={`/tasks/details/:id?`}
                exact
                render={() => <TaskDetailsView />}
              />
              {/* Fallback */}
              <Route
                path={`${path}`}
                render={() => <Redirect to={`${path}/main/1`} />}
              />
            </Switch>
          </TaskProvider>
        </CampaignOrderProvider>
      </OrgProvider>
    </UserProvider>
  );
};

export default TaskManagementRoutes;
