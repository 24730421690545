import React from "react";
import { withRouter } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import { graphql } from "react-apollo";
import compose from "lodash/flowRight";
import CURRENT_USER from "../../../../GraphQl/Queries/CURRENT_USER";
import GET_SIGNED_URL from "../../../../GraphQl/Queries/GET_SIGNED_URL";
import OverlaySpinner from "../../../../../platform/shared/OverlaySpinner";
import ErrorPage from "../../../../../platform/shared/ErrorPage";

const AdvertiserDetailsSignupsLoader = props => {
  const { signups, advertiser, setReport, setCreatives, creatives } = props;

  const {
    data: signedURL,
    loading: signedURLsLoading,
    error: signedURLsError,
  } = useQuery(GET_SIGNED_URL, {
    variables: {
      key: signups.key,
    },
    fetchPolicy: "no-cache",
  });

  if (signedURLsLoading) return <OverlaySpinner />;
  if (signedURLsError) return <ErrorPage />;
  return React.cloneElement(props.children, {
    ...props.children.props,
    signedURL,
    advertiser,
    setReport,
    setCreatives,
    creatives,
  });
};

export default compose(
  graphql(CURRENT_USER, {
    props: ({ data: { currentUser }, ownProps }) => {
      return ownProps.currentUser || { currentUser };
    },
  })
)(withRouter(AdvertiserDetailsSignupsLoader));
