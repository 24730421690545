import React, { useEffect, useState } from "react";
import { PageContentLayout, PageHeaderLayout } from "../../../shared/layout";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { TagsOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  message,
  Radio,
  Row,
  Select,
  Typography,
} from "antd";
import { v4 } from "uuid";

const { Text } = Typography;
const { Option } = Select;

const TagsManagerView = ({
  onSubmit,
  advertisers,
  currentOrgId,
  headerSubmit,
  tagType,
  carts,
  orgs,
  isUpdate = false,
  platforms,
  onCard = true,
  details, // If is update details value is populated
  measurementTags, // Exclusive Input for Publisher Domain Manager
}) => {
  const [form] = Form.useForm();
  const [ecommerceSetup, setEcommerceSetup] = useState(null);
  const [isMultiTagType, setIsMultiTagType] = useState("no");

  // Filters for editing of tags by type
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const source = searchParams.get("source");

  if (source) {
    switch (source) {
      case "impression":
        tagType = "Impression";
        break;
      case "publisher":
        tagType = "Publisher";
        break;
      default:
        tagType = "Measurement";
        break;
    }
  }

  if (details) {
    if (details.isPublisherDomain) tagType = "Publisher";
    else if (details.isAdvertiser) tagType = "Impression";
  }

  const domainLookUp = orgId => {
    let domain = null;
    const advertiser = orgs.orgs.find(org => orgId === org.id);
    const parentChannelPartner = advertiser.parentOrg.find(
      org => org.level === "CHANNEL_PARTNER" && org.tagDomain !== null
    );
    if (advertiser.level === "CHANNEL_PARTNER") {
      domain = advertiser.tagDomain;
    } else if (parentChannelPartner) {
      domain = parentChannelPartner.tagDomain;
    }
    if (!domain) {
      domain = "https://tags.cnna.io";
    }
    return domain;
  };

  const dspAdvertiserId = orgId => {
    if (!orgs) return null;
    const org = orgs.orgs.find(org => orgId === org.id);
    return org && org.dataConfig && org.dataConfig.dspAdvertiserId;
  };

  const orgAdvertiserId =
    tagType === "Measurement" ? dspAdvertiserId(currentOrgId) : null;

  // Initial Values for Measurement tag manager form
  let initialValuesMeasurement = {};

  useEffect(() => {
    if (isUpdate && ecommerceSetup === null)
      setEcommerceSetup(initialValuesMeasurement.ecommerce);
  }, [ecommerceSetup, initialValuesMeasurement, isUpdate]);

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      await onSubmit(values);
      await message.success(isUpdate ? "Tag Updated" : "Tag Created");
      form.resetFields();
    } catch (error) {
      console.log(error);
      const errorMessage =
        error && error.message ? error.message : "Unknown error occured";
      message.error(`Failed to create a tag: ${errorMessage}`);
    }
  };

  const validateNoSpecialChars = (_, value) => {
    const regex = /[!@#$%{}^&*()_+=;:\\|,<>?]/; // Allows only alphanumeric characters and spaces
    if (value && regex.test(value)) {
      return Promise.reject(new Error("No special characters allowed!"));
    }
    return Promise.resolve();
  };

  if (isUpdate) {
    if (tagType === "Measurement") {
      initialValuesMeasurement = {
        advertiser: details.advertiser,
        appId: details.appId,
        domain: domainLookUp(details.advertiser),
        ecommerce: details.ecommerce ? details.ecommerce : false,
        name: details.name,
        platform: details.cart,
        signups: details.signups ? details.signups : false,
        dspTracking: details.dspTracking ? details.dspTracking : false,
        orgAdvertiserId: orgAdvertiserId,
      };
    } else if (tagType === "Publisher") {
      initialValuesMeasurement = {
        advertiser: details.advertiser,
        appId: details.appId,
        domainUrl: "",
        name: details.name,
        domainProtocol: "https://",
        domainExtension: ".com",
        domain: domainLookUp(currentOrgId),
        isMulti: "no",
      };

      if (details.publisherDomain) {
        const parsedUrl = new URL(
          details.publisherDomain ? details.publisherDomain : ""
        );

        const extenstion =
          "." +
          parsedUrl.hostname.split(".")[
            parsedUrl.hostname.split(".").length - 1
          ];

        initialValuesMeasurement.domainUrl = parsedUrl.hostname.replace(
          extenstion,
          ""
        );
        initialValuesMeasurement.domainExtension = parsedUrl.protocol + "//";
        initialValuesMeasurement.domainExtension = extenstion;
      }

      if (details.type && details.type.length) {
        if (details.type.length > 1) initialValuesMeasurement.isMulti = "yes";
      }
    } else if (tagType === "Impression") {
      initialValuesMeasurement = {
        dsp: details.impressionEnvironments
          ? details.impressionEnvironments
          : details.dsp,
        name: details.name,
        appId: details.appId,
        advertiser: details.advertiser,
      };
    }
  } else {
    if (tagType === "Measurement") {
      initialValuesMeasurement = {
        ecommerce: false,
        signups: false,
        advertiser: currentOrgId,
        appId: v4(),
        domain: domainLookUp(currentOrgId),
        dspTracking: false,
        orgAdvertiserId: orgAdvertiserId,
      };
    } else if (tagType === "Publisher") {
      initialValuesMeasurement = {
        advertiser: currentOrgId,
        domainProtocol: "https://",
        domainExtension: ".com",
        isMulti: "no",
        domain: domainLookUp(currentOrgId),
      };
    }
  }

  const impressionsItems = (
    <>
      <Form.Item
        label="Impression Tag Name"
        name="name"
        rules={[{ required: true, message: "Name Required" }]}
      >
        <Input placeholder="Enter tag name" />
      </Form.Item>
      {isUpdate && (
        <Form.Item
          label="App ID"
          name="appId"
          rules={[{ required: true, message: "App ID Required" }]}
        >
          <Input placeholder="Enter App ID" />
        </Form.Item>
      )}
      <Form.Item
        label="Advertising Platform"
        name="dsp"
        rules={[
          {
            required: true,
            message: "Advertising Platform Required",
          },
        ]}
      >
        <Select
          style={{ width: "100%" }}
          placeholder="Select Advertising Platform"
          options={platforms}
        />
      </Form.Item>
      <Form.Item name="advertiser" hidden noStyle>
        <Input type="hidden" />
      </Form.Item>
    </>
  );

  const publisherDomainItems = (
    <>
      <Row gutter={[11, 11]}>
        <Col span={24}>
          <Form.Item
            label="Publisher Tag Name"
            name="name"
            hidden={isMultiTagType === "exist"}
            rules={[
              () => ({
                validator(_, value) {
                  //If process with google ads is enabled then google ads customer id is required
                  if (isMultiTagType !== "exist") {
                    if (value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(`Name Required`);
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input placeholder="Enter tag name" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            name={"advertiser"}
            rules={[
              () => ({
                validator(_, value) {
                  //If process with google ads is enabled then google ads customer id is required
                  if (isMultiTagType !== "exist") {
                    if (value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(`Advertiser Required`);
                  }
                  return Promise.resolve();
                },
              }),
            ]}
            label={"Advertiser"}
            hidden={isMultiTagType === "exist"}
          >
            <Select
              placeholder="Select Advertiser"
              options={advertisers}
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          {isUpdate ? (
            <Form.Item
              label="App ID"
              name="appId"
              rules={[{ required: true, message: "App ID Required" }]}
            >
              <Input placeholder="Enter App ID" />
            </Form.Item>
          ) : null}
        </Col>
        <Col span={24}>
          <Form.Item
            label="Domain"
            name="domainUrl"
            rules={[
              {
                required: true,
                message: "Domain Required",
              },
              {
                max: 45,
                message: "Maximum length is 45 characters",
              },
              { validator: validateNoSpecialChars },
            ]}
          >
            <Input
              addonBefore={
                <Select
                  defaultValue={"https://"}
                  onChange={val => {
                    form.setFieldsValue({ domainProtocol: val });
                  }}
                >
                  <Option value="http://">http://</Option>
                  <Option value="https://">https://</Option>
                </Select>
              }
              addonAfter={
                <Select
                  defaultValue=".com"
                  onChange={val => {
                    form.setFieldsValue({ domainExtension: val });
                  }}
                >
                  <Option value=".com">.com</Option>
                  <Option value=".net">.net</Option>
                  <Option value=".edu">.edu</Option>
                  <Option value=".org">.org</Option>
                  <Option value=".gov">.gov</Option>
                  <Option value=".mil">.mil</Option>
                </Select>
              }
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            name={"isMulti"}
            label={"Will the tag acts as a Measurement Tags?"}
          >
            <Radio.Group onChange={val => setIsMultiTagType(val.target.value)}>
              <Radio value={"no"}>No</Radio>
              <Radio value={"yes"}>Yes</Radio>
              {!isUpdate ? (
                <Radio value={"exist"}>Yes, Use existing Measuerment Tag</Radio>
              ) : null}
            </Radio.Group>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            name={"measurementTag"}
            hidden={isMultiTagType !== "exist"}
            rules={[
              () => ({
                validator(_, value) {
                  //If process with google ads is enabled then google ads customer id is required
                  if (isMultiTagType === "exist") {
                    if (value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(`Measurement Tag Required`);
                  }
                  return Promise.resolve();
                },
              }),
            ]}
            label={"Which tag should be used?"}
          >
            <Select
              placeholder="Select Measurement Tags"
              options={measurementTags}
              style={{ width: "100%" }}
              showSearch
            />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item name="domainProtocol" noStyle hidden>
        <Input type="hidden" />
      </Form.Item>
      <Form.Item name="domainExtension" noStyle hidden>
        <Input type="hidden" />
      </Form.Item>
      <Form.Item label="Tag Domain" name="domain" hidden noStyle>
        <Input type="hidden" />
      </Form.Item>
    </>
  );

  return (
    <React.Fragment>
      {headerSubmit ? (
        <PageHeaderLayout
          title={"Create New Tag"}
          titleIcon={<TagsOutlined />}
          rightContent={
            <Button
              shape="round"
              type="primary"
              onClick={() => {
                form.validateFields().then(async values => {
                  message.success("Tag Created");
                  await onSubmit(values);
                });
              }}
            >
              Create Tag
            </Button>
          }
        />
      ) : null}

      <PageContentLayout>
        {tagType === "Measurement" && (
          <Form
            form={form}
            layout="vertical"
            name={"createTag"}
            initialValues={initialValuesMeasurement}
          >
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Card title={<Text strong>BASICS</Text>}>
                  <Row gutter={[18, 18]}>
                    <Col span={8}>
                      <Form.Item
                        name={"name"}
                        rules={[
                          { required: true, message: "Name is required" },
                          {
                            max: 45,
                            message: "Maximum length is 45 characters",
                          },
                          { validator: validateNoSpecialChars },
                        ]}
                        label={"Tag Name"}
                      >
                        <Input placeholder="Input tag name" maxLength={46} />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        name={"advertiser"}
                        rules={[
                          { required: true, message: "Advertiser Required" },
                        ]}
                        label={"Advertiser"}
                      >
                        <Select
                          placeholder="Select Advertiser"
                          options={advertisers}
                          showSearch
                          filterOption={(input, option) =>
                            option.label
                              .toLowerCase()
                              .includes(input.toLowerCase().trim())
                          }
                          onChange={val => {
                            form.setFieldsValue({ domain: domainLookUp(val) });
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={[16, 16]}>
                    <Col span={8}>
                      {isUpdate ? (
                        <Form.Item
                          label="App ID"
                          name="appId"
                          rules={[
                            { required: true, message: "App ID Required" },
                          ]}
                        >
                          <Input placeholder="Enter App ID" />
                        </Form.Item>
                      ) : (
                        <Form.Item
                          label="App ID"
                          name="appId"
                          rules={[
                            { required: true, message: "App ID Required" },
                          ]}
                          hidden
                          noStyle
                        >
                          <Input type="hidden" />
                        </Form.Item>
                      )}
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        label="Tag Domain"
                        name="domain"
                        rules={[
                          { required: true, message: "Tag Domain Required" },
                        ]}
                        hidden
                        noStyle
                      >
                        <Input type="hidden" />
                      </Form.Item>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col span={24}>
                <Card title={<Text strong>ECOMMERCE</Text>}>
                  <Row gutter={[18, 18]}>
                    <Col span={8}>
                      <Form.Item
                        name={"ecommerce"}
                        label={"Will the tag measure ecommerce?"}
                      >
                        <Radio.Group
                          onChange={val => setEcommerceSetup(val.target.value)}
                        >
                          <Radio value={true}>Yes</Radio>
                          <Radio value={false}>No</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        name={"platform"}
                        rules={[
                          () => ({
                            validator(_, value) {
                              //If process with google ads is enabled then google ads customer id is required
                              if (ecommerceSetup) {
                                if (value) {
                                  return Promise.resolve();
                                }
                                return Promise.reject(`Cart Required`);
                              }
                              return Promise.resolve();
                            },
                          }),
                        ]}
                        label={"Which shopping cart platform is being used?"}
                        hidden={!ecommerceSetup}
                      >
                        <Select
                          placeholder="Selected Advertiser"
                          options={carts}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col span={24}>
                <Card title={<Text strong>SIGN-UPS</Text>}>
                  <Row gutter={[18, 18]}>
                    <Col span={8}>
                      <Form.Item
                        name={"signups"}
                        label={"Will this tag measure sign-ups?"}
                      >
                        <Radio.Group>
                          <Radio value={true}>Yes</Radio>
                          <Radio value={false}>No</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col span={24}>
                <Card title={<Text strong>DSP Tracking</Text>}>
                  <Row gutter={[18, 18]}>
                    <Col span={8}>
                      <Form.Item
                        name={"dspTracking"}
                        label={"Will this tag have DSP-Level Tracking?"}
                      >
                        <Radio.Group
                          disabled={
                            orgAdvertiserId === null ||
                            orgAdvertiserId === "" ||
                            orgAdvertiserId === undefined ||
                            orgAdvertiserId === "NEXXEN"
                          }
                          defaultValue={false}
                        >
                          <Radio value={true}>Yes</Radio>
                          <Radio value={false}>No</Radio>
                        </Radio.Group>
                      </Form.Item>
                      <Form.Item
                        name={"orgAdvertiserId"}
                        defaultValue={orgAdvertiserId}
                      ></Form.Item>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Form>
        )}

        {tagType === "Impression" && (
          <Form
            form={form}
            layout="vertical"
            name={"createImpressionsTag"}
            initialValues={initialValuesMeasurement}
          >
            <Row gutter={[24, 24]}>
              <Col span={24}>
                {onCard ? (
                  <Card title={<Text strong>Impressions Tag</Text>}>
                    {impressionsItems}
                  </Card>
                ) : (
                  impressionsItems
                )}
              </Col>
            </Row>
          </Form>
        )}

        {tagType === "Publisher" && (
          <Form
            form={form}
            layout="vertical"
            name={"createPublisherTag"}
            initialValues={initialValuesMeasurement}
          >
            <Row gutter={[16, 16]}>
              <Col span={24}>
                {onCard ? (
                  <Card title={<Text strong>Publisher Domain</Text>}>
                    {publisherDomainItems}
                  </Card>
                ) : (
                  publisherDomainItems
                )}
              </Col>
            </Row>
          </Form>
        )}
        {!headerSubmit ? (
          <Col span={24}>
            <Row align="end" style={{ marginTop: "16px" }}>
              <Button shape="round" type="primary" onClick={handleSubmit}>
                {isUpdate ? "Update Tag" : "Create Tag"}
              </Button>
            </Row>
          </Col>
        ) : null}
      </PageContentLayout>
    </React.Fragment>
  );
};

TagsManagerView.propTypes = {
  onSubmit: PropTypes.func,
  providers: PropTypes.array,
  advertisers: PropTypes.array,
  currentOrgId: PropTypes.string,
  headerSubmit: PropTypes.bool,
  tagType: PropTypes.oneOf(["Measurement", "Impression", "Publisher"]),
  isUpdate: PropTypes.bool,
};

export default TagsManagerView;
