import React from "react";
import AdvertiserDetailsTransactionsReportsLoader from "../../../../../core/components/advertiser/advertiserdetails/transactions/AdvertiserDetailsTransactionsReportsLoader";
import AdvertiserDetailsTransactionsReportController from "../../../../../core/components/advertiser/advertiserdetails/transactions/AdvertiserDetailsTransactionsReportController";
import { HasAccess } from "@permify/react-role";
import DetailBar from "../components/DetailBar";
import { LoadingPage } from "../../../../shared/LoadingPage";
import { Row, Col } from "antd";
import DualAxisChart from "../../../../shared/charts/DualAxisChart";
import { summaryScale } from "../../utils/summaryScale";
import moment from "moment";
import TransactionsTable from "../components/TransactionsTable";
import TopCreatives from "../components/TopCreatives";
import TopImpressionDomains from "../components/TopImpressionDomains";
import TopInventoryTypes from "../components/TopInventoryTypes";

const TransactionsDetailsView = props => {
  return (
    <AdvertiserDetailsTransactionsReportsLoader {...props}>
      <AdvertiserDetailsTransactionsReportController>
        <TransactionsTab />
      </AdvertiserDetailsTransactionsReportController>
    </AdvertiserDetailsTransactionsReportsLoader>
  );
};

const TransactionsTab = ({
  advertiser,
  reportDataSummary,
  reportDataSummaryLoading,
  changeDatePicker,
  topCreatives,
  topImpressions,
  topInventoryTypes,
}) => {
  if (reportDataSummaryLoading) return <LoadingPage />;
  const { byDates, transactions } = reportDataSummary;

  const {
    impressionsTag: { dsp },
  } = advertiser;

  const dropDownOptions = [
    { key: "impressions", display: "Impressions" },
    { key: "transactions", display: "Transactions" },
    { key: "revenue", display: "Revenue" },
    { key: "customers", display: "Customers" },
  ];
  return (
    <HasAccess permissions={["default", "defaultSSO", "advertiserSSO"]}>
      <DetailBar
        {...reportDataSummary.summary}
        startDate={advertiser.startDate}
        endDate={advertiser.endDate}
        module="TRANSACTIONS"
      />

      <Row gutter={[16, 16]}>
        <Col xs={24}>
          <DualAxisChart
            byDate={byDates}
            xAxis="date"
            options={dropDownOptions}
            defaultLeftYValue={dropDownOptions[0]}
            defaultRightYValue={dropDownOptions[1]}
            customScale={summaryScale}
            onDatePickerChange={({ data, dates, rangeKey }) => {
              changeDatePicker(dates);
            }}
            has6MonDisables={false}
            allTimeDateRange={[
              moment(advertiser.startDate),
              moment(advertiser.endDate),
            ]}
          />
        </Col>
        <Col xs={24}>
          <TransactionsTable
            data={transactions ? transactions : []}
            total={0}
            topImpressions={topImpressions}
            dsp={dsp}
          />
        </Col>
        <Col xs={24}>
          <TopCreatives data={topCreatives} />
        </Col>
        <Col xs={24}>
          <TopImpressionDomains data={topImpressions} />
        </Col>
        <Col xs={24}>
          <TopInventoryTypes data={topInventoryTypes} />
        </Col>
      </Row>
    </HasAccess>
  );
};

export default TransactionsDetailsView;
