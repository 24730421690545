import { Input, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useCampaignRequestorContext } from "../../../../core/components/campaignRequestor/useCampaignRequestorContext";
import { useCampaignOrderQueueContext } from "../../../../core/components/campaigns/campaignordersqueue/useCampaignOrderQueueContext";
const { Search } = Input;

const CampaignOrderQueueFilters = ({
  handleFilterChanged,
  filteredOrgId,
  setFilteredOrgId,
}) => {
  const [advertisers, setAdvertisers] = useState([]);
  const { pagination } = useCampaignOrderQueueContext();
  const {
    loadingChildrenOrgs,
    childrenOrg,
    currentUserOrg,
    fetchChildrenOrgs,
  } = useCampaignRequestorContext();

  useEffect(() => {
    if (childrenOrg && childrenOrg.length) {
      setAdvertisers(
        childrenOrg.map(org => ({
          value: org.id,
          label: org.name,
        }))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [childrenOrg]);

  const handleFetchAdvertisers = (search = "") => {
    fetchChildrenOrgs({
      variables: {
        where: {
          OR: [
            { parentOrg_some: { id: currentUserOrg.id } },
            { id: currentUserOrg.id },
          ],
          name_contains: search,
        },
        orderBy: "name_ASC",
        first: 10,
      },
    });
  };

  const handleFilterCampaignsByAdvertiser = orgId => {
    setFilteredOrgId(orgId);
    handleFilterChanged({
      first: pagination.pageSize,
      orgId,
    });
  };

  const handleSearchChange = name => {
    handleFilterChanged({
      first: pagination.pageSize,
      name,
      orgId: filteredOrgId,
    });
  };

  return (
    <div style={{ display: "flex", gap: "10px" }}>
      <Select
        style={{
          width: 200,
        }}
        showSearch
        placeholder="Select Advertiser"
        optionFilterProp="children"
        loading={loadingChildrenOrgs}
        onFocus={() => handleFetchAdvertisers()}
        onSearch={searchText => handleFetchAdvertisers(searchText)}
        onSelect={value => handleFilterCampaignsByAdvertiser(value)}
        filterOption={(input, option) => {
          return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
        }}
        options={advertisers}
      />
      <Search
        placeholder="Search campaign"
        style={{
          width: 200,
        }}
        onSearch={value => handleSearchChange(value)}
        allowClear
      />
    </div>
  );
};

export default CampaignOrderQueueFilters;
