import React, { useState } from "react";
import { PageContentLayout, PageHeaderLayout } from "../../../shared/layout";
import { AreaChartOutlined, ToolOutlined } from "@ant-design/icons";
import { withRouter } from "react-router-dom";
import { Tabs, Space, Result, Card } from "antd";
import Button from "../../../shared/globalStyling/Button";
import {
  TabText,
  PageTitleText,
  ButtonText,
} from "../../../shared/globalStyling/styledText";
import CommerceTab from "./tabs/commerce";
import ExportCSVButton from "./components/ExportCSVButton";
import ExportExcelButton from "./components/ExportExcelButton";
import AdminModal from "./components/AdminModal";
import BingDetailsView from "./tabs/bing/BingDetailsView";
import GoogleDetailsView from "./tabs/google/GoogleDetailsView";
import AggregatedDetailsView from "./tabs/summary/AggregatedDetailsView";
import StatusTag from "../components/StatusTag";
import { PaidSearchLatencyDoc } from "../components";
import CommerceDetailsView from "./tabs/commerce/CommerceDetailsVIew";
import { orgLevelCustomization } from "../../../../core/permissions/orgLevelCustomization";
import EditPaidSearch from "./components/EditPaidSearch";

const { TabPane } = Tabs;
const PaidSearchDetailsView = ({
  history,
  location,
  match,
  selectedTab,
  summary,
  orgName,
  orgId,
  loadingCampaigns,
  generateReport,
  orgConfig,
  updateConfig,
  dataConfig,
  selectedSubTab,
  isMultiChannel,
  hasGoogle,
  hasBing,
  hasCommerce,
  reportCampaigns,
  setGoogleReport,
  setGoogleRevenueLocations,
  setReportBing,
  reprocessPaidSearch,
  currentUser,
  eventTags,
  updateStatus,
}) => {
  const { config, id, status, failedChannel } = summary;
  const { isTrafficker, isAdmin, exportData } = orgConfig;
  const orgCustomization = orgLevelCustomization(
    currentUser.role.org.dataConfig || {}
  );
  const { currencyFormat, roasFormat } = orgCustomization;
  // Set Document Title Dynamically
  let searchParams = new URLSearchParams(location.search);
  const selected = searchParams.get("tab");
  document.title = `${orgName} - ${
    selected ? (selected === "overview" ? "Display" : selected) : "Summary"
  }`;
  const [visibleAdmin, setIsVisibleAdmin] = useState(false);
  //TODO: Put this in a separate file
  const TabTopUI = () => {
    return (
      <Space
        className="ant-space-xs-vertical ant-space-md-horizontal ant-space-xs-container"
        size={"small"}
      >
        {exportData && (
          <>
            <ExportCSVButton
              orgName={orgName}
              generateReport={generateReport}
              loadingCampaigns={loadingCampaigns}
              config={config}
              hasBing={hasBing}
              hasGoogle={hasGoogle}
            />
            <ExportExcelButton
              orgName={orgName}
              generateReport={generateReport}
              loadingCampaigns={loadingCampaigns}
              config={config}
              hasBing={hasBing}
              hasGoogle={hasGoogle}
            />
          </>
        )}
        {isAdmin && (
          <>
            <Button
              style={{ margin: "0px" }}
              icon={<ToolOutlined />}
              type="primary"
              onClick={async () => {
                setIsVisibleAdmin(true);
              }}
            >
              <ButtonText text={"Admin Tools"} />
            </Button>
          </>
        )}
        {isTrafficker && (
          <EditPaidSearch
            onSubmit={updateConfig}
            paidSearch={summary}
            tags={
              eventTags && eventTags.eventsTargets
                ? eventTags.eventsTargets
                : null
            }
          />
        )}
      </Space>
    );
  };
  return (
    <React.Fragment>
      <AdminModal
        visible={visibleAdmin}
        setVisible={setIsVisibleAdmin}
        reprocess={reprocessPaidSearch}
        status={status}
        updateStatus={updateStatus}
      />
      <PageHeaderLayout
        title={
          <Space
            className="ant-space-xs-vertical ant-space-md-horizontal"
            size="small"
          >
            <PageTitleText text={orgName} />
            <StatusTag
              status={status}
              noAccessChannels={status === "NO_ACCESS" ? failedChannel : null}
            />
          </Space>
        }
        titleIcon={
          <AreaChartOutlined
            style={{
              marginRight: "10px",
              color: "lightgrey",
              fontSize: 20,
            }}
          />
        }
        rightContent={<TabTopUI />}
      />
      <PageContentLayout>
        {isMultiChannel && (
          <Tabs
            activeKey={selectedTab}
            onChange={selected => {
              let searchParams = new URLSearchParams(location.search);
              if (searchParams.get("tab")) searchParams.delete("tab");
              if (searchParams.get("subTab")) searchParams.delete("subTab");
              searchParams.append("tab", selected);
              history.push(`${location.pathname}?${searchParams.toString()}`);
            }}
          >
            <TabPane tab={<TabText text={"Summary"} />} key="summary">
              <AggregatedDetailsView
                id={id}
                orgConfig={orgConfig}
                currencyFormat={currencyFormat}
                roasFormat={roasFormat}
              />
            </TabPane>
            {summary.overallCommerce &&
            false && ( //Temporary Disable Commerce Tab
                <TabPane
                  tab={<TabText text={"Multi Clicks Commerce"} />}
                  key="multiclicks"
                >
                  <CommerceTab
                    orgId={orgId}
                    data={summary.overallCommerce}
                    orgConfig={orgConfig}
                  />
                </TabPane>
              )}
            {hasGoogle && (
              <TabPane tab={<TabText text={"Google"} />} key="google">
                <GoogleDetailsView
                  id={id}
                  match={match}
                  location={location}
                  history={history}
                  reportCampaigns={reportCampaigns}
                  orgId={orgId}
                  selectedTab={selectedSubTab}
                  orgConfig={orgConfig}
                  config={config}
                  dataConfig={dataConfig}
                  setGoogleReport={setGoogleReport}
                  setGoogleRevenueLocations={setGoogleRevenueLocations}
                  currentUser={currentUser}
                  currencyFormat={currencyFormat}
                  roasFormat={roasFormat}
                />
              </TabPane>
            )}

            {hasBing && (
              <TabPane tab={<TabText text={"Bing"} />} key="bing">
                <BingDetailsView
                  id={id}
                  orgConfig={orgConfig}
                  location={location}
                  history={history}
                  orgId={orgId}
                  selectedTab={selectedSubTab}
                  setReportBing={setReportBing}
                  currencyFormat={currencyFormat}
                  roasFormat={roasFormat}
                />
              </TabPane>
            )}

            {hasCommerce && (
              <TabPane
                tab={<TabText text={"Multi Clicks"} />}
                key="multiClicks"
              >
                <CommerceDetailsView
                  orgId={id}
                  orgConfig={orgConfig}
                  location={location}
                  match={match}
                  history={history}
                />
              </TabPane>
            )}
          </Tabs>
        )}
        {!isMultiChannel && hasGoogle && (
          <GoogleDetailsView
            id={id}
            match={match}
            location={location}
            history={history}
            reportCampaigns={reportCampaigns}
            orgId={orgId}
            selectedTab={selectedSubTab}
            orgConfig={orgConfig}
            config={config}
            dataConfig={dataConfig}
            setGoogleReport={setGoogleReport}
            setGoogleRevenueLocations={setGoogleRevenueLocations}
            currencyFormat={currencyFormat}
            roasFormat={roasFormat}
          />
        )}
        {!isMultiChannel && hasBing && (
          <BingDetailsView
            id={id}
            orgConfig={orgConfig}
            location={location}
            history={history}
            orgId={orgId}
            selectedTab={selectedSubTab}
            setReportBing={setReportBing}
            currencyFormat={currencyFormat}
            roasFormat={roasFormat}
          />
        )}
        {status && status === "PENDING" ? (
          <Card>
            <Result
              status={"404"}
              title={"Page Under Processing"}
              subTitle="Sorry, this page is currently undergoing processing. Please check back later"
            />
          </Card>
        ) : null}
        {status &&
        status === "PAUSED" &&
        !hasGoogle &&
        !hasBing &&
        !hasCommerce ? (
          <Card>
            <Result
              title={"Page Can't Be Processed"}
              subTitle="Sorry, This report are currently paused and can't be processed"
            />
          </Card>
        ) : null}
        <PaidSearchLatencyDoc />
      </PageContentLayout>
    </React.Fragment>
  );
};

export default withRouter(PaidSearchDetailsView);
