import gql from "graphql-tag";

const GET_CAMPAIGN_ORDER_REQUESTOR_ADVERTISER = gql`
  query CampaignRequestorAdvertiser($where: OrgWhereUniqueInput!) {
    org(where: $where) {
      id
      name
      cpm
    }
  }
`;

export default GET_CAMPAIGN_ORDER_REQUESTOR_ADVERTISER;
