import React, { useState } from "react";
import { withRouter, useHistory } from "react-router-dom";
import { PageContentLayout, PageHeaderLayout } from "../../../shared/layout";
import { Card, Row, Space, Table, Col, Modal } from "antd";
import { FormOutlined } from "@ant-design/icons";
import Button from "../../../shared/globalStyling/Button";
import { ButtonText } from "../../../shared/globalStyling/styledText";
import { StarOutlined } from "@ant-design/icons";
import FilterBar from "./components/FilterBar";
import {
  formatCurrency2SigFig,
  formatWholeNumberNaN,
} from "../../../../core/utils/campaigns";
import LaunchAdvertiser from "./components/LaunchAdvertiser";

const AdvertiserMainView = ({
  data,
  tags,
  currentOrgId,
  onCreateAdvertiser,
  filteredLocations,
  filteredAudiences,
  isSelfService,
}) => {
  const history = useHistory();
  const [openLaunch, setOpenLaunch] = useState(false);

  const columns = [
    {
      title: "Funnel Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: text => (text != null ? text : "-"),
    },
    {
      title: "Impressions",
      dataIndex: "totalImpressions",
      key: "totalImpressions",
      sorter: (a, b) => a.totalImpressions - b.totalImpressions,
      render: text => formatWholeNumberNaN(text),
    },
    {
      title: "Attributed Impressions",
      dataIndex: "impressions",
      key: "impressions",
      sorter: (a, b) => a.impressions - b.impressions,
      render: text => formatWholeNumberNaN(text),
    },
    {
      title: "Sales",
      dataIndex: "transactions",
      key: "transactions",
      sorter: (a, b) => a.transactions - b.transactions,
      render: text => formatWholeNumberNaN(text),
    },
    {
      title: "Revenue",
      dataIndex: "revenue",
      key: "revenue",
      sorter: (a, b) => a.revenue - b.revenue,
      render: text => formatCurrency2SigFig(text),
    },
  ];

  return (
    <React.Fragment>
      <Modal
        title={`Create Advertiser Funnel`}
        visible={openLaunch}
        footer={null}
        width={"75%"}
        onCancel={() => {
          setOpenLaunch(false);
        }}
      >
        <LaunchAdvertiser
          currentOrgId={currentOrgId}
          tags={tags}
          open={openLaunch}
          onCreate={values => {
            setOpenLaunch(false);
            onCreateAdvertiser(values);
          }}
          filteredLocations={filteredLocations}
          filteredAudiences={filteredAudiences}
          isSelfService={isSelfService}
        />
      </Modal>
      <PageHeaderLayout
        title="Advertiser"
        titleIcon={
          <StarOutlined
            style={{
              marginRight: "10px",
              color: "lightgrey",
              fontSize: 20,
            }}
          />
        }
        rightContent={
          <Row align="middle" justify="end">
            <Col flex="auto">
              <Space>
                <Button
                  onClick={() => {
                    setOpenLaunch(!openLaunch);
                  }}
                  type="primary"
                  ghost
                  icon={<FormOutlined />}
                >
                  <ButtonText text={"Add Funnel"} />
                </Button>
              </Space>
            </Col>
          </Row>
        }
      />
      <PageContentLayout>
        <Card
          title={
            <Row align="middle">
              <Col>
                <h3>Advertising Funnels</h3>
              </Col>
            </Row>
          }
          extra={
            <Row align="middle">
              <FilterBar />
            </Row>
          }
        >
          <Table
            // rowSelection={rowSelection}
            columns={columns}
            dataSource={data}
            onRow={(record, rowIndex) => {
              return {
                onClick: event => {
                  history.push(`/advertiser/details/${record.id}`);
                },
              };
            }}
          />
        </Card>
      </PageContentLayout>
    </React.Fragment>
  );
};

export default withRouter(AdvertiserMainView);
