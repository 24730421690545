import React, { useEffect } from "react";
import {
  Card,
  Form,
  Row,
  Col,
  Input,
  Tag,
  Modal,
  Button,
  Typography,
} from "antd";
import {
  PaperClipOutlined,
  PictureOutlined,
  CodeOutlined,
} from "@ant-design/icons";
import { uploadFileToS3 } from "../../../shared/upload/uploadFileToS3";
import { CopyBlock } from "react-code-blocks";
import { useState, useRef } from "react";
import { useTaskContext } from "../../../../core/components/tasks/useTaskContext";
import { message } from "antd";
import { S3Image } from "../../CampaignLauncher/components";
import S3File from "../../../shared/S3File";
import AvatarS3 from "../../../shared/images/AvatarS3";
import { getFileType } from "../../../../core/utils/constants/constants";
import { startCase } from "lodash";

const { Text } = Typography;

export const TaskComments = currentTask => {
  const [form] = Form.useForm();
  const [showCodeModal, setShowCodeModal] = useState(false);
  const textareaRef = useRef(null);
  // Mock comments data, pull data from actual API
  const {
    currentUser,
    createComment,
    createCommentLoading,
    fetchTask,
  } = useTaskContext();

  useEffect(() => {
    console.log(currentTask);
  }, [currentTask]);

  const currentTaskId =
    currentTask && currentTask.currentTask && currentTask.currentTask.id;
  const createdBy = startCase(
    currentTask &&
      currentTask.currentTask &&
      currentTask.currentTask.createdBy &&
      currentTask.currentTask.createdBy.name
      ? currentTask.currentTask.createdBy.name
      : currentTask &&
          currentTask.currentTask &&
          currentTask.currentTask.createdBy &&
          currentTask.currentTask.createdBy.username
  );
  const initialAvatar =
    currentTask &&
    currentTask.currentTask &&
    currentTask.currentTask.createdBy &&
    currentTask.currentTask.createdBy.avatar &&
    currentTask.currentTask.createdBy.avatar.key
      ? currentTask.currentTask.createdBy.avatar.key
      : null;

  const initialComments = {
    id: 1,
    createdBy: createdBy,
    avatar: initialAvatar,
    createdAt:
      currentTask &&
      currentTask.currentTask &&
      currentTask.currentTask.createdAt
        ? currentTask.currentTask.createdAt || new Date().toISOString()
        : "-",
    comment:
      currentTask &&
      currentTask.currentTask &&
      currentTask.currentTask.taskDetails
        ? currentTask.currentTask.taskDetails
        : "No task details available",
    attachedFiles:
      currentTask &&
      currentTask.currentTask &&
      currentTask.currentTask.attachedFiles
        ? currentTask.currentTask.attachedFiles
        : [],
    attachedImages:
      currentTask &&
      currentTask.currentTask &&
      currentTask.currentTask.attachedImages
        ? currentTask.currentTask.attachedImages
        : [],
    codeSnippet:
      currentTask &&
      currentTask.currentTask &&
      currentTask.currentTask.codeSnippet
        ? currentTask.currentTask.codeSnippet
        : [],
  };

  const [comments, setComments] = useState([initialComments]);
  const fileInputRef = useRef(null);
  const imageInputRef = useRef(null);

  const handleUploadFileClick = () => {
    fileInputRef.current.click();
  };

  const handleUploadImageClick = () => {
    imageInputRef.current.click();
  };

  const handleFileChange = e => {
    const files = Array.from(e.target.files);
    form.setFieldsValue({
      attachedFiles: [...(form.getFieldValue("attachedFiles") || []), ...files],
    });
    e.target.value = null;
  };

  const handleImageChange = e => {
    const files = Array.from(e.target.files);
    form.setFieldsValue({
      attachedImages: [
        ...(form.getFieldValue("attachedImages") || []),
        ...files,
      ],
    });
    e.target.value = null;
  };

  const handleRemoveFile = index => {
    const attachedFiles = form.getFieldValue("attachedFiles") || [];
    attachedFiles.splice(index, 1);
    form.setFieldsValue({ attachedFiles });
  };

  const handleRemoveImage = index => {
    const attachedImages = form.getFieldValue("attachedImages") || [];
    attachedImages.splice(index, 1);
    form.setFieldsValue({ attachedImages });
  };

  // const handleShowCodeModal = () => {
  //   setShowCodeModal(true);
  // };

  const handleEmbedCodeSnippet = () => {
    const codeSnippet = form.getFieldValue("attachedCodeSnippet");
    form.setFieldsValue({
      commentsCodeValue: codeSnippet,
      isCodeSnippet: true,
    });
    setShowCodeModal(false);
  };

  const handleUploadFilesToS3 = async files => {
    try {
      const medias = [];
      if (files.length) {
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          const media = await uploadFileToS3({ file });
          medias.push({
            ...media,
            fileType: getFileType(file.name),
          });
        }
      }
      return medias;
    } catch (error) {
      console.error(error);
      throw new Error("No greater than 2MB is allowed");
    }
  };

  const getTimeAgo = createdAt => {
    if (!createdAt || isNaN(new Date(createdAt))) {
      return "Invalid date";
    }

    const now = new Date();
    const commentDate = new Date(createdAt);
    const diffMs = now - commentDate; // Milliseconds difference

    // Calculate minutes and hours
    const diffMinutes = Math.floor(diffMs / (1000 * 60));
    const diffHours = Math.floor(diffMs / (1000 * 60 * 60));

    if (diffMinutes < 60) {
      return `${diffMinutes} minute${diffMinutes !== 1 ? "s" : ""} ago`;
    } else if (diffHours < 24) {
      return `${diffHours} hour${diffHours !== 1 ? "s" : ""} ago`;
    } else {
      return commentDate.toLocaleDateString("en-US");
    }
  };

  const linkify = text => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.split(urlRegex).map((part, index) => {
      if (part.match(urlRegex)) {
        return (
          <a
            href={part}
            key={index}
            style={{ color: "#007bff", textDecoration: "underline" }}
            target="_blank"
            rel="noopener noreferrer"
          >
            {part}
          </a>
        );
      }
      return part;
    });
  };

  const codeBlock = text => {
    const parts = [];
    const splitRegex = /(```[\s\S]*?```)/g;
    const codeBlockRegex = /```(\w+)?\n?([\s\S]*?)```/g;

    // Split text into normal text and code blocks
    const textParts = text.split(splitRegex);

    textParts.forEach(part => {
      if (part.startsWith("```")) {
        const match = codeBlockRegex.exec(part);
        if (match) {
          const [, lang, code] = match;
          parts.push({
            type: "code",
            content: code.trim(),
            language: lang || "plaintext",
          });
        }
        codeBlockRegex.lastIndex = 0;
      } else if (part) {
        parts.push({ type: "text", content: part });
      }
    });

    return parts;
  };

  const handleInsertCode = () => {
    const textarea =
      textareaRef.current &&
      textareaRef.current.resizableTextArea &&
      textareaRef.current.resizableTextArea.textArea;
    if (!textarea) return;

    const start = textarea.selectionStart;
    const end = textarea.selectionEnd;
    const currentValue = form.getFieldValue("comment") || "";
    const selectedText = currentValue.slice(start, end);

    let newValue, cursorPosition;

    if (selectedText) {
      // Wrap selected text with code blocks
      newValue =
        currentValue.slice(0, start) +
        "```\n" +
        selectedText +
        "\n```" +
        currentValue.slice(end);
      cursorPosition = start + selectedText.length + 8;
    } else {
      // Insert empty code block
      newValue =
        currentValue.slice(0, start) + "```\n\n```" + currentValue.slice(end);
      cursorPosition = start + 4;
    }

    // Update form value
    form.setFieldsValue({ comment: newValue });

    // Set cursor position after update
    setTimeout(() => {
      textarea.selectionStart = cursorPosition;
      textarea.selectionEnd = cursorPosition;
      textarea.focus();
    }, 0);
  };

  const handleSubmitComment = async values => {
    const {
      comment,
      attachedFiles,
      attachedImages,
      attachedCodeSnippet,
    } = values;
    let mediaImages = [];
    let mediaFiles = [];

    if (
      (!comment || !comment.trim()) &&
      (!attachedFiles || !attachedFiles.length) &&
      (!attachedImages || !attachedImages.length) &&
      (!attachedCodeSnippet || !attachedCodeSnippet.trim())
    ) {
      message.destroy();
      message.error("Comment or an attachment is required.");
      return;
    }

    try {
      message.loading("Posting comment...", 0);

      if (attachedImages && attachedImages.length) {
        mediaImages = await handleUploadFilesToS3(attachedImages);
      }

      if (attachedFiles && attachedFiles.length) {
        mediaFiles = await handleUploadFilesToS3(attachedFiles);
      }

      const commentData = {
        comment: comment || "",
        attachedImages: mediaImages.length ? { create: mediaImages } : {},
        attachedFiles: mediaFiles.length ? { create: mediaFiles } : {},
        codeSnippet: attachedCodeSnippet || "",
        createdBy: { connect: { id: currentUser.id } },
        task: { connect: { id: currentTaskId } },
      };

      const { data } = await createComment({
        variables: {
          data: commentData,
        },
      });

      // Extract the newly created comment from the response
      const newComment = data.createComment;

      // Append the new comment to the state
      const formattedComment = {
        id: newComment.id,
        createdBy:
          newComment.createdBy.name ||
          newComment.createdBy.username ||
          "Unknown",
        createdAt: new Date(newComment.createdAt).toLocaleDateString() || "-",
        comment: newComment.comment,
        attachedFiles: newComment.attachedFiles || [],
        attachedImages: newComment.attachedImages || [],
        codeSnippet: newComment.codeSnippet || "",
      };
      setComments(prevComments => [...prevComments, formattedComment]);

      await fetchTask({
        variables: {
          where: {
            id: currentTaskId,
          },
        },
      });

      form.resetFields(); // Clear the form
      message.destroy(); // Remove loading message
      message.success("Comment posted successfully!");
    } catch (error) {
      message.destroy(); // Remove loading message
      message.error(`Failed to post comment: ${error.message}`);
    }
  };

  const onButtonClick = () => {
    form.submit();
  };

  const displayedComments = [
    ...(comments || []),
    ...((currentTask &&
      currentTask.currentTask &&
      currentTask.currentTask.attachedComments) ||
      []),
  ];

  return (
    <div>
      <Form form={form} onFinish={handleSubmitComment} layout="vertical">
        <Card
          style={{
            borderTopLeftRadius: "7px",
            borderTopRightRadius: "7px",
            minHeight: "67vh",
          }}
          title={
            <Text style={{ fontWeight: "bolder", fontSize: "17px" }}>
              ACTIVITY
            </Text>
          }
        >
          <Col
            style={{
              maxHeight: "500px",
              overflowY: "auto",
              paddingRight: "8px",
            }}
          >
            {displayedComments.map(comment => (
              <Card key={comment.id} style={{ padding: "0", margin: "0" }}>
                <Row
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    marginBottom: 16,
                  }}
                >
                  <AvatarS3
                    imgKey={
                      comment.avatar ||
                      (comment.createdBy &&
                        comment.createdBy.avatar &&
                        comment.createdBy.avatar.key)
                    }
                    username={
                      (comment.createdBy && comment.createdBy.name) || createdBy
                    }
                    avatarProps={{ size: 45 }}
                    style={{ marginRight: 12 }}
                  />
                  <div style={{ flex: 1 }}>
                    <div style={{ display: "flex", alignItems: "baseline" }}>
                      <Text
                        style={{
                          fontWeight: "bold",
                          fontSize: 15,
                          paddingLeft: 19.5,
                        }}
                      >
                        {startCase(
                          comment.createdBy &&
                            typeof comment.createdBy === "object"
                            ? comment.createdBy.name ||
                                comment.createdBy.username
                            : comment.createdBy || "Unknown"
                        )}
                      </Text>

                      <span
                        style={{
                          color: "gray",
                          fontSize: 14,
                          marginLeft: "auto",
                        }}
                      >
                        {getTimeAgo(comment.createdAt)}
                      </span>
                    </div>

                    <Text
                      style={{
                        display: "block",
                        marginTop: 4,
                        fontSize: 14,
                        lineHeight: 1.5,
                        paddingLeft: 20,
                        whiteSpace: "pre-wrap",
                        wordBreak: "break-word",
                        overflowWrap: "break-word",
                        maxWidth: "100%",
                      }}
                    >
                      {(() => {
                        const rawText =
                          typeof comment.comment === "object" &&
                          comment.comment !== null
                            ? comment.comment.text || "No text available"
                            : comment.comment || "";

                        const processedParts = codeBlock(rawText);

                        return processedParts.map((part, index) => {
                          if (part.type === "text") {
                            return (
                              <span key={`text-${index}`}>
                                {linkify(part.content)}
                              </span>
                            );
                          }

                          // Basic syntax highlighting using CSS
                          return (
                            <pre
                              key={`code-${index}`}
                              style={{
                                backgroundColor: "#f0f0f0",
                                borderRadius: "4px",
                                overflowX: "auto",
                                margin: "0",
                                fontFamily: "monospace",
                                whiteSpace: "pre-wrap",
                                wordBreak: "break-word",
                              }}
                            >
                              <code
                                style={{
                                  color: "#333",
                                  display: "block",
                                  fontSize: "13px",
                                }}
                              >
                                {part.content}
                              </code>
                            </pre>
                          );
                        });
                      })()}
                    </Text>
                  </div>
                </Row>
                {comment.attachedFiles && (
                  <Row
                    style={{
                      display: "flex",
                      flexWrap: "nowrap",
                      paddingTop: "5px",
                      gap: "5px",
                      marginLeft: 70,
                    }}
                  >
                    {comment.attachedFiles.map((file, index) => (
                      <S3File key={index} file={file} />
                    ))}
                  </Row>
                )}
                {comment.attachedImages && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "10px",
                      paddingTop: "5px",
                      marginLeft: 70,
                    }}
                  >
                    {comment.attachedImages.map((file, index) => (
                      <S3Image
                        key={index}
                        imgKey={file.key}
                        height="25%"
                        width="25%"
                      />
                    ))}
                  </div>
                )}
                {typeof comment.codeSnippet === "string" &&
                  comment.codeSnippet.trim() && (
                    <Col
                      style={{
                        maxWidth: "100%",
                        maxHeight: "300px",
                        overflowY: "auto",
                        overflowX: "auto",
                      }}
                    >
                      <CopyBlock
                        text={comment.codeSnippet}
                        language="javascript"
                        wrapLongLines
                        showLineNumbers={false}
                        theme="dracula"
                        codeBlock
                      />
                    </Col>
                  )}
              </Card>
            ))}
          </Col>
        </Card>
        <Card>
          <Row
            style={{
              display: "flex",
              flexDirection: "column",
              border: "1px solid #d9d9d9",
              borderRadius: "4px",
              position: "relative",
            }}
          >
            <Form.Item noStyle shouldUpdate>
              {formInstance => {
                const attachedFiles =
                  formInstance.getFieldValue("attachedFiles") || [];
                const attachedImages =
                  formInstance.getFieldValue("attachedImages") || [];

                return attachedFiles.length > 0 || attachedImages.length > 0 ? (
                  <>
                    <div
                      style={{
                        backgroundColor: "#fafafa",
                        paddingInline: "8px",
                        paddingBlockStart: "5px",
                        paddingBlockEnd: "30px",
                        border: "1px solid #eee",
                        marginBottom: "8px",
                      }}
                    >
                      {/* Files rendering */}
                      {attachedFiles.length > 0 && (
                        <Row
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            gap: "4px",
                            width: "100%",
                            padding: "4px",
                          }}
                        >
                          {attachedFiles.map((file, index) => (
                            <Tag
                              closable
                              key={index}
                              onClose={() => handleRemoveFile(index)}
                              icon={<PaperClipOutlined />}
                              style={{
                                margin: 0,
                                display: "inline-flex",
                                alignItems: "center",
                                maxWidth: "100%",
                                position: "relative",
                                flexShrink: 0,
                              }}
                            >
                              {file.name.length > 30
                                ? `${file.name.slice(0, 30)}...`
                                : file.name}
                            </Tag>
                          ))}
                        </Row>
                      )}

                      {/* Images rendering */}
                      {attachedImages.length > 0 && (
                        <Row
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            gap: "4px",
                            width: "100%",
                            padding: "4px",
                          }}
                        >
                          {attachedImages.map((file, index) => (
                            <Tag
                              closable
                              key={index}
                              onClose={() => handleRemoveImage(index)}
                              icon={<PictureOutlined />}
                              style={{
                                margin: 0,
                                display: "inline-flex",
                                alignItems: "center",
                                maxWidth: "100%",
                                position: "relative",
                                flexShrink: 0,
                              }}
                            >
                              {file.name.length > 30
                                ? `${file.name.slice(0, 30)}...`
                                : file.name}
                            </Tag>
                          ))}
                        </Row>
                      )}
                    </div>
                    <Row style={{ flexGrow: 1 }}>
                      <Form.Item name="comment" noStyle>
                        <Input.TextArea
                          ref={textareaRef}
                          onChange={e =>
                            form.setFieldsValue({ comment: e.target.value })
                          }
                          autoSize={{ minRows: 1, maxRows: 5 }}
                          maxLength={3000}
                          placeholder="Enter task details"
                          style={{
                            border: "none",
                            borderRadius: "10px",
                            outline: "none",
                            resize: "none",
                            width: "100%",
                            padding: "10px",
                            boxShadow: "none",
                            marginTop: -30,
                          }}
                        />
                      </Form.Item>
                    </Row>
                  </>
                ) : (
                  <Row style={{ flexGrow: 1 }}>
                    <Form.Item name="comment" noStyle>
                      <Input.TextArea
                        ref={textareaRef}
                        onChange={e => {
                          form.setFieldsValue({ comment: e.target.value });
                        }}
                        maxLength={3000}
                        autoSize={{ minRows: 1, maxRows: 5 }}
                        placeholder="Add a comment"
                        style={{
                          border: "none",
                          outline: "none",
                          resize: "none",
                          width: "100%",
                          padding: "10px",
                          boxShadow: "none",
                        }}
                      />
                    </Form.Item>
                  </Row>
                );
              }}
            </Form.Item>

            {/* Hidden files input */}
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              multiple
              accept=".doc, .docx, .pdf, .txt, .xls, .xlsx, .ppt, .pptx, .csv"
              onChange={handleFileChange}
            />
            <input
              type="file"
              ref={imageInputRef}
              style={{ display: "none" }}
              multiple
              accept=".jpg, .jpeg, .png, .gif, .avif"
              onChange={handleImageChange}
            />

            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.isCodeSnippet !== currentValues.isCodeSnippet ||
                prevValues.commentsCodeValue !== currentValues.commentsCodeValue
              }
            >
              {({ getFieldValue }) =>
                getFieldValue("isCodeSnippet") &&
                getFieldValue("commentsCodeValue") && (
                  <Col
                    style={{
                      maxWidth: "100%",
                      maxHeight: "300px",
                      overflowY: "auto",
                      overflowX: "auto",
                    }}
                  >
                    <CopyBlock
                      text={getFieldValue("commentsCodeValue")}
                      language="javascript"
                      wrapLongLines
                      showLineNumbers={false}
                      theme="dracula"
                      codeBlock
                    />
                  </Col>
                )
              }
            </Form.Item>
          </Row>
          <Row
            style={{
              display: "flex",
              paddingTop: "12px",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <Col style={{ display: "flex" }}>
              <Form.Item name="attachedFiles" noStyle>
                <PaperClipOutlined
                  style={{
                    paddingInline: "5px",
                    color: "gray",
                    fontSize: "20px",
                  }}
                  onClick={handleUploadFileClick}
                />
              </Form.Item>
              <Form.Item name="attachedImages" noStyle>
                <PictureOutlined
                  style={{
                    paddingInline: "5px",
                    color: "gray",
                    fontSize: "20px",
                  }}
                  onClick={handleUploadImageClick}
                />
              </Form.Item>
              <CodeOutlined
                style={{
                  paddingInline: "5px",
                  color: "gray",
                  fontSize: "20px",
                }}
                onClick={handleInsertCode}
              />
              <Modal
                closable={false}
                visible={showCodeModal}
                bodyStyle={{ height: "300px", padding: "0" }}
                footer={
                  <>
                    <Button
                      onClick={() => setShowCodeModal(false)}
                      style={{ marginLeft: "8px" }}
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={handleEmbedCodeSnippet}
                      style={{ marginLeft: "8px" }}
                    >
                      Embed
                    </Button>
                  </>
                }
              >
                <Card
                  title={
                    <Text style={{ fontWeight: "bolder" }}>
                      SEND CODE SNIPPET
                    </Text>
                  }
                >
                  <Row style={{ paddingBottom: "20px" }}>
                    <Text>
                      Embed a code snippet by pasting the code into the field
                      below:
                    </Text>
                  </Row>
                  <Row
                    style={{
                      backgroundColor: "#f5f5f5",
                      border: "1px solid #d9d9d9",
                      borderRadius: "4px",
                      position: "relative",
                    }}
                  >
                    <Row style={{ flexGrow: 1 }}>
                      <Form.Item name="attachedCodeSnippet" noStyle>
                        <Input.TextArea
                          maxLength={5000}
                          autoSize={{ minRows: 6, maxRows: 6 }}
                          style={{
                            backgroundColor: "#f5f5f5",
                            border: "none",
                            outline: "none",
                            resize: "none",
                            width: "100%",
                            margin: "5px",
                          }}
                        />
                      </Form.Item>
                    </Row>
                  </Row>
                </Card>
              </Modal>
            </Col>
            <Col style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button onClick={onButtonClick} disabled={createCommentLoading}>
                Submit
              </Button>
            </Col>
          </Row>
        </Card>
      </Form>
    </div>
  );
};
