import React from "react";
import { PageContentLayout, PageHeaderLayout } from "../../shared/layout";
import { Card, Col, Row, Empty, Typography, Select } from "antd";
import { FilterOutlined, AuditOutlined } from "@ant-design/icons";
import { InvoiceView } from "./components";
import { useHistory } from "react-router-dom";
//style
import {
  cardMargin,
  cardStyleHeight,
  colPadding,
  cardBody,
  cardData,
} from "../../shared/globalStyling/style";

const { Text } = Typography;

const InvoiceMainView = ({
  invoiceList,
  invoiceFilterList,
  tableLoading,
  totalOverdue,
  setFilteredInfo,
  billingDetails,
}) => {
  const history = useHistory();

  const renderCard = (title, value, description) => (
    <Card
      style={{
        ...cardMargin,
        ...cardStyleHeight,
        height: "130px",
        borderRadius: "10px",
      }}
    >
      <Row style={{ marginBottom: "10px" }}>
        <Text type="secondary">{title}</Text>
      </Row>
      <Row>
        <Text strong style={cardData}>
          {value}
        </Text>
      </Row>
      {description && (
        <Row style={{ marginBottom: "10px" }}>
          <Text type="secondary">{description}</Text>
        </Row>
      )}
    </Card>
  );

  return (
    <React.Fragment>
      <PageHeaderLayout
        title="Invoices"
        titleIcon={
          <AuditOutlined
            style={{
              marginRight: "10px",
              fontSize: 20,
              color: "lightgrey",
            }}
          />
        }
      />
      <PageContentLayout>
        <Row type="flex">
          <Col xs={24} sm={12} md={12} lg={12} xl={6} style={colPadding}>
            {renderCard(
              "Advertiser Impressions",
              billingDetails.advertiserDetails.totalImpressionsSum,
              `Current Month: ${(
                (billingDetails.advertiserDetails.totalImpressionsSum / 1000) *
                0.75
              ).toFixed(2)} CPM: $0.75`
            )}
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={4} style={colPadding}>
            {renderCard(
              "Publisher Domains",
              billingDetails.publisher.uniqueClicksTags,
              "$500 monthly per tag"
            )}
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={4} style={colPadding}>
            {renderCard(
              "Publisher Funnel",
              billingDetails.publisher.nonPausedPublishers,
              "$50 monthly per domain"
            )}
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={5} style={colPadding}>
            {renderCard("Current Balance", totalOverdue)}
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={5} style={colPadding}>
            {renderCard("Overdue Balance", totalOverdue)}
          </Col>
        </Row>
        <Row>
          <Col span={24} style={colPadding}>
            <Card
              style={cardMargin}
              bodyStyle={cardBody}
              title="Invoices"
              loading={false}
              extra={
                <Select
                  suffixIcon={<FilterOutlined />}
                  defaultValue="UNPAID"
                  onChange={setFilteredInfo}
                  style={{ width: 120 }}
                  options={[
                    { value: "All", label: "All" },
                    { value: "PAID", label: "Paid" },
                    { value: "UNPAID", label: "Unpaid" },
                    { value: "SCHEDULED", label: "Scheduled" },
                    { value: "CANCELED", label: "Canceled" },
                    { value: "REFUNDED", label: "Refunded" },
                  ]}
                />
              }
            >
              {invoiceList && invoiceList.length !== 0 ? (
                <InvoiceView
                  invoiceList={invoiceList}
                  invoiceFilterList={invoiceFilterList}
                  loading={tableLoading}
                  history={history}
                />
              ) : (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              )}
            </Card>
          </Col>
        </Row>
      </PageContentLayout>
    </React.Fragment>
  );
};

export default InvoiceMainView;
