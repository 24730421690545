import React from "react";
import { useQuery } from "@apollo/react-hooks";
import { withRouter } from "react-router-dom";
import { graphql } from "react-apollo";
import compose from "lodash/flowRight";
import OverlaySpinner from "../../../../../platform/shared/OverlaySpinner";
import GET_SIGNED_URL from "../../../../GraphQl/Queries/GET_SIGNED_URL";
import ErrorPage from "../../../../../platform/shared/ErrorPage";
import CURRENT_USER from "../../../../GraphQl/Queries/CURRENT_USER";

const AdvertiserDetailsTransactionsReportsLoader = props => {
  const { advertiser, currentUser, setReport, setCreatives, creatives } = props;
  const currentOrgId = currentUser.role.org.id;
  const {
    data: signedURL,
    loading: signedURLsLoading,
    error: signedURLsError,
  } = useQuery(GET_SIGNED_URL, {
    variables: {
      key: advertiser.reports.length > 0 ? advertiser.reports[0].key : `-`,
    },
    fetchPolicy: "no-cache",
  });

  if (signedURLsLoading) return <OverlaySpinner />;
  if (signedURLsError) return <ErrorPage />;

  return React.cloneElement(props.children, {
    ...props.children.props,
    advertiser,
    signedURL,
    currentOrgId,
    setReport,
    setCreatives,
    creatives,
  });
};

export default compose(
  graphql(CURRENT_USER, {
    props: ({ data: { currentUser }, ownProps }) => {
      return ownProps.currentUser || { currentUser };
    },
  })
)(withRouter(AdvertiserDetailsTransactionsReportsLoader));
