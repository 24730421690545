import gql from "graphql-tag";

const REGENERATE_IMPRESSION_TAG = gql`
  mutation regenerateImpressionsTag(
    $id: String!
    $appId: String!
    $environment: String!
  ) {
    regenerateImpressionsTag(
      id: $id
      appId: $appId
      environment: $environment
    ) {
      id
      name
    }
  }
`;

export default REGENERATE_IMPRESSION_TAG;
