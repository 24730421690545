/* eslint-disable react-hooks/rules-of-hooks */
/** @jsx jsx */
import React, { useMemo, useState, useEffect } from "react";
import { jsx, css } from "@emotion/react";
import moment from "moment";
import CampaignsTable from "./components/CampaignsTable";
import PendingCampaignOrder from "./components/PendingCampaignOrder";
import { PageContentLayout, PageHeaderLayout } from "../../shared/layout";
import {
  Row,
  Col,
  DatePicker,
  Switch,
  Tooltip,
  message,
  Spin,
  Modal,
} from "antd";
import {
  AreaChartOutlined,
  UnorderedListOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import Button from "../../shared/globalStyling/Button";
import { withRouter } from "react-router-dom";
import { isEmpty } from "lodash";
import { FiltersBar } from "./components/FiltersBar";

// import UpdateCampaignModal from "./components/UpdateCampaignModal";
import BasicTools from "./campaigntools/BasicTools";
import ExportCSVbyDate from "./components/ExportCSVbyDate";
import { determineUserAccess } from "../../../core/permissions/userLevelAccess";
import { deriveStatus } from "./shared/StatusTag";
import exportCampaignTableData from "./components/ExportCampaignTableData";

// Global styles
import SharedOrgsModal from "../Modals/SharedOrgModal";
import ExportAbilityModal from "../Modals/ExportAbilityModal";
import BulkUpdateModal from "../Campaigns/components/BulkUpdateModal";
import "../../shared/globalStyling/responsive.css";
import { orgLevelCustomization } from "../../../core/permissions/orgLevelCustomization";
import { defaultAttributionWindow } from "../../../core/utils/constants/constants";
import { HasAccess } from "@permify/react-role";

const { RangePicker } = DatePicker;
const { confirm } = Modal;

const alltimeStyled = css`
  .ant-switch .ant-switch-handle {
    width: 32px;
    height: 31px;
    border: 6px solid white;
    border-radius: 73px;
    left: 4px;
  }

  .ant-switch-inner {
    font-size: 14px;
  }

  .ant-switch-checked .ant-switch-handle {
    left: calc(100% - 35px - 2px);
  }
`;

const CampaignsMainView = ({
  history,
  match,
  currentUser,
  queuedCampaignOrders,
  campaignOrders,
  campaignStatusFilter,
  onTableColChange,
  selectedTableColumns,
  possibleFields,
  total,
  current,
  pageSize,
  orgs,
  toggleUpdateModal,
  toggleExportAbilityModal,
  openUpdateModal,
  openExportAbilityModal,
  selectedRowKeys,
  selectedRows,
  onRowChange,
  orgConfig,
  exportDataCampaign,
  loadingExportDataCampaign,
  exportRangeDate,
  setExportRangeDate,
  editAllTimeFilter,
  getIsAllTimeFiltered,
  isPastData,
  updateCampaignOrder,
  updateBulkCampaignOrder,
  exportAbilityQuery,
  exportAbilityLoading,
  exportAbilityError,
  setExportAbilityError,
  exportAllToggle,
  setExportAllToggle,
  getAllCampaignOrdersConnection,
  onBulkDeleteCampaignOrder,
  overallReportLoading,
  overallReportError,
  exportAbilityEmptyData,
  setExportAbilityEmptyData,
  refetch,
  getCartProvider,
  cartProvider,
  currentCartProvider,
  setCurrentCartProvider,
  dataConfig,
  loadingExportCampaignTable,
  dataExportCampaignTable,
  exportCampaignTable,
  exportDataLoaded,
  setExportDataLoaded,
  exportCampaignNumberResults,
  setExportCampaignNumberResults,
}) => {
  const level = currentUser.role.org.level;

  let defaultDisplayAttributionWindow =
    (dataConfig && dataConfig.defaultDisplayAttributionWindow) ||
    defaultAttributionWindow.key;

  if (defaultDisplayAttributionWindow !== 30) {
    const attributedCampaignOrders = campaignOrders.map(campaignOrder => {
      if (
        campaignOrder.aggregateData &&
        campaignOrder.aggregateData.overallData &&
        campaignOrder.aggregateData.overallData.aggEvents &&
        campaignOrder.aggregateData.overallData.aggEvents
          .attributionWindowReport
      ) {
        const attributionWindowReport = campaignOrder.aggregateData.overallData.aggEvents.attributionWindowReport.find(
          report => report.attributionWindow === defaultDisplayAttributionWindow
        );
        if (attributionWindowReport) {
          campaignOrder.aggregateData.overallData.aggEvents = {
            ...campaignOrder.aggregateData.overallData.aggEvents,
            ...attributionWindowReport.aggEvents,
            attributionWindow: attributionWindowReport.attributionWindow,
          };
        }
      }
      return campaignOrder;
    });
    campaignOrders = attributedCampaignOrders;
  }
  // Set Document Title
  document.title = "Campaigns Main View";

  const [exportClicked, setExportClicked] = useState(false);
  const getParamsSearch = window.location.search;
  const params = new URLSearchParams(window.location.search);
  const isDateFiltered = params.get("date");
  const statusFilter = params.get("status");

  const userPermission = determineUserAccess(currentUser.permission);
  const orgCustomization = orgLevelCustomization(
    currentUser.role.org.dataConfig || {}
  );
  const { isTrafficker, isDemo, exportData } = userPermission;
  const { currencyFormat } = orgCustomization;
  const [tooltip, setTooltip] = useState(
    getIsAllTimeFiltered
      ? `Shows your data from the past 6 months`
      : `Shows your data from All Time`
  );

  const [toggleBulkUpdateModal, setToggleBulkUpdateModal] = useState(false);
  let filteredTableData = campaignOrders ? campaignOrders : [];

  if (
    isDateFiltered &&
    statusFilter &&
    statusFilter !== "pending" &&
    statusFilter !== "draft"
  ) {
    filteredTableData = campaignOrders.filter(record => {
      const checkStatus = null;
      const startDate =
        record &&
        moment(record.startDate)
          .seconds(0)
          .milliseconds(0)
          .toISOString()
          ? moment(record.startDate)
              .seconds(0)
              .milliseconds(0)
              .toISOString()
          : "N/A";

      const endDate =
        record &&
        moment(record.endDate)
          .seconds(0)
          .milliseconds(0)
          .toISOString()
          ? moment(record.endDate)
              .seconds(0)
              .milliseconds(0)
              .toISOString()
          : "N/A";

      const attributionWindow =
        record && record.attributionWindow ? record.attributionWindow : "N/A";

      const statusCampaign = record.status && record.status;

      const status = useMemo(
        () =>
          deriveStatus(
            startDate,
            endDate,
            attributionWindow,
            statusCampaign,
            checkStatus
          ),
        [startDate, endDate, attributionWindow, statusCampaign, checkStatus]
      );

      return statusFilter.toLowerCase() === status.toLowerCase();
    });
  }

  const handleExport = async () => {
    const params = new URLSearchParams(window.location.search);
    const dateRange = params.get("date");

    if (!dateRange) {
      message.warning(
        "No date range found. Please provide a date range not exceeding 8 months."
      );
      return;
    }

    // Adjusting the format to 'YYYY/MM/DD-YYYY/MM/DD'
    const [startDate, endDate] = dateRange
      .split("-")
      .map(date => new Date(date));

    if (!startDate || !endDate || isNaN(startDate) || isNaN(endDate)) {
      message.warning(
        "Invalid date range format. Please provide a valid format."
      );
      return;
    }

    // Calculate the difference in months
    const monthsDifference =
      (endDate.getFullYear() - startDate.getFullYear()) * 12 +
      (endDate.getMonth() - startDate.getMonth());

    if (monthsDifference > 8) {
      message.warning(
        "Date range exceeds 8 months. Please provide a valid date range not exceeding 8 months."
      );
      return;
    }

    setExportClicked(true);
    await exportCampaignTable();
  };

  useEffect(() => {
    if (exportDataLoaded && exportClicked) {
      if (dataExportCampaignTable.full.aggregate.count > 0) {
        exportCampaignTableData(dataExportCampaignTable);
        setExportClicked(false);
        setExportDataLoaded(false);
      } else {
        message.error("No data to export");
        setExportClicked(false);
        setExportDataLoaded(false);
      }
    }
  }, [
    exportDataLoaded,
    exportClicked,
    dataExportCampaignTable,
    setExportDataLoaded,
  ]);

  return (
    <React.Fragment>
      <SharedOrgsModal
        modalToggle={openUpdateModal}
        selectedRows={selectedRows}
        onCancel={toggleUpdateModal}
        orgs={orgs}
        currentUser={currentUser}
        onSubmit={async () => {
          try {
            message.loading("Sharing..", 0);

            const result = await Promise.all(
              selectedRows.map(campaignOrder => {
                return updateCampaignOrder({
                  variables: {
                    id: campaignOrder.id,
                    orgs: {
                      connect: campaignOrder.orgs
                        .filter(o => !o.__typename && !o.isRemoved)
                        .map(o => {
                          return { id: o.id };
                        }),
                      disconnect: campaignOrder.orgs
                        .filter(
                          o => o.__typename !== null && o.isRemoved === true
                        )
                        .map(o => {
                          return { id: o.id };
                        }),
                    },
                  },
                });
              })
            );
            if (result) {
              message.destroy();
              message.success(
                `Campaign${selectedRowKeys.length > 1 ? "s" : ""} shared`
              );
              // allow message to display a second to confirm user sharing has been successful
              setTimeout(() => {
                refetch();
              }, 1000);
            } else {
              message.destroy();
              message.error("Something went wrong");
            }
            toggleUpdateModal();
          } catch (err) {}
        }}
      />
      <ExportAbilityModal
        exportCampaignNumberResults={exportCampaignNumberResults}
        exportAllToggle={exportAllToggle}
        exportAbilityEmptyData={exportAbilityEmptyData}
        exportAbilityError={exportAbilityError || overallReportError}
        setExportAbilityError={setExportAbilityError}
        loading={exportAbilityLoading || overallReportLoading}
        modalToggle={openExportAbilityModal}
        selectedRows={selectedRows}
        onCancel={() => {
          setExportCampaignNumberResults(null);
          setExportAbilityEmptyData(false);
          setExportAbilityError(false);
          toggleExportAbilityModal();
        }}
        onSubmit={async () => {
          try {
            if (exportAllToggle) {
              getAllCampaignOrdersConnection({
                variables: { orgId: currentUser.role.org.id },
              });
              return;
            }

            const result = selectedRows.map(campaignOrder => campaignOrder.id);

            exportAbilityQuery({
              variables: { ids: result },
            });
            return;
          } catch (err) {
            setExportAbilityError(true);
            console.log(err);
          }
        }}
      />
      <BulkUpdateModal
        toggleBulkUpdateModal={toggleBulkUpdateModal}
        setToggleBulkUpdateModal={setToggleBulkUpdateModal}
        selectedRowKeys={selectedRowKeys}
        updateBulkCampaignOrder={updateBulkCampaignOrder}
        cartProvider={cartProvider}
        setCurrentCartProvider={setCurrentCartProvider}
        currentCartProvider={currentCartProvider}
      />
      <PageHeaderLayout
        rightContent={
          <Row>
            <Col className="ant-row-flex-column ant-row-align-items-center">
              <HasAccess roles={["isProcessor"]}>
                <Button
                  onClick={() => {
                    history.push(
                      `${match.path.replace(
                        /main\/:pageNumber\??/gi,
                        `v2/orderqueue/queued`
                      )}`
                    );
                  }}
                  icon={<UnorderedListOutlined />}
                  type="primary"
                  ghost
                >
                  Order Queue
                </Button>
              </HasAccess>
              <HasAccess roles={["isSelfService"]}>
                <Button
                  onClick={() => {
                    history.push(
                      `${match.path.replace(
                        /main\/:pageNumber\??/gi,
                        `requester`
                      )}`
                    );
                  }}
                  type="primary"
                  ghost
                >
                  Launch Campaign
                </Button>
              </HasAccess>

              {isTrafficker ? <BasicTools /> : null}
            </Col>
          </Row>
        }
        title={"Campaign Reporting"}
        titleIcon={
          <AreaChartOutlined
            style={{
              marginRight: "10px",
              color: "lightgrey",
              fontSize: 20,
            }}
          />
        }
      />
      <PageContentLayout>
        {!isEmpty(queuedCampaignOrders) &&
          queuedCampaignOrders
            .filter(
              campaignOrder =>
                campaignOrder.status === "PENDING_CLIENT" &&
                campaignOrder.orgs[0].id === currentUser.role.org.id
            )
            .map((campaignOrder, i) => (
              <PendingCampaignOrder
                key={`Pending-${i}`}
                data={campaignOrder}
                history={history}
              />
            ))}
        <PageHeaderLayout
          rightContent={
            <Col
              className="ant-row-flex-column ant-row-align-items-center"
              style={{ display: "flex" }}
              span={24}
            >
              {false && (
                <Col>
                  <RangePicker
                    style={{ marginRight: "10px", margin: "15px 10px 15px 0" }}
                    defaultValue={[]}
                    onChange={v => {
                      setExportRangeDate(v);
                    }}
                  />
                  <ExportCSVbyDate
                    exportDataCampaign={exportDataCampaign}
                    exportRangeDate={exportRangeDate}
                    loadingExportDataCampaign={loadingExportDataCampaign}
                  />
                </Col>
              )}
              {isPastData === true ? (
                <div css={alltimeStyled}>
                  <Tooltip placement="top" title={tooltip}>
                    <Switch
                      checkedChildren="Filtered"
                      unCheckedChildren="All Time"
                      size="default"
                      style={{
                        marginTop: "12px",
                        marginRight: "15px",
                        backgroundColor: "rgba(0, 0, 0, 0.25)",
                        height: "36px",
                        width: "112px",
                        marginBottom: "12px",
                      }}
                      defaultChecked={getIsAllTimeFiltered}
                      onChange={checked => {
                        if (checked === true) {
                          editAllTimeFilter({
                            variables: {
                              isAllTimeFiltered: true,
                            },
                          });
                          setTooltip(`Shows your data from the past 6 months`);
                        } else {
                          editAllTimeFilter({
                            variables: {
                              isAllTimeFiltered: false,
                            },
                          });
                          setTooltip(`Shows your data from All Time`);
                        }
                      }}
                    />
                  </Tooltip>
                </div>
              ) : null}
              <Button
                type="primary"
                ghost
                disabled={(selectedRowKeys || []).length < 1 || isDemo}
                onClick={() => toggleUpdateModal()}
              >
                Share Campaign
              </Button>
              {exportData && (
                <>
                  <Button
                    type="primary"
                    ghost
                    disabled={(selectedRowKeys || []).length < 1 || isDemo}
                    onClick={() => {
                      setExportAllToggle(false);
                      toggleExportAbilityModal();
                    }}
                  >
                    Export Campaign
                  </Button>
                  {/* <Button
                    disabled={selectedRowKeys.length >= 1}
                    type="primary"
                    onClick={() => {
                      setExportAllToggle(true);
                      toggleExportAbilityModal();
                    }}
                  >
                    Export All Campaigns
                  </Button> */}
                </>
              )}
              <Spin
                spinning={
                  loadingExportCampaignTable &&
                  exportClicked &&
                  !exportDataLoaded
                }
              >
                <Tooltip title="Downloads all the data from the table. You can filter table data by using the filters and search.">
                  <Button type="primary" onClick={handleExport}>
                    Export Table Data
                  </Button>
                </Tooltip>
              </Spin>
              {isTrafficker && (
                <Button
                  type="primary"
                  ghost
                  disabled={(selectedRowKeys || []).length < 1 || isDemo}
                  onClick={() => {
                    setToggleBulkUpdateModal(true);
                    getCartProvider();
                  }}
                >
                  Bulk Edit
                </Button>
              )}
              {getParamsSearch.includes("status=draft") && (
                <Button
                  type="primary"
                  ghost
                  disabled={(selectedRows || []).length < 1 || isDemo}
                  onClick={() => {
                    confirm({
                      title: `Would you like to delete the selected draft campaign${
                        selectedRows.length > 1 ? "s" : ""
                      }?`,
                      icon: <ExclamationCircleOutlined />,
                      onOk() {
                        onBulkDeleteCampaignOrder(selectedRows);
                      },
                    });
                  }}
                >
                  Discard Draft
                </Button>
              )}
            </Col>
          }
          title={" Campaigns"}
        />
        <FiltersBar
          orgs={orgs}
          orgConfig={orgConfig}
          tooltip="Used for filtering Flight Dates"
          isDemo={isDemo}
          level={level}
        />
        <CampaignsTable
          isDemo={isDemo}
          data={isDateFiltered ? filteredTableData : campaignOrders}
          onTableColChange={onTableColChange}
          selectedTableColumns={selectedTableColumns}
          possibleFields={possibleFields}
          campaignStatusFilter={campaignStatusFilter}
          total={total}
          current={current}
          pageSize={pageSize}
          selectedRowKeys={selectedRowKeys}
          onRowChange={onRowChange}
          currencyFormat={currencyFormat}
          selectedRows={selectedRows}
        />
      </PageContentLayout>
    </React.Fragment>
  );
};

export default withRouter(CampaignsMainView);
