import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import TagsMainLoader from "../../../../core/components/tags/main/TagsMainLoader";
import TagsMainController from "../../../../core/components/tags/main/TagsMainController";
import TagsMainView from "../main/TagsMainView";
import TagsLoader from "../../../../core/components/tags/TagsLoader";
import TagsController from "../../../../core/components/tags/TagsController";
import TagsManagerLoader from "../../../../core/components/tags/manager/TagsManagerLoader";
import TagsManagerController from "../../../../core/components/tags/manager/TagsManagerController";
import TagsManagerView from "../manager/TagsManagerView";
import TagsDetailsLoader from "../../../../core/components/tags/details/TagsDetailsLoader";
import TagsDetailsController from "../../../../core/components/tags/details/TagsDetailsController";
import TagsDetailsView from "../details/TagsDetailsView";
import { TagsManagerUpdateMeasurement } from "../../../../core/components/tags/manager/TagsManagerWrapper";

const TagsRoutes = props => {
  const { path } = props.match;

  return (
    <Switch>
      <Route
        path={`${path}/main/:pageNumber?`}
        exact
        render={() => (
          <TagsLoader {...props}>
            <TagsController>
              <TagsMainLoader>
                <TagsMainController>
                  <TagsMainView />
                </TagsMainController>
              </TagsMainLoader>
            </TagsController>
          </TagsLoader>
        )}
      />
      <Route
        path={`${path}/manager`}
        exact
        render={() => (
          <TagsLoader {...props}>
            <TagsController>
              <TagsManagerLoader>
                <TagsManagerController>
                  <TagsManagerView tagType={"Measurement"} />
                </TagsManagerController>
              </TagsManagerLoader>
            </TagsController>
          </TagsLoader>
        )}
      />
      <Route
        path={`${path}/manager`}
        exact
        render={() => (
          <TagsLoader {...props}>
            <TagsController>
              <TagsManagerLoader>
                <TagsManagerController>
                  <TagsManagerView tagType={"Measurement"} />
                </TagsManagerController>
              </TagsManagerLoader>
            </TagsController>
          </TagsLoader>
        )}
      />
      <Route
        path={`${path}/manager/:id?`}
        exact
        render={() => <TagsManagerUpdateMeasurement />}
      />
      <Route
        path={`${path}/details/:id`}
        exact
        render={() => (
          <TagsLoader {...props}>
            <TagsController>
              <TagsDetailsLoader>
                <TagsDetailsController>
                  <TagsDetailsView />
                </TagsDetailsController>
              </TagsDetailsLoader>
            </TagsController>
          </TagsLoader>
        )}
      />
      {/* Fallback */}
      <Route
        path={`${path}`}
        render={() => <Redirect to={`${path}/main/1`} />}
      />
    </Switch>
  );
};

export default TagsRoutes;
