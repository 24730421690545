export const getNumberWithSuffix = number => {
  // Input validation
  if (!Number.isInteger(number)) {
    throw new Error("Input must be an integer");
  }
  // Handle special cases for 11, 12, 13
  const specialCase = number % 100;
  if (specialCase >= 11 && specialCase <= 13) {
    return `${number}th `;
  }
  // Handle general cases
  switch (number % 10) {
    case 1:
      return `${number}st `;
    case 2:
      return `${number}nd `;
    case 3:
      return `${number}rd `;
    default:
      return `${number}th `;
  }
};
