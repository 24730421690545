import gql from "graphql-tag";

const GET_USERS_BASIC_DETAILS = gql`
  query users(
    $first: Int
    $skip: Int
    $orderBy: UserOrderByInput
    $where: UserWhereInput
  ) {
    users(first: $first, skip: $skip, orderBy: $orderBy, where: $where) {
      id
      name
      username
    }
  }
`;

export default GET_USERS_BASIC_DETAILS;
