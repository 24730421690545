import React from "react";
import { useLazyQuery, useMutation, useQuery } from "react-apollo";
import SIGNAL_TAGS from "../../../GraphQl/Queries/SIGNAL_TAGS";
import { LoadingPage } from "../../../../platform/shared/LoadingPage";
import TAG_REQUEST_ASSISTANCE from "../../../GraphQl/Mutations/TAG_REQUEST_ASSISTANCE";
import IMPRESSION_TAG_CREATE from "../../../GraphQl/Mutations/IMPRESSION_TAG_CREATE";
import moment from "moment";
import { withRouter } from "react-router-dom";
import GET_DSP from "../../../GraphQl/Queries/GET_DSP";
import GET_BASIC_TAG_ACTIVITY from "../../../GraphQl/Queries/GET_BASIC_TAG_ACTIVITY";
import CREATE_TEST_SIGNAL_LOGS from "../../../GraphQl/Mutations/CREATE_TEST_SIGNAL_LOGS";

const TagsMainLoader = props => {
  const { currentUser, location, isAttentionSignals } = props;
  const currentOrgId = currentUser.role.org.id;

  const params = new URLSearchParams(location.search);
  const status = params.get("status");
  const name = params.get("name");

  let disableFilter = { OR: [{ isDisabled_not: true }, { isDisabled: null }] };
  if (status === "disabled") {
    disableFilter = { isDisabled: true };
  }

  let nameFilter = {};
  if (name) {
    nameFilter = { name_contains: name };
  }

  const wherePublisher = {
    AND: [
      { clicksTag: { id_not: null } },
      { transactionsTag: { id_not: null } },
    ],
    OR: [
      { sharedOrgs_some: { org: { id: currentOrgId } } },
      { sharedOrgs_some: { org: { parentOrg_some: { id: currentOrgId } } } },
    ],
  };

  const whereAdvertiser = {
    OR: [
      { sharedOrgs_some: { org: { id: currentOrgId } } },
      { sharedOrgs_some: { org: { parentOrg_some: { id: currentOrgId } } } },
    ],
  };

  const whereImpressionTag = {
    orgs_some: { id: currentOrgId },
    isAdvertiser: true,
  };

  const whereMeasurementsTag = {
    AND: [
      {
        OR: [
          { orgs_some: { id: currentOrgId } },
          { orgs_some: { parentOrg_some: { id: currentOrgId } } },
        ],
      },
      { OR: [{ isAdvertiser_not: true }, { isAdvertiser: null }] },
      { AND: [disableFilter, nameFilter] },
    ],
  };

  const publisherDomainTags = {
    OR: [
      { orgs_some: { id: currentOrgId } },
      { orgs_some: { parentOrg_some: { id: currentOrgId } } },
    ],
    isPublisherDomain: true,
  };

  const {
    data: signalTagsData,
    loading: loadingSignalTags,
    refetch,
  } = useQuery(SIGNAL_TAGS, {
    variables: {
      wherePublisher,
      whereAdvertiser,
      whereImpressionTag,
      whereMeasurementsTag,
      publisherDomainTags,
    },
    fetchPolicy: "no-cache",
  });

  const [requestAssistance] = useMutation(TAG_REQUEST_ASSISTANCE, {
    variables: {
      data: {
        status: "Incoming",
        advertiser: currentUser.role.org.name,
        date: moment(),
      },
    },
  });

  const [createImpressionTag] = useMutation(IMPRESSION_TAG_CREATE, {
    onCompleted: () => {
      refetch();
    },
  });

  const [createLogs] = useMutation(CREATE_TEST_SIGNAL_LOGS, {
    onCompleted: () => {
      refetch();
    },
  });

  const [getBasicTagActivity, { loading: tagActivityLoading }] = useLazyQuery(
    GET_BASIC_TAG_ACTIVITY,
    {
      onCompleted: data => {
        const { getBasicTagActivity } = data;
        createLogs({
          variables: {
            id: getBasicTagActivity.tagId,
            createLogs: {
              testDate: moment().format(),
              pageViews: getBasicTagActivity.pageViews,
              signups: getBasicTagActivity.signups,
              transactions: getBasicTagActivity.transactions,
            },
          },
        });
      },
    }
  );

  const { data: advertisers, loading: dspLoading } = useQuery(GET_DSP);

  if (loadingSignalTags || dspLoading) return <LoadingPage />;

  return React.cloneElement(props.children, {
    ...props.children.props,
    signalTagsData,
    requestAssistance,
    createImpressionTag,
    refetch,
    currentOrgId,
    isAttentionSignals,
    advertisers,
    tagActivityLoading,
    getBasicTagActivity,
  });
};

export default withRouter(TagsMainLoader);
