import React, { useState, useEffect, useMemo, useCallback } from "react";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import Papa from "papaparse";
import JSZip from "jszip";
import { isEmpty } from "lodash";
import { determineUserAccess } from "../../../permissions/userLevelAccess";
import {
  byDateOrgCompute,
  filterbyCampaignOrder,
  toByDay,
  cleanFormatByDateReport,
  filterByDate,
} from "../../../../platform/ux/Home/utils";
import { compute } from "../../../../platform/ux/Home/utils/computeWeeklyReport";
import { prepareCSV } from "../../../../platform/ux/Home/utils/weeklyReportCSV";
import Storage from "@aws-amplify/storage";
import moment from "moment";
import { defaultCPM } from "../../../utils/constants/constants";
import { computeSpend } from "../../../utils/campaigns";
import { defaultAttributionWindow } from "../../../utils/constants/constants";

const DisplayChartsController = ({
  children,
  signedUrl,
  expiresAt,
  refetch,
  campaignOrdersData,
  showAnalytics,
  orgSummaries,
  deviceClicks,
  advertiserIds,
  orgId,
  selectedCOIds,
  setSelectedCOIds,
  loadingDeviceClicks,
  ...props
}) => {
  const dataConfig = props.currentUser.role.org.dataConfig;
  let defaultDisplayAttributionWindow =
    (dataConfig && dataConfig.defaultDisplayAttributionWindow) ||
    defaultAttributionWindow.key;

  const [rawHomeChartData, setRawHomeChartData] = useState(null);
  const [topCreatives, setTopCreatives] = useState([]);
  const [loadingRawHomeChartData, setLoadingRawHomeChartData] = useState(false);
  const [bySummaryRangeDate, setBySummaryRangeDate] = useState([]);
  const [isExportCompleted, setIsExportCompleted] = useState(true);
  const [dataSummaryRangeDate, setDataSummaryRangeDate] = useState([]);
  const [errorLoadingReport, setErrorLoadingReport] = useState(false);
  const [orgLogo, setOrgLogo] = useState(false);
  const [filteredOrg, setFilteredOrg] = useState({
    id: null,
    name: null,
  });

  const searchParams = new URLSearchParams(props.location.search);
  const startDateParams = searchParams.get("startDate");
  const endDateParams = searchParams.get("endDate");
  //priceVisible is customer facing - All data will have DSP costs stripped unless the logged in organization is MediaJelAdmi
  const userPermission = determineUserAccess(props.currentUser.permission);
  const MAX_RETRIES = 10; // Maximum number of retry attempts

  const s3Url = useMemo(() => {
    const currentTime = new Date().toUTCString();

    if (currentTime <= signedUrl.expiresAt) {
      //url is valid - set url
      return signedUrl.url;
    } else {
      //url is valid - refetch
      refetch();
      return null;
    }
  }, [signedUrl, refetch]);

  const fetchS3Url = async (s3Url, isDemo) => {
    const response = await fetch(s3Url, { cache: "force-cache" });

    if (!response.ok) {
      console.log("Fetch S3 URL Failed");
    }

    const jsonResponse = await response.json();

    if (isDemo) {
      if (!isEmpty(jsonResponse) && !isEmpty(jsonResponse.byDateOrg)) {
        jsonResponse.byDateOrg = jsonResponse.byDateOrg.map((c, i) => ({
          ...c,
          campaignName: `Campaign ${i}`,
        }));
      }
    }
    if (
      defaultDisplayAttributionWindow !== 30 &&
      jsonResponse.attributionWindowReports
    ) {
      const report = jsonResponse.attributionWindowReports.find(
        a => a.attributionWindow === defaultDisplayAttributionWindow
      );
      if (report) {
        jsonResponse.byDateOrg = report.byDate;
      }
    }
    setRawHomeChartData({
      ...jsonResponse,
      byDateOrg: cleanFormatByDateReport(jsonResponse.byDateOrg),
    });

    setLoadingRawHomeChartData(false);
    setTopCreatives(jsonResponse.topCreatives);
  };

  const getS3Report = useCallback(async () => {
    let retryCount = 0;
    setLoadingRawHomeChartData(true);

    try {
      await fetchS3Url(s3Url, props.currentUser.permission.isDemo);
    } catch (err) {
      retryCount++;
      if (retryCount <= MAX_RETRIES) {
        console.log("Retry fetching URL count: ", retryCount);
        await fetchS3Url(s3Url, props.currentUser.permission.isDemo);
      } else {
        setErrorLoadingReport(true);
        setLoadingRawHomeChartData(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.currentUser.permission.isDemo, s3Url]);

  const filteredCampaignOrders = useMemo(() => {
    if (campaignOrdersData) {
      if (filteredOrg.id) {
        const edges =
          campaignOrdersData &&
          campaignOrdersData.campaignOrdersConnection &&
          campaignOrdersData.campaignOrdersConnection.edges;

        const filtered = !isEmpty(edges)
          ? edges.filter(edge => {
              return (
                edge.node.orgs.filter(o => o.id === filteredOrg.id).length > 0
              );
            })
          : [];

        return !isEmpty(filtered) ? filtered.map(f => ({ ...f.node })) : [];
      } else {
        const edges =
          campaignOrdersData &&
          campaignOrdersData.campaignOrdersConnection &&
          campaignOrdersData.campaignOrdersConnection.edges;
        return !isEmpty(edges) ? edges.map(f => ({ ...f.node })) : [];
      }
    } else {
      return [];
    }
  }, [campaignOrdersData, filteredOrg]);

  const filteredCampaignOrderIds = useMemo(() => {
    if (selectedCOIds.length > 0) {
      return selectedCOIds;
    } else {
      return !isEmpty(filteredCampaignOrders)
        ? filteredCampaignOrders.map(i => i.id)
        : [];
    }
  }, [selectedCOIds, filteredCampaignOrders]);

  const filteredReport = useMemo(() => {
    if (rawHomeChartData && rawHomeChartData.byDateOrg) {
      if (filteredCampaignOrderIds.length > 0) {
        return filterbyCampaignOrder(
          rawHomeChartData.byDateOrg,
          filteredCampaignOrderIds
        );
      } else {
        return rawHomeChartData.byDateOrg;
      }
    }
    return [];
  }, [filteredCampaignOrderIds, rawHomeChartData]);

  const byDateReportChartData = useMemo(
    () =>
      bySummaryRangeDate.length > 0
        ? byDateOrgCompute(
            filterbyCampaignOrder(
              dataSummaryRangeDate,
              filteredCampaignOrderIds
            )
          )
        : byDateOrgCompute(filteredReport),
    [
      bySummaryRangeDate.length,
      dataSummaryRangeDate,
      filteredCampaignOrderIds,
      filteredReport,
    ]
  );

  const tableData = useMemo(
    () =>
      bySummaryRangeDate.length > 0
        ? filterbyCampaignOrder(dataSummaryRangeDate, filteredCampaignOrderIds)
        : filteredReport,
    [
      bySummaryRangeDate.length,
      dataSummaryRangeDate,
      filteredCampaignOrderIds,
      filteredReport,
    ]
  );

  const byDayReport = useMemo(
    () => toByDay(byDateOrgCompute(filteredReport || [])),
    [filteredReport]
  );

  async function prepareExport(type = "csv") {
    // default to csv
    const zip = new JSZip();
    const folder = zip.folder("ReportsCampaign");

    let reportToExport = tableData.slice(0);
    reportToExport = reportToExport.sort((a, b) => a.date - b.date);

    // delete data today if range date is default/empty
    // this only applies for advertiser weekly performance
    if (isEmpty(bySummaryRangeDate)) {
      reportToExport.pop();
    }

    let impressions = 0,
      clicks = 0,
      walkIns = 0,
      observations = 0,
      transactions = 0,
      revenue = 0,
      spend = 0;

    const copy = reportToExport.map(row => {
      const r = { ...row };
      const orgs = r.orgs ? r.orgs.map(o => o.name) : [];
      r.Organizations = orgs.toString();
      delete r.orgs;
      // avoid empty cells in the export
      for (const key in r) {
        if (r[key] === null) {
          r[key] = 0;
        }
      }
      impressions += r.impressions || 0;
      clicks += r.clicks || 0;
      walkIns += r.walkIns || 0;
      observations += r.observations || 0;
      transactions += r.transactionCount || 0;
      revenue += r.transactionTotal || 0;

      const calculateSpend = userPermission.priceVisible
        ? computeSpend(r.impressions, r.cpm || defaultCPM.cpm)
        : 0;
      spend += calculateSpend;

      const objToReturn = {
        DATE: r.xAxis,
        "ORDER ID": r.campaignOrderId,
        "CAMPAIGN ORDER NAME": r.campaignName,
        IMPRESSIONS: r.impressions,
        CLICKS: r.clicks,
        CTR: `${r.ctr.toFixed(3) || 0}%`,
        WALKINS: r.walkIns,
        OBSERVATIONS: r.observations,
        TRANSACTIONS: r.transactionCount,
        REVENUE: !isNaN(r.transactionTotal) ? r.transactionTotal.toFixed(3) : 0,
        ORGS: orgs && orgs.length ? JSON.stringify(orgs) : "",
        SPEND: !isNaN(calculateSpend) ? calculateSpend.toFixed(3) : 0,
      };

      if (!userPermission.priceVisible) {
        delete objToReturn["SPEND"];
      }
      return objToReturn;
    });

    const totalObjToReturn = {
      DATE: "Totals",
      "ORDER ID": "",
      "CAMPAIGN ORDER NAME": "",
      IMPRESSIONS: impressions,
      CLICKS: clicks,
      CTR: `${((clicks / impressions) * 100).toFixed(2)}%`,
      WALKINS: walkIns,
      OBSERVATIONS: observations,
      TRANSACTIONS: transactions,
      REVENUE: !isNaN(revenue) ? revenue.toFixed(3) : 0,
      SPEND: !isNaN(spend) ? spend.toFixed(3) : 0,
      ORGS: [],
    };

    if (!userPermission.priceVisible) {
      delete totalObjToReturn["SPEND"];
    }
    copy.push(totalObjToReturn);

    switch (type) {
      case "xlsx":
        const fileType =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        const ws = XLSX.utils.json_to_sheet(copy);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        folder.file(`PerformanceReport.xlsx`, data);
        break;
      default:
        // default is csv
        folder.file(`PerformanceReport.csv`, Papa.unparse(copy));
    }

    zip.generateAsync({ type: "blob" }).then(
      blob => {
        saveAs(blob, "Performance Report");
        setIsExportCompleted(true);
        return true;
      },
      function(e) {
        console.log("error", e);
        return false;
      }
    );
  }

  const onExportCSV = async () => {
    await prepareExport();
  };

  const onExportExcel = async () => {
    await prepareExport("xlsx");
  };

  const onExportWeeklyReportExcel = async () => {
    await onExportWeeklyReport("xlsx");
  };

  const onExportWeeklyReport = async (type = "csv") => {
    // default to csv
    // only affected by campaign order filter and not by date
    const reportData = byDateOrgCompute(filteredReport);
    const zip = new JSZip();
    const folder = zip.folder("Weekly Report");
    const prepareData = prepareCSV(compute(reportData));

    switch (type) {
      case "xlsx":
        const fileType =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        const ws = XLSX.utils.json_to_sheet(prepareData);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        folder.file(`WeeklyReport.xlsx`, data);
        console.log("yea");
        break;
      default:
        // default is csv
        folder.file(`WeeklyReport.csv`, Papa.unparse(prepareData));
    }

    zip.generateAsync({ type: "blob" }).then(
      blob => {
        saveAs(blob, "Weekly Report");
        return true;
      },
      function(e) {
        console.log("error", e);
        return false;
      }
    );
  };

  useEffect(() => {
    if (startDateParams && endDateParams) {
      const momentStartDate = moment(new Date(startDateParams));
      const momentEndDate = moment(new Date(endDateParams));

      // filter data to get only between range dates
      const filteredData = filterByDate(
        filteredReport,
        momentStartDate.startOf("day").unix(),
        momentEndDate.endOf("day").unix()
      );

      // set the date range on the selected date in calendar
      setBySummaryRangeDate([momentStartDate, momentEndDate]);
      // set the data into filtered ones with the selected range dates
      setDataSummaryRangeDate(filteredData);
    }
  }, [endDateParams, filteredReport, startDateParams]);

  useEffect(() => {
    if (props.loggedInRole) {
      try {
        if (props.loggedInRole.org.logo) {
          Storage.get(props.loggedInRole.org.logo.key).then(result => {
            setOrgLogo(result);
          });
        }
      } catch (err) {
        console.log(err);
      }
    }
  }, [props.loggedInRole]);

  useEffect(() => {
    if (rawHomeChartData) {
      setLoadingRawHomeChartData(false);
    }
  }, [rawHomeChartData]);

  useEffect(() => {
    if (filteredOrg.id) {
      setSelectedCOIds([]);
    }
  }, [filteredOrg, setSelectedCOIds]);

  useEffect(() => {
    if (showAnalytics) {
      getS3Report(); // Start the initial attempt
    }
  }, [getS3Report, showAnalytics]);

  return React.cloneElement(children, {
    ...props,
    startDateParams,
    endDateParams,
    userPermission,
    rawHomeChartData:
      rawHomeChartData && rawHomeChartData.byDateOrg
        ? rawHomeChartData.byDateOrg
        : [],
    loadingReport: loadingRawHomeChartData,
    bySummaryRangeDate,
    setBySummaryRangeDate,
    dataSummaryRangeDate,
    setDataSummaryRangeDate,
    filteredReport,
    byDayReport,
    tableData,
    filteredOrg,
    getS3Report,
    byDateReportRaw: filteredReport,
    byDateReportChartData,
    errorLoadingReport,
    setFilteredOrg,
    isExportCompleted,
    selectedCOIds,
    setSelectedCOIds,
    onExportCSV,
    showAnalytics,
    onExportWeeklyReport,
    disableRefresh: true,
    campaignOrders: filteredCampaignOrders,
    setIsExportCompleted,
    weeklyReportData: {
      ...compute(byDateOrgCompute(filteredReport)),
      topCreatives,
      advertiserName: props.loggedInRole.org.name,
      orgLogoUrl: orgLogo,
    },
    advertiserIds,
    filterBarProps: {
      campaignOrders: filteredCampaignOrders,
      selectedCOIds,
      setSelectedCOIds,
      orgConfigAccess: userPermission,
      onExportCSV,
      onExportExcel,
      onExportWeeklyReport,
      onExportWeeklyReportExcel,
      filteredOrg,
      setFilteredOrg,
      loadingReport: loadingRawHomeChartData,
      setIsExportCompleted,
      isExportCompleted,
      bySummaryRangeDate,
      setBySummaryRangeDate,
      setDataSummaryRangeDate,
      byDates: dataSummaryRangeDate,
      reportData: filteredReport,
    },
  });
};

export default DisplayChartsController;
