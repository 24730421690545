import React, { useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { Layout, Menu } from "antd";
import { SidebarIcon } from "../../.././core/utils/general";
import {
  subRoutes,
  subDivisions,
} from "../../.././core/utils/constants/constants";
import OrgDisplay from "../.././shared/OrgDisplay";
import sitemap from "../../routes/sitemap";
import {
  logo,
  subMenu,
  itemNotSelected,
  spanNotSelected,
  blackSpanNotSelected,
} from "../../shared/globalStyling/style";
import { determineUserAccess } from "../../../core/permissions/userLevelAccess";
//import ChatwootWidget from "../../../core/shared/ChatwootWidget";
//import { determineUserAccess } from "../../../core/permissions/userLevelAccess";
const { Sider } = Layout;
const { Item, SubMenu, ItemGroup } = Menu;

const getSpanStyle = sidebarColor => {
  if (!sidebarColor) return spanNotSelected;

  let cleanedColor = sidebarColor.startsWith("#")
    ? sidebarColor.slice(1)
    : sidebarColor;
  let [r, g, b] = cleanedColor.match(/.{1,2}/g).map(hex => parseInt(hex, 16));
  const isWhite = r >= 200 && g >= 200 && b >= 200;

  return isWhite ? blackSpanNotSelected : spanNotSelected;
};

const shouldShowSubCategory = (features, subDivisions) => {
  const existsInArray = (el, arr) => arr.includes(el);
  return features.some(feature => existsInArray(feature, subDivisions));
};

const transformURL = (url, feature) => {
  if (url !== undefined && url !== -1) return url;

  // The switch case is intentional in anticipation that there will be more features to be renamed.
  // If in the future, there are more features to be renamed, we can add more cases here.
  switch (feature) {
    case "DEMOGRAPHIC":
      return "/demograph";
    case "CREATE_TASK":
      return "/tasks/form";
    default:
      return `/${feature.toLowerCase()}`;
  }
};

const transformFeatureName = feature => {
  switch (feature) {
    case "DEMOGRAPHIC":
      return "Demograph";
    case "AUDIENCES":
      return "Audiences";
    case "LOCATIONS":
      return "Locations";
    case "ADVERTISER":
      return "Advertiser";
    case "PUBLISHER":
      return "Publisher";
    case "SIGNAL TAGS":
      return "Signal Tags";
    case "INVOICES":
      return "Invoices";
    case "AUDIENCE":
      return "Audience";
    case "TARGETING":
      return "Targeting";
    case "TAGS":
      return "Tags";
    case "CREATIVES":
      return "Creatives";
    case "RETAILS_STORES":
      return "Retail Stores";
    case "SETTINGS":
      return "Settings";
    case "KNOWLEDGE_BASE":
      return "Knowledge Base";
    case "ORGS":
      return "Organization";
    case "TASKS":
      return "Tasks";
    case "CREATE_TASK":
      return "Create Task";
    default:
      return feature;
  }
};

const accessibleFeatures = (
  allFeatures,
  features,
  rootPath,
  collapsed,
  subMenuSelected,
  setSubMenuSelected,
  sidebarColor,
  hasAccessAll = false, //Special Condition to show all feature without checking if user has access
  orgLevel,
  billing
) => {
  return allFeatures
    .filter(feature => {
      if (hasAccessAll) return true;
      return features.indexOf(feature) > -1;
    })
    .map((feature, i) => {
      if (
        (feature === "KNOWLEDGE_BASE" && orgLevel === "STANDARD") ||
        (feature === "INVOICES" && !billing) ||
        feature === "LIBRARY" ||
        feature === "HOME" ||
        feature === "USERS"
      ) {
        return null;
      }
      const selected =
        feature === rootPath && feature === "CREATE_TASK"
          ? "ant-menu-item-selected"
          : null;
      const keySubRoutes = Object.keys(subRoutes).find(key => key === feature);
      const featureName =
        feature.replace(/_/g, " ").charAt(0) +
        feature
          .replace(/_/g, " ")
          .slice(1)
          .toLowerCase();
      const siteMapIndex = Object.values(sitemap).findIndex(sitemapValues => {
        return sitemapValues === featureName;
      });

      const siteMapURL = Object.keys(sitemap)[siteMapIndex];
      return (
        <React.Fragment key={i}>
          {keySubRoutes ? (
            <SubMenu
              key={`${i}-submenu-${feature}`}
              onTitleClick={() => setSubMenuSelected(true)}
              title={
                <span>
                  {collapsed ? (
                    <Link
                      style={{ color: "rgba(255, 255, 255, 0.65)" }}
                      to={"/" + feature.toLowerCase()}
                    >
                      <SidebarIcon type={feature} />
                    </Link>
                  ) : (
                    <>
                      <Link style={subMenu} to={"/" + feature.toLowerCase()}>
                        <SidebarIcon type={feature} />
                        <span>
                          {feature === "CAMPAIGNS"
                            ? "Display"
                            : feature.replace(/_/g, " ").charAt(0) +
                              feature
                                .replace(/_/g, " ")
                                .slice(1)
                                .toLowerCase()}
                        </span>
                      </Link>
                    </>
                  )}
                </span>
              }
            >
              {subRoutes[keySubRoutes].map(subRoute => {
                const sitemapSubRouteIndex = Object.values(sitemap).findIndex(
                  sitemapValues => sitemapValues === subRoute
                );
                const subRouteURL = Object.keys(sitemap)[sitemapSubRouteIndex];
                let subName = subRoute;
                switch (subRoute) {
                  case "Paid Search Campaign":
                    subName = "Campaign";
                    break;
                  case "SEO Domains":
                    subName = "Domains";
                    break;
                  case "Paid Search Reports":
                    subName = "Reports";
                    break;
                  case "SEO Reports":
                    subName = "Reports";
                    break;
                  default:
                }
                if (orgLevel === "STANDARD" && subRoute === "Overview")
                  return null;

                return (
                  <Item key={subRoute}>
                    <Link to={subRouteURL ? subRouteURL : "/home"}>
                      <span>{subName}</span>
                    </Link>
                  </Item>
                );
              })}
            </SubMenu>
          ) : (
            <Item
              key={feature}
              style={!subMenuSelected ? {} : itemNotSelected}
              className={selected}
              onClick={() => setSubMenuSelected(false)}
            >
              <Link
                to={transformURL(siteMapURL, feature)}
                onClick={() => {
                  if (transformURL(siteMapURL, feature) === "/demograph") {
                    window.location.href = "/demograph";
                  }
                }}
              >
                <SidebarIcon type={feature} />
                <span
                  style={!subMenuSelected ? {} : getSpanStyle(sidebarColor)}
                >
                  {transformFeatureName(feature)}
                </span>
              </Link>
            </Item>
          )}
        </React.Fragment>
      );
    });
};

const renderCollapsedMenu = (
  divisions,
  features,
  rootPath,
  collapsed,
  subMenuSelected,
  setSubMenuSelected,
  sidebarColor,
  isSelfService,
  billing,
  orgLevel
) => {
  return (
    <ItemGroup key={"collapsedMenu"}>
      {divisions.map((division, i) => {
        if (division === "ATTENTION SIGNALS") {
          const hasPermission = features.includes("EDITORIAL");
          if (!hasPermission) return null;
          return accessibleFeatures(
            subDivisions[division],
            features,
            rootPath,
            collapsed,
            subMenuSelected,
            setSubMenuSelected,
            sidebarColor,
            true,
            orgLevel,
            billing
          );
        } else if (division === "LIBRARY") {
          if (!isSelfService) return null;
          return accessibleFeatures(
            subDivisions[division],
            features,
            rootPath,
            collapsed,
            subMenuSelected,
            setSubMenuSelected,
            sidebarColor,
            true,
            orgLevel,
            billing
          );
        } else if (division === "TASK MANAGEMENT") {
          const hasPermission = features.includes("TASK_MANAGEMENT");
          if (!hasPermission) return null;
          return accessibleFeatures(
            subDivisions[division],
            features,
            rootPath,
            collapsed,
            subMenuSelected,
            setSubMenuSelected,
            sidebarColor,
            true
          );
        } else if (division === "ADMIN") {
          let subDiv = subDivisions[division];

          const hasPermission = features.includes("ORGS");

          if (!hasPermission) {
            subDiv = ["SETTINGS"];
          }
          if (!hasPermission) return null;
          return accessibleFeatures(
            subDiv,
            features,
            rootPath,
            collapsed,
            subMenuSelected,
            setSubMenuSelected,
            sidebarColor,
            true,
            orgLevel,
            billing
          );
        } else {
          return accessibleFeatures(
            subDivisions[division],
            features,
            rootPath,
            collapsed,
            subMenuSelected,
            setSubMenuSelected,
            false,
            orgLevel,
            billing
          );
        }
      })}
    </ItemGroup>
  );
};

const renderExtendedMenu = (
  divisions,
  features,
  rootPath,
  collapsed,
  subMenuSelected,
  setSubMenuSelected,
  sidebarColor,
  isSelfService,
  billing,
  orgLevel
) => {
  return divisions.map((division, i) => {
    if (division === "ATTENTION SIGNALS") {
      const hasPermission = features.includes("EDITORIAL");
      if (!hasPermission) return null;
      return (
        <ItemGroup
          key={division}
          title={division}
          style={{ width: "100%", marginBottom: "10%" }}
        >
          {accessibleFeatures(
            subDivisions[division],
            features,
            rootPath,
            collapsed,
            subMenuSelected,
            setSubMenuSelected,
            sidebarColor,
            true,
            orgLevel,
            billing
          )}
        </ItemGroup>
      );
    }

    if (division === "LIBRARY") {
      if (!isSelfService) return null;
      return (
        <ItemGroup
          key={division}
          title={division}
          style={{ width: "100%", marginBottom: "10%" }}
        >
          {accessibleFeatures(
            subDivisions[division],
            features,
            rootPath,
            collapsed,
            subMenuSelected,
            setSubMenuSelected,
            sidebarColor,
            true,
            orgLevel,
            billing
          )}
        </ItemGroup>
      );
    }

    if (division === "TASK MANAGEMENT") {
      const hasPermission = features.includes("TASK_MANAGEMENT");
      if (!hasPermission) return null;
      return (
        <ItemGroup
          key={division}
          title={division}
          style={{ width: "100%", marginBottom: "10%" }}
        >
          {accessibleFeatures(
            subDivisions[division],
            features,
            rootPath,
            collapsed,
            subMenuSelected,
            setSubMenuSelected,
            sidebarColor,
            true
          )}
        </ItemGroup>
      );
    }

    if (division === "ADMIN") {
      let subDiv = subDivisions[division];

      const hasPermission = features.includes("ORGS");

      if (!hasPermission) {
        subDiv = ["SETTINGS"];
      }
      return (
        <ItemGroup
          key={division}
          title={division}
          style={{ width: "100%", marginBottom: "10%" }}
        >
          {accessibleFeatures(
            subDiv,
            features,
            rootPath,
            collapsed,
            subMenuSelected,
            setSubMenuSelected,
            sidebarColor,
            true,
            orgLevel,
            billing
          )}
        </ItemGroup>
      );
    }

    return division === "MAIN" ? (
      <ItemGroup
        key={division}
        title={division}
        style={{ width: "100%", marginBottom: "10%" }}
      >
        {accessibleFeatures(
          subDivisions[division],
          features,
          rootPath,
          collapsed,
          subMenuSelected,
          setSubMenuSelected,
          sidebarColor,
          false,
          orgLevel,
          billing
        )}
      </ItemGroup>
    ) : shouldShowSubCategory(features, subDivisions[division]) ? (
      <ItemGroup
        key={division}
        title={division}
        style={{ width: "100%", marginBottom: "10%" }}
      >
        {accessibleFeatures(
          subDivisions[division],
          features,
          rootPath,
          collapsed,
          subMenuSelected,
          setSubMenuSelected,
          false,
          orgLevel,
          billing
        )}
      </ItemGroup>
    ) : (
      ""
    );
  });
};

// We are assuming that we always want to show at least the "MAIN" subcategory
const renderSubCategoriesAndFeatures = (
  divisions,
  features,
  rootPath,
  collapsed,
  subMenuSelected,
  setSubMenuSelected,
  sidebarColor,
  isSelfService,
  billing,
  orgLevel
) => {
  return false
    ? renderCollapsedMenu(
        divisions,
        features,
        rootPath,
        collapsed,
        subMenuSelected,
        setSubMenuSelected,
        sidebarColor,
        isSelfService,
        billing,
        orgLevel
      )
    : renderExtendedMenu(
        divisions,
        features,
        rootPath,
        collapsed,
        subMenuSelected,
        setSubMenuSelected,
        sidebarColor,
        isSelfService,
        billing,
        orgLevel
      );
};

const Sidebar = props => {
  const userPermission = determineUserAccess(props.currentUser.permission);
  const orgLevel = props.currentUser.role.org.level;
  const { isSelfService, billing } = userPermission;

  const { collapsed } = props.state;
  const [subMenuSelected, setSubMenuSelected] = useState(false);
  const [logoKey, setLogoKey] = useState(props.logo ? props.logo.key : null);
  const [defaultLogoKey, setDefaultLogoKey] = useState(
    props.logo ? props.logo.key : null
  );
  const { features, location, toggleSideBar } = props;

  const rootPath = location.pathname.split("/")[1].toUpperCase();
  const divisions = Object.keys(subDivisions);
  const params = new URLSearchParams(props.location.search);
  let sidebarColor;
  //Listen for changes on local storage to update the logo on the sidebar
  window.addEventListener("imageKey", () => {
    const tempImage = localStorage.getItem("imageKey");
    if (tempImage) {
      setLogoKey(tempImage);
      localStorage.removeItem("imageKey");
    }
  });
  useEffect(() => {
    //Update logo key if the logo changes. This usually happens during preview advertiser
    if (props.logo && defaultLogoKey !== props.logo.key) {
      setDefaultLogoKey(props.logo.key);
      setLogoKey(props.logo.key);
    } else if (!props.logo && defaultLogoKey !== null) {
      //Consider the case where the org has no logo.
      setDefaultLogoKey(null);
      setLogoKey(null);
    }
  }, [defaultLogoKey, props.logo]);

  if (props && props.themeData && props.themeData.getOrgThemeConfig) {
    sidebarColor = props.themeData.getOrgThemeConfig.sidebarColor;
  }

  if (params.get("showNewSegments") === "true") {
    window.localStorage.setItem("showNewSegments", true);
  }

  if (params.get("showNewSegments") === "false") {
    window.localStorage.removeItem("showNewSegments");
  }

  const handleSubRouteForHorizontalNavigation = subRoute => {
    const sitemapSubRouteIndex = Object.values(sitemap).findIndex(
      sitemapValues => sitemapValues === subRoute
    );
    const subRouteURL = Object.keys(sitemap)[sitemapSubRouteIndex];
    return (
      <Menu.Item key={subRoute}>
        <Link to={subRouteURL}>{subRoute}</Link>
      </Menu.Item>
    );
  };

  if (props.horizontalNavigation) {
    return (
      <Menu theme="dark" mode="horizontal">
        <Menu.Item className="ant-menu-item-selected" key={"HOME"}>
          <Link to={"/home"}>
            <span>HOME</span>
          </Link>
        </Menu.Item>
        {features.map((feature, index) => (
          <React.Fragment key={index}>
            {Object.keys(subRoutes).find(key => key === feature) ? (
              <Menu.SubMenu
                key={feature}
                icon={<SidebarIcon type={feature} />}
                title={feature}
              >
                {subRoutes[feature].map(route =>
                  handleSubRouteForHorizontalNavigation(route)
                )}
              </Menu.SubMenu>
            ) : (
              <Menu.Item key={feature}>
                <Link to={"/" + feature.toLowerCase()}>
                  <SidebarIcon type={feature} />
                  <span>
                    {feature === "CAMPAIGNS"
                      ? "Display"
                      : feature.replace(/_/g, " ").charAt(0) +
                        feature
                          .replace(/_/g, " ")
                          .slice(1)
                          .toLowerCase()}
                  </span>
                </Link>
              </Menu.Item>
            )}
          </React.Fragment>
        ))}
      </Menu>
    );
  }

  return (
    <Sider
      collapsible
      trigger={null}
      breakpoint="xl"
      collapsedWidth="80"
      collapsed={collapsed}
      onBreakpoint={broken => {
        toggleSideBar(broken);
      }}
      style={{
        position: "sticky",
        top: 0,
        left: 0,
        height: "100vh",
        overflow: "auto",
      }}
    >
      <div
        className={
          collapsed ? "custom-sidebar-color collapsed" : "custom-sidebar-color"
        }
      >
        {/* {isSelfService === true && (
          <>
            {props.chatWootLoading === false && (
              <ChatwootWidget
                chatWootId={props.chatWootId}
                currentUser={props.currentUser}
              />
            )}
          </>
        )} */}
        <div style={collapsed ? null : logo}>
          <OrgDisplay imgKey={logoKey ? logoKey : null} collapsed={collapsed} />
        </div>
        <div>
          <Menu
            theme="dark"
            mode="inline"
            style={{
              border: "none",
              marginBottom: "30px",
            }}
          >
            {renderSubCategoriesAndFeatures(
              divisions,
              features,
              rootPath,
              collapsed,
              subMenuSelected,
              setSubMenuSelected,
              sidebarColor,
              isSelfService,
              billing,
              orgLevel
            )}
          </Menu>
        </div>
      </div>
    </Sider>
  );
};

export default withRouter(Sidebar);
