import gql from "graphql-tag";

const TASKS_CONNECTION = gql`
  query tasksConnection(
    $where: TaskWhereInput
    $orderBy: TaskOrderByInput
    $skip: Int
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    tasksConnection: tasksConnection(
      where: $where
      orderBy: $orderBy
      skip: $skip
      after: $after
      before: $before
      first: $first
      last: $last
    ) {
      pageInfo {
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          name
          dueDate
          recurrenceType
          weeklyDays
          monthlyType
          dayOfMonth
          dayOfWeek
          dateOfMonth
          yearlyDate
          yearlyMonth
          createdBy {
            id
            name
            avatar {
              id
              key
            }
            roles {
              id
              org {
                id
                name
                level
              }
            }
          }
          createdAt
          taskDetails
          recurring
          assigneeOrg {
            id
            name
          }
          assigneeUser {
            id
            name
            username
            avatar {
              id
              key
            }
          }
          assigneeUsers {
            id
            name
            username
            avatar {
              id
              key
            }
          }
          requestType
          requestSubType
          status
          channelPartner {
            id
            name
          }
          advertiser {
            id
            name
          }
          attachedImages {
            id
            name
            url
          }
          attachedFiles {
            id
            name
            url
          }
          codeSnippet
          relatedCampaigns {
            id
            name
            startDate
            endDate
          }
          attachedComments {
            id
            comment
            createdAt
            createdBy {
              id
              name
            }
            attachedFiles {
              id
              name
              url
            }
            attachedImages {
              id
              name
              url
            }
            codeSnippet
          }
          linkUrl
          urgency
        }
      }
      aggregate {
        count
      }
    }
    full: tasksConnection(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

export default TASKS_CONNECTION;
