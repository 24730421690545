import React from "react";
import { Row, Col, Table, Tooltip, Avatar, Card, Select } from "antd";
import DetailBar from "./components/DetailBar";
//import SummaryChart from "./components/charts/SummaryChart";
import { isEmpty } from "lodash";
import SummaryChart from "../../components/charts/SummaryChart";
import cnnaid from "../../../../../../assets/icons/transactions/cnnaid.png";
import cnnaip from "../../../../../../assets/icons/transactions/cnnaip.png";
import cnnafingerprint from "../../../../../../assets/icons/transactions/cnnafingerprint.png";
import moment from "moment";
import {
  TableColumnTitle,
  PrimaryTableRowText,
} from "../../../../../shared/globalStyling/styledText";
import ClicksSubTable from "./components/ClicksTimeline";

import { formatCurrency2SigFig } from "../../../../../../core/utils/campaigns";
const { Option } = Select;
const transactionAttributionWindowDataAdjustment = (
  transactions,
  attributionWindow
) => {
  const adjustedData = transactions.map(t => {
    const { node } = t;
    const { clicks, orderedOn } = node;
    const attributedWindow = moment(orderedOn).subtract(
      attributionWindow,
      "days"
    );
    const filteredClicks = clicks.filter(c => {
      return moment(c.clickTime).isSameOrAfter(attributedWindow);
    });
    if (filteredClicks && filteredClicks.length) {
      t.node.clicks = filteredClicks;
      t.node.lastClick = filteredClicks[filteredClicks.length - 1].clickTime;
      t.node.totalClick = filteredClicks.length;
    }
    return t;
  });
  return adjustedData;
};
const CommerceTab = ({
  commerce,
  setByCommerceRangeDate,
  attributionDropdownChange,
  attributionWindowDropdownChange,
  orgConfig,
  totalTransactions,
  pageNumber,
  transactions,
  location,
  history,
  startDate,
  endDate,
  attribution,
  attributionWindow,
}) => {
  const { byDate, conversions, clicks, customer, revenue } = commerce;
  const { priceVisible } = orgConfig;
  const columns = [
    {
      title: <TableColumnTitle text={"Order"} />,
      index: "orderId",
      key: "orderId",
      render: (text, { node }) => {
        return <PrimaryTableRowText text={node.orderId} />;
      },
    },
    {
      title: <TableColumnTitle text={"Last Clicked On"} />,
      index: "lastClick",
      key: "lastClick",
      render: (text, { node }) => {
        return (
          <PrimaryTableRowText
            text={moment(node.lastClick).format("YYYY-MM-DD H:mm:ss")}
          />
        );
      },
    },
    {
      title: <TableColumnTitle text={"Clicks"} />,
      index: "totalClick",
      key: "totalClick",
      render: (text, { node }) => {
        return <PrimaryTableRowText text={node.totalClick} />;
      },
    },
    {
      title: <TableColumnTitle text={"Ordered On"} />,
      index: "orderedOn",
      key: "orderedOn",
      render: (text, { node }) => {
        return (
          <PrimaryTableRowText
            text={moment(node.orderedOn).format("YYYY-MM-DD H:mm:ss")}
          />
        );
      },
    },
    {
      title: <TableColumnTitle text={"Total"} />,
      index: "total",
      key: "total",
      render: (text, { node }) => {
        return <PrimaryTableRowText text={formatCurrency2SigFig(node.total)} />;
      },
    },
    {
      title: <TableColumnTitle text={""} />,
      dataIndex: "attributionMethod",
      key: "attributionMethod",
      align: "left",
      width: "8%",
      render: (text, { node }) => {
        let attributionIcon = cnnaip;
        let tip = "";
        if (node.attributionMethod === "COOKIES") {
          attributionIcon = cnnaid;
          tip =
            "Cookie Match - Deterministic match using browser based cookie / uuid match.";
        } else if (node.attributionMethod === "FINGERPRINT") {
          attributionIcon = cnnafingerprint;
          tip =
            "User Match - Probabilistic match using user fingerprint. (User fingerprint - The combination of user agent and IP address to produce a unique hash)";
        } else if (node.attributionMethod === "IP") {
          tip = "IP Match - Probabilistic match using IP address.";
          attributionIcon = cnnaip;
        } else if (node.attributionMethod === "DEVICE_FINGERPRINT") {
          tip =
            "Device Match - Probabilistic match using user device model information (Device model, OS, OS version ) combined with IP address";
          attributionIcon = cnnafingerprint;
        }
        return (
          <Tooltip title={tip}>
            <Avatar src={attributionIcon} />
          </Tooltip>
        );
      },
    },
  ];

  return (
    <>
      <Row gutter={35}>
        <Col xs={24}>
          <DetailBar
            multiClicks={clicks || 0}
            customers={customer || 0}
            revenue={revenue || 0}
            transactions={conversions || 0}
            showPrices={priceVisible}
          />
        </Col>
        <Col xs={24}>
          <SummaryChart
            byDates={isEmpty(byDate) ? [] : byDate}
            reportData={byDate}
            bySummaryRangeDate={
              startDate && endDate
                ? [moment(startDate).utc(), moment(endDate).utc()]
                : null
            }
            dataSummaryRangeDate={byDate}
            setBySummaryRangeDate={setByCommerceRangeDate}
            showTransactions={true}
            showPrices={priceVisible}
            defaultXValue="conversions"
            hasTooltip={false}
          />
        </Col>
        <Col xs={24}>
          <Card
            title={"Transactions"}
            extra={
              <Row>
                <Col>
                  {"Attribution Method: "}
                  <Select
                    defaultValue="All"
                    value={attribution ? attribution : "All"}
                    style={{ width: 120 }}
                    onChange={val => {
                      attributionDropdownChange(val);
                    }}
                  >
                    <Option value={"ALL"}>All</Option>
                    <Option value={"COOKIES"}>Cookies</Option>
                    <Option value={"IP"}>IP Address</Option>
                    <Option value={"FINGERPRINT"}>Fingerprint</Option>
                    <Option value={"DEVICE_FINGERPRINT"}>
                      Device Fingerprint
                    </Option>
                  </Select>
                </Col>
                <Col>
                  {"Attribution Window: "}
                  <Select
                    defaultValue={30}
                    value={attributionWindow ? Number(attributionWindow) : 30}
                    style={{ width: 120 }}
                    onChange={val => {
                      attributionWindowDropdownChange(val);
                    }}
                  >
                    <Option value={30}>30 Days</Option>
                    <Option value={20}>20 Days</Option>
                    <Option value={10}>10 Days</Option>
                    <Option value={5}>5 Days</Option>
                    <Option value={2}>2 Day</Option>
                    <Option value={1}>1 Day</Option>
                  </Select>
                </Col>
              </Row>
            }
          >
            <Table
              dataSource={transactionAttributionWindowDataAdjustment(
                transactions,
                attributionWindow
              )}
              columns={columns}
              pagination={{
                position: ["none", "bottomCenter"],
                total: totalTransactions,
                current: Number(pageNumber),
                responsive: true,
                showSizeChanger: false,
                showLessItems: true,
                size: "default",
              }}
              rowKey={({ node }) => node.id}
              expandable={{
                expandedRowRender: record => {
                  return <ClicksSubTable clicks={record.node.clicks} />;
                },
              }}
              onChange={(paginationObject, filtersObject, sorterObject) => {
                const nextPage = location.pathname.split("/");
                nextPage[4] = paginationObject.current;
                const searchParams = new URLSearchParams(
                  window.location.search
                );

                history.push({
                  pathname: nextPage.join("/"),
                  search: `?${searchParams.toString()}`,
                });
              }}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default CommerceTab;
