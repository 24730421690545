import React from "react";
import moment from "moment";

const colorPicker = (date, value) => {
  let color = "success";
  if (!date) {
    color = "secondary";
  } else if (date && value < 1) {
    color = "danger";
  }
  return color;
};

const typePicker = (date, value) => {
  let color = "success";
  if (!date) {
    color = "default";
  } else if (date && value < 1) {
    color = "error";
  }
  return color;
};

const TagsMainController = ({ ...props }) => {
  const {
    signalTagsData,
    requestAssistance,
    createImpressionTag,
    refetch,
    currentOrgId,
    isAttentionSignals,
    advertisers,
    getBasicTagActivity,
  } = props;

  const publishersTags = [];
  const measurementsTags = [];
  const impressionsTags = [];

  // Process publisher tags
  signalTagsData.editorials.forEach(publisher => {
    const { clicksTag, transactionsTag } = publisher;
    const publisherExisting = publishersTags.find(p => p.id === clicksTag.id);
    const measurementExisting = measurementsTags.findIndex(
      p => p.id === transactionsTag.id
    );

    if (!publisherExisting) {
      publishersTags.push({
        id: clicksTag.id,
        name: clicksTag.name,
        advertiser: clicksTag.orgs[0].name,
        domain: clicksTag.domain,
        pageViews: clicksTag.pageViews,
        tagId: clicksTag.eventTags[0].appId[0],
        orgId: clicksTag.orgs[0].id,
        date: clicksTag.tagUpdateDate
          ? moment(clicksTag.tagUpdateDate).format("MM/DD/YYYY HH:MM")
          : "Signal Unknown",
        color: typePicker(clicksTag.tagUpdateDate, clicksTag.pageViews),
        typeColor: colorPicker(clicksTag.tagUpdateDate, clicksTag.pageViews),
      });
    }

    const {
      transactions,
      pageViews,
      signups,
      name,
      id,
      tagUpdateDate,
      eventTags,
      orgs,
      isSignUps,
    } = transactionsTag;
    const appId = eventTags[0].appId[0];

    if (measurementExisting === -1) {
      measurementsTags.push({
        id,
        name,
        advertiser: orgs[0].name,
        orgId: orgs[0].id,
        funnels: 1,
        measuredEvents: {
          pageViews,
          transactions,
          signups,
          isSignUps,
          date: tagUpdateDate
            ? moment(tagUpdateDate).format("MM/DD/YYYY HH:MM")
            : "Signal Unknown",
          colors: {
            transactions: typePicker(tagUpdateDate, transactions),
            pageViews: typePicker(tagUpdateDate, pageViews),
            signups: typePicker(tagUpdateDate, signups),
          },
          typeColors: {
            transactions: colorPicker(tagUpdateDate, transactions),
            pageViews: colorPicker(tagUpdateDate, pageViews),
            signups: colorPicker(tagUpdateDate, signups),
          },
        },
        appId,
      });
    } else {
      measurementsTags[measurementExisting].funnels += 1;
    }
  });

  // Process impression tags
  signalTagsData.impressionTags.forEach(impressionsTag => {
    impressionsTags.push({
      id: impressionsTag.id,
      name: impressionsTag.name,
      dsp: impressionsTag.impressionEnvironments
        ? impressionsTag.impressionEnvironments
        : impressionsTag.dsp,
      tagId: impressionsTag.eventTags[0].appId[0],
      orgId: impressionsTag.orgs[0].id,
      impressions: impressionsTag.pageViews,
      color: typePicker(impressionsTag.tagUpdateDate, impressionsTag.pageViews),
      typeColor: colorPicker(
        impressionsTag.tagUpdateDate,
        impressionsTag.pageViews
      ),
      date: impressionsTag.tagUpdateDate
        ? moment(impressionsTag.tagUpdateDate).format("MM/DD/YYYY HH:MM")
        : "Signal Unknown",
    });
  });

  // Process measurement tags
  signalTagsData.measurementsTags.forEach(measurementsTag => {
    const measurementExisting = measurementsTags.findIndex(
      p => p.id === measurementsTag.id
    );

    const {
      transactions,
      pageViews,
      signups,
      name,
      id,
      tagUpdateDate,
      eventTags,
      orgs,
      isSignUps,
      type,
      isPublisherDomain,
      domain,
    } = measurementsTag;
    const appId = eventTags[0].appId[0];

    // Add the Native Measurement tag to publisher tag list
    if (type.includes("PUBLISHER")) {
      const publisherExisting = publishersTags.findIndex(p => p.id === id);

      if (publisherExisting === -1) {
        publishersTags.push({
          id,
          name,
          advertiser: orgs[0].name,
          domain,
          pageViews,
          tagId: appId,
          orgId: orgs[0].id,
          date: tagUpdateDate
            ? moment(tagUpdateDate).format("MM/DD/YYYY HH:MM")
            : "Signal Unknown",
          color: typePicker(tagUpdateDate, pageViews),
          typeColor: colorPicker(tagUpdateDate, pageViews),
        });
      }
    }

    if (isPublisherDomain && !type.includes("MEASUREMENT")) return;

    if (measurementExisting === -1) {
      measurementsTags.push({
        id,
        name,
        advertiser: orgs[0].name,
        orgId: orgs[0].id,
        funnels: 0,
        measuredEvents: {
          pageViews,
          transactions,
          signups,
          isSignUps,
          date: tagUpdateDate
            ? moment(tagUpdateDate).format("MM/DD/YYYY HH:MM")
            : "Signal Unknown",
          colors: {
            transactions: typePicker(tagUpdateDate, transactions),
            pageViews: typePicker(tagUpdateDate, pageViews),
            signups: typePicker(tagUpdateDate, signups),
          },
          typeColors: {
            transactions: colorPicker(tagUpdateDate, transactions),
            pageViews: colorPicker(tagUpdateDate, pageViews),
            signups: colorPicker(tagUpdateDate, signups),
          },
        },
        appId,
      });
    }
  });

  // Process advertiser tags
  signalTagsData.advertisers.forEach(advertiser => {
    const { transactionsTag, transactionsTags } = advertiser;
    if (transactionsTag) {
      const measurementExisting = measurementsTags.findIndex(
        p => p.id === transactionsTag.id
      );

      const {
        transactions,
        pageViews,
        signups,
        name,
        id,
        tagUpdateDate,
        eventTags,
        orgs,
        isSignUps,
      } = transactionsTag;
      const appId = eventTags[0].appId[0];

      if (measurementExisting === -1) {
        measurementsTags.push({
          id,
          name,
          advertiser: orgs[0].name,
          orgId: orgs[0].id,
          funnels: 1,
          measuredEvents: {
            pageViews,
            transactions,
            signups,
            isSignUps,
            date: tagUpdateDate
              ? moment(tagUpdateDate).format("MM/DD/YYYY HH:MM")
              : "Signal Unknown",
            colors: {
              transactions: typePicker(tagUpdateDate, transactions),
              pageViews: typePicker(tagUpdateDate, pageViews),
              signups: typePicker(tagUpdateDate, signups),
            },
            typeColors: {
              transactions: colorPicker(tagUpdateDate, transactions),
              pageViews: colorPicker(tagUpdateDate, pageViews),
              signups: colorPicker(tagUpdateDate, signups),
            },
          },
          appId,
        });
      } else {
        measurementsTags[measurementExisting].funnels += 1;
      }
    } else {
      transactionsTags.forEach(tag => {
        const { eventsTarget } = tag;
        const measurementExisting = measurementsTags.findIndex(
          p => p.id === eventsTarget.id
        );

        const {
          transactions,
          pageViews,
          signups,
          name,
          id,
          tagUpdateDate,
          eventTags,
          orgs,
          isSignUps,
        } = eventsTarget;
        const appId = eventTags[0].appId[0];

        if (measurementExisting === -1) {
          measurementsTags.push({
            id,
            name,
            funnels: 1,
            advertiser: orgs[0].name,
            orgId: orgs[0].id,
            measuredEvents: {
              pageViews,
              transactions,
              signups,
              isSignUps,
              date: tagUpdateDate
                ? moment(tagUpdateDate).format("MM/DD/YYYY HH:MM")
                : "Signal Unknown",
              colors: {
                transactions: typePicker(tagUpdateDate, transactions),
                pageViews: typePicker(tagUpdateDate, pageViews),
                signups: typePicker(tagUpdateDate, signups),
              },
              typeColors: {
                transactions: colorPicker(tagUpdateDate, transactions),
                pageViews: colorPicker(tagUpdateDate, pageViews),
                signups: colorPicker(tagUpdateDate, signups),
              },
            },
            appId,
          });
        } else {
          measurementsTags[measurementExisting].funnels += 1;
        }
      });
    }
  });

  // Process publisher domain tags
  signalTagsData.publisherDomainTags.forEach(publisherDomainTag => {
    const publisherExisting = publishersTags.findIndex(
      p => p.id === publisherDomainTag.id
    );

    if (publisherExisting === -1) {
      publishersTags.push({
        id: publisherDomainTag.id,
        advertiser: publisherDomainTag.orgs[0].name,
        name: publisherDomainTag.name,
        domain: publisherDomainTag.domain,
        pageViews: publisherDomainTag.pageViews,
        tagId: publisherDomainTag.eventTags[0].appId[0],
        orgId: publisherDomainTag.orgs[0].id,
        date: publisherDomainTag.tagUpdateDate
          ? moment(publisherDomainTag.tagUpdateDate).format("MM/DD/YYYY HH:MM")
          : "Signal Unknown",
        color: typePicker(
          publisherDomainTag.tagUpdateDate,
          publisherDomainTag.pageViews
        ),
        typeColor: colorPicker(
          publisherDomainTag.tagUpdateDate,
          publisherDomainTag.pageViews
        ),
      });
    }
  });

  const onImressionsTagCreate = async input => {
    const { name, dsp } = input;
    await createImpressionTag({
      variables: {
        name,
        dsp,
        orgId: currentOrgId,
      },
    });
  };

  const testTag = async ({ appId, orgId, tagId }) => {
    await getBasicTagActivity({
      variables: {
        appId,
        orgId,
        tagId,
      },
    });
  };

  publishersTags.forEach(t => {
    if (t.name === "Native Measurement Tag") console.log(t);
  });

  return React.cloneElement(props.children, {
    ...props.children.props,
    publishersTags,
    measurementsTags,
    impressionsTags,
    onImressionsTagCreate,
    requestAssistance,
    refetch,
    isAttentionSignals,
    advertisers: advertisers.getDSP,
    testTag,
  });
};

export default TagsMainController;
