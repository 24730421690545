import React from "react";
import { useLocation } from "react-router-dom";

const TagsManagerUpdateController = ({
  data,
  orgs,
  currentOrgId,
  carts,
  onSuccess = () => {},
  updateEventTag,
  platforms,
  updateImpressionTag,
  ...props
}) => {
  const { eventsTarget } = data;
  const location = useLocation();

  const advertisers = orgs.orgs.map(org => {
    return { value: org.id, label: org.name };
  });
  const { getCarts } = carts;
  const cart = getCarts.find(cart => cart.value === eventsTarget.cart) || {
    label: "Unsupported Cart",
    value: "thirdparty", //Mark unsupported carts as thirdparty
    eventsTracked: [],
  };
  const details = {
    id: eventsTarget.id,
    name: eventsTarget.name,
    appId: eventsTarget.eventTags[0].appId[0],
    cartLabel: cart.label,
    cart: cart.value,
    advertiser: eventsTarget.orgs[0].id,
    ecommerce: eventsTarget.transactions > 0 ? true : eventsTarget.isCommerce,
    signups: eventsTarget.signups > 0 ? true : eventsTarget.isSignUps,
    isPublisherDomain: eventsTarget.isPublisherDomain,
    publisherDomain: eventsTarget.domain,
    isAdvertiser: eventsTarget.isAdvertiser,
    impressionEnvironments: eventsTarget.impressionEnvironments,
    dsp: eventsTarget.dsp,
    type: eventsTarget.type,
  };

  const onSubmit = values => {
    console.log(values);
    const searchParams = new URLSearchParams(location.search);
    const source = searchParams.get("source");

    switch (source) {
      case "impression":
        updateImpressionTag({
          variables: {
            data: {
              advertiserName: values.name,
              environment: values.dsp,
              orgId: values.advertiser,
              id: eventsTarget.id,
              appId: values.appId,
            },
          },
        });
        break;
      case "publisher":
        let type = [];

        if (details.isPublisherDomain) type.push("PUBLISHER");
        if (values.isMulti === "yes") {
          type.push("PUBLISHER");
          type.push("MEASUREMENT");
        }

        updateEventTag({
          variables: {
            data: {
              id: eventsTarget.id,
              name: values.name,
              orgId: values.advertiser,
              publisherDomain:
                values.domainProtocol +
                values.domainUrl +
                values.domainExtension,
              isPublisherDomain: details.isPublisherDomain,
              isEcommerce: eventsTarget.isCommerce,
              isSignUps: eventsTarget.isSignUps,
              cart: eventsTarget.cart,
              domain: values.domain,
              appId: values.appId,
              type,
            },
          },
        });
        break;
      default:
        updateEventTag({
          variables: {
            data: {
              id: eventsTarget.id,
              name: values.name,
              orgId: values.advertiser,
              isEcommerce: values.ecommerce,
              cart: values.platform,
              isSignUps: values.signups,
              domain: values.domain,
              appId: values.appId,
            },
          },
        });
        break;
    }
  };

  let tagType = "Measurement";
  if (eventsTarget.isPublisherDomain) tagType = "Publisher";

  return React.cloneElement(props.children, {
    ...props.children.props,
    details,
    advertisers,
    currentOrgId,
    carts: carts.getCarts,
    orgs,
    onSubmit,
    tagType,
    platforms: platforms.getDSP,
  });
};

export default TagsManagerUpdateController;
