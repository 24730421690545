import gql from "graphql-tag";

const SIGNAL_TAGS = gql`
  query SignalTags(
    $wherePublisher: EditorialWhereInput!
    $whereAdvertiser: AdvertiserWhereInput!
    $whereImpressionTag: EventsTargetWhereInput!
    $whereMeasurementsTag: EventsTargetWhereInput!
    $publisherDomainTags: EventsTargetWhereInput!
  ) {
    editorials: editorials(where: $wherePublisher) {
      id
      name
      transactions
      customers
      clicks
      pageView
      trackMesurements
      clicksTag {
        id
        name
        pageViews
        tagUpdateDate
        domain
        eventTags(first: 1) {
          appId
        }
        orgs(first: 1) {
          id
          name
          website
        }
      }
      transactionsTag {
        id
        name
        eventTags(first: 1) {
          appId
        }
        cart
        tag
        pageViews
        signups
        transactions
        basketItems
        tagUpdateDate
        testLogs {
          id
          testDate
          pageViews
          signups
          transactions
          basketItems
        }
        isSignUps
        orgs(first: 1) {
          id
          name
        }
        createdAt
      }
    }
    advertisers: advertisers(where: $whereAdvertiser) {
      id
      name
      transactionsTags {
        id
        eventsTarget {
          id
          name
          eventTags(first: 1) {
            appId
          }
          cart
          tag
          pageViews
          signups
          transactions
          basketItems
          tagUpdateDate
          testLogs {
            id
            testDate
            pageViews
            signups
            transactions
            basketItems
          }
          isSignUps
          orgs(first: 1) {
            id
            name
          }
          createdAt
        }
      }
      transactionsTag {
        id
        name
        eventTags(first: 1) {
          appId
        }
        cart
        tag
        pageViews
        signups
        transactions
        basketItems
        tagUpdateDate
        testLogs {
          id
          testDate
          pageViews
          signups
          transactions
          basketItems
        }
        isSignUps
        orgs(first: 1) {
          id
          name
        }
        createdAt
      }
    }
    impressionTags: eventsTargets(where: $whereImpressionTag) {
      id
      name
      dsp
      impressionEnvironments
      pageViews
      tagUpdateDate
      eventTags(first: 1) {
        id
        appId
      }
      orgs(first: 1) {
        id
        name
      }
    }
    measurementsTags: eventsTargets(where: $whereMeasurementsTag) {
      id
      name
      eventTags(first: 1) {
        appId
      }
      cart
      tag
      pageViews
      signups
      transactions
      basketItems
      total
      tagUpdateDate
      testLogs {
        id
        testDate
        pageViews
        total
        signups
        transactions
        basketItems
      }
      isSignUps
      orgs(first: 1) {
        id
        name
      }
      createdAt
      type
      isPublisherDomain
    }
    publisherDomainTags: eventsTargets(where: $publisherDomainTags) {
      id
      name
      pageViews
      tagUpdateDate
      domain
      eventTags(first: 1) {
        appId
      }
      orgs(first: 1) {
        id
        name
        website
      }
    }
  }
`;

export default SIGNAL_TAGS;
