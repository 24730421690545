import gql from "graphql-tag";

const LIST_CAMPAIGNORDERS_WITH_PACING = gql`
  query campaignOrdersWithPacing(
    $where: CampaignOrderWhereInput!
    $orderBy: CampaignOrderOrderByInput
  ) {
    campaignOrdersConnection(where: $where, orderBy: $orderBy) {
      pageInfo {
        hasNextPage
      }
      edges {
        node {
          id
          name
          startDate
          endDate
          orgs {
            id
            name
          }
        }
      }
      aggregate {
        count
      }
    }
    full: campaignOrdersConnection(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

export default LIST_CAMPAIGNORDERS_WITH_PACING;
