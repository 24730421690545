/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";

// Ant Design
import {
  Tag,
  Row,
  Col,
  Card,
  Spin,
  Input,
  Button,
  Divider,
  message,
  Typography,
  Grid,
  Select,
  Popconfirm,
} from "antd";

import {
  TeamOutlined,
  FormOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { Gauge } from "@ant-design/plots";

// Custom Components
import { PageHeaderLayout } from "../../../shared/layout";
import { ButtonText } from "../../../shared/globalStyling/styledText";

// Demographic Components
import CategoryList from "./CategoryList";
import SubCategoryList from "./SubCategoryList";
import SelectedMetricList from "./SelectedMetricList";
import SearchResultsView from "./SearchResultsView";
import MobileViewCategoryList from "./MobileViewCategoryList";

// Modals
import AudienceBuilderSaveModal from "./Modals/AudienceBuilderSaveModal";

// Form
import AudienceDataPointInput from "./Metrics/AudienceDataPointInputForm";

// Constants
import { graphConfiguration } from "../../../../core/utils/constants/demographicConstants";

// Utils
import { getGaugeColor, getColorTag } from "../utils/getColorRange";
import { filterAndMapCategories } from "../utils/searchUtils";
import { transformAudienceSchema } from "../utils/transformAudienceSchema";
import { isMobileScreen } from "../utils/mobileUtils";
import {
  getExistingMetricValue,
  extractPrimaryMetric,
  findSelectedSubCategory,
  findCategoryAndSubCategory,
} from "../utils/dataMapper";
import {
  cardBodyStyle,
  categoryList,
  searchResultList,
  subCategoryList,
  gaugeStyle,
  sideBarCardStyle,
  sideBarTitleStyle,
} from "../styling/inline-style";
import "../styling/styles.css";
import { AudienceType, SegmentType } from "../constants/constants";
import { useAudienceBuilderContext } from "../context/AudienceBuilderContext";

const { Option } = Select;
const { Text, Title } = Typography;

const AudienceBuilderPage = props => {
  const {
    history,
    audienceSchema,
    fetchAudienceCount,
    handleCreateAudience,
    refetchAudienceSchema,
    loadingAudienceSchema,
    handleFetchAudienceDemographs,
  } = props;

  const {
    addAsNewCard,
    setInputValue,
    setMatchCharacteristics,
    setMetricValue,
    setActiveTabKey,
    setUserSelections,
    setSelectedCheckboxes,
  } = useAudienceBuilderContext();

  const { useBreakpoint } = Grid;
  const breakPoint = useBreakpoint();

  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState();
  const [selectedMetrics, setSelectedMetrics] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);

  const [open, setOpen] = useState(false);
  const [metrics, setMetrics] = useState([]);
  const [loading, setLoading] = useState(false);

  const [searchValues, setSearchValues] = useState(null);
  const [reachCountMode, setReachCountMode] = useState(AudienceType.Devices);
  const [reachCountDevices, setReachCountDevices] = useState(0);
  const [groupedCategories, setGroupedCategories] = useState([]);
  const [graphStateConfig, setGraphStateConfig] = useState(graphConfiguration);
  const [categoryName, setCategoryName] = useState(null);

  const onCategoryClick = category => {
    if (category === selectedCategory) {
      setSelectedCategory(null);
      setSelectedSubCategory(null);
      setSubCategories([]);
      return;
    }

    setSelectedCategory(category);
    setSubCategories(category.datapoints);

    if (isMobileScreen(breakPoint)) {
      setActiveTabKey("2");
    }
  };

  const onSubCategoryClick = subCategory => {
    setSelectedSubCategory(subCategory);
    setMetrics({
      ...extractPrimaryMetric(subCategory),
    });
    setSelectedCheckboxes([]);

    // Map all the user selections from the mappings
    handleUserSelections(subCategory);

    const existingDataPoint = selectedMetrics.find(item => {
      return item.key === subCategory.key;
    });

    const metricValue = getExistingMetricValue(
      subCategory.mappings[0],
      selectedMetrics
    );

    if (metricValue) {
      setInputValue(metricValue.value);
      setMetricValue(metricValue);
      setMatchCharacteristics(metricValue.operator);
    } else if (existingDataPoint) {
      handleSelectedMetric(existingDataPoint);
    } else {
      setInputValue(undefined);
      setMetricValue(null);
    }

    if (isMobileScreen(breakPoint)) {
      setActiveTabKey("3");
    }
    subCategory.categoryName === undefined
      ? setCategoryName(subCategory.mappings[0].providerCategory)
      : setCategoryName(subCategory.categoryName);
  };

  const handleUserSelections = subCategory => {
    if (!subCategory || !Array.isArray(subCategory.mappings)) {
      return;
    }

    const userSelections =
      subCategory.mappings.length > 1
        ? subCategory.mappings.map(item => item.userSelections[0])
        : subCategory.mappings[0].userSelections;

    setUserSelections(userSelections);
  };

  const transformInputValueByType = metric => {
    if (metric.dataType === "boolean") {
      return metric.value === 1;
    }
    return metric.value;
  };

  const handleSelectedMetric = metric => {
    try {
      const { key, value, uniqueId, operator } = metric;
      const inputValue = transformInputValueByType(metric);
      const dimensions = audienceSchema.internalMicroserviceAudienceSchema;

      const selectedSubCategory = findSelectedSubCategory(categories, key);

      const { matchedDimension, category } = findCategoryAndSubCategory(
        dimensions,
        audienceSchema,
        key
      );

      // Set the value you see in the input field/Audience Data Point Input Form
      setInputValue(inputValue);
      setSelectedCheckboxes(value);
      setMetricValue(metric);
      setMatchCharacteristics(operator);
      setSubCategories(category.datapoints);
      setSelectedCategory(category);
      handleUserSelections(selectedSubCategory);
      setSelectedSubCategory(selectedSubCategory);
      setMetrics({
        name: matchedDimension.subCategory,
        type: matchedDimension.type,
        key: key,
        uniqueId: uniqueId,
        mappings: matchedDimension.mappings[0],
      });

      if (isMobileScreen(breakPoint)) {
        setActiveTabKey("3");
      }
    } catch (error) {
      message.error("Failed to select metric. Please try again.");
    }
  };

  const processDeviceCount = async metrics => {
    try {
      setLoading(true);
      let response = { data: { getAudienceCount: { count: 0 } } };

      if (metrics.length > 0) {
        response = await fetchAudienceCount(metrics);
      }

      const audienceCountData = response.data.getAudienceCount;

      const totalDeviceCount =
        reachCountMode === AudienceType.Devices
          ? audienceCountData.reachedDevices
          : audienceCountData.reachedIndividuals;

      const percentage = (totalDeviceCount * 100) / 100000;
      setReachCountDevices(totalDeviceCount);
      setGraphStateConfig(prevState => ({
        ...prevState,
        percent: percentage / 100,
        range: {
          color: getGaugeColor(totalDeviceCount),
        },
        statistic: {
          ...prevState.statistic,
          title: {
            ...prevState.statistic.title,
            formatter: () =>
              totalDeviceCount ? totalDeviceCount.toLocaleString() : "0",
          },
        },
      }));
    } catch (error) {
      message.error("Failed to fetch audience count. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const removeAudienceMetric = async metric => {
    try {
      const filteredMetrics = selectedMetrics.filter(
        item => item.uniqueId !== metric.uniqueId
      );

      setInputValue(undefined);
      setMetricValue(null);
      setSelectedMetrics(filteredMetrics);
      setLoading(true);
      await processDeviceCount(filteredMetrics);
      setLoading(false);
    } catch (error) {
      message.error("Failed to remove metric. Please try again.");
    }
  };

  const removeAllAudienceMetric = async () => {
    try {
      // Clear state of selected metrics
      setSelectedMetrics([]);
      // Refesh the device count
      await processDeviceCount([]);
    } catch (error) {
      message.error("Failed to remove metric. Please try again.");
    }
  };

  /**
   * Checks if a new metric has duplicate values within an array of selected metrics.
   *
   * This function compares the `key` of the `newMetric` with each `existingMetric` in the `selectedMetrics` array.
   * It then checks if any of the `value` elements in the `newMetric` have a matching `value` element in the `existingMetric`.
   *
   * @param {Object} newMetric - The metric object to check for duplicates. Contains a `key` and an array of `value` objects.
   * @param {Array} selectedMetrics - An array of existing metric objects, each containing a `key` and an array of `value` objects.
   *
   * @returns {boolean} `true` if there is at least one matching `key` and a duplicate value found in `selectedMetrics`; otherwise, `false`.
   */

  const updateAudienceMetric = async newValues => {
    try {
      // First, update the selected item with the new values
      const updatedArray = selectedMetrics.map(item =>
        item.uniqueId === newValues.uniqueId ? { ...item, ...newValues } : item
      );

      // Then, ensure the first item's subCategoryLogicalOperator matches the second item
      const adjustedArray = updatedArray.map((item, index) => {
        if (index === 0 && updatedArray[1]) {
          // Update the first item's operator to match the second item's operator
          item.subCategoryLogicalOperator =
            updatedArray[1].subCategoryLogicalOperator;
        }
        return item;
      });

      setSelectedMetrics(adjustedArray);
      await processDeviceCount(adjustedArray);
    } catch (error) {
      message.error("Failed to update metric. Please try again.");
    }
  };

  const mergeUniqueValues = (existingValues, newValues) => {
    return [
      ...existingValues,
      ...newValues.filter(
        newVal => !existingValues.some(val => val.value === newVal.value)
      ),
    ].sort((a, b) => a.label.localeCompare(b.label));
  };

  const generateUniqueId = name => {
    const trimmedName = name
      .trim()
      .toLowerCase()
      .replace(/\s+/g, "-");
    return `${trimmedName}-${Date.now()}-${Math.random()
      .toString(36)
      .substring(2, 15)}`;
  };

  const getSegmentType = matchCharacteristics => {
    let segmentType = SegmentType.Include; // or another default value, if needed
    if (matchCharacteristics === "=") {
      segmentType = SegmentType.Include;
    } else if (matchCharacteristics === "!=") {
      segmentType = SegmentType.Exclude;
    }
    return segmentType;
  };

  const handleAddOrUpdateDatapoint = async value => {
    let success = false;
    let uniqueId = null;
    try {
      message.destroy();
      // Deconstruct data
      const { matchCharacteristics, dataPoint } = value;

      let operator = matchCharacteristics;
      let segmentType = getSegmentType(matchCharacteristics);

      // Check datapoint is already in the selected metrics
      if (dataPoint.uniqueId && !addAsNewCard) {
        // Execute Update Logic
        uniqueId = dataPoint.uniqueId;

        const existing = selectedMetrics.find(item => {
          return item.uniqueId === dataPoint.uniqueId;
        });

        const { dataType } = existing;

        let newValue = null;
        switch (dataType) {
          case "userSelections":
            newValue = dataPoint.value;
            operator = "=";
            break;
          case "number":
            newValue = dataPoint.value;
            break;
          case "checkbox":
            newValue = dataPoint.value;
            break;
          case "boolean":
            newValue = dataPoint.value === 1;
            operator = "=";
            break;
          default:
            newValue = mergeUniqueValues(existing.value, dataPoint.value);
        }

        Object.assign(existing, {
          displayValue: dataPoint.label,
          value: newValue,
          operator: operator,
          segmentType: segmentType,
        });
      } else {
        // Add to List, nothing else. Simple.
        uniqueId = generateUniqueId(dataPoint.name);

        const { value, name, key, dataType, label } = dataPoint;

        const newDataPoint = {
          categoryName: categoryName,
          operator: operator,
          value: value,
          label: name,
          key: key,
          dataType: dataType,
          displayValue: label,
          subCategoryLogicalOperator: "AND",
          logicalOperator: "OR",
          segmentType: segmentType,
          uniqueId: uniqueId,
        };

        selectedMetrics.push(newDataPoint);
      }

      success = true;
    } catch (error) {
      console.log(error);
      message.error("Failed to update metrics. Please try again.");
    } finally {
      const combinedMetrics = [...selectedMetrics];
      processDeviceCount(combinedMetrics);
      setSelectedMetrics(combinedMetrics);

      return { success, uniqueId };
    }
  };

  const separateCombinedItems = (items, index) => {
    let listOriginal = [...selectedMetrics];
    listOriginal.splice(index, 1, ...items.combineValues);
    setSelectedMetrics(listOriginal);
    processDeviceCount(listOriginal);
  };

  const handleDragAndDrop = result => {
    const { combine, source } = result;

    // Handle combining items
    if (combine) {
      const sourceItem = selectedMetrics[source.index];
      const combineItem = selectedMetrics.find(
        item => item.uniqueId === combine.draggableId
      );

      if (combineItem.isCombined) {
        const combinedItem = {
          ...combineItem,
          combineValues: [...combineItem.combineValues, sourceItem],
        };

        const updatedList = selectedMetrics
          .filter(item => item.uniqueId !== sourceItem.uniqueId) // Remove source item
          .map(item =>
            item.uniqueId === combineItem.uniqueId ? combinedItem : item
          ); // Replace combineItem with combinedItem
        setSelectedMetrics(updatedList);
        processDeviceCount(updatedList);
        return;
      }

      // Merge sourceItem and combineItem logic here
      const combinedItem = {
        operator: combineItem.operator,
        subCategoryLogicalOperator: "AND",
        combineCardLogicalOperator: "OR",
        isCombined: true,
        combineValues: [sourceItem, combineItem],
        segmentType: combineItem.segmentType,
        uniqueId: generateUniqueId(combineItem.label),
      };

      const updatedList = selectedMetrics
        .filter(item => item.uniqueId !== sourceItem.uniqueId) // Remove source item
        .map(item =>
          item.uniqueId === combineItem.uniqueId ? combinedItem : item
        ); // Replace combineItem with combinedItem

      setSelectedMetrics(updatedList);
      processDeviceCount(updatedList);
      return;
    }

    if (!result.destination) {
      return; // If the item is dropped outside of any droppable area
    }

    let updatedIncludeList = selectedMetrics.filter(
      item => item.segmentType === SegmentType.Include
    );
    let updatedExcludeList = selectedMetrics.filter(
      item => item.segmentType === SegmentType.Exclude
    );

    let movedItem;

    // Determine which list the item is moving from
    if (result.source.droppableId === "include") {
      [movedItem] = updatedIncludeList.splice(result.source.index, 1);
    } else if (result.source.droppableId === "exclude") {
      [movedItem] = updatedExcludeList.splice(result.source.index, 1);
    }

    // Check if moving the item to a conflicting operator list
    const isMovingToConflict =
      (result.source.droppableId === "include" &&
        result.destination.droppableId === "exclude") ||
      (result.source.droppableId === "exclude" &&
        result.destination.droppableId === "include");

    if (isMovingToConflict) {
      // Check if there's already an item with the same key but a different operator in the destination list

      const conflictingMetric = selectedMetrics.find(item => {
        return item.key === movedItem.key && isMovingToConflict;
      });

      const existings = selectedMetrics.filter(
        item => item.key === movedItem.key
      );

      if (conflictingMetric && existings.length > 1) {
        // Revert the move and show an error message
        return message.error(
          "You cannot move this item because it conflicts with an existing operator."
        );
      }
    }

    // Handle the move and logic depending on the destination
    if (result.destination.droppableId === "include") {
      movedItem.segmentType = SegmentType.Include;
      if (result.destination.index === 0) {
        movedItem.subCategoryLogicalOperator = "AND";
      }
      updatedIncludeList.splice(result.destination.index, 0, movedItem);
    } else if (result.destination.droppableId === "exclude") {
      movedItem.segmentType = SegmentType.Exclude;
      updatedExcludeList.splice(result.destination.index, 0, movedItem);
    }

    // Combine the updated lists and update the state
    const updatedList = [...updatedIncludeList, ...updatedExcludeList];
    setSelectedMetrics(updatedList);
    processDeviceCount(updatedList);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const validateSubmission = data => {
    if (reachCountDevices < 10000) {
      message.error("Minimum of 10,000 devices required to save audience");
      return false;
    }
    // Place future validation logic here
    return true;
  };

  // Submit to Save Audience
  const handleSubmit = async data => {
    try {
      if (validateSubmission(data) === true) {
        const audienceData = {
          ...data,
          metrics: selectedMetrics,
          reachCount: reachCountDevices,
        };

        await handleCreateAudience(audienceData);
        await handleFetchAudienceDemographs();

        message.success("Successfully Created an Audience");
        history.push("/demograph");
      }
    } catch (error) {
      message.error(
        `Failed to create an Audience. Please try again later.: ${error}`
      );
    }
  };

  const onChangeReachCount = async value => {
    setReachCountMode(value);
  };

  const resetList = () => {
    setCategories(Object.values(groupedCategories));
    setSubCategories([]);
    setSelectedCategory(null);
    setSelectedSubCategory(null);
  };

  useEffect(() => {
    processDeviceCount(selectedMetrics);
  }, [reachCountMode]);

  useEffect(() => {
    // Clear datapoint selection/memory on the form
    if (selectedMetrics.length === 0) {
      setMetricValue(null);
    }
  }, [selectedMetrics]);

  useEffect(() => {
    // Fetch the audience schema when the page loads
    refetchAudienceSchema();
  }, []);

  useEffect(() => {
    const {
      filteredCategories,
      filteredSubCategories,
    } = filterAndMapCategories(groupedCategories, searchValues);

    if (!filteredCategories.length) {
      resetList();
      return;
    }

    setCategories(filteredCategories);
    setSelectedCategory(filteredCategories[0]);
    setSubCategories(filteredSubCategories);
  }, [searchValues]);

  useEffect(() => {
    const { groupedCategories } = transformAudienceSchema(audienceSchema);

    setGroupedCategories(groupedCategories);
    setCategories(Object.values(groupedCategories));
  }, [audienceSchema]);

  return (
    <>
      <AudienceBuilderSaveModal
        visible={open}
        handleSubmit={handleSubmit}
        handleCancel={handleCancel}
        history={history}
      />

      {!isMobileScreen(breakPoint) && (
        <PageHeaderLayout
          title={"Demograph"}
          titleIcon={<TeamOutlined className="page-header-bar-icon" />}
          rightContent={
            <Row>
              <Col>
                <Button
                  onClick={() => {
                    setOpen(true);
                  }}
                  type="primary"
                  ghost
                  icon={<FormOutlined />}
                >
                  <ButtonText text={"Save this Audience"} />
                </Button>
              </Col>
            </Row>
          }
        />
      )}

      <Row>
        {isMobileScreen(breakPoint) && (
          <Col xs={24} sm={24}>
            <Card
              title={
                <div>
                  <Col xs={24} sm={24}>
                    <Title level={4} style={{ textAlign: "center" }}>
                      {reachCountDevices
                        ? reachCountDevices.toLocaleString()
                        : 0}
                    </Title>
                  </Col>
                  <Col xs={24} sm={24}>
                    <div className="horizontal-scroll-container">
                      {selectedMetrics.map((item, index) => {
                        const { operator, dataType, value, label } = item;

                        let displayValue = value;

                        if (dataType === "Boolean") {
                          displayValue = value === 1 ? "Yes" : "No";
                        }
                        return (
                          <div
                            id={index}
                            key={index}
                            style={{
                              borderLeft: `5px solid ${getColorTag(operator)}`,
                            }}
                            className="mobileScreenStyle"
                          >
                            <span>{label}</span>
                            <p>{displayValue}</p>
                          </div>
                        );
                      })}
                    </div>
                    <Divider />
                  </Col>
                  <Col xs={24} sm={24}>
                    <span style={{ marginBottom: "0.5em" }}>
                      EXPLORE AUDIENCE CATEGORIES
                    </span>
                  </Col>
                  <Col xs={24} sm={24}>
                    <Input
                      allowClear
                      disabled={loadingAudienceSchema}
                      placeholder="Search..."
                      value={searchValues}
                      onChange={e => setSearchValues(e.target.value)}
                      className="search-input"
                    />
                  </Col>
                  <Col xs={24} sm={24}>
                    <Button
                      onClick={() => {
                        setOpen(true);
                      }}
                      type="primary"
                      style={{ width: "100%" }}
                      icon={<FormOutlined />}
                    >
                      <ButtonText text={"Save this Audience"} />
                    </Button>
                  </Col>
                </div>
              }
              bordered={false}
              bodyStyle={{
                padding: "1em",
                height: "100%",
                marginBottom: "1em",
                marginLeft: "0.5em",
                marginRight: "0.5em",
              }}
            >
              <MobileViewCategoryList
                categories={categories}
                subCategories={subCategories}
                selectedCategory={selectedCategory}
                onCategoryClick={onCategoryClick}
                onSubCategoryClick={onSubCategoryClick}
                titleFontStyle={categoryList.titleFontStyle}
                listItemStyle={categoryList.listItemStyle}
                selectedStyle={categoryList.selectedStyle}
                selectedFontStyle={categoryList.selectedFontStyle}
                updateAudienceMetrics={handleAddOrUpdateDatapoint}
                fetchAudienceCount={fetchAudienceCount}
                removeAudienceMetric={removeAudienceMetric}
                loading={loading}
                metric={metrics}
              />
            </Card>
          </Col>
        )}
        {!isMobileScreen(breakPoint) && (
          <Col
            xs={24}
            xl={19}
            xxl={19}
            span={19}
            className="col-category-parent desktop-view"
          >
            <Card
              title={
                <div className="card-header-title-layout">
                  EXPLORE AUDIENCE CATEGORIES
                  <Input
                    allowClear
                    disabled={loadingAudienceSchema}
                    placeholder="Search..."
                    value={searchValues}
                    onChange={e => setSearchValues(e.target.value)}
                    className="search-input"
                  />
                </div>
              }
              bordered={false}
              bodyStyle={cardBodyStyle}
            >
              <Row className="card-body-row-style">
                {!searchValues && (
                  <>
                    <Col
                      xs={24}
                      sm={24}
                      md={7}
                      xl={7}
                      xxl={7}
                      span={7}
                      className="col-category-list"
                    >
                      <Spin spinning={loadingAudienceSchema}>
                        <CategoryList
                          list={categories}
                          selectedCategory={selectedCategory}
                          onCategoryClick={onCategoryClick}
                          titleFontStyle={categoryList.titleFontStyle}
                          listItemStyle={categoryList.listItemStyle}
                          selectedStyle={categoryList.selectedStyle}
                          selectedFontStyle={categoryList.selectedFontStyle}
                        ></CategoryList>
                      </Spin>
                    </Col>
                    {!selectedCategory && (
                      <>
                        <Col
                          xs={24}
                          xl={17}
                          xxl={17}
                          span={17}
                          className="col-empty-view"
                        >
                          <Text className="empty-view-text">
                            Select categories on the left to browser targeting
                            metrics
                          </Text>
                          <br />
                          <Text className="empty-view-text-description">
                            When you are satisfied, name your audience to save
                            it for use
                          </Text>
                        </Col>
                      </>
                    )}
                    {selectedCategory && (
                      <>
                        <Col
                          xs={24}
                          sm={24}
                          md={7}
                          lg={7}
                          xl={7}
                          xxl={7}
                          span={7}
                          className="col-sub-category-list"
                        >
                          <SubCategoryList
                            list={subCategories}
                            selectedCategory={selectedSubCategory}
                            loading={false}
                            listItemStyle={subCategoryList.listItemStyle}
                            titleFontStyle={subCategoryList.titleFontStyle}
                            selectedStyle={subCategoryList.selectedStyle}
                            selectedFontStyle={
                              subCategoryList.selectedFontStyle
                            }
                            onCategoryClick={onSubCategoryClick}
                            setSelectedSubCategory={setSelectedSubCategory}
                          ></SubCategoryList>
                        </Col>
                      </>
                    )}
                  </>
                )}
                {searchValues && (
                  <Col
                    xs={24}
                    sm={24}
                    md={14}
                    lg={14}
                    xl={14}
                    xxl={14}
                    span={14}
                    className="search-results-view-style"
                  >
                    <SearchResultsView
                      searchValues={searchValues}
                      subCategories={subCategories}
                      selectedCategory={selectedSubCategory}
                      selectedStyle={searchResultList.selectedStyle}
                      selectedFontStyle={searchResultList.selectedFontStyle}
                      titleFontStyle={searchResultList.titleFontStyle}
                      listItemStyle={searchResultList.listItemStyle}
                      onCategoryClick={onSubCategoryClick}
                      setSearchValues={setSearchValues}
                    />
                  </Col>
                )}
                {selectedCategory && selectedSubCategory && (
                  <Col sm={4} md={10} lg={10} xl={10} xxl={10} span={10}>
                    <AudienceDataPointInput
                      updateAudienceMetrics={handleAddOrUpdateDatapoint}
                      fetchAudienceCount={fetchAudienceCount}
                      removeAudienceMetric={removeAudienceMetric}
                      loading={loading}
                      metric={metrics}
                      handleSelectedMetric={handleSelectedMetric}
                    ></AudienceDataPointInput>
                  </Col>
                )}
              </Row>
            </Card>
          </Col>
        )}

        {!isMobileScreen(breakPoint) && (
          <Col xs={24} xl={5} xxl={5} span={5}>
            <Card bordered={false} style={sideBarCardStyle}>
              <Gauge {...graphStateConfig} style={gaugeStyle} />
              {selectedMetrics.length > 0 && reachCountDevices < 10000 && (
                <center>
                  <Tag icon={<ExclamationCircleOutlined />} color="warning">
                    Minimum of 10,000
                  </Tag>
                </center>
              )}
              <Divider></Divider>

              <Row justify="space-between" align="middle">
                <Col>
                  <Text>Audience Type </Text>
                </Col>
                <Col>
                  <Select
                    defaultValue={AudienceType.Devices}
                    value={reachCountMode}
                    onChange={onChangeReachCount}
                  >
                    <Option value={AudienceType.Devices}>Devices</Option>
                    <Option value={AudienceType.Individuals}>
                      Individuals
                    </Option>
                  </Select>
                </Col>
              </Row>
              <Divider></Divider>
              <Typography style={sideBarTitleStyle}>ANALYTIC</Typography>
              <SelectedMetricList
                list={selectedMetrics}
                updateAudienceMetric={updateAudienceMetric}
                handleSelectedMetric={handleSelectedMetric}
                removeAudienceMetric={removeAudienceMetric}
                handleDragAndDrop={handleDragAndDrop}
                separateCombinedItems={separateCombinedItems}
                loading={loading}
              ></SelectedMetricList>
              {selectedMetrics.length > 0 && (
                <Popconfirm
                  title="Are you sure you want to clear all items?"
                  onConfirm={removeAllAudienceMetric}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button type="danger" style={{ marginBottom: "16px" }}>
                    Clear All
                  </Button>
                </Popconfirm>
              )}
            </Card>
          </Col>
        )}
      </Row>
    </>
  );
};

export default AudienceBuilderPage;
