import React, { useEffect, useState } from "react";
import { Card, Col, Form, Radio, Row, Select, Space, Typography } from "antd";
import "./styles.css";
import {
  advertiserTypes,
  defaultIabCategory,
  iabCategories,
  regulatedCategories,
} from "../../../core/utils/constants/constants";
import CampaignRequestorLocationsDialog from "./CampaignRequestorLocationsDialog";
import { CampaignRequestorRetailStoreTable } from "./CampaignRequestorRetailStoreTable";

const { Text } = Typography;

const CampaignRequestorCategories = ({
  form,
  setPreviousValues,
  restField,
  name,
  fieldKey,
}) => {
  const [showAdvertiserType, setShowAdvertiserType] = useState(
    form.getFieldValue("isRegulated")
  );
  const [showRetailStore, setShowRetailStore] = useState(false);
  const [showRetailStoresModal, setShowRetailStoresModal] = useState(false);

  const attributionTargets = form.getFieldValue("attributionTargets") || [];

  const handleIsRegulatedChange = e => {
    const value = e.target.value;
    if (!value) {
      form.setFieldsValue({ category: null, advertiserType: null });
      setShowAdvertiserType(false);
    } else {
      form.setFieldsValue({
        category: defaultIabCategory.code,
      });
      setShowAdvertiserType(true);
    }
  };

  const handleCategoryChange = value => {
    if (value === "IAB7-5") {
      setShowAdvertiserType(true);
    } else {
      setShowAdvertiserType(false);
    }
  };

  const handleAdvertiserTypeChange = value => {
    if (value === "RETAIL_LOCAL_BRICK_AND_MORTAR") {
      setShowRetailStore(true);
    } else {
      setShowRetailStore(false);
    }
  };

  useEffect(() => {
    const values = form.getFieldsValue();
    if (
      values.category === defaultIabCategory.code &&
      form.getFieldValue("isRegulated")
    ) {
      setShowAdvertiserType(true);
    }

    if (values.advertiserType === "RETAIL_LOCAL_BRICK_AND_MORTAR") {
      setShowRetailStore(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.getFieldValue("category"), form.getFieldValue("advertiserType")]);

  return (
    <>
      <Card id="requester-categories" title="CATEGORIES">
        <Space direction="vertical" size="small" style={{ width: "100%" }}>
          <Row>
            <Col span={12}>
              <Space
                direction="vertical"
                size="small"
                style={{ width: "100%" }}
              >
                <Text>Does this campaign promote a regulated industry?</Text>

                <Form.Item name="isRegulated">
                  <Radio.Group onChange={handleIsRegulatedChange}>
                    <Space direction="vertical">
                      <Radio value={true}>Yes</Radio>
                      <Radio value={false}>No</Radio>
                    </Space>
                  </Radio.Group>
                </Form.Item>
              </Space>
            </Col>
            <Col span={12}>
              <Space
                direction="vertical"
                size="small"
                style={{ width: "100%" }}
              >
                {showAdvertiserType ? (
                  <>
                    <Text>Which Regulated Industry does it promote?</Text>
                    <Form.Item name="category">
                      <Select
                        style={{ width: "100%" }}
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.label
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        options={regulatedCategories.map(iab => ({
                          value: iab.code,
                          label: iab.category,
                        }))}
                        onChange={handleCategoryChange}
                      />
                    </Form.Item>

                    {showAdvertiserType && (
                      <>
                        <Text>Advertiser Type</Text>
                        <Form.Item name="advertiserType">
                          <Select
                            style={{ width: "100%" }}
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.label
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            options={
                              advertiserTypes.map(advertiser => ({
                                value: advertiser.key,
                                label: advertiser.name,
                              })) || []
                            }
                            onChange={handleAdvertiserTypeChange}
                          />
                        </Form.Item>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <Text>Which category best fits this campaign?</Text>
                    <Form.Item name="category">
                      <Select
                        style={{ width: "100%" }}
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.label
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        options={iabCategories.map(iab => ({
                          value: iab.code,
                          label: iab.category,
                        }))}
                      />
                    </Form.Item>
                  </>
                )}
              </Space>
            </Col>
          </Row>

          {showRetailStore && (
            <div>
              <Row>
                <Col span={24}>
                  <CampaignRequestorRetailStoreTable
                    form={form}
                    editable={true}
                    setShowRetailStoresModal={setShowRetailStoresModal}
                    setPreviousValues={setPreviousValues}
                  />
                </Col>
              </Row>
            </div>
          )}
        </Space>
      </Card>

      {showRetailStoresModal && (
        <CampaignRequestorLocationsDialog
          visible={showRetailStoresModal}
          setVisible={setShowRetailStoresModal}
          audienceType="ATTRIBUTION"
          onAddSelections={targets => {
            const attributionFieldValues = {
              measureWalkInTraffic: true,
              attributionTargets: targets,
            };
            form.setFieldsValue(attributionFieldValues);
            setPreviousValues({
              ...form.getFieldsValue(),
              ...attributionFieldValues,
            });
          }}
          selectedLocations={attributionTargets}
        />
      )}
    </>
  );
};

export default CampaignRequestorCategories;
