import React, { useState } from "react";
import { Button, Space, Tooltip, Switch } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { PageContentLayout, PageHeaderLayout } from "../../shared/layout";
import {
  DollarCircleOutlined,
  ShareAltOutlined,
  ToolOutlined,
} from "@ant-design/icons";
import { withRouter } from "react-router-dom";
import {
  OrganizationsTable,
  FiltersBar,
  SharedOrgsModal,
  ReprocessModal,
  PaidSearchOnboardingDocs,
} from "./components";
import { orgLevelCustomization } from "../../../core/permissions/orgLevelCustomization";
import "./styles/styles.css";
import LaunchPaidSearch from "./components/LaunchPaidSearch";

const PaidSearchMainView = ({
  history,
  data,
  possibleFields,
  selectedTableColumns,
  selectedRowKeys,
  childOrgs,
  childOrgsLoading,
  getSharedDetails,
  onRowChange,
  sharedOrgsLoading,
  sharedOrgs,
  selectedRows,
  currentOrgId,
  setSharedOrgs,
  onCancelSharedModal,
  setSharedOrgsToggle,
  sharedOrgsToggle,
  onSubmitSharedModal,
  setToBeSavedData,
  toBeSavedData,
  location,
  current,
  total,
  pageSize,
  reprocessNew,
  reprocessAll,
  reprocessStatuses,
  getCurrentStatus,
  reporcessStatusLoading,
  isAdmin,
  filter,
  isChannelPartner,
  currentUser,
  eventTags,
  launchPaidSearchEvent,
}) => {
  const orgCustomization = orgLevelCustomization(
    currentUser.role.org.dataConfig || {}
  );
  const { currencyFormat } = orgCustomization;
  const isShowOnboardingDocs = isAdmin || isChannelPartner;

  const [tooltip, setTooltip] = useState(
    filter === "allTime"
      ? "Shows your data from All Time"
      : "Shows your data for this month"
  );
  document.title = "Paid Search Main View";
  const [reprocessVisible, setReprocessVisible] = useState(false);
  //TODO: Put this in a separate file
  const rightContent = (
    <Space
      id="paid-search-main-right-content"
      className="ant-space-xs-vertical ant-space-md-horizontal"
      size={"middle"}
    >
      <Tooltip placement="top" title={tooltip}>
        <Switch
          id="paid-search-main-all-time-switch"
          checkedChildren="Current Month"
          unCheckedChildren="All Time"
          size="default"
          defaultChecked={filter === "month"}
          onChange={checked => {
            const searchParams = new URLSearchParams(window.location.search);
            if (searchParams.get("filter")) searchParams.delete("filter");
            if (checked === true) {
              searchParams.append("filter", "month");
              setTooltip(`Shows your data for this month`);
            } else {
              setTooltip(`Shows your data from All Time`);
            }

            history.push({
              pathname: location.pathname,
              search: `?${searchParams.toString()}`,
            });
          }}
        />
      </Tooltip>
      <Button
        id="paid-search-main-share-button"
        icon={childOrgsLoading ? <LoadingOutlined /> : <ShareAltOutlined />}
        shape="round"
        type="primary"
        ghost
        disabled={childOrgsLoading || (selectedRowKeys || []).length < 1}
        onClick={() => {
          setSharedOrgsToggle(true);
          getSharedDetails();
        }}
      >
        Share Paid Search
      </Button>
      <Button
        id="paid-search-main-admin-button"
        icon={<ToolOutlined />}
        type="primary"
        shape="round"
        ghost
        onClick={() => {
          getCurrentStatus();
          setReprocessVisible(true);
        }}
      >
        Admin Tools
      </Button>
    </Space>
  );

  return (
    <React.Fragment>
      <ReprocessModal
        visible={reprocessVisible}
        setVisible={setReprocessVisible}
        reprocessNew={reprocessNew}
        reprocessAll={reprocessAll}
        reprocessStatuses={reprocessStatuses}
        reporcessStatusLoading={reporcessStatusLoading}
      />
      <SharedOrgsModal
        childOrgs={childOrgs && childOrgs.orgs}
        modalToggle={sharedOrgsToggle}
        sharedOrgsLoading={sharedOrgsLoading}
        sharedOrgs={sharedOrgs}
        selectedRows={selectedRows}
        currentOrgId={currentOrgId}
        setSharedOrgs={setSharedOrgs}
        setSharedOrgsToggle={setSharedOrgsToggle}
        onCancel={onCancelSharedModal}
        onSubmit={onSubmitSharedModal}
        setToBeSavedData={setToBeSavedData}
        toBeSavedData={toBeSavedData}
      />
      <PageHeaderLayout
        title={"Paid Search"}
        titleIcon={
          <DollarCircleOutlined
            style={{
              marginRight: "10px",
              color: "lightgrey",
              fontSize: 20,
            }}
          />
        }
        titleRightIcon={isShowOnboardingDocs && <PaidSearchOnboardingDocs />}
        rightContent={
          <LaunchPaidSearch
            orgs={childOrgs && childOrgs.orgs}
            currentOrgId={currentOrgId}
            tags={
              eventTags && eventTags.eventsTargets
                ? eventTags.eventsTargets
                : null
            }
            onSubmit={launchPaidSearchEvent}
          />
        }
      />
      <PageContentLayout>
        <PageHeaderLayout
          title={"Paid Search Organization"}
          rightContent={rightContent}
        />
        <FiltersBar />
        <OrganizationsTable
          data={data}
          possibleFields={possibleFields}
          history={history}
          selectedTableColumns={selectedTableColumns}
          selectedRowKeys={selectedRowKeys}
          onRowChange={onRowChange}
          current={current}
          pageSize={pageSize}
          total={total}
          filter={filter}
          currencyFormat={currencyFormat}
        />
      </PageContentLayout>
    </React.Fragment>
  );
};

export default withRouter(PaidSearchMainView);
