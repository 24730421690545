import gql from "graphql-tag";

const UPDATE_EDITORIAL = gql`
  mutation UpdateAdvertiser(
    $data: EditorialUpdateInput!
    $where: EditorialWhereUniqueInput!
    $dataRemove: EditorialUpdateInput!
  ) {
    removeOldValues: updateEditorial(data: $dataRemove, where: $where) {
      id
      name
    }
    updateEditorial(data: $data, where: $where) {
      id
      name
    }
  }
`;

export default UPDATE_EDITORIAL;
