import React from "react";
import { Table, Card, Tooltip, Avatar } from "antd";
import {
  TableColumnTitle,
  PrimaryTableRowText,
} from "../../../../../../shared/globalStyling/styledText";
import cnnaid from "../../../../../../../assets/icons/transactions/cnnaid.png";
import cnnaip from "../../../../../../../assets/icons/transactions/cnnaip.png";
import cnnafingerprint from "../../../../../../../assets/icons/transactions/cnnafingerprint.png";
import { ReactComponent as GlobePhoneIcon } from "../../../../../../../assets/icons/transactions/globephone.svg";
import moment from "moment";
import ImpressionsSubTable from "./ImpressionsTimeline";

const SignUpsTable = ({ data = [], loading = true }) => {
  const columns = [
    {
      title: <TableColumnTitle text={"Sign Up ID"} />,
      index: "signUpId",
      key: "signUpId",
      render: (text, { signUpId }) => {
        return <PrimaryTableRowText text={signUpId} />;
      },
    },
    {
      title: <TableColumnTitle text={"Sign Up On"} />,
      index: "signUpTime",
      key: "signUpTime",
      render: (text, { signUpTime }) => {
        return (
          <PrimaryTableRowText
            text={moment(signUpTime).format("YYYY-MM-DD H:mm:ss")}
          />
        );
      },
    },
    {
      title: <TableColumnTitle text={"Page Views"} />,
      index: "impressions",
      key: "impressions",
      render: (text, { impressions }) => {
        return <PrimaryTableRowText text={impressions} />;
      },
    },
    {
      title: <TableColumnTitle text={"First Page Views"} />,
      index: "firstImpression",
      key: "firstImpression",
      render: (text, { firstImpression }) => {
        return (
          <PrimaryTableRowText
            text={moment(firstImpression).format("YYYY-MM-DD H:mm:ss")}
          />
        );
      },
    },
    {
      title: <TableColumnTitle text={""} />,
      dataIndex: "attributionMethod",
      key: "attributionMethod",
      align: "left",
      width: "8%",
      render: (value, { attributionMethod }) => {
        let attributionIcon = cnnaip;
        let tip = "";
        if (Object.hasOwn(attributionMethod, "COOKIES")) {
          attributionIcon = cnnaid;
          tip = `Cookie Match - Deterministic match using browser based cookie / uuid match.`;
        } else if (Object.hasOwn(attributionMethod, "FINGERPRINT")) {
          attributionIcon = cnnafingerprint;
          tip = `User Match - Probabilistic match using user fingerprint. (User fingerprint - 
                The combination of user agent and IP address to produce a unique hash)`;
        } else if (Object.hasOwn(attributionMethod, "IP")) {
          tip = `IP Match - Probabilistic match using IP address.`;
          attributionIcon = cnnaip;
        } else if (Object.hasOwn(attributionMethod, "DEVICE_FINGERPRINT")) {
          tip = `Device Match - Probabilistic match using user device model information (Device model, OS, OS version ) 
              combined with IP address`;
        }

        const attributionIcn = () => {
          if (Object.hasOwn(attributionMethod, "DEVICE_FINGERPRINT")) {
            return (
              <span
                role="img"
                className="anticon"
                style={{ verticalAlign: "-5px" }}
              >
                <GlobePhoneIcon
                  height="35"
                  width="35"
                  style={{
                    opacity: "1.0",
                  }}
                />
              </span>
            );
          } else {
            return <Avatar src={attributionIcon} />;
          }
        };

        return (
          <Tooltip title={tip}>
            <Avatar src={attributionIcn()} />
          </Tooltip>
        );
      },
    },
  ];

  return (
    <Card title="Sign Ups">
      <Table
        loading={loading}
        rowKey={record => record.id}
        dataSource={data}
        columns={columns}
        pagination={{
          position: ["none", "bottomCenter"],
          total: data.length,
          responsive: true,
          showSizeChanger: false,
          showLessItems: true,
          size: "default",
        }}
        scroll={{ x: 1200, y: 1700 }}
        expandable={{
          expandedRowRender: record => {
            return (
              <ImpressionsSubTable
                clicks={record.clicksList}
                attributionMethod={record.attributionMethod}
              />
            );
          },
        }}
      />
    </Card>
  );
};

export default SignUpsTable;
