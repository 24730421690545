import React from "react";
import { DatePicker, Menu, Row, Col, Dropdown, Button } from "antd";
import moment from "moment";
import { DownOutlined, CalendarOutlined } from "@ant-design/icons";
import { ranges } from "../hooks/ByDateFilter";
const { RangePicker } = DatePicker;

const DatePickerField = ({
  defaultPickerValue = [],
  defaultValue = [],
  value = [],
  dropDownValue = "Custom",
  setDropDownValue = () => {},
  setCustomDateRange = () => {},
  setDateChanged = () => {},
  has6MonDisables = true,
  showDropdown = true, //show dropdown or not
  allTimeDateRange = ["2020-01-01", moment().format("YYYY-MM-DD")],
  disabled = false,
}) => {
  /**
   * 6 months lookback only
   */
  const getDateValue = dateVal => {
    if (!dateVal) return moment();
    if (moment.isMoment(dateVal)) return dateVal;
    return moment(dateVal);
  };

  const start = getDateValue(value[0]);
  const end = getDateValue(value[1]);

  const disabledDates = current => {
    if (!current) return false;

    if (has6MonDisables) {
      return (
        current.isBefore(moment().subtract(6, "months")) ||
        current.isAfter(moment())
      );
    }
    return current.isAfter(moment());
  };

  const onHandleDropdown = e => {
    setDropDownValue(e.key);
    const dates = e.key === "All Time" ? allTimeDateRange : ranges[e.key];
    setCustomDateRange([moment(dates[0]), moment(dates[1])]);
    setDateChanged(true);
  };

  const menuItems = Object.entries(ranges).map(([key]) => (
    <Menu.Item key={key} icon={<CalendarOutlined />}>
      {key}
    </Menu.Item>
  ));

  return (
    <Row justify={"center"}>
      <Col xs={24} md={15} lg={!showDropdown ? 24 : 15}>
        <RangePicker
          disabled={disabled}
          style={{ width: "230px" }}
          allowClear={false}
          disabledDate={disabledDates}
          value={[start, end]}
          onCalendarChange={dates => {
            if (!dates) return;
            const [newStart, newEnd] = dates;

            if (newStart && newEnd) {
              setCustomDateRange([newStart, newEnd]);
              setDropDownValue("Custom");
              setDateChanged(true);
            }
          }}
        />
      </Col>
      {showDropdown && (
        <Col xs={24} md={9}>
          <Dropdown
            overlay={<Menu onClick={onHandleDropdown}>{menuItems}</Menu>}
            disabled={disabled}
          >
            <Button style={{ width: "100%" }}>
              <CalendarOutlined />
              {dropDownValue} <DownOutlined />
            </Button>
          </Dropdown>
        </Col>
      )}
    </Row>
  );
};

export default DatePickerField;
