import React, { useState, useEffect } from "react";
import {
  Card,
  Col,
  Table,
  Input,
  Button,
  Select,
  Space,
  Row,
  message,
  Tag,
  Tooltip,
  Badge,
} from "antd";
import TaskUpdateModal from "./TaskUpdateModal";
import { useTaskContext } from "../../../../core/components/tasks/useTaskContext";
import {
  taskTypes,
  subTypes,
  clientTypes,
  urgencyTypes,
  urgencyColors,
} from "../configs/task.config";
import { withRouter, Link } from "react-router-dom";
import qs from "qs";
import useSearchParams from "../../../../core/hooks/useSearchParams";
import { CloseOutlined, SyncOutlined } from "@ant-design/icons";

const { Option, OptGroup } = Select;

const UnassignedTasksView = ({ history }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const {
    fetchTasksPaginated,
    task,
    unassignedTaskPagination,
    setUnassignedTaskPagination,
    tasksLoading,
    updateTask,
    updateTaskLoading,
    currentUser,
  } = useTaskContext();
  const unassignedData = task.filter(item => item.assigneeUser === null);
  const [searchParams, setSearchParams] = useSearchParams({
    requestTypes: "",
    requestSubTypes: "",
    clientTypes: "",
    name: "",
    urgency: "",
  });

  const [selectedUpdate, setSelectedUpdate] = useState([]);

  useEffect(() => {
    handleFetchTasksPaginated({
      skip:
        (unassignedTaskPagination.current - 1) *
          unassignedTaskPagination.pageSize || 0,
      first: unassignedTaskPagination.pageSize,
      ...searchParams,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    unassignedTaskPagination.pageSize,
    unassignedTaskPagination.current,
    searchParams,
  ]);

  const handleFetchTasksPaginated = ({
    skip,
    first,
    name = "",
    requestTypes = "",
    requestSubTypes = "",
    clientTypes = "",
    urgency = "",
  }) => {
    const requestTypesArray = requestTypes ? requestTypes.split(",") : [];
    const requestSubTypesArray = requestSubTypes
      ? requestSubTypes.split(",")
      : [];
    const clientTypesArray = clientTypes ? clientTypes.split(",") : [];
    const urgencyArray = urgency ? urgency.split(",") : [];

    fetchTasksPaginated({
      variables: {
        where: {
          AND: [
            {
              name_contains: name,
              assigneeUser: null,
              assigneeUsers_every: { id: null },
            },
            ...(requestTypesArray.length
              ? [{ requestType_in: requestTypesArray }]
              : []),
            ...(requestSubTypesArray.length
              ? [{ requestSubType_in: requestSubTypesArray }]
              : []),
            ...(clientTypesArray.length
              ? [
                  {
                    createdBy: {
                      roles_some: { org: { level_in: clientTypesArray } },
                    },
                  },
                ]
              : []),
            ...(urgencyArray.length ? [{ urgency_in: urgencyArray }] : []),
          ],
          OR: [
            {
              createdBy: {
                roles_some: {
                  org: {
                    OR: [
                      {
                        parentOrg_some: {
                          id: currentUser.role.org.id,
                        },
                      },
                      {
                        id: currentUser.role.org.id,
                      },
                    ],
                  },
                },
              },
            },
            { channelPartner: { id: currentUser.role.org.id } },
          ],
        },
        orderBy: "updatedAt_DESC",
        skip,
        first,
      },
    });
  };

  const handleTaskTypeFilter = selectedValues => {
    const updatedFilters = {
      ...searchParams,
      requestTypes: selectedValues.join(","),
    };
    setSearchParams(updatedFilters);
    setUnassignedTaskPagination(prev => ({ ...prev, current: 1 }));
  };

  const handleTaskSubTypeFilter = selectedValues => {
    const updatedFilters = {
      ...searchParams,
      requestSubTypes: selectedValues.join(","),
    };
    setSearchParams(updatedFilters);
    setUnassignedTaskPagination(prev => ({ ...prev, current: 1 }));
  };

  const handleClientTypeFilter = selectedValues => {
    const updatedFilters = {
      ...searchParams,
      clientTypes: selectedValues.join(","),
    };
    setSearchParams(updatedFilters);
    setUnassignedTaskPagination(prev => ({ ...prev, current: 1 }));
  };

  const handleUrgencyFilter = selectedValues => {
    const updatedFilters = {
      ...searchParams,
      urgency: selectedValues.join(","),
    };
    setSearchParams(updatedFilters);
    setUnassignedTaskPagination(prev => ({ ...prev, current: 1 }));
  };

  const handleTableChange = ({ current, pageSize }) => {
    setUnassignedTaskPagination(prev => ({ ...prev, current, pageSize }));

    const newQuery = { ...searchParams };
    const queryString = qs.stringify(newQuery, { addQueryPrefix: true });
    history.push(`/tasks/unassigned/${current}${queryString}`);

    handleFetchTasksPaginated({
      skip: (current - 1) * pageSize || 0,
      first: pageSize,
      ...newQuery,
    });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleUpdate = async values => {
    try {
      message.loading("Updating...", 0);
      const { dueDate, status, assigneeOrg, assignee } = values;

      const updatedData = {
        dueDate: dueDate ? dueDate.toISOString() : null,
        status,
        assigneeOrg: assigneeOrg ? { connect: { id: assigneeOrg } } : null,
        assigneeUsers:
          assignee && assignee.length > 0
            ? { connect: assignee.map(id => ({ id })) }
            : { disconnectAll: true },
      };

      await updateTask({
        variables: { data: updatedData, where: { id: selectedUpdate.id } },
      });
      setIsModalVisible(false);
      message.destroy();
      message.success("Updated successfully!");
      history.push(`/tasks/main/1`);
    } catch (error) {
      message.destroy();
      message.error("Failed to update.");
      console.error("Failed to update task:", error);
    }
  };

  const handleSearchChange = name => {
    const updatedFilters = { ...searchParams, name };
    setSearchParams(updatedFilters);
  };

  const columns = [
    {
      title: "Task Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <>
          <Tooltip
            title={record.urgency ? `URGENCY: ${record.urgency}` : undefined}
          >
            <Badge
              dot
              color={urgencyColors[record.urgency] || "white"}
              style={{ marginRight: 8 }}
            />
          </Tooltip>
          <Link
            to={`/tasks/details/${record.id}`}
            style={{ textDecoration: "none" }}
          >
            {text}
          </Link>
        </>
      ),
    },
    {
      title: "Due Date",
      dataIndex: "dueDate",
      key: "dueDate",
      sorter: (a, b) => new Date(a.dueDate) - new Date(b.dueDate),
      render: (dueDate, record) => {
        const isPastDue = new Date(dueDate) < new Date();
        return (
          <>
            <span
              style={{
                color: dueDate ? (isPastDue ? "red" : "inherit") : "inherit",
              }}
            >
              {dueDate ? new Date(dueDate).toLocaleDateString("en-CA") : "N/A"}
            </span>
            {record.recurring && (
              <SyncOutlined style={{ marginLeft: 8, color: "gray" }} />
            )}
          </>
        );
      },
    },
    {
      title: "Request Type",
      dataIndex: "requestType",
      key: "requestType",
      render: (requestType, record) => (
        <>
          {requestType || "N/A"}
          <br />
          <span style={{ color: "gray" }}>{record.requestSubType || ""}</span>
        </>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: status => {
        if (!status) return "";
        const formattedStatus = status
          .split("_")
          .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Title case each word
          .join(" ");
        return formattedStatus;
      },
    },
    {
      title: "Client Type",
      dataIndex: "clientType",
      key: "clientType",
      render: (_, record) => {
        const orgLevel =
          record.createdBy &&
          record.createdBy.roles &&
          record.createdBy.roles[0] &&
          record.createdBy.roles[0].org &&
          record.createdBy.roles[0].org.level;
        const formattedOrgLevel = orgLevel
          ? orgLevel
              .split("_")
              .map(word => word.charAt(0).toUpperCase() + word.slice(1))
              .join(" ")
          : "N/A";
        return formattedOrgLevel;
      },
    },
    {
      title: "Client",
      dataIndex: "client",
      key: "client",
      render: (_, record) => {
        const orgName =
          record.createdBy &&
          record.createdBy.roles &&
          record.createdBy.roles[0] &&
          record.createdBy.roles[0].org &&
          record.createdBy.roles[0].org.name;
        return (
          <>
            <Tag
              color="geekblue"
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: "150px",
              }}
            >
              {orgName || "N/A"}
            </Tag>
          </>
        );
      },
    },
    {
      title: "Created",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (_, record) =>
        record.createdAt ? new Date(record.createdAt).toLocaleDateString() : "",
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <Button
            type="link"
            onClick={() => {
              setSelectedUpdate(record);
              setIsModalVisible(true);
            }}
          >
            Update
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <>
      <Card
        title="TASKS"
        extra={
          <Row gutter={16} align="middle">
            <Row>
              <Button
                style={{ width: "100%", height: "36px", fontSize: "14px" }}
                shape="round"
                type={"default"}
                disabled={
                  !searchParams ||
                  !Object.values(searchParams).some(param => param)
                }
                onClick={() => {
                  setSearchParams({
                    requestTypes: "",
                    requestSubTypes: "",
                    clientTypes: "",
                    name: "",
                    urgency: "",
                  });
                  setUnassignedTaskPagination(prev => ({
                    ...prev,
                    current: 1,
                  }));

                  history.push(
                    "/tasks/unassigned/1?requestTypes=&requestSubTypes=&clientTypes=&name=&urgency="
                  );
                }}
                icon={<CloseOutlined />}
              >
                Clear
              </Button>
            </Row>
            <Col>
              <Input.Search
                placeholder="Search tasks"
                onSearch={value => handleSearchChange(value)}
                style={{ width: 200 }}
                allowClear
              />
            </Col>
            <Col>
              <Button
                type="primary"
                onClick={() => {
                  history.push(`/tasks/form/`);
                }}
              >
                Create Task
              </Button>
            </Col>
          </Row>
        }
      >
        <Row gutter={16} style={{ marginBottom: 16 }} justify="end">
          <Col span={6}>
            <Row align="middle" gutter={8}>
              <Col flex="none">Request Type:</Col>
              <Col flex="auto">
                <Select
                  mode="multiple"
                  style={{ width: "100%" }}
                  onChange={handleTaskTypeFilter}
                  value={
                    searchParams.requestTypes
                      ? searchParams.requestTypes.split(",")
                      : []
                  }
                  maxTagCount={1}
                  placeholder="Filter by Request Type"
                  allowClear
                >
                  {taskTypes.map(type => (
                    <Option key={type} value={type}>
                      {type}
                    </Option>
                  ))}
                </Select>
              </Col>
            </Row>
          </Col>
          <Col span={6}>
            <Row align="middle" gutter={8}>
              <Col flex="none">Request Subtype:</Col>
              <Col flex="auto">
                <Select
                  mode="multiple"
                  style={{ width: "100%" }}
                  onChange={handleTaskSubTypeFilter}
                  value={
                    searchParams.requestSubTypes
                      ? searchParams.requestSubTypes.split(",")
                      : []
                  }
                  maxTagCount={1}
                  placeholder="Filter by Request Subtype"
                  allowClear
                >
                  {Object.entries(subTypes).map(([mainType, subTypeArray]) => (
                    <OptGroup label={mainType} key={mainType}>
                      {subTypeArray.map(subType => (
                        <Option key={`${mainType}-${subType}`} value={subType}>
                          {subType}
                        </Option>
                      ))}
                    </OptGroup>
                  ))}
                </Select>
              </Col>
            </Row>
          </Col>
          <Col span={6}>
            <Row align="middle" gutter={8}>
              <Col flex="none">Client Type:</Col>
              <Col flex="auto">
                <Select
                  mode="multiple"
                  style={{ width: "100%" }}
                  onChange={handleClientTypeFilter}
                  value={
                    searchParams.clientTypes
                      ? searchParams.clientTypes.split(",")
                      : []
                  }
                  maxTagCount={1}
                  placeholder="Filter by Client Type"
                  allowClear
                >
                  {Object.keys(clientTypes).map(type => (
                    <Option key={type} value={clientTypes[type]}>
                      {type}
                    </Option>
                  ))}
                </Select>
              </Col>
            </Row>
          </Col>
          <Col span={6}>
            <Row align="middle" gutter={8}>
              <Col flex="none">Urgency:</Col>
              <Col flex="auto">
                <Select
                  mode="multiple"
                  style={{ width: "100%" }}
                  onChange={handleUrgencyFilter}
                  value={
                    searchParams.urgency ? searchParams.urgency.split(",") : []
                  }
                  maxTagCount={1}
                  placeholder="Filter by Urgency"
                  allowClear
                >
                  {Object.keys(urgencyTypes).map(type => (
                    <Option key={type} value={urgencyTypes[type]}>
                      <Badge color={urgencyColors[urgencyTypes[type]]} />
                      {type}
                    </Option>
                  ))}
                </Select>
              </Col>
            </Row>
          </Col>
        </Row>
        <Table
          columns={columns}
          dataSource={unassignedData}
          loading={tasksLoading}
          pagination={{
            ...unassignedTaskPagination,
            showSizeChanger: true,
            hideOnSinglePage: false,
          }}
          onChange={handleTableChange}
          scroll={{ x: 1000 }}
        />
        <TaskUpdateModal
          visible={isModalVisible}
          onCancel={handleCancel}
          onUpdate={handleUpdate}
          selected={selectedUpdate}
          loading={updateTaskLoading}
        />
      </Card>
    </>
  );
};

export default withRouter(UnassignedTasksView);
