import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { uniqueId } from "lodash";
import "./styles.css";
import { Button, Card, Col, message, Space, Steps, Typography } from "antd";
import { useCampaignRequestorContext } from "../../../core/components/campaignRequestor/useCampaignRequestorContext";
import { campaignTypeMap } from "../../../core/utils/constants/constants";
import {
  buildGeoTargetsPayload,
  buildLineItemPayload,
  buildLineItemUpdatePayload,
  deleteProperties,
} from "./CampaignRequestorUtil";
import moment from "moment";
import { MinusCircleFilled } from "@ant-design/icons";
import { usePermify } from "@permify/react-role";
import CampaignRequestorCloneDialog from "./CampaignRequestorCloneDialog";
import { getCampaignOrderErrorMessage } from "../../../core/utils/constants/campaignOrderErrorType";

const { Step } = Steps;
const { Text } = Typography;

const cartToMap = {
  "dutchie-iframe": "DUTCHIEJS",
  "dutchie-subdomain": "DUTCHIE",
  shopifyuniversal: "SHOPIFYUNIVERSAL",
};

const defaultSteps = [
  {
    title: "Details",
    bookmarkId: "requester-details",
  },
  {
    title: "Duration & Budget",
    bookmarkId: "requester-duration-budget",
  },
  {
    title: "Categories",
    bookmarkId: "requester-categories",
  },
  {
    title: "Attribution Reporting",
    bookmarkId: "requester-attribution-reporting",
  },
];

const defaultActiveSteps = {
  details: false,
  durationAndBudget: false,
  categories: false,
  attributionReporting: false,
};

const CampaignRequestorSummary = ({
  form,
  previousValues,
  mode,
  handleInitialValues,
}) => {
  const [steps, setSteps] = useState(defaultSteps);
  const [activeSteps, setActiveSteps] = useState(defaultActiveSteps);
  const [visibleCloneDialog, setVisibleCloneDialog] = useState(false);

  const [canEdit, setCanEdit] = useState(false);
  const { isAuthorized } = usePermify();

  // TODO: Refactor this to use only 1 source of truth for values
  const values = {
    ...previousValues,
    ...form.getFieldsValue(),
  };

  const {
    createCampaignOrder,
    selectedAdvertiser,
    currentUserOrg,
    currentUser,
    currentCampaignOrder,
    loadingCreateCampaignOrder,
    loadingUpdateCampaignOrder,
    updateCampaignOrder,
    setSelectedAdvertiser,
  } = useCampaignRequestorContext();
  const history = useHistory();
  const camapignName = form.getFieldValue("name");

  const validateForm = async () => {
    try {
      await form.validateFields();
    } catch (error) {
      message.error(error.errorFields[0].errors[0]);
      return;
    }
  };

  const handleSaveDraft = async data => {
    try {
      message.loading("Saving draft...", 0);
      const result = await createCampaignOrder({
        variables: {
          data,
        },
      });

      message.destroy();
      message.success("Draft saved successfully");

      const campaignOrder = result.data.createCampaignOrderRequestor;
      return campaignOrder;
    } catch (error) {
      const errorMessages = getCampaignOrderErrorMessage(error);
      // Destroy the loading message and display all error messages
      message.destroy();
      message.error(errorMessages[0], 5);
    }
  };

  const handleNextStep = async () => {
    let campaignOrderId = currentCampaignOrder ? currentCampaignOrder.id : null;
    try {
      await form.validateFields();
      if (!currentCampaignOrder) {
        const campaignOrder = await handleSave();
        campaignOrderId = campaignOrder.id;
      } else {
        history.push(`/campaigns/requester/${campaignOrderId}?mode=review`);
      }
    } catch (error) {
      message.error(error.errorFields[0].errors[0]);
      return;
    }
  };

  const handleUpdate = async data => {
    try {
      message.loading("Updating campaign...", 0);
      await updateCampaignOrder({
        variables: {
          where: {
            id: currentCampaignOrder.id,
          },
          data,
        },
      });
      message.destroy();
      message.success("Campaign updated successfully");
    } catch (error) {
      const errorMessages = getCampaignOrderErrorMessage(error);
      // Destroy the loading message and display all error messages
      message.destroy();
      message.error(errorMessages[0], 5);
    }
  };

  const handleSave = async (status = "DRAFT") => {
    const attributionTargets = values.attributionTargets;
    const geoTargets = values.geoTargets;
    const campaigns = values.campaigns;
    const region = values.region;
    const transactionsConfig = values.transactionsConfig;
    const signUpConfig = values.signUpConfig;

    delete values.numberOfDays;

    // Initialize data object
    let data = {
      ...values,
      startDate: values.startDate || moment(),
      budget: Number(values.budget),
      category: {
        set: values.category,
      },
      cpm: Number(values.cpm),
      freeImpressions: Number(values.freeImpressions || 0),
      attributionTargets: [],
      geoTargets: [],
      campaigns: [],
      transactionsConfig: null,
      signUpConfig: null,
      notes: values.notes,
      status,
      requesterVersion: 2, // Created from new campaign requester
    };

    // Remove unnecessary fields
    delete data.advertiserId;
    delete data.impressions;

    // Check if attributionTargets, if so, build payload
    if (
      (attributionTargets && attributionTargets.length) ||
      (currentCampaignOrder && currentCampaignOrder.attributionTargets.length)
    ) {
      data.attributionTargets = buildGeoTargetsPayload({
        targets: attributionTargets || [],
        originalTargets: currentCampaignOrder
          ? currentCampaignOrder.attributionTargets || []
          : [],
        orgId: selectedAdvertiser.id,
      });
    }
    // Check if geoTargets, if so, build payload
    if (
      (geoTargets && geoTargets.length) ||
      (currentCampaignOrder && currentCampaignOrder.geoTargets.length)
    ) {
      data.geoTargets = buildGeoTargetsPayload({
        targets: geoTargets,
        originalTargets: currentCampaignOrder
          ? currentCampaignOrder.geoTargets || []
          : [],
        orgId: selectedAdvertiser.id,
      });
    }

    const draftName =
      form.getFieldValue("name") ||
      `DRAFT ${uniqueId("#")}-${new Date().getTime()}`;

    const numberOfDays = moment(values.endDate).diff(
      moment(values.startDate),
      "days"
    );

    // Check if campaigns, if so, build payload
    if (campaigns && campaigns.length) {
      // Filter new campaigns from currentCampaignOrder
      const newLineItems = campaigns.filter(campaign => !campaign.id);
      const originalLineItems = currentCampaignOrder
        ? currentCampaignOrder.campaigns[0].lineItems
        : [];

      const updatedLineItems = campaigns.filter(campaign =>
        originalLineItems.find(c => c.id === campaign.id)
      );

      const deletedLineItems =
        originalLineItems.filter(
          campaign => !campaigns.find(c => c.id === campaign.id)
        ) || [];

      if (
        !currentCampaignOrder ||
        (currentCampaignOrder &&
          currentCampaignOrder.campaigns &&
          !currentCampaignOrder.campaigns.length)
      ) {
        // Build campaigns payload
        data.campaigns = {
          create: [
            {
              name: values.name || draftName,
              budgetTotal: Number(values.budget),
              budgetByDay: Number(values.budget) / numberOfDays,
              startDate: values.startDate || moment(),
              endDate: values.endDate || moment().add(2, "days"),
              cpm: Number(values.cpm),
              orgs: {
                connect: {
                  id: selectedAdvertiser.id,
                },
              },
              lineItems: {
                create: newLineItems.map(lineItem =>
                  buildLineItemPayload({
                    lineItem,
                    selectedAdvertiser,
                    originalLineItems,
                  })
                ),
              },
            },
          ],
        };

        if (currentCampaignOrder) {
          data.campaigns.create[0].lineItems = {
            ...data.campaigns.create[0].lineItems,
            update: updatedLineItems.map((lineItem, index) =>
              buildLineItemPayload({
                lineItem,
                selectedAdvertiser,
                originalLineItems,
              })
            ),
          };
        }
      }

      // Check if there are updated campaigns, if so, build payload
      if (updatedLineItems.length) {
        data.campaigns = {
          update: {
            where: {
              id: currentCampaignOrder.campaigns[0].id,
            },
            data: {
              lineItems: {
                update: updatedLineItems.map(lineItem =>
                  buildLineItemUpdatePayload({
                    lineItem,
                    selectedAdvertiser,
                    originalLineItems,
                  })
                ),
                create: newLineItems.map(lineItem =>
                  buildLineItemPayload({
                    lineItem,
                    selectedAdvertiser,
                    originalLineItems,
                  })
                ),
                delete: deletedLineItems.map(lineItem => ({
                  id: lineItem.id,
                })),
              },
            },
          },
        };
      }
    }

    if (region && region.length) {
      data.region = {
        set: region.reduce(
          (acc, regionGroup) => acc.concat(regionGroup.regions),
          []
        ),
      };
    }

    if (currentCampaignOrder) {
      if (transactionsConfig) {
        let appId = null;
        if (transactionsConfig.appId) {
          // Check if appId is type string
          if (typeof transactionsConfig.appId === "string") {
            appId = transactionsConfig.appId;
          } else {
            appId = transactionsConfig.appId[0];
          }
        }

        data.transactionTarget = appId;
        // Check if transactionsConfig.cart is in cartToMap
        if (transactionsConfig.cart && cartToMap[transactionsConfig.cart]) {
          transactionsConfig.cart = cartToMap[transactionsConfig.cart];
        }

        data.transactionsConfig = {
          upsert: {
            create: {
              appId: appId && appId.length ? appId : null,
              cart:
                transactionsConfig &&
                transactionsConfig.cart &&
                transactionsConfig.cart.toUpperCase(),
              traffId: transactionsConfig.traffId,
              gtmAccess: transactionsConfig.gtmAccess,
              gtmStatus: transactionsConfig.gtmStatus,
              tracksBasketItems: transactionsConfig.tracksBasketItems,
              tag: transactionsConfig.tag,
            },
            update: {
              appId: appId && appId.length ? appId : null,
              cart:
                transactionsConfig &&
                transactionsConfig.cart &&
                transactionsConfig.cart.toUpperCase(),
              traffId: transactionsConfig.traffId,
              gtmAccess: transactionsConfig.gtmAccess,
              gtmStatus: transactionsConfig.gtmStatus,
              tracksBasketItems: transactionsConfig.tracksBasketItems,
              tag: transactionsConfig.tag,
            },
          },
        };
      } else if (
        !transactionsConfig &&
        currentCampaignOrder.transactionsConfig
      ) {
        data.transactionsConfig = {
          delete: true,
        };
      }

      if (signUpConfig) {
        let appId = null;
        // Check if appId is type string
        if (signUpConfig.appId) {
          if (typeof signUpConfig.appId === "string") {
            appId = signUpConfig.appId;
          } else {
            appId = signUpConfig.appId[0];
          }
        }

        data.signUpCampaign = true;
        data.signUpConfig = {
          upsert: {
            create: {
              appId: appId,
              locationId: null,
              name: signUpConfig.tag ? signUpConfig.tag.name : "",
            },
            update: {
              appId: appId,
              locationId: null,
              name: signUpConfig.tag ? signUpConfig.tag.name : "",
            },
          },
        };
      } else if (!signUpConfig && currentCampaignOrder.signUpConfig) {
        data.signUpConfig = {
          delete: true,
        };
      }

      if (status === "PENDING") {
        data = {
          ...data,
          campaignReviewProcess: {
            upsert: {
              create: { stateLaw: null, scaleBudget: null },
              update: { stateLaw: null, scaleBudget: null },
            },
          },
          reviewerId: currentUser.id,
        };
      }

      await handleUpdate({
        ...data,
        type: campaignTypeMap[form.getFieldValue("type")],
      });
    } else {
      form.setFieldsValue({ name: draftName });

      data = {
        ...data,
        name: draftName,
        type: campaignTypeMap[form.getFieldValue("type")],
        requestorId: currentUser.id,
        status: "DRAFT",
        kpi: "CPM",
        kpiGoal: 0,
        isRegulated: true,
        attributionWindow: 30,
        budget: Number(form.getFieldValue("budget") || 0),
        category: {
          set: "IAB7-5",
        },
        advertiser: {
          connect: {
            id: selectedAdvertiser.id,
          },
        },
        orgs: {
          connect: {
            id: currentUserOrg.id,
          },
        },
        createdBy: {
          connect: {
            id: currentUser.id,
          },
        },
        campaignChecklistProcess: {
          create: {
            processOne: false,
            processOneText: [],
            processTwo: false,
            processTwoText: [],
            processThree: false,
            processThreeText: [],
            processFour: false,
            processFourText: [],
            processFive: false,
            processFiveText: null,
          },
        },
        adUnits: {
          create: [
            {
              name: "",
              clickthrough: "",
            },
          ],
        },
      };

      if (transactionsConfig) {
        let appId = null;
        if (transactionsConfig.appId) {
          // Check if appId is type string
          if (typeof transactionsConfig.appId === "string") {
            appId = transactionsConfig.appId;
          } else {
            appId = transactionsConfig.appId[0];
          }
        }

        data.transactionTarget = appId;
        // Check if transactionsConfig.cart is in cartToMap
        if (transactionsConfig.cart && cartToMap[transactionsConfig.cart]) {
          transactionsConfig.cart = cartToMap[transactionsConfig.cart];
        }
        data.transactionsConfig = {
          create: {
            appId: appId && appId.length ? appId : null,
            cart:
              transactionsConfig &&
              transactionsConfig.cart &&
              transactionsConfig.cart.toUpperCase(),
            traffId: transactionsConfig.traffId,
            gtmAccess: transactionsConfig.gtmAccess,
            gtmStatus: transactionsConfig.gtmStatus,
            tracksBasketItems: transactionsConfig.tracksBasketItems,
            tag: transactionsConfig.tag,
          },
        };
      }

      if (signUpConfig) {
        let appId = null;
        // Check if appId is type string
        if (signUpConfig.appId) {
          if (typeof signUpConfig.appId === "string") {
            appId = signUpConfig.appId;
          } else {
            appId = signUpConfig.appId[0];
          }
        }

        data.signUpCampaign = true;
        data.signUpConfig = {
          create: {
            appId: appId,
            locationId: null,
          },
        };
      }

      const campaignOrder = await handleSaveDraft(data);
      if (campaignOrder) {
        history.push(`/campaigns/requester/${campaignOrder.id}`);
      }

      return campaignOrder;
    }
  };

  const handleSubmitRequest = async () => {
    try {
      // Check if form is valid
      const status =
        currentCampaignOrder.status === "LIVE" ? "LIVE_PENDING" : "PENDING";

      await validateForm();
      await handleSave(status);
      history.push("/campaigns/main/1");
    } catch (error) {}
  };

  const isActive = stepIndex => {
    switch (stepIndex) {
      case 0:
        return activeSteps.details;
      case 1:
        return activeSteps.durationAndBudget;
      case 2:
        return activeSteps.categories;
      case 3:
        return activeSteps.attributionReporting;
      default:
        return activeSteps[`lineItem${stepIndex - 4}`];
    }
  };

  useEffect(() => {
    const validateFields = async fields => {
      try {
        await form.validateFields(fields);
        return true;
      } catch (error) {
        return false;
      }
    };

    const checkValidity = async () => {
      let updatedActiveSteps = { ...defaultActiveSteps };
      let attributionReporting = false;
      const updatedSteps = [...defaultSteps];

      const details = await validateFields(["advertiserId"]);
      const durationAndBudget = await validateFields([
        "budget",
        "cpm",
        "startDate",
        "endDate",
      ]);
      const categoryFields = ["category", "advertiserType"];
      const advertiserType = form.getFieldValue("advertiserType");

      if (advertiserType === "RETAIL_LOCAL_BRICK_AND_MORTAR") {
        categoryFields.push("attributionTargets");
      }

      const categories = await validateFields(categoryFields);

      // Attribution Reporting
      // Check if awarenessCampaign is true
      const awarenessCampaign = form.getFieldValue("awarenessCampaign");
      if (awarenessCampaign) {
        attributionReporting = await validateFields(["awarenessCampaign"]);
      } else {
        const transactionsConfigField = await validateFields([
          "transactionsConfig",
        ]);
        const signUpConfigField = await validateFields(["signUpConfig"]);
        attributionReporting =
          transactionsConfigField || signUpConfigField || false;
      }

      updatedActiveSteps = {
        ...updatedActiveSteps,
        details,
        durationAndBudget,
        categories,
        attributionReporting,
      };

      // Check if campaigns are valid
      const campaigns = form.getFieldValue("campaigns");
      if (campaigns && campaigns.length) {
        for (let index = 0; index < campaigns.length; index++) {
          const campaign = campaigns[index];
          const targetingType = campaign.targetingType;

          updatedSteps.push({
            title: campaign.name,
            bookmarkId: `requester-line-item-${index}`,
          });

          const lineItemFields = [
            ["campaigns", index, "name"],
            ["campaigns", index, "endDate"],
            ["campaigns", index, "budgetTotal"],
            ["campaigns", index, "cpm"],
            ["campaigns", index, "targetingType"],
            ["campaigns", index, "audienceType"],
            ["campaigns", index, "adGroup", "clickthrough"],
            ["campaigns", index, "adGroup", "adGroupCreatives"],
          ];

          switch (targetingType) {
            case "REGION":
              lineItemFields.push(["campaigns", index, "targetingRegions"]);
              break;
            case "LOCAL":
              lineItemFields.push(["campaigns", index, "targetingLocations"]);
              break;

            case "COUNTRY":
              lineItemFields.push(["campaigns", index, "targetingCountries"]);
              break;

            default:
              break;
          }

          if (campaign.audienceType === "FIRST_PARTY_DATA") {
            lineItemFields.push([
              "campaigns",
              index,
              "firstPartyDataAudiences",
            ]);
          }

          const lineItemDetails = await validateFields(lineItemFields);

          updatedActiveSteps = {
            ...updatedActiveSteps,
            [`lineItem${index}`]: lineItemDetails,
          };
        }
      }

      setSteps([...updatedSteps]);
      setActiveSteps({
        ...updatedActiveSteps,
      });
    };
    checkValidity();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, previousValues]);

  useEffect(() => {
    const checkPermission = async () => {
      const authorized = await isAuthorized(
        ["isProcessor", "isTrafficker"],
        []
      );
      setCanEdit(authorized);
    };

    checkPermission();
  }, [isAuthorized]);

  return (
    <>
      <Col className="campaign-requester-sticky">
        {" "}
        {/* Added card component in the action button( Clone | Save as Draft), then wrapped by this Col */}
        <Card
          title={
            <div
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              SUMMARY {camapignName ? <small>- [{camapignName}] </small> : ""}
              {selectedAdvertiser ? (
                <div style={{ display: "flex" }}>
                  <small style={{ fontWeight: 300 }}>
                    {selectedAdvertiser.name}
                  </small>
                </div>
              ) : (
                ""
              )}
            </div>
          }
          className="campaign-requester-summary"
        >
          {mode === "edit" && (
            <Steps className="requester-summary" direction="vertical">
              {steps.map((step, index) => {
                return (
                  <Step
                    key={index}
                    title={
                      <a
                        href={`#${step.bookmarkId}`}
                        style={{ color: "inherit" }}
                        onClick={e => {
                          e.preventDefault();
                          const element = document.getElementById(
                            step.bookmarkId
                          );
                          const yOffset = -80; // Adjust this value as needed
                          const y =
                            element.getBoundingClientRect().top +
                            window.pageYOffset +
                            yOffset;
                          window.scrollTo({ top: y, behavior: "smooth" });
                        }}
                      >
                        {step.title}
                      </a>
                    }
                    status={isActive(index) ? "finish" : "wait"}
                    icon={
                      !isActive(index) ? (
                        <MinusCircleFilled
                          style={{ fontSize: 10, fill: "#00000040" }}
                        />
                      ) : null
                    }
                  />
                );
              })}
            </Steps>
          )}
          {mode === "review" && (
            <Text>
              Take a moment to review the details of this request before
              submitting it.
            </Text>
          )}
        </Card>
        <Card>
          <Space direction="vertical" size="middle" style={{ width: "100%" }}>
            {!currentCampaignOrder && (
              <Button
                type="secondary"
                style={{ width: "100%" }}
                onClick={() => setVisibleCloneDialog(true)}
              >
                Clone
              </Button>
            )}
            <Button
              style={{ width: "100%" }}
              onClick={() =>
                handleSave(
                  currentCampaignOrder ? currentCampaignOrder.status : "DRAFT"
                )
              }
              disabled={
                loadingCreateCampaignOrder ||
                loadingUpdateCampaignOrder ||
                !canEdit
              }
            >
              {`${
                currentCampaignOrder ||
                !Object.values(activeSteps).some(step => !step)
                  ? "Save"
                  : "Save as Draft"
              }`}
            </Button>

            {mode === "review" && (
              <Button
                style={{ width: "100%" }}
                onClick={() =>
                  history.push(
                    `/campaigns/requester/${currentCampaignOrder.id}?mode=edit`
                  )
                }
                disabled={
                  loadingCreateCampaignOrder ||
                  loadingUpdateCampaignOrder ||
                  !canEdit
                }
              >
                Make Edits
              </Button>
            )}
            {mode === "edit" && currentCampaignOrder && (
              <Button
                style={{ width: "100%" }}
                disabled={
                  loadingCreateCampaignOrder ||
                  loadingUpdateCampaignOrder ||
                  Object.values(activeSteps).some(step => !step) ||
                  !canEdit
                }
                onClick={() => handleNextStep(currentCampaignOrder.status)}
              >
                Next
              </Button>
            )}
            {mode === "review" && (
              <Button
                type="primary"
                style={{ width: "100%" }}
                onClick={() => handleSubmitRequest()}
                disabled={
                  loadingCreateCampaignOrder ||
                  loadingUpdateCampaignOrder ||
                  !canEdit
                }
              >
                Submit Request
              </Button>
            )}
          </Space>
        </Card>
      </Col>

      {visibleCloneDialog && (
        <CampaignRequestorCloneDialog
          visible={visibleCloneDialog}
          setVisible={setVisibleCloneDialog}
          orgId={selectedAdvertiser && selectedAdvertiser.id}
          onAddSelections={campaignOrders => {
            setVisibleCloneDialog(false);
            if (campaignOrders && campaignOrders.length) {
              const advertiser = campaignOrders[0].advertiser;
              const campaignOrder = deleteProperties({
                object: campaignOrders[0],
                properties: ["id", "createdAt", "updatedAt", "__typename"],
              });
              delete campaignOrder.id;

              const { transactionsConfig, signUpConfig } = campaignOrder;
              transactionsConfig &&
                transactionsConfig.id &&
                delete transactionsConfig.id;
              signUpConfig && signUpConfig.id && delete signUpConfig.id;

              setSelectedAdvertiser(advertiser);
              handleInitialValues({
                ...campaignOrder,
                advertiser,
                name: `${campaignOrders[0].name} - ${new Date().getTime()}`,
                status: "DRAFT",
                startDate: moment(),
                endDate: moment().add(2, "days"),
                transactionsConfig,
              });
            }
          }}
        />
      )}
    </>
  );
};

export default CampaignRequestorSummary;
