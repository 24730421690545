import React, { useState } from "react";
import moment from "moment";
import { Avatar, Tooltip } from "antd";
import { isEmpty } from "lodash";

import PaginationTable from "../../../../../../shared/PaginationTable";
import ImpressionSubTable from "./ImpressionsTimeLine";
import ClicksSubTable from "./ClicksTimeLine";
import cnnaid from "../../../../../../../assets/icons/transactions/cnnaid.png";
import cnnaip from "../../../../../../../assets/icons/transactions/cnnaip.png";
import cnnafingerprint from "../../../../../../../assets/icons/transactions/cnnafingerprint.png";
import { ReactComponent as GlobePhoneIcon } from "../../../../../../../assets/icons/transactions/globephone.svg";
import TransactionImpressions from "./transactionimpressions";
// Global Styling
import {
  TableColumnTitle,
  PrimaryTableRowText,
} from "../../../../../../shared/globalStyling/styledText";
import { formatCurrency2SigFig } from "../../../../../../../core/utils/campaigns";
import { useHistory, useLocation } from "react-router";

const TransactionsTable = ({
  transactionData,
  transactionTotalRecord,
  type,
  dateTrue,
  dateFilter,
  isSimplified,
  reportName,
  campaignOrderId,
  setCurrentIdx,
  loading,
  currentPage,
  setCurrentPage,
  isDemo,
}) => {
  const location = useLocation();
  const history = useHistory();

  const [pageSize, setPageSize] = useState(10);

  const params = new URLSearchParams(location.search);
  if (!params.get("pageSize")) {
    params.delete("pageSize");
    params.append("pageSize", 10);
  }

  const columns = [
    {
      title: <TableColumnTitle text={"Order"} />,
      dataIndex: "transactionId",
      key: "transactionId",
      align: "left",
      width: "13%",
      sorter: (a, b) => a.transactionId - b.transactionId,
      render: transactionId => <PrimaryTableRowText text={transactionId} />,
    },

    type === "CPC"
      ? {}
      : {
          title: <TableColumnTitle text={"Last Impression"} />,
          dataIndex: "latestDate",
          key: "latestDate",
          align: "left",
          width: "20%",
          sorter: (a, b) => {
            if (!a.latestDate && !b.latestDate) return 0;
            if (!a.latestDate) return 1;
            if (!b.latestDate) return -1;

            const dateA = moment(a.latestDate);
            const dateB = moment(b.latestDate);

            return dateA.valueOf() - dateB.valueOf();
          },
          render: latestDate => (
            <PrimaryTableRowText
              text={
                latestDate ? moment(latestDate).format("MM/DD/YY HH:mm") : ""
              }
            />
          ),
        },

    type === "CPC"
      ? {
          title: <TableColumnTitle text={"Clicks"} />,
          dataIndex: "totalClicks",
          key: "clicks",
          align: "left",
          width: "17%",
          sorter: (a, b) =>
            (parseInt(a.totalClicks) || 0) - (parseInt(b.totalClicks) || 0),
          render: totalClicks => (
            <PrimaryTableRowText text={totalClicks || 0} />
          ),
        }
      : {
          title: <TableColumnTitle text={"Impressions"} />,
          dataIndex: "totalImpressions",
          key: "impressions",
          align: "left",
          width: "17%",
          sorter: (a, b) =>
            (parseInt(a.totalImpressions) || 0) -
            (parseInt(b.totalImpressions) || 0),
          render: totalImpressions => (
            <PrimaryTableRowText text={totalImpressions || 0} />
          ),
        },

    {
      title: <TableColumnTitle text={"Ordered on"} />,
      dataIndex: "transaction",
      key: "TRANSACTION_TIME",
      align: "left",
      width: "15%",
      sorter: (a, b) =>
        moment(a.transaction, moment.DATETIME_LOCAL_MS).unix() -
        moment(b.transaction, moment.DATETIME_LOCAL_MS).unix(),
      render: transaction => (
        <PrimaryTableRowText
          text={moment(transaction, moment.DATETIME_LOCAL_MS).format(
            "MM/DD/YY HH:mm"
          )}
        />
      ),
    },

    {
      title: <TableColumnTitle text={"Total"} />,
      dataIndex: "transactionTotal",
      key: "total",
      align: "left",
      width: "12%",
      sorter: (a, b) => a.transactionTotal - b.transactionTotal,
      render: total => (
        <PrimaryTableRowText
          text={
            total && !isNaN(total)
              ? formatCurrency2SigFig(total)
              : formatCurrency2SigFig(0)
          }
        />
      ),
    },
    {
      title: <TableColumnTitle text={""} />,
      dataIndex: "attributionMethod",
      key: "attributionMethod",
      align: "left",
      width: "8%",
      render: col => {
        let record;

        record = col;
        if (typeof col === "string") {
          record = JSON.parse(col);
        }

        let method = "";
        let attributionIcon = cnnaip;
        let tip = "";
        //Single object with 1 key
        if (record) {
          method = Object.keys(record)[0];
          if (method === "COOKIE") {
            attributionIcon = cnnaid;
            tip =
              "Cookie Match - Deterministic match using browser based cookie / uuid match.";
          } else if (method === "FINGERPRINT") {
            attributionIcon = cnnafingerprint;
            tip =
              "User Match - Probabilistic match using user fingerprint. (User fingerprint - The combination of user agent and IP address to produce a unique hash)";
          } else if (method === "DEVICE_FINGERPRINT") {
            tip =
              "Device Match - Probabilistic match using user device model information (Device model, OS, OS version ) combined with IP address";
          } else if (method === "IP") {
            tip = "IP Match - Probabilistic match using IP address.";
            attributionIcon = cnnaip;
          }
        }
        const attributionIcn = () => {
          if (method === "DEVICE_FINGERPRINT") {
            return (
              <span
                role="img"
                className="anticon"
                style={{ verticalAlign: "-5px" }}
              >
                <GlobePhoneIcon
                  height="35"
                  width="35"
                  style={{
                    opacity: "1.0",
                  }}
                />
              </span>
            );
          } else {
            return <Avatar src={attributionIcon} />;
          }
        };
        return <Tooltip title={tip}>{attributionIcn()}</Tooltip>;
      },
    },
  ];

  // Commented it for now
  //if (transactionsConfig === null || !transactionsConfig.tracksBasketItems) {
  //  columns.pop();
  // }

  const newTransactionData = dateTrue
    ? transactionData.filter(
        obj =>
          moment(obj.transaction).format("YYYY/MM/DD") >= dateFilter[0] &&
          moment(obj.transaction).format("YYYY/MM/DD") <= dateFilter[1]
      )
    : transactionData;

  const transactionsWithKeys =
    newTransactionData &&
    newTransactionData.map((item, i) => {
      const record = { ...item };
      record.value = i;
      return record;
    });

  const transactionDataCount =
    transactionData && transactionData.length ? transactionData.length : 0;

  return (
    <PaginationTable
      loading={loading}
      bordered={false}
      columns={columns}
      data={transactionsWithKeys}
      rowKey={record => record.value}
      scroll={{ x: 850, y: 400 }}
      showSizeChanger
      size={"default"}
      total={isSimplified ? transactionTotalRecord : transactionDataCount}
      current={currentPage}
      pageSize={pageSize}
      onChange={(paginationObject, filtersObject, sorterObject) => {
        setPageSize(paginationObject.pageSize);
        setCurrentPage(paginationObject.current);
        if (isSimplified) {
          if (Number(paginationObject.pageSize) !== Number(pageSize)) {
            setCurrentPage(1);
            setCurrentIdx(0);
          } else {
            setCurrentIdx(
              Number(pageSize) * Math.abs(paginationObject.current - 1)
            );
          }

          if (params.get("pageSize")) params.delete("pageSize");
          if (!isEmpty(paginationObject) && paginationObject.pageSize > 10) {
            params.append("pageSize", paginationObject.pageSize);
          }
          history.push(`?${params.toString()}`);
        }
      }}
      expandedRowRender={
        !isSimplified
          ? record => {
              const { impressions, clicks } = record;
              return type === "CPC" ? (
                <ClicksSubTable clicks={clicks ? clicks : []} />
              ) : (
                <ImpressionSubTable
                  impressions={impressions ? impressions : []}
                />
              );
            }
          : record => {
              return (
                <TransactionImpressions
                  reportName={reportName}
                  transactionId={record.transactionId}
                  campaignOrderId={campaignOrderId}
                  isDemo={isDemo}
                />
              );
            }
      }
      rowClassName={record => {
        return record && record.totalImpressions ? "" : "hide-expand";
      }}
    />
  );
};

export default TransactionsTable;
