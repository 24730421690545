import React from "react";
import { Input } from "antd";

const TableSearch = ({ searchText, onSearch }) => {
  return (
    <Input
      placeholder="Search..."
      value={searchText}
      allowClear
      onChange={e => onSearch(e.target.value)}
      style={{
        marginBottom: 16,
        width: 300, // Adjust the width as needed
        marginLeft: "0.4em",
      }}
    />
  );
};

export default TableSearch;
