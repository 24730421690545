import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { Storage } from "aws-amplify";
import { Button, Card, Form, Input, Row, Table, Upload } from "antd";
import React, { useEffect, useState } from "react";

const UpdateCreatives = ({
  creatives = [],
  maskedCreatives = [],
  onUpdate,
}) => {
  console.log(creatives);
  const [form] = Form.useForm();
  const [dataSource, setDataSource] = useState();
  let cleanedCreatives = [];

  creatives.forEach(c => {
    const { name } = c;
    const creativeExists = cleanedCreatives.find(cc => cc.creativeID === name);
    if (!creativeExists) {
      cleanedCreatives.push({
        index: cleanedCreatives.length,
        creativeID: c.name,
        name: null,
        url: null,
        id: null,
        action: null,
      });
    }
  });

  maskedCreatives = maskedCreatives.forEach(c => {
    const { creativeID } = c;
    const creativeExists = cleanedCreatives.findIndex(
      cc => cc.creativeID === creativeID
    );
    if (creativeExists === -1) {
      console.log(c);
      cleanedCreatives.push({
        index: cleanedCreatives.length,
        creativeID: c.creativeID,
        name: c.name,
        url: c.url,
        id: c.id,
        action: null,
      });
    } else {
      cleanedCreatives[creativeExists] = {
        ...cleanedCreatives[creativeExists],
        ...c,
      };
    }
  });

  useEffect(() => {
    if (!dataSource) setDataSource(cleanedCreatives);
  }, [cleanedCreatives, dataSource]);

  const columns = [
    {
      title: "",
      key: "image",
      width: "5%",
      render: (_, { index }) => {
        const thisUrl = form.getFieldValue(["data", index, "url"]);
        const thisId = form.getFieldValue(["data", index, "id"]);
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const [loading, setLoading] = useState(false);
        return (
          <Upload
            name="file"
            listType="picture-card"
            beforeUpload={async file => {
              setLoading(true);
              const { name } = file;
              const fname = `${Date.now()}-${name}`;
              const response = await Storage.put(fname, file, {
                contentType: "image/*",
              });
              const urlUploaded = await Storage.get(response.key, {
                expires: 600000,
              });
              let action = "create";
              if (thisId) action = "update";
              form.setFieldsValue({
                data: form
                  .getFieldValue("data")
                  .map((item, idx) =>
                    idx === index ? { ...item, url: urlUploaded, action } : item
                  ),
              });

              setLoading(false);

              return false;
            }}
            showUploadList={false}
            accept=".png, .jpeg, .jpg, .mp4, .gif, .avif"
          >
            {thisUrl ? (
              <img
                src={thisUrl}
                style={{ width: "100%", height: "100%" }}
                alt="creative"
              />
            ) : (
              <button style={{ border: 0, background: "none" }} type="button">
                {loading ? <LoadingOutlined /> : <PlusOutlined />}
                <div style={{ marginTop: 8 }}>Upload</div>
              </button>
            )}
          </Upload>
        );
      },
    },
    {
      title: "Creative ID",
      dataIndex: "creativeID",
      key: "creativeID",
      render: (_, { index, creativeID }) => {
        return (
          <>
            <Form.Item name={["data", index, "creativeID"]} hidden noStyle />
            {creativeID}
          </>
        );
      },
    },
    {
      title: "Creative Name",
      dataIndex: "name",
      key: "name",
      render: (_, { index, name }) => {
        return (
          <Form.Item name={["data", index, "name"]}>
            <Input
              placeholder="Enter Creative Name"
              onChange={() => {
                const thisId = form.getFieldValue(["data", index, "id"]);
                let action = "create";
                if (thisId) action = "update";
                form.setFieldsValue({
                  data: form
                    .getFieldValue("data")
                    .map((item, idx) =>
                      idx === index ? { ...item, action } : item
                    ),
                });
              }}
            />
          </Form.Item>
        );
      },
    },
    {
      title: "URL",
      dataIndex: "url",
      key: "url",
      render: (_, { index }) => {
        return (
          <Form.Item name={["data", index, "url"]}>
            <Input
              placeholder="Enter Image URL"
              onChange={() => {
                const thisId = form.getFieldValue(["data", index, "id"]);
                let action = "create";
                if (thisId) action = "update";
                form.setFieldsValue({
                  data: form
                    .getFieldValue("data")
                    .map((item, idx) =>
                      idx === index ? { ...item, action } : item
                    ),
                });
              }}
            />
          </Form.Item>
        );
      },
    },
  ];
  return (
    <Card title="Creative Details">
      <Form
        form={form}
        initialValues={{ data: cleanedCreatives }}
        onFinish={values => {
          onUpdate(values.data);
        }}
      >
        <Form.Item name="data" hidden />
        <Table dataSource={cleanedCreatives} columns={columns}></Table>
        <Row justify="end">
          <Button type="primary" htmlType="submit">
            Update
          </Button>
        </Row>
      </Form>
    </Card>
  );
};

export default UpdateCreatives;
