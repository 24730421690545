import React from "react";
import { PageContentLayout, PageHeaderLayout } from "../../../shared/layout";
import { TagsOutlined } from "@ant-design/icons";
import { Button, Col, Row } from "antd";
import DetailsCard from "./components/DetailsCard";
import GeneratedTagsCard from "./components/GeneratedTagsCard";
import SignUpsCard from "./components/SignUpsCard";
import TestLogsCard from "./components/TestLogsCard";
import RegenerateTag from "./components/RegenarateTag";
import { withRouter, useLocation } from "react-router-dom";

const TagsDetailsView = ({
  details,
  generatedTag,
  signUpsTag,
  testTag,
  testLogs,
  tagActivityLoading,
  regenerateTag,
  userPermission,
  carts,
  history,
  dsps,
}) => {
  const { isTrafficker } = userPermission;

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const source = searchParams.get("source");

  let type = "measurement";

  switch (source) {
    case "impression":
      type = "impression";
      break;
    case "publisher":
      type = "publisher";
      break;
    default:
      type = "measurement";
      break;
  }
  if (details.isPublisherDomain) type = "publisher";
  else if (details.isAdvertiser) type = "impression";

  return (
    <React.Fragment>
      <PageHeaderLayout
        title={details.name}
        titleIcon={<TagsOutlined />}
        style={{ paddingTop: "10px", paddingBottom: "10px" }}
        rightContent={
          <Row gutter={[16, 16]}>
            <Col>
              {isTrafficker ? (
                <RegenerateTag
                  cart={details.cart}
                  environment={
                    details.impressionEnvironments
                      ? details.impressionEnvironments
                      : details.dsp
                  }
                  regenerateTag={regenerateTag}
                  domain={details.domain}
                  carts={carts}
                  dsps={dsps}
                  type={type}
                  appId={details.appId}
                />
              ) : null}
            </Col>
            <Col>
              {isTrafficker ? (
                <Button
                  type="primary"
                  shape="round"
                  onClick={() => {
                    history.push(
                      `/tags/manager/${details.id}?source=${source}`
                    );
                  }}
                >
                  Update Tag
                </Button>
              ) : null}
            </Col>
          </Row>
        }
      />
      <PageContentLayout>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <DetailsCard details={details} type={type} />
              </Col>
              <Col span={24}>
                <TestLogsCard
                  logs={testLogs}
                  hasBasketItems={generatedTag.basketItems > 0}
                  hasSignups={signUpsTag.signups > 0}
                  testTag={testTag}
                  loading={tagActivityLoading}
                  type={type}
                />
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <GeneratedTagsCard
                  generatedTag={generatedTag}
                  type={type}
                  details={details}
                />
              </Col>
              {signUpsTag && (signUpsTag.signups > 0 || signUpsTag.hasSignups) && (
                <Col span={24}>
                  <SignUpsCard signUpsTag={signUpsTag} />
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      </PageContentLayout>
    </React.Fragment>
  );
};

export default withRouter(TagsDetailsView);
