import React from "react";
import { Card, Row, Col, InputNumber } from "antd";
import { RiseOutlined } from "@ant-design/icons";

const styles = {
  options: {
    border: "1px solid #ebebeb",
    borderRadius: "4px",
    marginBottom: "15px",
    width: "100%",
  },
  titleRow: {
    marginBottom: "10px",
  },
  secondaryText: {
    fontSize: "10px",
    marginLeft: "23px",
  },
  goalSecondaryText: {
    fontSize: "10px",
  },
  goalRow: {
    width: "75%",
    marginBottom: "15px",
  },
  inputStyle: {
    width: "200px",
  },
};

const Title = ({ titleText }) => {
  return (
    <Row type="flex" gutter={10} align="middle">
      <Col>
        <RiseOutlined />
        <span> {titleText}</span>
      </Col>
    </Row>
  );
};

const CpmCard = ({
  changeValue,
  border,
  isLive,
  canEdit,
  isUserTrafficker,
  cpm,
  getCPM,
  defaultCPM,
  setCpmState,
  onChange,
  cpmDisabled,
}) => {
  return (
    <>
      {isUserTrafficker === true ? (
        <Card bordered={border} title={<Title titleText="CPM" />}>
          <Row>
            <Col span={12} xs={24} sm={24}>
              <Row style={styles.goalRow} className="span-mobile">
                <InputNumber
                  className="span-mobile"
                  style={styles.inputStyle}
                  disabled={(isLive && !canEdit) || cpmDisabled}
                  defaultValue={
                    cpm ? cpm : getCPM ? parseFloat(getCPM) : defaultCPM.cpm
                  }
                  value={
                    cpm ? cpm : getCPM ? parseFloat(getCPM) : defaultCPM.cpm
                  }
                  onChange={value => {
                    changeValue("cpm", parseFloat(value));
                    onChange("cpm", parseFloat(value));
                    setCpmState(parseFloat(value));
                  }}
                />
              </Row>
            </Col>
          </Row>
        </Card>
      ) : null}
    </>
  );
};

export default CpmCard;
