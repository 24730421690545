import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Col,
  Row,
  Modal,
  Select,
  message,
  Spin,
  Checkbox,
  Tag,
} from "antd";
import Button from "../../../shared/globalStyling/Button";
import { withApollo } from "react-apollo";
import { MapConfig } from "../../../shared/maps/configs/mapConfigs";

const CreateRegionGroup = ({
  isFormOpen,
  closeForm,
  handleSubmitRegionGroup,
  handleUpdateRegionGroup,
  currentRegion,
  presetRegionGroups,
  presetRegionGroupsLoading,
  google,
}) => {
  const [form] = Form.useForm();
  const [isLoading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState({
    name: "",
    preset: "",
    isPresetList: false,
    regions: [],
  });
  const [mode, setMode] = useState("Create");
  const [selectedRegions, setSelectedRegions] = useState([]);

  useEffect(() => {
    if (currentRegion) {
      setMode("Update");
      form.setFieldsValue({
        name: currentRegion.name,
        regions: currentRegion.regions,
        isPresetList: currentRegion.isPresetList,
      });
      setSelectedRegions(currentRegion.regions || []);
    } else {
      setMode("Create");
      form.resetFields();
      setInitialValues({});
    }
  }, [currentRegion, form]);

  useEffect(() => {
    searchInputPlacesInit();
  });

  const handleSubmit = async values => {
    setLoading(true);
    message.loading("Action in progress...", 0);
    let payload = {
      ...values,
      regions: selectedRegions,
    };

    let request = null;

    if (currentRegion) {
      payload = { ...payload, id: currentRegion.id };
      request = handleUpdateRegionGroup;
    } else {
      request = handleSubmitRegionGroup;
    }

    await request(payload)
      .then(() => {
        message.destroy();
        message.success(`Successfully ${mode}d Region Group`);
      })
      .catch(err => {
        message.destroy();
        message.error("Something went wrong");
      })
      .finally(() => {
        setLoading(false);
        closeForm(false);
        form.resetFields();
      });
  };

  const searchInputPlacesInit = () => {
    const input = document.getElementById("search-input-tl");

    // Initialize Google Places Autocomplete
    const options = {
      componentRestrictions: MapConfig.componentRestrictions,
      types: ["(regions)"],
    };
    const searchBox = new google.maps.places.Autocomplete(input, options);
    // Listener for place selection
    searchBox.addListener("place_changed", () => {
      const place = searchBox.getPlace();
      if (!place || !place.geometry) {
        message.error("Error: No places found!");
        return;
      }

      const isRegionAlreadySelected = selectedRegions.some(
        selectedRegion => selectedRegion === place.formatted_address
      );

      if (isRegionAlreadySelected) {
        message.warning("Region is already selected");
        return;
      } else {
        setSelectedRegions([...selectedRegions, place.formatted_address]);
      }

      input.value = ""; // Clear the input field after selection
    });
  };

  const handleRemoveRegion = value => {
    const filteredRegions = selectedRegions.filter(region => region !== value);
    setSelectedRegions(filteredRegions);
  };

  const handlePresetChange = value => {
    if (value !== "none") {
      const selectedPreset = presetRegionGroups.getRegionGroupWithPresetList.find(
        preset => preset.id === value
      );

      if (selectedPreset) {
        setSelectedRegions(selectedPreset.regions);
      } else {
        message.error(`Preset not found`);
      }
    } else {
      setSelectedRegions([]);
    }
  };

  const preset =
    presetRegionGroups &&
    presetRegionGroups.getRegionGroupWithPresetList
      .filter(p => p.isPresetList)
      .map(p => {
        return { label: p.name, value: p.id };
      })
      .sort((a, b) => a.label.localeCompare(b.label));

  return (
    <Modal
      title={`${mode}  Region Group`}
      visible={isFormOpen}
      centered
      footer={null}
      onCancel={() => {
        closeForm(false);
        form.resetFields();
      }}
    >
      {presetRegionGroupsLoading ? (
        <Spin />
      ) : (
        <Form
          layout="vertical"
          onFinish={handleSubmit}
          form={form}
          initialValues={initialValues}
        >
          <Row type="flex" justify="space-around">
            <Col span={24}>
              <Form.Item label="Name" name="name" rules={[{ required: true }]}>
                <Input
                  placeholder={"Name of Region Group"}
                  name="name"
                  disabled={isLoading}
                />
              </Form.Item>
            </Col>
          </Row>
          {preset.length > 0 && (
            <Row
              type="flex"
              justify="space-around"
              style={{ marginBottom: "0.5em" }}
            >
              <Col span={24}>
                <Form.Item
                  label="Presets"
                  name="presets"
                  help="Select a list to populate the data. This serves as a template and will not record the previously selected list."
                >
                  <Select
                    defaultValue="none"
                    onSelect={handlePresetChange}
                    options={[{ label: "None", value: "none" }, ...preset]}
                  />
                </Form.Item>
              </Col>
            </Row>
          )}

          <Row type="flex" justify="space-around">
            <Col span={24}>
              <Form.Item label="Region" name="regions">
                <input
                  type="input"
                  name="regions"
                  id="search-input-tl"
                  className="ant-input"
                  onFocus={e => {
                    e.target.setAttribute("autocomplete", "no");
                  }}
                />
                {/* <Input placeholder="Search and select a region" /> */}
                <div style={{ marginTop: "8px" }}>
                  {selectedRegions
                    // .filter(region => !region.delete)
                    .map(region => (
                      <Tag
                        key={region}
                        closable
                        onClose={() => handleRemoveRegion(region)}
                      >
                        {region}
                      </Tag>
                    ))}
                </div>

                {/* <Select
                  mode="multiple"
                  showSearch
                  labelInValue
                  filterOption={false}
                  onSearch={debounceFetcher}
                  notFoundContent={
                    fetching ? (
                      <span>
                        <Spin size="small" style={{ marginRight: 4 }} />
                        Please wait this might take a while
                      </span>
                    ) : null
                  }
                  name="regions"
                  options={options}
                /> */}
              </Form.Item>
            </Col>
          </Row>

          <Row type="flex" justify="space-around">
            <Col span={24}>
              <Form.Item name="isPresetList" valuePropName="checked">
                <Checkbox>Preset</Checkbox>
              </Form.Item>
            </Col>
          </Row>
          <Row type="flex" justify="space-around" align="middle">
            <Col span={24}>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Button htmlType="submit" type="primary" disabled={isLoading}>
                  {mode}
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      )}
    </Modal>
  );
};

export default withApollo(CreateRegionGroup);
