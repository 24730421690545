import React from "react";
import { useMutation, useQuery } from "react-apollo";
import { withRouter } from "react-router-dom";
import LIST_ORGS_SHARING from "../../../GraphQl/Queries/LIST_ORGS_SHARING";
import { LoadingPage } from "../../../../platform/shared/LoadingPage";
import CREATE_EVENT_TAG from "../../../GraphQl/Mutations/CREATE_EVENT_TAG";
import EVENTS_TARGETS from "../../../GraphQl/Queries/EVENTS_TARGETS";
import UPDATE_EVENT_TAG from "../../../GraphQl/Mutations/UPDATE_EVENT_TAG";
import { message } from "antd";

const TagsManagerPublisherLoader = props => {
  const { currentUser, history } = props;
  const currentOrgId = currentUser.role.org.id;

  const { data: orgs, loading: orgsLoading } = useQuery(LIST_ORGS_SHARING, {
    variables: {
      parentid: currentOrgId,
    },
  });

  const [createEventTag] = useMutation(CREATE_EVENT_TAG, {
    onCompleted: values => {
      history.push(
        `/tags/details/${values.createEventTag.id}?source=publisher`
      );
    },
    onError: error => {
      console.error("Error creating events target:", error);
      message.error(`${error}`);
    },
  });

  const [updateEventTag] = useMutation(UPDATE_EVENT_TAG, {
    onCompleted: values => {
      history.push(
        `/tags/details/${values.updateEventTag.id}?source=publisher`
      );
    },
  });

  const { data: measurementTags, loading: loadingTags } = useQuery(
    EVENTS_TARGETS,
    {
      variables: {
        where: {
          OR: [
            { orgs_some: { id: currentOrgId } },
            { orgs_some: { parentOrg_some: { id: currentOrgId } } },
          ],
          isPublisherDomain_not: true,
          isAdvertiser_not: true,
        },
      },
    }
  );

  if (orgsLoading || loadingTags) return <LoadingPage />;
  return React.cloneElement(props.children, {
    ...props.children.props,
    orgs,
    currentOrgId,
    createEventTag,
    updateEventTag,
    measurementTags: measurementTags.eventsTargets,
  });
};

export default withRouter(TagsManagerPublisherLoader);
