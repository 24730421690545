import React, { useEffect } from "react";
import { Card, Space, Table, Tag, Input, Tooltip } from "antd";
import moment from "moment";
import { useCampaignOrderQueueContext } from "../../../../core/components/campaigns/campaignordersqueue/useCampaignOrderQueueContext";
import { Link } from "react-router-dom";

const { Search } = Input;

const columns = [
  {
    title: "Campaign Details",
    dataIndex: "name",
    key: "name",
    sorter: true,
    render: (text, record) => (
      <Space size={4} direction="vertical">
        <Link to={`/campaigns/v2/orderqueue/details/${record.id}`}>{text}</Link>
        <Space size={4}>
          {/* <Tag color="purple">ECM</Tag> */}
          <Tag color="blue">
            {(record.advertiser && record.advertiser.name) || ""}
          </Tag>
        </Space>
      </Space>
    ),
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: (text, record) => {
      if (record.dspConfig && record.dspConfig.status) {
        switch (record.dspConfig.status) {
          case "LAUNCHING":
            return <Tag color="orange">Launching</Tag>;
          case "ERROR":
            return (
              <Tooltip title={record.dspConfig.error}>
                <Tag color="red">Error</Tag>
              </Tooltip>
            );

          default:
            break;
        }
      }
      switch (text) {
        case "APPROVED":
          return "Pending Launch";

        case "LIVE_APPROVED":
          return "Pending Update";

        default:
          break;
      }
    },
  },
  {
    title: "Launch Date",
    dataIndex: "startDate",
    key: "startDate",
    sorter: true,
    render: text => moment(text).format("MM/DD/YYYY"),
  },
  {
    title: "Submitted On",
    key: "updatedAt",
    dataIndex: "updatedAt",
    sorter: true,
    render: text => moment(text).format("MM/DD/YYYY"),
  },
];

export const CampaignOrderQueueReady = () => {
  const {
    campaignOrders,
    loadingCampaignOrdersQueue,
    fetchCampaignOrdersQueue,
    pagination,
    setPagination,
  } = useCampaignOrderQueueContext();

  useEffect(() => {
    handleFetchCampaignOrdersQueue({
      skip: 0,
      first: pagination.pageSize,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFetchCampaignOrdersQueue = ({
    skip,
    first,
    name = "",
    sortField,
    sortOrder,
  }) => {
    fetchCampaignOrdersQueue({
      variables: {
        where: {
          AND: [
            {
              status_in: ["APPROVED", "LIVE_APPROVED"],
              name_contains: name,
            },
          ],
        },
        orderBy:
          sortField && sortOrder
            ? `${sortField}_${sortOrder.toUpperCase()}`
            : "updatedAt_DESC",
        skip,
        first,
      },
    });
  };

  const handleTableChange = ({ current, pageSize }, filters, sorter) => {
    const sortField = sorter.field;
    const sortOrder =
      sorter.order === "ascend"
        ? "asc"
        : sorter.order === "descend"
        ? "desc"
        : null;

    setPagination(prev => ({ ...prev, current, pageSize }));
    handleFetchCampaignOrdersQueue({
      skip: (current - 1) * pageSize || 0,
      first: pageSize,
      sortField,
      sortOrder,
    });
  };

  const handleSearchChange = name => {
    handleFetchCampaignOrdersQueue({
      first: pagination.pageSize,
      name,
    });
  };

  return (
    <Card
      title="READY TO LAUNCH"
      extra={
        <div>
          <Search
            placeholder="Search campaign"
            style={{
              width: 200,
            }}
            onSearch={value => handleSearchChange(value)}
            allowClear
          />
        </div>
      }
    >
      <Table
        columns={columns}
        dataSource={campaignOrders}
        loading={loadingCampaignOrdersQueue}
        pagination={pagination}
        onChange={handleTableChange}
      />
    </Card>
  );
};
