import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Col,
  Dropdown,
  Form,
  Input,
  message,
  Row,
  Space,
  Switch,
  Table,
  Tag,
  Typography,
  Menu,
  Select,
} from "antd";
import "./styles.css";
import {
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import { useTagContext } from "../../../core/components/tags/useTagContext";

const { Title } = Typography;

const kpiDefaultOptions = [
  {
    key: "transactions",
    kpi: "Transactions",
  },
  {
    key: "signups",
    kpi: "Sign-Ups",
  },
];

export const CampaignRequestorKpiTable = ({
  form,
  handleSelectMeasurementTag,
  editable = false,
  setPreviousValues,
}) => {
  const { fetchCarts, carts } = useTagContext();
  const [kpis, setKpis] = useState(kpiDefaultOptions);
  const awarenessCampaign = form.getFieldValue("awarenessCampaign");
  const transactionsConfig = form.getFieldValue("transactionsConfig");
  const signUpConfig = form.getFieldValue("signUpConfig");

  const handleAwarenessCampaignChange = e => {
    const value = e.target.checked;
    if (value) {
      setKpis([]);
    } else {
      setKpis(kpiDefaultOptions);
    }
  };

  const processTag = ({ eventType, config, updatedKpis }) => {
    const matchedTag = updatedKpis.find(tag => tag.key === eventType);
    const newTag = {
      ...matchedTag,
      ...config,
    };

    // Replace the matched tag with the updated tag
    const updatedTags = updatedKpis.map(tag =>
      tag.key === eventType ? newTag : tag
    );
    return updatedTags;
  };

  const handleRemoveKPI = record => {
    // Don't remove the kpi instead just clear the values
    const updatedKpis = kpis.map(kpi =>
      kpi.key === record.key
        ? { ...kpi, appId: null, cart: null, tag: null }
        : kpi
    );
    setKpis(updatedKpis);

    // Clear the hidden input values
    if (record.key === "transactions") {
      form.setFieldsValue({ transactionsConfig: null });
    } else {
      form.setFieldsValue({ signUpConfig: null });
    }

    setPreviousValues({
      ...form.getFieldsValue(),
      transactionsConfig: null,
      signUpConfig: null,
    });
  };

  useEffect(() => {
    setKpis(awarenessCampaign ? [] : kpiDefaultOptions);
  }, [awarenessCampaign]);

  useEffect(() => {
    let updatedKpis = [...kpis];
    if (transactionsConfig) {
      updatedKpis = processTag({
        eventType: "transactions",
        config: transactionsConfig,
        updatedKpis,
      });
    }

    if (signUpConfig) {
      updatedKpis = processTag({
        eventType: "signups",
        config: {
          ...signUpConfig,
          tag: {
            name: signUpConfig.tag
              ? signUpConfig.tag.name
              : signUpConfig.name || "",
          },
        },
        updatedKpis,
      });
    }

    setKpis(updatedKpis);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionsConfig, signUpConfig]);

  useEffect(() => {
    fetchCarts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="requester-table-wrapper">
      <div className="requester-table-header">
        <Row>
          <Col span={12}>
            <Title level={5}>KPI MEASUREMENT</Title>
          </Col>
          <Col span={12}>
            <div style={{ textAlign: "end" }}>
              <Space size="middle" align="center">
                {editable && (
                  <Form.Item
                    name="awarenessCampaign"
                    valuePropName="checked"
                    style={{ marginBottom: 0 }}
                  >
                    <Checkbox onChange={handleAwarenessCampaignChange}>
                      Awareness-Only Campaign
                    </Checkbox>
                  </Form.Item>
                )}
              </Space>
            </div>
          </Col>
        </Row>
      </div>
      {kpis.length ? (
        <Table
          key="kpi"
          columns={[
            {
              title: "KPI",
              dataIndex: "kpi",
              key: "kpi",
              width: 130,
              render: (_, { kpi }) => (
                <Tag color="green" key={kpi}>
                  {kpi}
                </Tag>
              ),
            },
            {
              title: "Corresponding Tag",
              dataIndex: "correspondingTag",
              key: "correspondingTag",
              ellipsis: true, // Enables ellipsis for text overflow
              width: 230, // Set a fixed width for the column
              render: (_, { tag, appId, key }) => {
                if (tag && tag.name) {
                  return tag.name;
                } else if (appId || !editable) {
                  return "";
                }

                return (
                  <Button onClick={() => handleSelectMeasurementTag(key)}>
                    Select Measurement Tag
                  </Button>
                );
              },
            },
            {
              title: "Tag ID",
              dataIndex: "tagId",
              key: "tagId",
              render: (_, { appId }) => {
                if (appId) {
                  return appId;
                }
                return "";
              },
            },
            {
              title: "Basket Items",
              dataIndex: "tracksBasketItems",
              key: "tracksBasketItems",
              render: (_, record) => {
                if (record && record.key === "transactions" && record.cart) {
                  const cartValue = (carts || []).map(cart => cart.value);
                  let cart = record.cart;
                  switch (cart) {
                    case "DUTCHIEJS":
                      cart = "dutchie-iframe";
                      break;
                    case "DUTCHIE":
                      cart = "dutchie-subdomain";
                      break;
                    case "SHOPIFY":
                      cart = "shopifyuniversal";
                      break;
                    default:
                      break;
                  }

                  return (
                    <Switch
                      checked={record.tracksBasketItems}
                      disabled={!cartValue.includes(cart.toLowerCase())}
                      onChange={checked => {
                        form.setFieldsValue({
                          transactionsConfig: {
                            ...transactionsConfig,
                            tracksBasketItems: checked,
                          },
                        });
                        setPreviousValues({
                          ...form.getFieldsValue(),
                          transactionsConfig: {
                            ...transactionsConfig,
                            tracksBasketItems: checked,
                          },
                        });
                      }}
                    />
                  );
                }
                return "";
              },
            },
            {
              title: "Retail ID(s)",
              dataIndex: "traffId",
              key: "traffId",
              width: 200,
              render: (_, record) => {
                if (record && record.key === "transactions" && record.cart) {
                  return (
                    <Select
                      mode="tags"
                      style={{ width: "100%" }}
                      placeholder="Please select"
                      defaultValue={
                        record.traffId ? record.traffId.split(",") : []
                      }
                      onChange={value => {
                        form.setFieldsValue({
                          transactionsConfig: {
                            ...transactionsConfig,
                            traffId: value.join(","),
                          },
                        });
                        setPreviousValues({
                          ...form.getFieldsValue(),
                          transactionsConfig: {
                            ...transactionsConfig,
                            traffId: value,
                          },
                        });
                      }}
                    />
                  );
                }
                return "";
              },
            },
            {
              title: "",
              dataIndex: "actions",
              key: "actions",
              render: (_, record) => {
                const { appId, key } = record;
                if (!appId) {
                  return "";
                }

                const handleCopyToClipBoard = () => {
                  navigator.clipboard.writeText(appId);
                  message.success("Copied to clipboard", 3);
                };

                return (
                  <Dropdown
                    trigger={["click"]}
                    overlay={
                      <Menu>
                        <Menu.Item>
                          <Button
                            type="text"
                            icon={<CopyOutlined />}
                            onClick={() => handleCopyToClipBoard()}
                          >
                            Copy
                          </Button>
                        </Menu.Item>
                        {editable && (
                          <>
                            <Menu.Item>
                              <Button
                                type="text"
                                icon={<EditOutlined />}
                                onClick={() => handleSelectMeasurementTag(key)}
                              >
                                Edit
                              </Button>
                            </Menu.Item>
                            <Menu.Item>
                              <Button
                                type="text"
                                icon={<DeleteOutlined />}
                                danger
                                onClick={() => handleRemoveKPI(record)}
                              >
                                Remove
                              </Button>
                            </Menu.Item>
                          </>
                        )}
                      </Menu>
                    }
                  >
                    <MoreOutlined style={{ color: "black" }} />
                  </Dropdown>
                );
              },
            },
          ]}
          dataSource={kpis}
          pagination={false}
          className="requester-table"
          summary={() => (
            <Table.Summary.Row>
              <Table.Summary.Cell index={0} colSpan={6}>
                {" "}
                2 Tags
              </Table.Summary.Cell>
            </Table.Summary.Row>
          )}
        />
      ) : (
        []
      )}

      {/* Create hidden input for transactionsConfig */}
      <Form.Item
        name="transactionsConfig"
        hidden
        rules={[
          ({ getFieldValue }) => ({
            validator(_, value) {
              const signUpConfig = getFieldValue("signUpConfig");
              const awarenessCampaign = getFieldValue("awarenessCampaign");

              if (signUpConfig || awarenessCampaign || value) {
                return Promise.resolve();
              }
              return Promise.reject(
                new Error("Please select a tag for transactions or sign-ups")
              );
            },
          }),
        ]}
      >
        <Input />
      </Form.Item>

      {/* Create hidden input signUpConfig */}
      <Form.Item
        name="signUpConfig"
        hidden
        rules={[
          ({ getFieldValue }) => ({
            validator(_, value) {
              const transactionsConfig = getFieldValue("transactionsConfig");
              const awarenessCampaign = getFieldValue("awarenessCampaign");
              if (transactionsConfig || awarenessCampaign || value) {
                return Promise.resolve();
              }
              return Promise.reject(
                new Error("Please select a tag for transactions or sign-ups")
              );
            },
          }),
        ]}
      >
        <Input />
      </Form.Item>
    </div>
  );
};
