import React, { useState } from "react";
import { Card, Space } from "antd";
import CampaignRequestorTagsDialog from "./CampaignRequestorTagsDialog";
import CampaignRequestorLocationsDialog from "./CampaignRequestorLocationsDialog";
import { CampaignRequestorKpiTable } from "./CampaignRequestorKpiTable";

const eventTypeMap = {
  transactions: "transactionsConfig",
  signups: "signUpConfig",
};

const CampaignRequestorAttributionReporting = ({
  form,
  google,
  setPreviousValues,
}) => {
  const geoTargets = form.getFieldValue("geoTargets") || [];
  const attributionTargets = form.getFieldValue("attributionTargets") || [];

  const [showTagsModal, setShowTagsModal] = useState(false);
  const [eventType, setEventType] = useState(null);
  const [showRetailStoresModal, setShowRetailStoresModal] = useState(false);

  const handleSelectMeasurementTag = key => {
    setEventType(key);
    setShowTagsModal(true);
  };

  return (
    <>
      <Card id="requester-attribution-reporting" title="ATTRIBUTION REPORTING">
        <Space direction="vertical" size="large" style={{ width: "100%" }}>
          <CampaignRequestorKpiTable
            form={form}
            editable={true}
            handleSelectMeasurementTag={handleSelectMeasurementTag}
            setPreviousValues={setPreviousValues}
          />

          {/* <CampaignRequestorWalkInTable
            form={form}
            editable={true}
            setShowRetailStoresModal={setShowRetailStoresModal}
            setPreviousValues={setPreviousValues}
          /> */}
        </Space>
      </Card>

      {showTagsModal && (
        <CampaignRequestorTagsDialog
          visible={showTagsModal}
          setVisible={setShowTagsModal}
          form={form}
          eventType={eventType}
          onAddSelections={eventTarget => {
            form.setFieldsValue({
              [eventTypeMap[eventType]]: eventTarget
                ? {
                    appId:
                      eventTarget &&
                      eventTarget.eventTags &&
                      eventTarget.eventTags.length
                        ? eventTarget.eventTags[0].appId
                        : null,
                    cart: eventTarget.cart,
                    traffId: null,
                    gtmAccess: null,
                    gtmStatus: null,
                    tracksBasketItems: null,
                    tag: {
                      ...eventTarget.tag,
                      name: eventTarget.name,
                    },
                  }
                : null,
            });

            setPreviousValues({
              ...form.getFieldsValue(),
              [eventTypeMap[eventType]]: eventTarget
                ? {
                    appId:
                      eventTarget &&
                      eventTarget.eventTags &&
                      eventTarget.eventTags.length
                        ? eventTarget.eventTags[0].appId
                        : null,
                    cart: eventTarget.cart,
                    traffId: null,
                    gtmAccess: null,
                    gtmStatus: null,
                    tracksBasketItems: null,
                    tag: {
                      ...eventTarget.tag,
                      name: eventTarget.name,
                    },
                  }
                : null,
            });
          }}
        />
      )}

      {showRetailStoresModal && (
        <CampaignRequestorLocationsDialog
          visible={showRetailStoresModal}
          setVisible={setShowRetailStoresModal}
          form={form}
          google={google}
          audienceType="ATTRIBUTION"
          onAddSelections={targets => {
            form.setFieldsValue({ geoTargets: targets });
            setPreviousValues({
              ...form.getFieldsValue(),
              geoTargets: targets,
            });
          }}
          selectedLocations={[
            ...geoTargets,
            ...(form.getFieldValue("measureWalkInTraffic")
              ? attributionTargets
              : []
            ).map(target => ({
              ...target,
              disabled: true,
            })),
          ]}
        />
      )}
    </>
  );
};

export default CampaignRequestorAttributionReporting;
