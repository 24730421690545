import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { btnS, search } from "./globalStyling/style";
import { Input, Space } from "antd";
import GlobalButton from "./globalStyling/Button";
import { CloseOutlined } from "@ant-design/icons";

//Global Search Bar design usally used on main tables.
const SearchWithClear = ({
  location,
  history,
  searchFormat = "searchTerm",
  placeholder = "Search name",
}) => {
  const params = new URLSearchParams(location.search);
  const searchTerm = params.get(searchFormat);

  const [searchValue, setSearchValue] = useState(searchTerm);
  return (
    <Space
      className="ant-space-xs-vertical ant-space-md-horizontal"
      size={"small"}
    >
      <Input.Search
        placeholder={placeholder}
        style={search}
        value={searchValue}
        onChange={e => setSearchValue(e.target.value)}
        allowClear
        onSearch={searchValue => {
          if (searchTerm) {
            params.delete(searchFormat);
          }
          if (searchValue !== "") {
            params.append(searchFormat, searchValue);
          } else {
            params.delete(searchFormat);
          }
          history.push({
            pathname: location.pathname,
            search: `?${params.toString()}`,
          });
        }}
        defaultValue={searchTerm}
      />
      <GlobalButton
        shape="round"
        style={btnS}
        type={"default"}
        onClick={() => {
          setSearchValue("");
          params.delete(searchFormat);
          history.push({
            pathname: location.pathname,
            search: `?${params.toString()}`,
          });
        }}
        disabled={!searchTerm}
        icon={<CloseOutlined />}
      >
        Clear
      </GlobalButton>
    </Space>
  );
};

export default withRouter(SearchWithClear);
