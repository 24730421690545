import gql from "graphql-tag";

const EVENTS_TARGETS = gql`
  query eventsTargets($where: EventsTargetWhereInput!) {
    eventsTargets(where: $where) {
      id
      name
      eventTags {
        appId
      }
      cart
      tag
      pageViews
      total
      signups
      transactions
      basketItems
      tagUpdateDate
      testLogs {
        id
        testDate
        pageViews
        signups
        total
        transactions
        basketItems
      }
      isSignUps
      isCommerce
      orgs {
        id
        name
      }
      createdAt
    }
  }
`;

export default EVENTS_TARGETS;
