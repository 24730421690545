// Import necessary libraries and components
import React, { useState, useRef, useEffect } from "react";
import {
  Card,
  Col,
  Row,
  Typography,
  Button,
  Form,
  Input,
  Select,
  DatePicker,
  Tag,
  message,
  Badge,
} from "antd";
import {
  FileAddOutlined,
  PaperClipOutlined,
  PictureOutlined,
  CodeOutlined,
} from "@ant-design/icons";
import { useTaskContext } from "../../../../core/components/tasks/useTaskContext";
import { withRouter } from "react-router-dom";
import { debounce } from "lodash";
import moment from "moment";

// Import custom components and configurations
import { PageContentLayout, PageHeaderLayout } from "../../../shared/layout";
import { TaskDatePickerModal } from "./TaskDatePickerModal";
import TaskCodeSnippetModal from "./TaskCodeSnippetModal";
import TaskRelatedCampaignsModal from "./TaskRelatedCampaignsModal";
// Import custom styles
import "../styles/DatePickerStyles.css";
import { useOrgContext } from "../../../../core/components/orgs/useOrgContext";

import { useUserContext } from "../../../../core/components/users/useUserContext";
import { uploadFileToS3 } from "../../../shared/upload/uploadFileToS3";
import {
  taskTypes,
  subTypes,
  urgencyTypes,
  urgencyColors,
} from "../configs/task.config";
import { useParams } from "react-router-dom";
import OverlaySpinner from "../../../shared/OverlaySpinner";
import { getFileType } from "../../../../core/utils/constants/constants";
import { startCase } from "lodash";

const { Text } = Typography;
const { Option } = Select;

const TaskForm = ({ history }) => {
  const { id: currentTaskId } = useParams(); // Extract the ID from the URL with alias as currentTaskId

  const [form] = Form.useForm();
  const textareaRef = useRef(null);
  const {
    currentUser,
    createTask,
    fetchTask,
    currentTask,
    taskLoading,
    updateTask,
  } = useTaskContext();
  const {
    fetchBasicOrgsDetails,
    basicOrgsDetails,
    basicOrgsDetailsLoading,
  } = useOrgContext();
  const {
    fetchBasicUsersDetails,
    basicUsersDetails,
    basicUsersDetailsLoading,
  } = useUserContext();

  const [initialAttachedFiles, setInitialAttachedFiles] = useState([]);
  const [initialAttachedImages, setInitialAttachedImages] = useState([]);
  const [initialRelatedCampaigns, setInitialRelatedCampaigns] = useState([]);
  const [newAttachedFiles, setNewAttachedFiles] = useState([]);
  const [newAttachedImages, setNewAttachedImages] = useState([]);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  useEffect(() => {
    if (currentTask) {
      setInitialAttachedFiles(currentTask.attachedFiles || []);
      setInitialAttachedImages(currentTask.attachedImages || []);
      setInitialRelatedCampaigns(currentTask.relatedCampaigns || []);
    }
  }, [currentTask]);

  const [assigneeOrgs, setAssigneeOrgs] = useState([]);
  const [channelPartnerOrgs, setChannelPartnerOrgs] = useState([]);
  const [advertiserOrgs, setAdvertiserOrgs] = useState([]);
  const [assigneeUsers, setAssigneeUsers] = useState(basicUsersDetails || []);
  const [orgTypeRequest, setOrgTypeRequest] = useState("");

  const userOrgName =
    currentUser && currentUser.role && currentUser.role.org.name;
  const level =
    currentUser &&
    currentUser.role &&
    currentUser.role.org &&
    currentUser.role.org.level;
  const isChannelPartnerOrg = level === "CHANNEL_PARTNER";
  const [filteredSubTypes, setFilteredSubTypes] = useState([]);
  const [isSubTypeDisabled, setIsSubTypeDisabled] = useState(true);
  const [taskDetailVisible, setTaskDatePickerVisible] = useState(false);

  const fileInputRef = useRef(null);
  const imageInputRef = useRef(null);
  const [attachedImages, setAttachedImages] = useState([]);
  const [attachedFiles, setAttachedFiles] = useState([]);

  const [showCodeSnippetModal, setShowCodeSnippetModal] = useState(false);
  const [attachedCodeSnippet, setAttachedCodeSnippet] = useState("");

  const [showRelatedCampaignModal, setRelatedCampaignModal] = useState(false);

  const [dueDateRecurringConfig, setDueDateRecurringConfig] = useState({});

  const relatedCampaigns = form.getFieldValue("relatedCampaigns") || [];

  const handleSubTypeChange = value => {
    const subTypesForSelected = subTypes[value] || [];
    setFilteredSubTypes(subTypesForSelected);
    setIsSubTypeDisabled(subTypesForSelected.length === 0);
    form.setFieldsValue({ requestSubType: "" });
  };

  useEffect(() => {
    if (currentTaskId && currentTask) {
      const subTypesForSelected = subTypes[currentTask.requestType] || [];
      setFilteredSubTypes(subTypesForSelected);
      setIsSubTypeDisabled(subTypesForSelected.length === 0);
    }
  }, [currentTaskId, currentTask]);

  const handleInsertCode = () => {
    const textarea =
      textareaRef.current &&
      textareaRef.current.resizableTextArea &&
      textareaRef.current.resizableTextArea.textArea;
    if (!textarea) return;

    const start = textarea.selectionStart;
    const end = textarea.selectionEnd;
    const currentValue = form.getFieldValue("taskDetails") || "";
    const selectedText = currentValue.slice(start, end);

    let newValue, cursorPosition;

    if (selectedText) {
      // Wrap selected text with code blocks
      newValue =
        currentValue.slice(0, start) +
        "```\n" +
        selectedText +
        "\n```" +
        currentValue.slice(end);
      cursorPosition = start + selectedText.length + 8;
    } else {
      // Insert empty code block
      newValue =
        currentValue.slice(0, start) + "```\n\n```" + currentValue.slice(end);
      cursorPosition = start + 4;
    }

    // Update form value
    form.setFieldsValue({ taskDetails: newValue });

    // Set cursor position after update
    setTimeout(() => {
      textarea.selectionStart = cursorPosition;
      textarea.selectionEnd = cursorPosition;
      textarea.focus();
    }, 0);
  };

  const handleUploadFilesToS3 = async files => {
    try {
      const medias = [];
      if (files.length) {
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          const media = await uploadFileToS3({ file });
          medias.push({
            ...media,
            fileType: getFileType(file.name),
          });
        }
      }
      return medias;
    } catch (error) {
      console.error(error);
      throw new Error("File Size Exceeded: No greater than 2MB is allowed");
    }
  };

  const handleFileDelete = (fileId, type) => {
    if (type === "file") {
      setAttachedFiles(attachedFiles.filter(file => file.id !== fileId));
      setNewAttachedFiles(newAttachedFiles.filter(file => file.id !== fileId));
    } else if (type === "image") {
      setAttachedImages(attachedImages.filter(image => image.id !== fileId));
      setNewAttachedImages(
        newAttachedImages.filter(image => image.id !== fileId)
      );
    }
  };

  const handleSubmit = async () => {
    const {
      name,
      dueDate,
      requestType,
      requestSubType,
      linkUrl,
      urgency,
      assigneeOrgId,
      assigneeUserId: selectedAssignees = [],
      advertiserId,
      taskDetails,
      relatedCampaigns,
    } = form.getFieldsValue();
    await form.validateFields();

    let channelPartnerId = form.getFieldValue("channelPartnerId");

    const {
      recurring,
      recurrenceType,
      dateOfMonth,
      dayOfMonth,
      dayOfWeek,
      monthlyType,
      weeklyDays,
      yearlyDate,
      yearlyMonth,
    } = dueDateRecurringConfig;

    const createdBy = {
      connect: {
        id: currentUser.id,
      },
    };

    // Use initial files to detect deletions
    const filesToDelete = initialAttachedFiles.filter(
      file => !attachedFiles.some(currentFile => currentFile.id === file.id)
    );

    const imagesToDelete = initialAttachedImages.filter(
      image =>
        !attachedImages.some(currentImage => currentImage.id === image.id)
    );

    const campaignsToDelete = initialRelatedCampaigns.filter(
      campaign =>
        !relatedCampaigns.some(
          currentCampaign => currentCampaign.id === campaign.id
        )
    );

    // Use separate state for tracking new files
    let mediaFiles = [];
    let mediaImages = [];

    try {
      if (currentTaskId) {
        // Check and upload only new files
        if (newAttachedFiles.length > 0) {
          mediaFiles = await handleUploadFilesToS3(newAttachedFiles);
        }

        if (newAttachedImages.length > 0) {
          mediaImages = await handleUploadFilesToS3(newAttachedImages);
        }
      } else {
        if (attachedFiles.length > 0) {
          mediaFiles = await handleUploadFilesToS3(attachedFiles);
        }
        if (attachedImages.length > 0) {
          mediaImages = await handleUploadFilesToS3(attachedImages);
        }
      }
    } catch (error) {
      message.error(error.message);
      return;
    }

    // Determine channel partner ID if not set
    if (!channelPartnerId && isChannelPartnerOrg) {
      channelPartnerId = currentUser.role.org.id;
    }

    const assigneeUserIds = selectedAssignees.map(assignee =>
      typeof assignee === "object" && assignee.key ? assignee.key : assignee
    );
    const previousAssigneeUsers =
      currentTask && currentTask.assigneeUsers ? currentTask.assigneeUsers : [];
    const usersToConnect = assigneeUserIds.filter(
      id => id && !previousAssigneeUsers.some(user => user.id === id)
    );
    const usersToDisconnect = previousAssigneeUsers.filter(
      user => !assigneeUserIds.includes(user.id)
    );
    const assigneeUsers = {};
    if (usersToConnect.length > 0) {
      assigneeUsers.connect = usersToConnect.map(id => ({ id }));
    }

    // Build the main task data object
    let data = {
      name,
      dueDate,
      createdBy,
      requestType,
      requestSubType,
      linkUrl,
      urgency,
      assigneeOrg: assigneeOrgId ? { connect: { id: assigneeOrgId } } : {},
      assigneeUsers,
      channelPartner: channelPartnerId
        ? { connect: { id: channelPartnerId } }
        : {},
      advertiser: advertiserId ? { connect: { id: advertiserId } } : {},
      attachedFiles:
        mediaFiles && mediaFiles.length ? { create: mediaFiles } : {},
      attachedImages:
        mediaImages && mediaImages.length ? { create: mediaImages } : {},
      relatedCampaigns:
        relatedCampaigns && relatedCampaigns.length
          ? {
              connect: relatedCampaigns.map(campaign => ({
                id: campaign.id,
              })),
            }
          : undefined, // Ensure related campaigns are included if any
      taskDetails,
      codeSnippet: attachedCodeSnippet,
      status: "NOT_STARTED",
    };

    // Add recurring data, resetting the data object if it is already stored in the GQL
    if (recurring) {
      data = { ...data, recurring, recurrenceType };
      switch (recurrenceType) {
        case "DAILY":
          data = {
            ...data,
            dateOfMonth: null,
            dayOfMonth: null,
            dayOfWeek: null,
            monthlyType: null,
            weeklyDays: { set: null },
            yearlyDate: null,
            yearlyMonth: null,
          };
          break;
        case "MONTHLY":
          data =
            monthlyType === "DATE_OF_MONTH"
              ? {
                  ...data,
                  dateOfMonth,
                  monthlyType,
                  weeklyDays: { set: null },
                  dayOfMonth: null,
                  dayOfWeek: null,
                  yearlyDate: null,
                  yearlyMonth: null,
                }
              : {
                  ...data,
                  dayOfMonth,
                  dayOfWeek: dayOfWeek.toUpperCase(),
                  monthlyType,
                  dateOfMonth: null,
                  weeklyDays: { set: null },
                  yearlyDate: null,
                  yearlyMonth: null,
                };
          break;
        case "WEEKLY":
          data = {
            ...data,
            weeklyDays: { set: weeklyDays },
            dateOfMonth: null,
            dayOfMonth: null,
            dayOfWeek: null,
            monthlyType: null,
            yearlyDate: null,
            yearlyMonth: null,
          };
          break;
        case "YEARLY":
          data = {
            ...data,
            yearlyDate,
            yearlyMonth,
            dateOfMonth: null,
            dayOfMonth: null,
            dayOfWeek: null,
            monthlyType: null,
            weeklyDays: { set: null },
          };
          break;
        default:
          break;
      }
    }

    try {
      message.loading(`${currentTaskId ? "Updating" : "Creating"} task...`, 0);

      if (currentTaskId) {
        // Delete files that have been removed by the user
        if (filesToDelete.length) {
          await updateTask({
            variables: {
              where: { id: currentTaskId },
              data: {
                attachedFiles: {
                  delete: filesToDelete.map(file => ({ id: file.id })),
                },
              },
            },
          });
        }

        if (imagesToDelete.length) {
          await updateTask({
            variables: {
              where: { id: currentTaskId },
              data: {
                attachedImages: {
                  delete: imagesToDelete.map(image => ({ id: image.id })),
                },
              },
            },
          });
        }

        if (campaignsToDelete.length) {
          await updateTask({
            variables: {
              where: { id: currentTaskId },
              data: {
                relatedCampaigns: {
                  disconnect: campaignsToDelete.map(campaign => ({
                    id: campaign.id,
                  })),
                },
              },
            },
          });
        }

        if (usersToDisconnect.length > 0) {
          assigneeUsers.disconnect = usersToDisconnect.map(user => ({
            id: user.id,
          }));
        }

        // Filter out the data that is not needed for the update
        const { status, createdBy, ...filteredData } = data;

        // Proceed with the main update including new files
        await updateTask({
          variables: {
            where: { id: currentTaskId },
            data: filteredData,
          },
        });
        history.push(`/tasks/details/${currentTaskId}`);
      } else {
        await createTask({ variables: { data } });
        if (history) {
          history.push(`/tasks/main/1`);
        }
      }

      message.destroy();
      message.success(
        `Task ${currentTaskId ? "updated" : "created"} successfully`
      );
    } catch (error) {
      message.destroy();
      message.error(
        `Failed to ${currentTaskId ? "update" : "create"} task: ${
          error.message
        }`
      );
    }
  };

  const fetchBasicOrgs = debounce((name = "") => {
    setOrgTypeRequest("ASSIGNEE");
    fetchBasicOrgsDetails({
      variables: {
        first: 10,
        skip: 0,
        where: {
          AND: [
            {
              OR: [
                { parentOrg_some: { id: currentUser.role.org.id } },
                { id: currentUser.role.org.id },
              ],
              name_contains: name,
            },
          ],
          OR: [
            {
              roles_some: {
                org: {
                  level: "PLATFORM",
                },
                roleItems_some: {
                  feature: "TASK_MANAGEMENT",
                },
              },
            },
          ],
        },
      },
    });
  }, 500);

  const fetchChannelPartnerOrgs = debounce((name = "") => {
    setOrgTypeRequest("CHANNEL_PARTNER");
    fetchBasicOrgsDetails({
      variables: {
        first: 30,
        skip: 0,
        where: {
          AND: [
            {
              level_in: ["PLATFORM", "CHANNEL_PARTNER"],
            },
            {
              name_contains: name,
            },
          ],
        },
      },
    });
  }, 500);

  const fetchAdvertiserOrgs = debounce((name = "") => {
    const channelPartnerId =
      level !== "PLATFORM"
        ? currentUser.role.org.id
        : form.getFieldValue("channelPartnerId");
    if (!channelPartnerId) {
      setAdvertiserOrgs([]);
      return;
    }
    setOrgTypeRequest("ADVERTISER");
    fetchBasicOrgsDetails({
      variables: {
        first: 1000,
        skip: 0,
        where: {
          AND: [
            {
              OR: [
                {
                  parentOrg_some: {
                    id: channelPartnerId,
                  },
                },
                {
                  id: channelPartnerId,
                },
              ],
            },
            {
              name_contains: name,
            },
          ],
        },
      },
    });
  }, 500);

  const fetchBasicUsers = debounce((name = "") => {
    const assigneeOrgId = form.getFieldValue("assigneeOrgId");
    if (!assigneeOrgId) {
      setAssigneeUsers([]);
      return;
    }
    setOrgTypeRequest("CHANNEL_PARTNER");
    fetchBasicUsersDetails({
      variables: {
        first: 100,
        skip: 0,
        where: {
          AND: [
            {
              roles_some: {
                org: {
                  id: assigneeOrgId,
                  level: "PLATFORM",
                },
                roleItems_some: {
                  feature: "TASK_MANAGEMENT",
                },
              },
              name_contains: name,
            },
          ],
        },
      },
    });
  }, 500);

  useEffect(() => {
    if (currentTaskId) {
      fetchTask({
        variables: {
          where: {
            id: currentTaskId,
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    switch (orgTypeRequest) {
      case "ASSIGNEE":
        setAssigneeOrgs(basicOrgsDetails);
        break;
      case "CHANNEL_PARTNER":
        setChannelPartnerOrgs(basicOrgsDetails);
        break;

      case "ADVERTISER":
        setAdvertiserOrgs(basicOrgsDetails);
        break;
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basicOrgsDetails]);

  useEffect(() => {
    setAssigneeUsers(basicUsersDetails);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basicUsersDetails]);

  useEffect(() => {
    if (currentTaskId && currentTask && form) {
      form.setFieldsValue({
        name: currentTask.name,
        dueDate: currentTask.dueDate ? moment(currentTask.dueDate) : null,
        requestType: currentTask.requestType,
        requestSubType: currentTask.requestSubType,
        linkUrl: currentTask.linkUrl,
        urgency: currentTask.urgency,
        assigneeOrgId: currentTask.assigneeOrg
          ? currentTask.assigneeOrg.id
          : null,
        assigneeUserId:
          currentTask.assigneeUsers && currentTask.assigneeUsers.length > 0
            ? currentTask.assigneeUsers.map(user => ({
                key: user.id,
                label: startCase(user.name || user.username),
              }))
            : currentTask.assigneeUser
            ? currentTask.assigneeUser.id
            : undefined,
        channelPartnerId: currentTask.channelPartner
          ? currentTask.channelPartner.id
          : null,
        advertiserId: currentTask.advertiser ? currentTask.advertiser.id : null,
        taskDetails: currentTask.taskDetails,
        relatedCampaigns:
          currentTask.relatedCampaigns && currentTask.relatedCampaigns.length
            ? currentTask.relatedCampaigns.map(rCampaign => ({
                ...rCampaign,
                startDate: moment(rCampaign.startDate),
                endDate: moment(rCampaign.endDate),
              }))
            : [],
      });

      setAssigneeOrgs(
        assigneeOrgs.concat(
          currentTask.assigneeOrg ? [currentTask.assigneeOrg] : []
        )
      );
      setChannelPartnerOrgs(
        channelPartnerOrgs.concat(
          currentTask.channelPartner ? [currentTask.channelPartner] : []
        )
      );
      setAdvertiserOrgs(
        advertiserOrgs.concat(
          currentTask.advertiser ? [currentTask.advertiser] : []
        )
      );
      setAssigneeUsers(
        assigneeUsers.concat(
          currentTask.assigneeUser ? [currentTask.assigneeUser] : []
        )
      );
      setAttachedFiles(currentTask.attachedFiles || []);
      setAttachedImages(currentTask.attachedImages || []);
      setAttachedCodeSnippet(currentTask.codeSnippet || "");
      setDueDateRecurringConfig({
        recurring: currentTask.recurring,
        recurrenceType: currentTask.recurrenceType,
        dateOfMonth: currentTask.dateOfMonth,
        dayOfMonth: currentTask.dayOfMonth,
        dayOfWeek: currentTask.dayOfWeek,
        monthlyType: currentTask.monthlyType,
        weeklyDays: currentTask.weeklyDays,
        yearlyDate: currentTask.yearlyDate,
        yearlyMonth: currentTask.yearlyMonth,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTask]);

  if (taskLoading) {
    return <OverlaySpinner />;
  }

  return (
    <>
      <PageHeaderLayout
        title={currentTaskId ? "Update Task" : "Create Task"}
        titleIcon={
          <FileAddOutlined
            style={{
              marginRight: "10px",
              fontSize: 20,
            }}
          />
        }
      />
      <PageContentLayout>
        <Form form={form} layout="vertical">
          {/* TASK DETAILS */}
          <Row gutter={[30, 30]}>
            <Col span={16}>
              <Card
                style={{ borderRadius: "7px" }}
                title={
                  <Text style={{ fontWeight: "bolder" }}>TASK DETAILS</Text>
                }
              >
                <Row gutter={[18, 18]}>
                  <Col span={10}>
                    <Form.Item
                      name="name"
                      label={
                        <div>
                          <div>Task Name</div>
                          <div style={{ color: "gray", fontSize: "12px" }}>
                            The name you will identify this task by
                          </div>
                        </div>
                      }
                      rules={[
                        { required: true, message: "Task Name is Required" },
                      ]}
                      colon={false}
                      style={{ paddingRight: "10px" }}
                    >
                      <Input placeholder="Enter task name" />
                    </Form.Item>
                  </Col>
                  <Col span={14}>
                    {level === "PLATFORM" && (
                      <Form.Item
                        name="dueDate"
                        label={
                          <div>
                            <div>Due Date</div>
                            <div style={{ color: "gray", fontSize: "12px" }}>
                              Optional: Date of task completion
                            </div>
                          </div>
                        }
                        colon={false}
                        layout="vertical"
                      >
                        {/* CUSTOM STYLE DATEPICKER */}
                        <DatePicker
                          onOpenChange={open => {
                            if (open) {
                              setTaskDatePickerVisible(true); // Open the modal when DatePicker is clicked
                            }
                          }}
                          open={false}
                        />
                      </Form.Item>
                    )}
                  </Col>
                </Row>
                <Row gutter={[18, 18]}>
                  <Col span={10}>
                    <Form.Item
                      name="requestType"
                      label={
                        <div>
                          <div>Request Type</div>
                          <div style={{ color: "gray", fontSize: "12px" }}>
                            Select the type that best matches this task
                          </div>
                        </div>
                      }
                      rules={[
                        { required: true, message: "Request Type is Required" },
                      ]}
                      colon={false}
                      style={{ paddingRight: "10px" }}
                    >
                      <Select
                        placeholder="Select request type"
                        onChange={handleSubTypeChange}
                      >
                        {taskTypes.map((type, index) => (
                          <Option key={index} value={type}>
                            {type}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={10}>
                    <Form.Item
                      name="requestSubType"
                      colon={false}
                      label={
                        <div>
                          <div>Request Details</div>
                          <div style={{ color: "gray", fontSize: "12px" }}>
                            Optional: Select a sub-type that best matches this
                            task
                          </div>
                        </div>
                      }
                    >
                      <Select
                        placeholder="Select request details"
                        disabled={isSubTypeDisabled}
                      >
                        {filteredSubTypes.map((subtype, index) => (
                          <Option key={index} value={subtype}>
                            {subtype}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[18, 18]}>
                  <Col span={10}>
                    <Form.Item
                      name="linkUrl"
                      label={
                        <div>
                          <div>Link</div>
                          <div style={{ color: "gray", fontSize: "12px" }}>
                            Optional: Attach one link to the task
                          </div>
                        </div>
                      }
                      colon={false}
                      style={{ paddingRight: "10px" }}
                      rules={[
                        {
                          max: 499,
                          message: "Cannot be any longer than 500 characters",
                        },
                      ]}
                    >
                      <Input placeholder="Enter link" maxLength={500} />
                    </Form.Item>
                  </Col>
                  <Col span={10}>
                    <Form.Item
                      name="urgency"
                      label={
                        <div>
                          <div>Urgency</div>
                          <div style={{ color: "gray", fontSize: "12px" }}>
                            Optional: Set the urgency or priority level of this
                            task
                          </div>
                        </div>
                      }
                      colon={false}
                      style={{ paddingRight: "10px" }}
                    >
                      <Select placeholder="Select urgency type">
                        {Object.keys(urgencyTypes).map(urgency => (
                          <Option key={urgency} value={urgencyTypes[urgency]}>
                            <Badge
                              color={urgencyColors[urgencyTypes[urgency]]}
                              style={{ marginRight: 8 }}
                            />
                            {urgency}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
            </Col>

            {/* REQUEST DETAILS */}
            <Col span={16}>
              <Card
                style={{ borderRadius: "7px" }}
                title={
                  <Text style={{ fontWeight: "bolder" }}>REQUEST DETAILS</Text>
                }
              >
                <Row gutter={[18, 18]}>
                  {level === "PLATFORM" && (
                    <>
                      <Col span={10}>
                        <Form.Item
                          name="assigneeOrgId"
                          label={
                            <div>
                              <div>Assignee Org</div>
                              <div style={{ color: "gray", fontSize: "12px" }}>
                                The org that will receive this task
                              </div>
                            </div>
                          }
                          colon={false}
                          style={{ paddingRight: "10px" }}
                        >
                          <Select
                            showSearch
                            placeholder="Select Assignee Org"
                            loading={basicOrgsDetailsLoading}
                            onFocus={() => fetchBasicOrgs()}
                            onSearch={searchText => fetchBasicOrgs(searchText)}
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            filterSort={(optionA, optionB) =>
                              optionA.children
                                .toLowerCase()
                                .localeCompare(optionB.children.toLowerCase())
                            }
                            onChange={() => {
                              form.setFieldsValue({ assigneeUserId: [] });
                            }}
                          >
                            {assigneeOrgs.map(org => (
                              <Option key={org.id} value={org.id}>
                                {org.name}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>

                      <Col span={10}>
                        <Form.Item
                          name="assigneeUserId"
                          label={
                            <div>
                              <div>Assignee</div>
                              <div style={{ color: "gray", fontSize: "12px" }}>
                                The person that will receive this task
                              </div>
                            </div>
                          }
                          colon={false}
                          style={{ paddingRight: "10px" }}
                          rules={[
                            {
                              validator: (_, value) =>
                                value && value.length > 5
                                  ? Promise.reject(
                                      "Maximum 5 assignees allowed"
                                    )
                                  : Promise.resolve(),
                            },
                          ]}
                        >
                          <Select
                            mode="multiple"
                            placeholder="Select Assignee"
                            showSearch
                            loading={basicUsersDetailsLoading}
                            onFocus={() => fetchBasicUsers()}
                            onSearch={searchText => fetchBasicUsers(searchText)}
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            filterSort={(optionA, optionB) =>
                              optionA.children
                                .toLowerCase()
                                .localeCompare(optionB.children.toLowerCase())
                            }
                            onChange={selectedValues => {
                              if (selectedValues.length > 5) {
                                form.setFieldsValue({
                                  assigneeUserId: selectedValues.slice(0, 5),
                                });
                                message.destroy();
                                message.error("Maximum 5 assignees allowed");
                              }
                            }}
                          >
                            {assigneeUsers.map(user => (
                              <Option key={user.id} value={user.id}>
                                {startCase(user.name || user.username)}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    </>
                  )}
                </Row>
                <Row gutter={[18, 18]}>
                  <Col span={10}>
                    <Form.Item
                      label={
                        <div>
                          <div>Channel Partner</div>
                          <div style={{ color: "gray", fontSize: "12px" }}>
                            The partner this task is regarding
                          </div>
                        </div>
                      }
                      rules={
                        level !== "PLATFORM"
                          ? [] // No rules when level is CHANNEL_PARTNER
                          : [
                              {
                                required: true,
                                message: "Channel Partner is Required",
                              },
                            ]
                      }
                      colon={false}
                      style={{ paddingRight: "10px" }}
                      name="channelPartnerId"
                    >
                      <Select
                        placeholder={
                          level !== "PLATFORM" ? userOrgName : "Select Partner"
                        }
                        showSearch
                        disabled={level !== "PLATFORM"}
                        loading={basicOrgsDetailsLoading}
                        // value={
                        //   level === "CHANNEL_PARTNER" ? userName : undefined
                        // }
                        onFocus={() => fetchChannelPartnerOrgs()}
                        onSearch={searchText =>
                          fetchChannelPartnerOrgs(searchText)
                        }
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        filterSort={(optionA, optionB) => {
                          return optionA.children
                            .toLowerCase()
                            .localeCompare(optionB.children.toLowerCase());
                        }}
                        onChange={() => {
                          form.setFieldsValue({ advertiserId: [] });
                        }}
                      >
                        {channelPartnerOrgs.map(org => (
                          <Option key={org.id} value={org.id}>
                            {org.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={10}>
                    <Form.Item
                      name="advertiserId"
                      showLineNumbers
                      colon={false}
                      style={{ paddingRight: "10px" }}
                      label={
                        <div>
                          <div>Advertiser</div>
                          <div style={{ color: "gray", fontSize: "12px" }}>
                            The advertiser this task is regarding
                          </div>
                        </div>
                      }
                      rules={[
                        { required: true, message: "Advertiser is Required" },
                      ]}
                    >
                      <Select
                        placeholder="Select Advertiser"
                        showSearch
                        loading={basicOrgsDetailsLoading}
                        onFocus={() => fetchAdvertiserOrgs()}
                        onSearch={searchText => fetchAdvertiserOrgs(searchText)}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        filterSort={(optionA, optionB) =>
                          optionA.children
                            .toLowerCase()
                            .localeCompare(optionB.children.toLowerCase())
                        }
                      >
                        {advertiserOrgs.map(org => (
                          <Option key={org.id} value={org.id}>
                            {org.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[18, 18]}>
                  <Col span={24}>
                    <Form.Item
                      name="taskDetails"
                      colon={false}
                      style={{ paddingRight: "10px" }}
                      label={
                        <div>
                          <div>Task Details</div>
                          <div style={{ color: "gray", fontSize: "12px" }}>
                            Details about this task
                          </div>
                        </div>
                      }
                    >
                      <Row
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          border: "1px solid #d9d9d9",
                          borderRadius: "4px",
                          position: "relative",
                        }}
                      >
                        {(((attachedFiles && attachedFiles.length > 0) ||
                          (attachedImages && attachedImages.length > 0)) && (
                          <>
                            <div
                              style={{
                                backgroundColor: "#fafafa",
                                paddingInline: "8px",
                                paddingBlockStart: "5px",
                                paddingBlockEnd: "30px",
                                border: "1px solid #eee",
                                marginBottom: "8px",
                              }}
                            >
                              {/* Render tags inside the text box */}
                              {attachedFiles && attachedFiles.length > 0 && (
                                <Row
                                  style={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    gap: "4px",
                                    width: "100%",
                                    padding: "4px",
                                  }}
                                >
                                  {attachedFiles.map((file, index) => (
                                    <Tag
                                      closable
                                      key={index}
                                      onClose={() => {
                                        if (currentTaskId) {
                                          handleFileDelete(file.id, "file");
                                        } else {
                                          const updatedFiles = attachedFiles.filter(
                                            (_, i) => i !== index
                                          );
                                          setAttachedFiles(updatedFiles);
                                        }
                                      }}
                                      icon={<PaperClipOutlined />}
                                      style={{
                                        margin: 0,
                                        display: "inline-flex",
                                        alignItems: "center",
                                        maxWidth: "100%",
                                        position: "relative",
                                        flexShrink: 0,
                                      }}
                                    >
                                      {file.name.length > 30
                                        ? `${file.name.slice(0, 30)}...`
                                        : file.name}
                                    </Tag>
                                  ))}
                                </Row>
                              )}
                              {attachedImages && attachedImages.length > 0 && (
                                <Row
                                  style={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    gap: "4px",
                                    width: "100%",
                                    padding: "4px",
                                  }}
                                >
                                  {attachedImages.map((file, index) => (
                                    <Tag
                                      closable
                                      key={index}
                                      onClose={() => {
                                        if (currentTaskId) {
                                          handleFileDelete(file.id, "image");
                                        } else {
                                          const updatedImages = attachedImages.filter(
                                            (_, i) => i !== index
                                          );
                                          setAttachedImages(updatedImages);
                                        }
                                      }}
                                      icon={<PictureOutlined />}
                                      style={{
                                        margin: 0,
                                        display: "inline-flex",
                                        alignItems: "center",
                                        maxWidth: "100%",
                                        position: "relative",
                                        flexShrink: 0,
                                      }}
                                    >
                                      {file.name.length > 30
                                        ? `${file.name.slice(0, 30)}...`
                                        : file.name}
                                    </Tag>
                                  ))}
                                </Row>
                              )}
                            </div>
                            <div style={{ flexGrow: 1 }}>
                              <Form.Item name="taskDetails" noStyle>
                                <Input.TextArea
                                  ref={textareaRef}
                                  onChange={e => {
                                    form.setFieldsValue({
                                      taskDetails: e.target.value,
                                    });
                                  }}
                                  maxLength={3000}
                                  placeholder="Enter task details"
                                  style={{
                                    border: "none",
                                    borderRadius: "10px",
                                    outline: "none",
                                    resize: "vertical",
                                    minHeight: 2,
                                    width: "100%",
                                    padding: "10px",
                                    boxShadow: "none",
                                    marginTop: -30,
                                  }}
                                />
                              </Form.Item>
                            </div>
                          </>
                        )) || (
                          <div style={{ flexGrow: 1 }}>
                            <Form.Item name="taskDetails" noStyle>
                              <Input.TextArea
                                ref={textareaRef}
                                onChange={e => {
                                  form.setFieldsValue({
                                    taskDetails: e.target.value,
                                  });
                                }}
                                maxLength={3000}
                                placeholder="Enter task details"
                                style={{
                                  border: "none",
                                  outline: "none",
                                  resize: "vertical",
                                  minHeight: 2,
                                  width: "100%",
                                  padding: "10px",
                                  boxShadow: "none",
                                }}
                              />
                            </Form.Item>
                          </div>
                        )}

                        {/* Hidden files input */}
                        <input
                          type="file"
                          ref={fileInputRef}
                          style={{ display: "none" }}
                          multiple
                          accept=".doc, .docx, .pdf, .txt, .xls, .xlsx, .ppt, .pptx, .csv"
                          onChange={file => {
                            const newFiles = Array.from(file.target.files);
                            if (newFiles.length > 0) {
                              setAttachedFiles(prevFiles => [
                                ...prevFiles,
                                ...newFiles,
                              ]);
                              if (currentTaskId) {
                                setNewAttachedFiles(prevFiles => [
                                  ...prevFiles,
                                  ...newFiles,
                                ]);
                              }
                            }
                          }}
                        />

                        <input
                          type="file"
                          ref={imageInputRef}
                          style={{ display: "none" }}
                          multiple
                          accept=".jpg, .jpeg, .png, .gif, .avif"
                          onChange={async image => {
                            const files = Array.from(
                              image.target.files
                            ).filter(file => file.type.startsWith("image/"));
                            setAttachedImages(prevFiles => [
                              ...prevFiles,
                              ...files,
                            ]);
                            if (currentTaskId) {
                              setNewAttachedImages(prevFiles => [
                                ...prevFiles,
                                ...files,
                              ]);
                            }
                          }}
                        />
                      </Row>

                      <Row style={{ paddingTop: "10px" }}>
                        <PaperClipOutlined
                          style={{
                            paddingInline: "5px",
                            color: "gray",
                            fontSize: "20px",
                          }}
                          onClick={() => {
                            fileInputRef.current.click();
                          }}
                        />
                        <PictureOutlined
                          style={{
                            paddingInline: "5px",
                            color: "gray",
                            fontSize: "20px",
                          }}
                          onClick={() => {
                            imageInputRef.current.click();
                          }}
                        />
                        <CodeOutlined
                          style={{
                            paddingInline: "5px",
                            color: "gray",
                            fontSize: "20px",
                          }}
                          onClick={handleInsertCode}
                        />
                      </Row>
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
            </Col>

            {/* Action Button */}
            <Col
              span={16}
              style={{ display: "flex", justifyContent: "right", gap: "10px" }}
            >
              {level === "PLATFORM" && (
                <Col style={{ paddingInline: "5px" }}>
                  <Button onClick={() => history.goBack()}>Back</Button>
                </Col>
              )}
              <Button
                type="primary"
                onClick={async () => {
                  setButtonDisabled(true);
                  try {
                    await handleSubmit();
                  } catch (error) {
                    if (error.errorFields) {
                      const errorMessages = error.errorFields
                        .map(field => field.errors.join(", "))
                        .join(", ");
                      message.destroy();
                      message.error(errorMessages);
                    } else {
                      message.error(error.message || "An error occurred");
                    }
                  } finally {
                    setButtonDisabled(false);
                  }
                }}
                disabled={buttonDisabled}
              >
                {currentTaskId ? "Update Task" : "Create Task"}
              </Button>
            </Col>
          </Row>

          {taskDetailVisible && (
            <TaskDatePickerModal
              visible={taskDetailVisible}
              setVisible={setTaskDatePickerVisible}
              currentDueDateConfig={dueDateRecurringConfig}
              onClose={values => {
                setDueDateRecurringConfig(values);
                form.setFieldsValue({ dueDate: values.dueDate });
              }}
            />
          )}

          {/* Create hidden relatedCampaigns Form.Item Input */}
          <Form.Item name="relatedCampaigns">
            <Input type="hidden" />
          </Form.Item>
        </Form>
      </PageContentLayout>

      {showCodeSnippetModal && (
        <TaskCodeSnippetModal
          visible={showCodeSnippetModal}
          setVisible={setShowCodeSnippetModal}
          onEmbedSnippet={codeSnippet => {
            setAttachedCodeSnippet(codeSnippet);
          }}
        />
      )}

      {showRelatedCampaignModal && (
        <TaskRelatedCampaignsModal
          visible={showRelatedCampaignModal}
          setVisible={setRelatedCampaignModal}
          channelPartnerOrgId={
            level === "CHANNEL_PARTNER"
              ? currentUser.role.org.id
              : form.getFieldValue("channelPartnerId")
          }
          selectedCampaigns={relatedCampaigns}
          onAddSelections={selectedCampaignOrders => {
            form.setFieldsValue({ relatedCampaigns: selectedCampaignOrders });
          }}
        />
      )}
    </>
  );
};

export default withRouter(TaskForm);
