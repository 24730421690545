import React, { useState } from "react";
import { Tooltip, Button, Menu, Dropdown, Checkbox, Row, Col } from "antd";
//Global Styling
import { MenuItemText } from "../../../shared/globalStyling/styledText";
import { MoreOutlined, CloseCircleOutlined } from "@ant-design/icons";

const TableColumnsDropDown = ({
  possibleFields,
  onTableColChange,
  selectedTableColumns,
}) => {
  const fields = Object.keys(possibleFields);
  const [visible, setVisible] = useState(false);
  return (
    <Dropdown
      visible={visible}
      placement="bottomCenter"
      overlay={
        <Menu selectedKeys={selectedTableColumns}>
          {fields &&
            fields.map(key => (
              <Menu.Item key={key} onClick={() => onTableColChange(key)}>
                <Checkbox
                  checked={
                    selectedTableColumns.findIndex(el => el === key) !== -1
                      ? true
                      : false
                  }
                />
                <MenuItemText text={possibleFields[key].title} />
              </Menu.Item>
            ))}
          <Menu.Item
            key="close"
            style={{ backgroundColor: "#1890ff", color: "white" }}
            onClick={() => setVisible(false)}
          >
            <Row>
              <Col span={2}>
                <CloseCircleOutlined />
              </Col>
              <Col offset={2} span={20}>
                Close Dropdown
              </Col>
            </Row>
          </Menu.Item>
        </Menu>
      }
    >
      <Tooltip title="Additional columns">
        <Button
          ghost
          onClick={() => setVisible(!visible)}
          shape="circle"
          icon={<MoreOutlined />}
          style={{ color: "black", border: "none" }}
        />
      </Tooltip>
    </Dropdown>
  );
};

export { TableColumnsDropDown };
