import React, { useEffect, useState } from "react";
import {
  Table,
  Card,
  Tooltip,
  Avatar,
  Select,
  Row,
  Col,
  Typography,
  Skeleton,
  Input,
} from "antd";
import { withRouter } from "react-router-dom";
import {
  PrimaryTableRowText,
  TableColumnTitle,
} from "../../../../shared/globalStyling/styledText";
import ClicksSubTable from "./ClicksTimeline";
import moment from "moment";
import { isEmpty } from "lodash";
import { formatCurrency2SigFig } from "../../../../../core/utils/campaigns";
import cnnaid from "../../../../../assets/icons/transactions/cnnaid.png";
import cnnaip from "../../../../../assets/icons/transactions/cnnaip.png";
import cnnafingerprint from "../../../../../assets/icons/transactions/cnnafingerprint.png";
import { ReactComponent as GlobePhoneIcon } from "../../../../../assets/icons/transactions/globephone.svg";

const { Search } = Input;
const { Text } = Typography;
const TransactionsTable = ({
  data = [],
  loading = false,
  filterLoading,
  total,
  history,
  location,
  match,
}) => {
  const { params } = match;
  const { pageNumber } = params;
  const urlParams = new URLSearchParams(location.search);
  const method = urlParams.get("method") ? urlParams.get("method") : "All";
  const pageViews = urlParams.get("pageViews")
    ? urlParams.get("pageViews")
    : "All";
  const url = urlParams.get("url") ? urlParams.get("url") : "";

  const [current, setCurrent] = useState(pageNumber);
  useEffect(() => {
    setCurrent(pageNumber);
  }, [pageNumber]);
  const columns = [
    {
      title: <TableColumnTitle text={"Order"} />,
      index: "orderId",
      key: "orderId",
      render: (text, { orderId }) => {
        return <PrimaryTableRowText text={orderId} />;
      },
    },
    {
      title: <TableColumnTitle text={"Last Page Viewed On"} />,
      index: "lastClick",
      key: "lastClick",
      render: (text, { clicksList }) => {
        const lastClick = clicksList.reduce((acc, curr) => {
          if (!acc) acc = moment(curr.viewTime);
          if (acc < moment(curr.viewTime)) {
            return moment(curr.viewTime);
          }
          return acc;
        }, null);
        return (
          <PrimaryTableRowText
            text={moment(lastClick).format("YYYY-MM-DD - H:mm:ss")}
          />
        );
      },
    },
    {
      title: <TableColumnTitle text={"Page Views"} />,
      index: "clicks",
      key: "clicks",
      render: (text, { clicksList }) => {
        return <PrimaryTableRowText text={clicksList.length} />;
      },
    },
    {
      title: <TableColumnTitle text={"Ordered On"} />,
      index: "orderTime",
      key: "orderTime",
      render: (text, { orderTime }) => {
        return (
          <PrimaryTableRowText
            text={moment(orderTime).format("YYYY-MM-DD - H:mm:ss")}
          />
        );
      },
    },
    {
      title: <TableColumnTitle text={"Total"} />,
      index: "total",
      key: "total",
      render: (text, { total }) => {
        return <PrimaryTableRowText text={formatCurrency2SigFig(total)} />;
      },
    },
    {
      title: <TableColumnTitle text={"Page Purchased"} />,
      index: "pageURL",
      key: "pageURL",
      render: (text, { pageURL }) => {
        return <PrimaryTableRowText text={pageURL} />;
      },
    },
    {
      title: <TableColumnTitle text={""} />,
      dataIndex: "attributionMethod",
      key: "attributionMethod",
      align: "left",
      width: "8%",
      render: (text, { attributionMethod, attributionValue }) => {
        let attributionIcon = cnnaip;
        let tip = "";
        if (attributionMethod === "COOKIES") {
          attributionIcon = cnnaid;
          tip = `Cookie Match - Deterministic match using browser based cookie / uuid match.`;
        } else if (attributionMethod === "FINGERPRINT") {
          attributionIcon = cnnafingerprint;
          tip = `User Match - Probabilistic match using user fingerprint. (User fingerprint - 
            The combination of user agent and IP address to produce a unique hash)`;
        } else if (attributionMethod === "IP") {
          tip = `IP Match - Probabilistic match using IP address.`;
          attributionIcon = cnnaip;
        } else if (attributionMethod === "DEVICE_FINGERPRINT") {
          tip = `Device Match - Probabilistic match using user device model information (Device model, OS, OS version ) 
          combined with IP address`;
        }

        const attributionIcn = () => {
          if (attributionMethod === "DEVICE_FINGERPRINT") {
            return (
              <span
                role="img"
                className="anticon"
                style={{ verticalAlign: "-5px" }}
              >
                <GlobePhoneIcon
                  height="35"
                  width="35"
                  style={{
                    opacity: "1.0",
                  }}
                />
              </span>
            );
          } else {
            return <Avatar src={attributionIcon} />;
          }
        };

        return (
          <Tooltip title={tip}>
            <Avatar src={attributionIcn()} />
          </Tooltip>
        );
      },
    },
  ];
  return (
    <React.Fragment>
      <Card title={"Transactions"}>
        {loading ? (
          <Skeleton active />
        ) : (
          <>
            <Row justify="end" gutter={[16, 16]}>
              <Col xs={24} md={6} xl={4}>
                <Row gutter={[5, 5]}>
                  <Col span={24}>
                    <Text>Page Views</Text>
                  </Col>
                  <Col span={24}>
                    <Select
                      style={{ width: "100%" }}
                      defaultValue={pageViews}
                      disabled={filterLoading}
                      options={[
                        { label: "All", value: "All" },
                        { label: "≥ 2", value: "2" },
                        { label: "≥ 5", value: "5" },
                        { label: "≥ 10", value: "10" },
                      ]}
                      onChange={value => {
                        const nextPage = location.pathname.split("/");
                        nextPage[4] = 1;
                        const searchParams = new URLSearchParams(
                          location.search
                        );
                        if (searchParams.get("pageViews"))
                          searchParams.delete("pageViews");
                        if (value !== "All") {
                          searchParams.append("pageViews", value);
                        }
                        history.push({
                          pathname: nextPage.join("/"),
                          search: `?${searchParams.toString()}`,
                        });
                      }}
                    />
                  </Col>
                </Row>
              </Col>
              <Col xs={24} md={6} xl={4}>
                <Row gutter={[5, 5]}>
                  <Col span={24}>
                    <Text>Attribution Method</Text>
                  </Col>
                  <Col span={24}>
                    <Select
                      style={{ width: "100%" }}
                      defaultValue={method}
                      disabled={filterLoading}
                      options={[
                        { label: "All", value: "All" },
                        { label: "IP", value: "IP" },
                        { label: "Cookies", value: "COOKIES" },
                        {
                          label: "Device Fingerprint",
                          value: "DEVICE_FINGERPRINT",
                        },
                        { label: "Fingerprint", value: "FINGERPRINT" },
                      ]}
                      onChange={value => {
                        const nextPage = location.pathname.split("/");
                        nextPage[4] = 1;
                        const searchParams = new URLSearchParams(
                          location.search
                        );
                        if (searchParams.get("method"))
                          searchParams.delete("method");
                        if (value !== "All") {
                          searchParams.append("method", value);
                        }
                        history.push({
                          pathname: nextPage.join("/"),
                          search: `?${searchParams.toString()}`,
                        });
                      }}
                    />
                  </Col>
                </Row>
              </Col>
              <Col xs={24} md={6} xl={4}>
                <Row gutter={[5, 5]}>
                  <Col span={24}>
                    <Text>Page View URL</Text>
                  </Col>
                  <Col span={24}>
                    <Search
                      placeholder="Page View URL"
                      defaultValue={url}
                      allowClear
                      onSearch={value => {
                        const nextPage = location.pathname.split("/");
                        nextPage[4] = 1;
                        const searchParams = new URLSearchParams(
                          location.search
                        );
                        searchParams.delete("url");
                        if (value !== "" && value !== null) {
                          searchParams.append("url", value);
                        }
                        history.push({
                          pathname: nextPage.join("/"),
                          search: `?${searchParams.toString()}`,
                        });
                      }}
                      style={{ width: "100%" }}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Table
              dataSource={data}
              columns={columns}
              onChange={paginationObject => {
                const nextPage = location.pathname.split("/");
                nextPage[4] = paginationObject.current;
                const searchParams = new URLSearchParams(
                  window.location.search
                );
                if (searchParams.get("pageSize"))
                  searchParams.delete("pageSize");
                if (
                  !isEmpty(paginationObject) &&
                  paginationObject.pageSize > 10
                ) {
                  searchParams.append("pageSize", paginationObject.pageSize);
                }
                history.push({
                  pathname: nextPage.join("/"),
                  search: `?${searchParams.toString()}`,
                });
              }}
              pagination={{
                position: ["none", "bottomCenter"],
                total: total ? total : data.length,
                responsive: true,
                showSizeChanger: false,
                showLessItems: true,
                size: "default",
                current: Number(current),
              }}
              rowKey={({ orderId }) => orderId}
              scroll={{ x: 1200, y: 1700 }}
              expandable={{
                expandedRowRender: record => {
                  return (
                    <ClicksSubTable
                      clicks={record.clicksList}
                      attributionMethod={record.attributionMethod}
                    />
                  );
                },
              }}
            />
          </>
        )}
      </Card>
    </React.Fragment>
  );
};

export default withRouter(TransactionsTable);
