import React, { useState } from "react";
import { Row, Col, Card, Select, Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import PublishersCTRTable from "./PublisherCTRTable";
import PublisherRingChart from "./PublisherRingChart";
import { prepareCTR } from "../../../../../../../core/utils/campaigns";
// Global Styling
import {
  CardTitleText,
  MenuItemText,
  TableColumnTitle,
} from "../../../../../../shared/globalStyling/styledText";
//colors
import { colors } from "../../../../../../../core/utils/constants/listOfColors";

const { Option } = Select;
const Style = {
  campaignCardStyle: {
    margin: 5,
    padding: 2,
    height: 340,
    backgroundColor: "#fff",
  },
  campaignCardStyleCreatives: {
    backgroundColor: "#fff",
  },
  keyColors: {
    spent: "#1665d8",
    remaining: "#34aa44",
    budget: "#030303",
    total: "#bec0c3",
    value: "#99999",
  },
  title: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  xs: {
    span: 24,
    offset: 0,
  },
  sm: {
    span: 24,
    offset: 0,
  },
  md: {
    span: 16,
    offset: 0,
  },
  lg: {
    span: 16,
    offset: 0,
  },
  xl: {
    span: 16,
    offset: 0,
  },
  xsPie: {
    span: 24,
    offset: 0,
  },
  smPie: {
    span: 24,
    offset: 0,
  },
  mdPie: {
    span: 8,
    offset: 0,
  },
  lgPie: {
    span: 8,
    offset: 0,
  },
  xlPie: {
    span: 8,
    offset: 0,
  },
};

const {
  xs,
  sm,
  md,
  lg,
  xl,
  xsPie,
  smPie,
  mdPie,
  lgPie,
  xlPie,
  keyColors,
  campaignCardStyle,
  campaignCardStyleCreatives,
} = Style;

const options = [
  {
    value: "impressions",
    text: "Impressions",
  },
  {
    value: "CTR",
    text: "CTR Percentage",
  },
  {
    value: "clicks",
    text: "Clicks",
  },
];

const Extra = ({ setPublisherDimension, searchValue, setSearchValue }) => {
  return (
    <Row type="flex" justify="space-between" align="middle">
      <Col style={{ paddingRight: "10px" }} span={8}>
        <CardTitleText text={"Breakdown"} />
      </Col>
      <Col span={16}>
        <Row type="flex" justify="end" align="middle">
          <span style={{ marginRight: "10px" }}>
            <TableColumnTitle text={"Show By:"} />
          </span>
          <Select
            style={{ width: "60%" }}
            defaultValue={options[0].value}
            onChange={value => {
              setPublisherDimension(value);
            }}
          >
            {options.map((option, index) => {
              return (
                <Option key={index} value={option.value}>
                  <MenuItemText text={option.text} />
                </Option>
              );
            })}
          </Select>
        </Row>
      </Col>
    </Row>
  );
};

const PublisherDetails = props => {
  const {
    selectedPublisherDimension,
    setPublisherDimension,
    publisherData,
  } = props;

  const [searchValue, setSearchValue] = useState("");

  const parsedData = prepareCTR(publisherData);
  const colorValuePairs = parsedData.map((el, index) => {
    if (el.xAxis) {
      return {
        ...el,
        value: el.xAxis,
        color: colors[index],
      };
    } else if (el.value) {
      return {
        ...el,
        color: colors[index],
      };
    }
    return {
      ...el,
      value: `Publisher ${index}`,
      color: colors[index],
    };
  });

  const filteredData = colorValuePairs.filter(item =>
    item.value.toLowerCase().includes(searchValue.toLowerCase())
  );

  return (
    <Row gutter={35}>
      <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
        <Card
          style={campaignCardStyleCreatives}
          headStyle={{ borderWidth: "3px", height: "66px" }}
          bodyStyle={{ padding: 0 }}
          title={<CardTitleText text={"Publishers"} />}
          extra={
            <Input
              placeholder="Search Publishers..."
              value={searchValue}
              onChange={e => setSearchValue(e.target.value)}
              style={{ width: "100%" }}
              addonBefore={<SearchOutlined />}
            />
          }
        >
          <PublishersCTRTable publisherData={filteredData} />
        </Card>
      </Col>
      <Col xs={xsPie} sm={smPie} md={mdPie} lg={lgPie} xl={xlPie}>
        <Card
          style={campaignCardStyleCreatives}
          bodyStyle={{ padding: 0, height: "100%" }}
          headStyle={{ borderWidth: "2px", height: "66px" }}
          title={
            <Extra
              setPublisherDimension={setPublisherDimension}
              searchValue={searchValue}
              setSearchValue={setSearchValue}
            />
          }
        >
          <PublisherRingChart
            data={filteredData}
            keyColors={keyColors}
            height={campaignCardStyle.height}
            selectedPublisherDimension={selectedPublisherDimension}
          />
        </Card>
      </Col>
    </Row>
  );
};

export default PublisherDetails;
