import React, { useEffect, useState } from "react";
import moment from "moment";

const AdvertiserDetailsSignupsController = props => {
  const { signedURL, advertiser, setReport, setCreatives, creatives } = props;
  const [reportDataSummary, setReportData] = useState({
    byDates: [],
    signups: [],
    summary: {
      signups: 0,
    },
  });
  const [reportDataRawSummary, setReportRawData] = useState();
  const [reportDataSummaryLoading, setReportLoading] = useState(true);

  useEffect(() => {
    if (signedURL) {
      const s3UrlSummary = new URL(signedURL.getSignedURL);
      fetch(s3UrlSummary).then(async response => {
        if (response && response.ok) {
          try {
            await response.json().then(json => {
              if (json) {
                let report = [];
                if (json.byDates) {
                  report.push({
                    name: "Signups - By Dates",
                    value: json.byDates,
                  });
                }

                if (json.signups) {
                  console.log(json);
                  report.push({
                    name: "Signups - Signups List",
                    value: json.signups.map(s => ({
                      "Sign Up ID": s.signupId,
                      "Last Impression":
                        s.completeFirstImpression.IMPRESSION_TIME,
                      "Total Impressions": s.totalImpressions,
                      "Signed Up On": s.signups_tstamp,
                      "Attribution Method": Object.keys(s.attributionMethod)[0]
                        .toLowerCase()
                        .replace(/_/g, " ") // Replace underscores with spaces
                        .replace(/\b\w/g, c => c.toUpperCase()), // Capitalize first letter of each word,
                      Impressions: JSON.stringify(s.impressions),
                    })),
                  });
                }
                setReport(report);
              }

              let creativesRaw = [];
              json.signups = json.signups.map(s => {
                return {
                  ...s,
                  impressions: s.impressions.map(i => {
                    const maskCreative = creatives.find(
                      c => c.creativeID === i.CREATIVEID
                    );

                    const creativeExists = creatives.findIndex(
                      c => c.name === i.CREATIVEID
                    );
                    if (creativeExists === -1) {
                      creativesRaw.push({
                        name: i.CREATIVEID,
                      });
                    }

                    return {
                      ...i,
                      CREATIVEURL: maskCreative ? maskCreative.url : "",
                      CREATIVEID: maskCreative
                        ? maskCreative.name
                        : i.CREATIVEID,
                    };
                  }),
                };
              });

              setCreatives(creativesRaw);
              setReportData(json);
              setReportRawData(json);
              setReportLoading(false);
            });
          } catch (err) {
            console.log(err);
            setReportLoading(false);
          }
        } else {
          setReportLoading(false);
        }
      });
    }
  }, [creatives, setCreatives, setReport, signedURL]);

  const changeDatePicker = dates => {
    const datePickerStartDate = dates[0].format("YYYY-MM-DD");
    const datePickerEndDate = dates[1].format("YYYY-MM-DD");

    const { byDates, signups } = reportDataRawSummary;

    const filteredByDates = byDates.filter(
      date =>
        moment(date.date).isSameOrBefore(datePickerEndDate) &&
        moment(date.date).isSameOrAfter(datePickerStartDate)
    );

    const filteredSignups = signups.filter(
      su =>
        moment(su.signups_tstamp).isSameOrAfter(datePickerStartDate) &&
        moment(su.signups_tstamp).isSameOrBefore(datePickerEndDate)
    );

    let summary = {
      signups: 0,
      impressions: 0,
      totalImpressions: 0,
    };

    filteredSignups.forEach(su => {
      summary.impressions += parseFloat(su.totalImpressions);
      summary.signups += 1;
    });

    summary.totalImpressions = filteredByDates.reduce(
      (acc, curr) => (acc += curr.totalImpressions),
      0
    );

    let report = [];
    if (filteredByDates) {
      report.push({
        name: "Signups - By Dates",
        value: filteredByDates,
      });
    }

    if (filteredSignups) {
      report.push({
        name: "Signups - Signups List",
        value: filteredSignups.map(s => ({
          "Sign Up ID": s.signupId,
          "Last Impression": s.completeFirstImpression.IMPRESSION_TIME,
          "Total Impressions": s.totalImpressions,
          "Signed Up On": s.signups_tstamp,
          "Attribution Method": Object.keys(s.attributionMethod)[0]
            .toLowerCase()
            .replace(/_/g, " ") // Replace underscores with spaces
            .replace(/\b\w/g, c => c.toUpperCase()), // Capitalize first letter of each word,
          Impressions: JSON.stringify(s.impressions),
        })),
      });
      setReport(report);
    }

    setReportData({
      byDates: filteredByDates,
      signups: filteredSignups,
      summary: {
        ...summary,
      },
    });
  };

  return React.cloneElement(props.children, {
    ...props.children.props,
    reportDataSummary,
    advertiser,
    reportDataSummaryLoading,
    changeDatePicker,
  });
};

export default AdvertiserDetailsSignupsController;
