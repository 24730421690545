import React from "react";
import { Card, Col, Tag, Row, Space, Typography } from "antd";
import moment from "moment";
import { formatWholeNumberNaN } from "../../../../../core/utils/campaigns";
import { CopyBlock } from "react-code-blocks";
import GenTagInstructionsModal from "./GenTagInstructionsModal";
import GeneratedImagePixelTagModal from "./GeneratedImagePixelTagModal";

const { Text } = Typography;

const typePicker = (date, value) => {
  let color = "success";
  if (!date) {
    color = "secondary";
  } else if (date && value < 1) {
    color = "danger";
  }

  return color;
};

const colorPicker = (date, value) => {
  let color = "success";
  if (!date) {
    color = "default";
  } else if (date && value < 1) {
    color = "error";
  }

  return color;
};

const GeneratedTagsCard = ({ generatedTag, type, details }) => {
  const {
    pageViews,
    transactions,
    basketItems,
    date,
    tag,
    cart,
  } = generatedTag;

  const { appId } = details;
  const generatedTagValue = tag ? tag.generatedTag : "";

  const tagCards = () => {
    if (type === "measurement") {
      return (
        <>
          <Col xs={24} sm={12} md={8} lg={6}>
            <Tag
              key={1}
              color={colorPicker(date, pageViews)}
              style={{ width: "100%" }}
            >
              <Space direction="vertical" size={0}>
                <Text type={typePicker(date, pageViews)} strong>
                  Page Views
                </Text>
                <Text type={typePicker(date, pageViews)} strong>
                  {formatWholeNumberNaN(pageViews)}
                </Text>
                <Text
                  type={typePicker(date, pageViews)}
                  disabled
                  style={{
                    whiteSpace: "normal",
                    wordBreak: "break-word",
                    width: "100%",
                  }}
                >
                  {date
                    ? moment(date).format("YYYY/MM/DD - HH:mm:ss")
                    : "Signal Unknown"}
                </Text>
              </Space>
            </Tag>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6}>
            <Tag
              key={1}
              color={colorPicker(date, transactions)}
              style={{ width: "100%" }}
            >
              <Space direction="vertical" size={0}>
                <Text type={typePicker(date, transactions)} strong>
                  Transactions
                </Text>
                <Text type={typePicker(date, transactions)} strong>
                  {formatWholeNumberNaN(transactions)}
                </Text>
                <Text
                  type={typePicker(date, transactions)}
                  disabled
                  style={{
                    whiteSpace: "normal",
                    wordBreak: "break-word",
                    width: "100%",
                  }}
                >
                  {date
                    ? moment(date).format("YYYY/MM/DD - HH:mm:ss")
                    : "Signal Unknown"}
                </Text>
              </Space>
            </Tag>
          </Col>
          {hasBasketItems && (
            <Col xs={24} sm={12} md={8} lg={6}>
              <Tag
                key={1}
                color={colorPicker(date, basketItems)}
                style={{ width: "100%" }}
              >
                <Space direction="vertical" size={0}>
                  <Text type={typePicker(date, basketItems)} strong>
                    Basket Items
                  </Text>
                  <Text type={typePicker(date, basketItems)} strong>
                    {formatWholeNumberNaN(basketItems)}
                  </Text>
                  <Text type={typePicker(date, basketItems)} disabled>
                    {date
                      ? moment(date).format("YYYY/MM/DD - HH:mm:ss")
                      : "Signal Unknown"}
                  </Text>
                </Space>
              </Tag>
            </Col>
          )}
        </>
      );
    } else if (type === "publisher") {
      return (
        <Col xs={24} sm={12} md={8} lg={6}>
          <Tag
            key={1}
            color={colorPicker(date, pageViews)}
            style={{ width: "100%" }}
          >
            <Space direction="vertical" size={0}>
              <Text type={typePicker(date, pageViews)} strong>
                Page Views
              </Text>
              <Text type={typePicker(date, pageViews)} strong>
                {formatWholeNumberNaN(pageViews)}
              </Text>
              <Text
                type={typePicker(date, pageViews)}
                disabled
                style={{
                  whiteSpace: "normal",
                  wordBreak: "break-word",
                  width: "100%",
                }}
              >
                {date
                  ? moment(date).format("YYYY/MM/DD - HH:mm:ss")
                  : "Signal Unknown"}
              </Text>
            </Space>
          </Tag>
        </Col>
      );
    } else if (type === "impression") {
      return (
        <Col xs={24} sm={12} md={8} lg={6}>
          <Tag
            key={1}
            color={colorPicker(date, pageViews)}
            style={{ width: "100%" }}
          >
            <Space direction="vertical" size={0}>
              <Text type={typePicker(date, pageViews)} strong>
                Impressions
              </Text>
              <Text type={typePicker(date, pageViews)} strong>
                {formatWholeNumberNaN(pageViews)}
              </Text>
              <Text
                type={typePicker(date, pageViews)}
                disabled
                style={{
                  whiteSpace: "normal",
                  wordBreak: "break-word",
                  width: "100%",
                }}
              >
                {date
                  ? moment(date).format("YYYY/MM/DD - HH:mm:ss")
                  : "Signal Unknown"}
              </Text>
            </Space>
          </Tag>
        </Col>
      );
    }
  };

  const hasBasketItems = basketItems > 0;
  return (
    <Card
      title={`GENERATED TAG`}
      extra={
        <GenTagInstructionsModal cart={cart} generatedTag={generatedTagValue} />
      }
    >
      <Row gutter={[12, 12]}>
        <Col span={12}>
          <Text type="secondary">
            Results shown below may be delayed up to 1 hour
          </Text>
        </Col>
        <Col span={4} offset={8}>
          <GeneratedImagePixelTagModal cart={cart} appId={appId} />
        </Col>
        {tagCards()}

        <Col span={24}>
          <Card style={{ overflowX: "auto" }}>
            <CopyBlock
              text={generatedTagValue}
              language="javascript"
              wrapLongLines
              theme="dracula"
            />
          </Card>
        </Col>
      </Row>
    </Card>
  );
};

export default GeneratedTagsCard;
