import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Row,
  Col,
  Tabs,
  Modal,
  Form,
  Radio,
  Divider,
  Tooltip,
  Badge,
} from "antd";
import { PageHeaderLayout, PageContentLayout } from "../../../shared/layout";
import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  LinkOutlined,
} from "@ant-design/icons";
import { withRouter, useParams } from "react-router-dom";
import Text from "antd/lib/typography/Text";
import { TaskComments } from "./TaskComments";
import { useTaskContext } from "../../../../core/components/tasks/useTaskContext";
import { statusTypes, urgencyColors } from "../configs/task.config";
import OverlaySpinner from "../../../shared/OverlaySpinner";
import { Histories } from "../../../shared/Histories";
import AvatarS3 from "../../../shared/images/AvatarS3";
import { startCase } from "lodash";

const { TabPane } = Tabs;

const TaskDetailsView = ({ history }) => {
  const [form] = Form.useForm();
  const { id: currentTaskId } = useParams();
  const [activeTab, setActiveTab] = useState("1");
  const [insideTab, setInsideTab] = useState("1");

  const handleTabChange = key => {
    setActiveTab(key);

    if (key === "2" && currentTaskId) {
      fetchHistories({
        variables: {
          where: {
            referenceId: currentTaskId,
            type: "TASK",
          },
        },
      });
    }
  };

  const handleInsideTabChange = key => {
    setInsideTab(key);
  };

  const [visibleTaskUpdate, setVisibleTaskUpdate] = useState(false);

  const {
    currentUser,
    updateTask,
    updateTaskLoading,
    currentTask,
    fetchTask,
    taskLoading,
    fetchHistories,
    histories,
    loadingHistories,
  } = useTaskContext();

  useEffect(() => {
    if (currentTaskId) {
      fetchTask({
        variables: {
          where: {
            id: currentTaskId,
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!currentTaskId) return <p>Select a task to see the details.</p>;

  if (taskLoading) {
    return <OverlaySpinner />;
  }

  const level =
    currentUser &&
    currentUser.role &&
    currentUser.role.org &&
    currentUser.role.org.level;

  const handleUpdate = async () => {
    try {
      const values = await form.validateFields();
      await updateTask({
        variables: {
          data: {
            status: values.status,
          },
          where: { id: currentTaskId },
        },
      });
      await fetchTask({
        variables: {
          where: {
            id: currentTaskId,
          },
        },
      });
      await fetchHistories({
        variables: {
          where: { referenceId: currentTaskId },
        },
      });
      setVisibleTaskUpdate(false);
    } catch (error) {
      console.log("Validate Failed:", error);
    }
  };

  return (
    <>
      <PageHeaderLayout
        title={currentTask && currentTask.name}
        titleIcon={
          !currentTask || !currentTask.urgency ? (
            <CheckCircleOutlined
              style={{
                marginRight: "10px",
                fontSize: 25,
              }}
            />
          ) : (
            <Tooltip
              title={
                currentTask.urgency
                  ? `URGENCY: ${currentTask.urgency}`
                  : undefined
              }
            >
              <Badge
                className="custom-badge"
                style={{
                  marginRight: "10px",
                }}
                color={
                  currentTask ? urgencyColors[currentTask.urgency] : "white"
                }
              />
            </Tooltip>
          )
        }
        titleDescription={
          currentTask && currentTask.status === null
            ? "N/A"
            : currentTask && currentTask.status.replace(/_/g, " ").toUpperCase()
        }
        titleDescriptionStyle={{ marginLeft: "10px" }}
        rightContent={
          <Row>
            {/* Conditionally render Edit and Update buttons only for 'standard' level */}
            {level !== "CHANNEL_PARTNER" && (
              <>
                <Col style={{ paddingInline: "5px" }}>
                  <Button
                    onClick={() => history.push(`/tasks/form/${currentTaskId}`)}
                  >
                    Edit Task
                  </Button>
                </Col>
                <Col style={{ paddingInline: "5px" }}>
                  <Button
                    onClick={() => {
                      setVisibleTaskUpdate(true);
                    }}
                  >
                    Update Status
                  </Button>
                </Col>
              </>
            )}

            {/* Back button available for both levels */}
            <Col style={{ paddingInline: "5px" }}>
              <Button onClick={() => history.goBack()}>Back</Button>
            </Col>
          </Row>
        }
        style={{ height: "80px", border: "none" }}
      />
      <Row
        style={{
          backgroundColor: "white",
          paddingLeft: "3%",
          height: "45px",
          border: "none",
        }}
      >
        <Tabs activeKey={activeTab} onChange={handleTabChange}>
          <TabPane tab="Details" key="1" />
          <TabPane tab="History" key="2" />
        </Tabs>
      </Row>

      {/* Content Layout where tab content is rendered */}
      <PageContentLayout>
        {activeTab === "1" && (
          <Row style={{ justifyContent: "center" }}>
            <Col span={12}>
              {/* TASK COMMMENT */}
              <TaskComments
                currentTask={currentTask}
                currentTaskId={currentTaskId}
              />
            </Col>
            <Col span={7} style={{ paddingLeft: "40px" }}>
              <Card style={{ borderRadius: "7px", minHeight: "82vh" }}>
                <Tabs
                  activeKey={insideTab}
                  onChange={handleInsideTabChange}
                  tabBarStyle={{
                    margin: 0,
                    marginBottom: 15,
                  }}
                  tabBarExtraContent={
                    currentTask &&
                    currentTask.linkUrl && (
                      <Tooltip title={currentTask.linkUrl}>
                        <LinkOutlined
                          style={{ fontSize: 16, cursor: "pointer" }}
                          onClick={() =>
                            window.open(currentTask.linkUrl, "_blank")
                          }
                        />
                      </Tooltip>
                    )
                  }
                >
                  <TabPane tab="Details" key="1" />
                </Tabs>

                {insideTab === "1" && (
                  <>
                    <Col style={{ display: "block", paddingRight: "3vw" }}>
                      <span
                        style={{
                          display: "block",
                          color: "gray",
                          paddingBottom: "5px",
                          fontSize: "15px",
                        }}
                      >
                        Created On
                      </span>
                      <ClockCircleOutlined
                        size={20}
                        style={{ color: "gray", paddingRight: "5px" }}
                      />
                      <Text style={{ fontSize: "14px" }}>
                        {currentTask && currentTask.createdAt
                          ? new Date(currentTask.createdAt).toLocaleDateString()
                          : "N/A"}
                      </Text>
                    </Col>
                    <Divider style={{ marginBlock: 10 }} />
                    <Col style={{ display: "block", paddingRight: "3vw" }}>
                      <span
                        style={{
                          display: "block",
                          color: "gray",
                          paddingBottom: "5px",
                          fontSize: "15px",
                        }}
                      >
                        Requested By
                      </span>
                      <Text style={{ fontSize: "14px" }}>
                        {startCase(
                          currentTask &&
                            currentTask.createdBy &&
                            (currentTask.createdBy.name ||
                              currentTask.createdBy.username ||
                              "Unknown")
                        )}
                      </Text>
                    </Col>
                    <Divider style={{ marginBlock: 10 }} />
                    <Col style={{ display: "block", paddingRight: "3vw" }}>
                      <span
                        style={{
                          display: "block",
                          color: "gray",
                          paddingBottom: "5px",
                          fontSize: "15px",
                        }}
                      >
                        Channel Partner
                      </span>
                      <Text style={{ fontSize: "14px" }}>
                        {currentTask && currentTask.channelPartner
                          ? currentTask.channelPartner &&
                            currentTask.channelPartner.name
                          : "-"}
                      </Text>
                    </Col>
                    <Divider style={{ marginBlock: 10 }} />
                    <Col style={{ display: "block", paddingRight: "3vw" }}>
                      <span
                        style={{
                          display: "block",
                          color: "gray",
                          paddingBottom: "5px",
                          fontSize: "15px",
                        }}
                      >
                        Advertiser
                      </span>
                      <Text style={{ fontSize: "14px" }}>
                        {currentTask && currentTask.advertiser
                          ? currentTask.advertiser &&
                            currentTask.advertiser.name
                          : "-"}
                      </Text>
                    </Col>
                    <Divider style={{ marginBlock: 10 }} />
                    <Col style={{ display: "block", paddingRight: "2.5vw" }}>
                      <span
                        style={{
                          display: "block",
                          color: "gray",
                          paddingBottom: "5px",
                          fontSize: "15px",
                        }}
                      >
                        Request Type
                      </span>
                      <Text style={{ fontSize: "14px" }}>
                        {(currentTask && currentTask.requestType) || "-"}
                      </Text>
                    </Col>
                    <Divider style={{ marginBlock: 10 }} />
                    <Col style={{ display: "block", paddingRight: "3vw" }}>
                      <span
                        style={{
                          display: "block",
                          color: "gray",
                          paddingBottom: "5px",
                          fontSize: "15px",
                        }}
                      >
                        Request Details
                      </span>
                      <Text style={{ fontSize: "14px" }}>
                        {currentTask && currentTask.requestSubType
                          ? currentTask.requestSubType
                          : "-"}
                      </Text>
                    </Col>
                    <Divider style={{ marginBlock: 10 }} />
                    <Col style={{ display: "block", paddingRight: "2.5vw" }}>
                      <span
                        style={{
                          display: "block",
                          color: "gray",
                          paddingBottom: "5px",
                          fontSize: "15px",
                        }}
                      >
                        Assignee Org
                      </span>
                      <Text style={{ fontSize: "14px" }}>
                        {currentTask && currentTask.assigneeOrg
                          ? currentTask.assigneeOrg &&
                            currentTask.assigneeOrg.name
                          : "-"}
                      </Text>
                    </Col>
                    <Divider style={{ marginBlock: 10 }} />
                    <Col
                      style={{
                        display: "block",
                        paddingRight: "3vw",
                        overflow: "hidden",
                      }}
                    >
                      <span
                        style={{
                          display: "block",
                          color: "gray",
                          paddingBottom: "5px",
                          fontSize: "15px",
                        }}
                      >
                        Assignee
                      </span>
                      <Text style={{ fontSize: "14px" }}>
                        {currentTask &&
                        currentTask.assigneeUsers &&
                        currentTask.assigneeUsers.length > 0
                          ? currentTask.assigneeUsers.map((user, index) => (
                              <Row
                                key={index}
                                style={{
                                  alignItems: "center",
                                  marginBottom: "3px",
                                  display: "flex",
                                  gap: "7px",
                                }}
                              >
                                <AvatarS3
                                  imgKey={user.avatar && user.avatar.key}
                                  username={user.name || "-"}
                                  avatarProps={{ size: 20 }}
                                />
                                {startCase(user.name || user.username)}
                              </Row>
                            ))
                          : startCase(
                              currentTask &&
                                currentTask.assigneeUser &&
                                currentTask.assigneeUser.name
                            ) || "-"}
                      </Text>
                    </Col>
                  </>
                )}
              </Card>
            </Col>
          </Row>
        )}
        {activeTab === "2" && (
          <Row>
            <Col span={14}>
              <Histories histories={histories} loading={loadingHistories} />
            </Col>
          </Row>
        )}
      </PageContentLayout>

      {visibleTaskUpdate && (
        <Modal
          closable
          onCancel={() => setVisibleTaskUpdate(false)}
          width={"250px"}
          visible={visibleTaskUpdate}
          title="TASK STATUS"
          footer={[
            <Button
              key="update"
              type="primary"
              onClick={handleUpdate}
              disabled={updateTaskLoading}
              loading={taskLoading}
            >
              {updateTaskLoading ? "Updating..." : "Update"}
            </Button>,
          ]}
        >
          <Form form={form} layout="vertical">
            <Row justify="center">
              <Form.Item
                name="status"
                label={
                  <Text style={{ paddingBottom: "10px" }}>
                    Set the task status below
                  </Text>
                }
              >
                <Radio.Group
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "200px",
                  }}
                >
                  {Object.keys(statusTypes).map(status => (
                    <Radio
                      key={statusTypes[status]}
                      value={statusTypes[status]}
                      style={{ height: "25px", padding: "15px" }}
                    >
                      {status}
                    </Radio>
                  ))}
                </Radio.Group>
              </Form.Item>
            </Row>
          </Form>
        </Modal>
      )}
    </>
  );
};

export default withRouter(TaskDetailsView);
