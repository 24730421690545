import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { btnS } from "../../../../shared/globalStyling/style";
import { Input, Space } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import GlobalButton from "../../../../shared/globalStyling/Button";

//Global Search Bar design usally used on main tables.
const MeasurementTableSearch = ({ location, history }) => {
  const params = new URLSearchParams(location.search);
  const tagIdSearch = params.get("tagId");
  const nameSearch = params.get("name");

  const [tagIdSearchValues, setTagIdSearchValue] = useState();
  const [nameSearchValues, setNameSearchValue] = useState();

  return (
    <Space
      className="ant-space-xs-vertical ant-space-md-horizontal"
      size={"small"}
    >
      <Input
        placeholder={"Search By Tag ID"}
        value={tagIdSearchValues}
        onChange={e => setTagIdSearchValue(e.target.value)}
        onPressEnter={val => {
          const searchValue = val.target.value;
          if (tagIdSearch) params.delete("tagId");

          if (searchValue !== "") {
            params.append("tagId", searchValue);
          } else {
            params.delete("tagId");
          }

          history.push({
            pathname: location.pathname,
            search: `?${params.toString()}`,
          });
        }}
        defaultValue={tagIdSearch}
      />
      <Input.Search
        placeholder={"Search By Name"}
        value={nameSearchValues}
        onChange={e => setNameSearchValue(e.target.value)}
        onSearch={searchValue => {
          if (nameSearch) {
            params.delete("name");
          }

          if (searchValue !== "") {
            params.append("name", searchValue);
          } else {
            params.delete("name");
          }

          history.push({
            pathname: location.pathname,
            search: `?${params.toString()}`,
          });
        }}
        defaultValue={nameSearch}
      />

      <GlobalButton
        shape="round"
        style={btnS}
        type={"default"}
        onClick={() => {
          if (tagIdSearch) params.delete("tagId");
          if (nameSearch) params.delete("name");

          setNameSearchValue();
          setTagIdSearchValue();
          history.push({
            pathname: location.pathname,
            search: `?${params.toString()}`,
          });
        }}
        disabled={!tagIdSearch && !nameSearch}
        icon={<CloseOutlined />}
      >
        Clear
      </GlobalButton>
    </Space>
  );
};

export default withRouter(MeasurementTableSearch);
