import gql from "graphql-tag";

const UPDATE_IMPRESSION_TAG = gql`
  mutation updateImpressionsTag($data: UpdateImpressionsTagInput!) {
    updateImpressionsTag(data: $data) {
      id
      name
    }
  }
`;

export default UPDATE_IMPRESSION_TAG;
