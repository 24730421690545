import React, { useEffect, useState } from "react";
import Fuse from "fuse.js";
import { PageContentLayout, PageHeaderLayout } from "../../../shared/layout";
import { TagOutlined } from "@ant-design/icons";
import { mainTitleIcon } from "../../../shared/globalStyling/style";
import {
  Button,
  Card,
  Col,
  Row,
  Select,
  Table,
  Tabs,
  Space,
  Input,
  message,
  Typography,
  Tag,
} from "antd";
import DocumentationAlert from "./components/DocumentationAlert";
import MainTable from "./components/MainTable";
import RequestAssistance from "./components/RequestAssitance";
import { withRouter } from "react-router-dom";
import CreateImpressionsTag from "../../SignalTags/main/components/CreateImpressionsTag";
import CreatePublisherDomainTag from "../../SignalTags/main/components/CreatePublsiherDomainTag";
import { formatNumber } from "../../../../core/utils/campaigns";
import MeasurementTableSearch from "./components/MeasurementTableSearch";
const { Search } = Input;
const { Text } = Typography;
const { TabPane } = Tabs;

const performFuzzySearch = (data, searchKey, searchValue) => {
  const options = {
    keys: [searchKey],
    threshold: 0.3, // Adjust the threshold for fuzzy matching
  };

  const fuse = new Fuse(data, options);
  return fuse.search(searchValue).map(result => result.item);
};

const TagsMainView = ({
  history,
  location,
  requestAssistance,
  publishersTags,
  impressionsTags,
  measurementsTags,
  onImressionsTagCreate,
  refetch,
  isAttentionSignals,
  advertisers,
  testTag,
}) => {
  document.title = "Tags Tracking Main View";
  const params = new URLSearchParams(location.search);
  const status = params.get("status") ? params.get("status") : "live";
  const tagId = params.get("tagId");
  const name = params.get("name");

  const [activeTab, setActiveTab] = useState("main");
  const [filteredPublisherTags, setFilteredPublisherTags] = useState();
  const [filteredImpressionTags, setFilteredImpressionTags] = useState();
  const [filteredMeasurementTags, setFilteredMeasurementTags] = useState();

  useEffect(() => {
    setFilteredPublisherTags(publishersTags);
  }, [publishersTags]);

  useEffect(() => {
    setFilteredImpressionTags(impressionsTags);
  }, [impressionsTags]);

  useEffect(() => {
    setFilteredMeasurementTags(measurementsTags);
  }, [measurementsTags]);

  useEffect(() => {
    if (measurementsTags && tagId) {
      const searchTags = performFuzzySearch(measurementsTags, "appId", tagId);
      setFilteredMeasurementTags(searchTags);
    }
  }, [measurementsTags, history, tagId]);

  useEffect(() => {
    if (measurementsTags && name) {
      const searchTags = performFuzzySearch(measurementsTags, "name", name);
      setFilteredMeasurementTags(searchTags);
    }
  }, [measurementsTags, history, name]);

  const publisherColumn = [
    {
      title: "Tag Name",
      dataIndex: "name",
      key: "name",
      width: "25%",
    },
    {
      title: "Advertiser",
      dataIndex: "advertiser",
      key: "advertiser",
      width: "10%",
      ellipsis: true,
    },
    {
      title: "Domain",
      dataIndex: "domain",
      key: "domain",
      width: "15%",
      ellipsis: true,
    },
    {
      title: "Measured Events",
      key: "pageViews",
      width: "10%",
      render: record => {
        return (
          <Tag key={1} color={record.color}>
            <Space direction="vertical" size={0}>
              <Text type={record.typeColor}>Page Views</Text>
              <Text type={record.typeColor} strong>
                {formatNumber(record.pageViews)}
              </Text>
              <Text type="secondary">{record.date}</Text>
            </Space>
          </Tag>
        );
      },
    },
    {
      title: "Tag ID",
      dataIndex: "tagId",
      key: "tagId",
      width: "25%",
      render: text => (
        <Space direction="horizontal">
          <Typography.Paragraph
            copyable
            onClick={event => event.stopPropagation()}
          >
            {text}
          </Typography.Paragraph>
        </Space>
      ),
    },
    {
      title: "Action",
      key: "action",
      width: "10%",
      render: record => (
        <Typography.Link
          onClick={event => {
            event.stopPropagation();
            testTag({
              appId: record.tagId,
              orgId: record.orgId,
              tagId: record.id,
            });
            message.info(
              `Signal Tag Test for ${record.name} initiated. May take a couple of minutes to complete.`
            );
          }}
        >
          Test Signal
        </Typography.Link>
      ),
    },
  ];

  const impressionColumn = [
    {
      title: "Tag Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Platform",
      dataIndex: "dsp",
      key: "dsp",
    },
    {
      title: "Measured Events",
      key: "impressions",
      width: "10%",
      render: record => {
        return (
          <Tag key={1} color={record.color}>
            <Space direction="vertical" size={0}>
              <Text type={record.typeColor}>Impressions</Text>
              <Text type={record.typeColor} strong>
                {formatNumber(record.impressions)}
              </Text>
              <Text type="secondary">{record.date}</Text>
            </Space>
          </Tag>
        );
      },
    },
    {
      title: "Tag ID",
      dataIndex: "tagId",
      key: "tagId",
      render: text => (
        <Space direction="horizontal">
          <Typography.Paragraph
            copyable
            onClick={event => event.stopPropagation()}
          >
            {text}
          </Typography.Paragraph>
        </Space>
      ),
    },
    {
      title: "Action",
      key: "action",
      width: "10%",
      render: record => (
        <Typography.Link
          onClick={event => {
            event.stopPropagation();
            testTag({
              appId: record.tagId,
              orgId: record.orgId,
              tagId: record.id,
            });
            message.info(
              `Signal Tag Test for ${record.name} initiated. May take a couple of minutes to complete.`
            );
          }}
        >
          Test Signal
        </Typography.Link>
      ),
    },
  ];

  const renderMeasurementTagsContent = () => (
    <div>
      <PageHeaderLayout
        title={"Measurement Tags"}
        rightContent={
          <Row align="middle" justify="center" gutter={[5, 5]}>
            <Col>
              <Select
                defaultValue={"live"}
                value={status}
                style={{ width: "120px" }}
                onChange={val => {
                  if (status) {
                    params.delete("status");
                  }
                  params.append("status", val);
                  history.push({
                    pathname: location.pathname,
                    search: `?${params.toString()}`,
                  });
                }}
              >
                <Select.Option value="live">LIVE</Select.Option>
                <Select.Option value="disabled">DISABLED</Select.Option>
              </Select>
            </Col>
            <Col>
              <MeasurementTableSearch />
            </Col>
          </Row>
        }
      />
      <Row
        align="middle"
        justify="center"
        style={{ background: "white", padding: "10px" }}
      >
        <Col xl={18} lg={24} offset={1}>
          <DocumentationAlert />
        </Col>
        <Col xl={4} lg={24} offset={1}>
          <Button
            type="primary"
            shape="round"
            onClick={() => {
              history.push(`/tags/manager?source=measurement`);
            }}
          >
            Create New Tag
          </Button>
        </Col>
      </Row>
      <Card>
        <MainTable
          tags={filteredMeasurementTags}
          testTag={testTag}
          isAttentionSignals={isAttentionSignals}
        />
      </Card>
    </div>
  );

  return (
    <React.Fragment>
      <PageHeaderLayout
        title={"Tags"}
        titleIcon={<TagOutlined style={mainTitleIcon} />}
        rightContent={
          <RequestAssistance onRequestAssistance={requestAssistance} />
        }
      />
      <PageContentLayout>
        {isAttentionSignals ? (
          <Tabs activeKey={activeTab} onChange={setActiveTab}>
            <TabPane tab="Measurement Tags" key="main">
              {renderMeasurementTagsContent()}
            </TabPane>
            <TabPane tab="Publisher Domain Tags" key="domain">
              <Card
                title={"Publisher Domain Tags"}
                extra={
                  <Space direction="horizontal">
                    <Search
                      placeholder="Search"
                      allowClear
                      onSearch={search => {
                        if (!search) {
                          setFilteredPublisherTags(publishersTags);
                          return;
                        }
                        setFilteredPublisherTags(() => {
                          const searchResults = performFuzzySearch(
                            publishersTags,
                            "name",
                            search
                          );
                          return searchResults;
                        });
                      }}
                      style={{
                        width: 200,
                      }}
                    />
                    <CreatePublisherDomainTag />
                  </Space>
                }
              >
                <Table
                  columns={publisherColumn}
                  dataSource={filteredPublisherTags}
                  onRow={(record, rowIndex) => {
                    return {
                      onClick: () => {
                        history.push(
                          `/tags/details/${record.id}?source=publisher`
                        );
                      },
                    };
                  }}
                />
              </Card>
            </TabPane>
            <TabPane tab="Impression Tags" key="impressions">
              <Card
                title={"Impressions Tags"}
                extra={
                  <Space direction="horizontal">
                    <Search
                      placeholder="Search"
                      allowClear
                      onSearch={search => {
                        setFilteredImpressionTags(
                          impressionsTags.filter(item =>
                            Object.values(item).some(value =>
                              value
                                .toString()
                                .toLowerCase()
                                .includes(search.toLowerCase())
                            )
                          )
                        );
                      }}
                      style={{
                        width: 200,
                      }}
                    />
                    <CreateImpressionsTag
                      onSubmit={onImressionsTagCreate}
                      refetch={refetch}
                      advertisers={advertisers}
                    />
                  </Space>
                }
              >
                <Table
                  columns={impressionColumn}
                  dataSource={filteredImpressionTags}
                  onRow={(record, rowIndex) => {
                    return {
                      onClick: () => {
                        history.push(
                          `/tags/details/${record.id}?source=impression`
                        );
                      },
                    };
                  }}
                />
              </Card>
            </TabPane>
          </Tabs>
        ) : (
          renderMeasurementTagsContent()
        )}
      </PageContentLayout>
    </React.Fragment>
  );
};

export default withRouter(TagsMainView);
