import React, { useState, useEffect } from "react";
import { useMutation } from "@apollo/react-hooks";
import moment from "moment";
import { formatCurrency2SigFig } from "../../../core/utils/campaigns";
import GENERATE_ATTENTION_SIGNAL_INVOICE from "../../GraphQl/Mutations/GENERATE_ATTENTION_SIGNAL_INVOICE";
const Controller = ({
  currentuser,
  listInvoice,
  invoiceList,
  billingDetails,
  // createInvoice,
  ...props
}) => {
  const [filteredInfo, setFilteredInfo] = useState("UNPAID");
  const [invoiceFilterList, setInvoiceFilterList] = useState([]);
  const [invoiceListData, setInvoiceListData] = useState([]);
  const [overDueAmount, setOverDueAmount] = useState(0.0);

  const [createInvoice] = useMutation(GENERATE_ATTENTION_SIGNAL_INVOICE);

  useEffect(() => {
    if (invoiceList) {
      let paid = 0.0,
        unpaid = 0.0,
        overdueAmount = 0.0;

      let dataRaw = invoiceList.billingInvoices.map(invoiceData => {
        const {
          id,
          name,
          description,
          amount,
          dueDate,
          status,
          invoice,
          createdAt,
          details,
        } = invoiceData;

        // let amount = computed_amount_money.amount / 100;
        let duration = moment.duration(
          moment(new Date()).diff(new Date(dueDate))
        );
        let overdue = Math.ceil(duration.asDays());

        switch (status) {
          case "PAID":
            paid = paid + parseFloat(amount);
            break;
          case "UNPAID":
            unpaid = unpaid + parseFloat(amount);
            if (overdue > 0) {
              overdueAmount = overdueAmount + parseFloat(amount);
            }
            break;

          default:
            break;
        }

        return {
          key: id,
          issuedDate: moment.utc(createdAt).format("MM/DD/Y"),
          dueDate: moment.utc(dueDate).format("MM/DD/Y"),
          invoice_number: invoice,
          title: name,
          amount: formatCurrency2SigFig(amount),
          balance: formatCurrency2SigFig(amount),
          overdue:
            status === "PAID" ||
            status === "REFUNDED" ||
            status === "CANCELED" ||
            overdue <= 0 ||
            overdue <= 0
              ? "-"
              : overdue + " day",
          status,
          public_url: description,
          details,
        };
      });

      let invoiceFilterListData = dataRaw.filter(invoice => {
        return invoice.status.includes(filteredInfo);
      });
      setOverDueAmount(overdueAmount);
      setInvoiceFilterList(invoiceFilterListData);
      setInvoiceListData(dataRaw);
    }
  }, [filteredInfo, invoiceList]);

  useEffect(() => {
    if (invoiceListData.length) {
      if (filteredInfo === "All") {
        setInvoiceFilterList(invoiceListData);
      } else {
        let filterInvoice = invoiceListData.filter(
          invoice => invoice.status === filteredInfo
        );
        setInvoiceFilterList(filterInvoice);
      }
    }
  }, [filteredInfo, invoiceListData]);

  const onCreateInvoice = async (method, invoiceList) => {
    const { key } = invoiceList[0];

    try {
      const invoice = await createInvoice({
        variables: {
          billingId: key,
          type: method,
          orgId: currentuser.role.org.id,
        },
      });

      return invoice;
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <React.Fragment>
      {React.cloneElement(props.children, {
        ...props,
        invoiceFilterList,
        invoiceList: invoiceListData,
        filteredInfo,
        setFilteredInfo,
        totalOverdue: formatCurrency2SigFig(overDueAmount),
        billingDetails: billingDetails.getBillingDetails,
        onCreateInvoice,
      })}
    </React.Fragment>
  );
};

export default Controller;
