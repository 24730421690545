import React from "react";
import {
  AreaChartOutlined,
  BookOutlined,
  DashboardOutlined,
  DesktopOutlined,
  EnvironmentOutlined,
  GoldOutlined,
  RocketOutlined,
  ShopOutlined,
  TeamOutlined,
  TrademarkCircleOutlined,
  UsergroupAddOutlined,
  UserOutlined,
  DollarCircleOutlined,
  SearchOutlined,
  ReadOutlined,
  HighlightOutlined,
  RobotOutlined,
  CodeOutlined,
  FileTextOutlined,
  StarOutlined,
  DotChartOutlined,
  CompressOutlined,
  TagOutlined,
  AppstoreAddOutlined,
  SettingOutlined,
  InfoCircleOutlined,
  UnorderedListOutlined,
  FileAddOutlined,
} from "@ant-design/icons";
import { map, isEqual, reduce } from "lodash";

//matching helper used to match strain effects to input
export const effectsMatched = (arr1, arr2) => {
  const matched = {};
  arr1.forEach(item => {
    const { name } = item;
    arr2.forEach(item2 => {
      if (item2 === name) {
        matched[item2] = 1;
      }
    });
  });
  return matched;
};

// Takes single object and pulls keys if they exist
// Output is an array of strings
export const filterEffects = Obj => {
  let result = [];

  Object.keys(Obj).forEach(item1 => {
    if (item1 !== "__typename") {
      const parsed = Object.keys(Obj[item1]).filter(item2 => {
        if (Obj[item1][item2] && item2 !== "__typename") {
          return item2;
        }
        return null;
      });
      result = result.concat(parsed);
    }
  });

  return result;
};

// Takes single object and pulls keys if they exist
// Output is an array of strings
//Assumes object to not have nesting of arrays
export const filterEffectsToplevel = Obj => {
  let result = [];
  Object.keys(Obj).forEach(item => {
    if (Obj[item] && item !== "__typename") {
      result.push(item);
    }
  });
  return result;
};

export const getUsersFromRoles = roles => {
  let users = [];

  roles.forEach(role => {
    role.users.forEach(user => {
      if (users.includes(user)) {
        return;
      }
      users.push(user);
    });
  });

  return users;
};

export const getRolesFeaturesWithActions = roles => {
  //expects roles as array from user and returns features with actions
  let rolefeatures = [];

  roles.forEach(role => {
    role.roleItems.forEach(item => {
      if (rolefeatures.includes(item)) {
        return;
      }
      rolefeatures.push(`${item.feature} - ${item.actions[0]}`);
    });
  });
  return rolefeatures;
};

export const getFeaturesByOrg = (roles, orgId) => {
  let features = [];
  roles.forEach(role => {
    if (role.org.id === orgId) {
      role.roleItems.forEach(item => {
        if (features.includes(item)) {
          return;
        }
        features.push(item.feature);
      });
    }
  });

  return features;
};

export const getFeaturesByUser = (roles, orgId) => {
  let features = [];
  roles.forEach(role => {
    if (role.org.id === orgId) {
      role.roleItems.forEach(item => {
        if (features.includes(item)) {
          return;
        }
        features.push(item.feature);
      });
    }
  });

  return features;
};

export const getFeaturesWithActionsByOrg = (roles, orgId) => {
  let features = [];
  roles.forEach(role => {
    if (role.org.id === orgId) {
      role.roleItems.forEach(item => {
        if (features.includes(item)) {
          return;
        }
        features.push({ feature: item.feature, actions: item.actions });
      });
    }
  });

  return features;
};

export const getOrgsFromRole = roles => {
  let orgs = [];

  roles.forEach(role => {
    orgs.push({
      id: role.org.id,
      name: role.org.name,
      isMaster: role.org.isMaster,
    });
  });

  return orgs;
};

export const getMasterRole = roles => {
  for (const role of roles) {
    if (role.isMaster) return role;
  }
};
export const SidebarIcon = props => {
  const { type } = props;
  switch (type) {
    case "DASHBOARD":
      return <DashboardOutlined />;
    case "PAID_SEARCH":
      return <DollarCircleOutlined />;
    case "LOCATIONS":
      return <EnvironmentOutlined />;
    case "CATALOG":
      return <ShopOutlined />;
    case "BRANDS":
      return <TrademarkCircleOutlined />;
    case "DISCOVER":
      return <DesktopOutlined />;
    case "USERS":
      return <UserOutlined />;
    case "ORGS":
      return <TeamOutlined />;
    case "CAMPAIGNS":
      return <AreaChartOutlined />;
    case "CAMPAIGNMANAGER":
      return <RocketOutlined />;
    case "LIBRARY":
      return <BookOutlined />;
    case "SEGMENTS":
      return <GoldOutlined />;
    case "AUDIENCES":
      return <UsergroupAddOutlined />;
    case "SEO":
      return <SearchOutlined />;
    case "ARTICLES":
      return <ReadOutlined />;
    case "EDITORIAL":
      return <HighlightOutlined />;
    case "AI":
      return <RobotOutlined />;
    case "ADVERTISER":
      return <StarOutlined />;
    case "PUBLISHER":
      return <ReadOutlined />;
    case "SIGNAL TAGS":
      return <CodeOutlined />;
    case "INVOICES":
      return <FileTextOutlined />;
    case "DEMOGRAPHIC":
      return <DotChartOutlined />;
    case "AUDIENCE":
      return <UsergroupAddOutlined />;
    case "TARGETING":
      return <CompressOutlined />;
    case "TAGS":
      return <TagOutlined />;
    case "CREATIVES":
      return <AppstoreAddOutlined />;
    case "RETAILS_STORES":
      return <EnvironmentOutlined />;
    case "SETTINGS":
      return <SettingOutlined />;
    case "KNOWLEDGE_BASE":
      return <InfoCircleOutlined />;
    case "TASKS":
      return <UnorderedListOutlined />;
    case "CREATE_TASK":
      return <FileAddOutlined />;
    default:
      return <AreaChartOutlined />;
  }
};

/**
 * Deep compares two objects
 * @param {Object} a The first object.
 * @param {Object} b The second object.
 * @return {{different: array, missing_from_first: array, missing_from_second: array}}
 * The result of comparing object a and object b. An array of objects with the key of the changed path and the value of the resulting value
 */

export const diff = (a, b) => {
  var result = {
    different: [],
    missing_from_first: [],
    missing_from_second: [],
  };

  reduce(
    a,
    function(result, value, key) {
      if (b.hasOwnProperty(key)) {
        if (isEqual(value, b[key])) {
          return result;
        } else {
          if (typeof a[key] != typeof {} || typeof b[key] != typeof {}) {
            //dead end.
            result.different.push(key);
            return result;
          } else {
            var deeper = diff(a[key], b[key]);
            result.different = result.different.concat(
              map(deeper.different, sub_path => {
                return key + "." + sub_path;
              })
            );

            result.missing_from_second = result.missing_from_second.concat(
              map(deeper.missing_from_second, sub_path => {
                return key + "." + sub_path;
              })
            );

            result.missing_from_first = result.missing_from_first.concat(
              map(deeper.missing_from_first, sub_path => {
                return key + "." + sub_path;
              })
            );
            return result;
          }
        }
      } else {
        result.missing_from_second.push(key);
        return result;
      }
    },
    result
  );

  reduce(
    b,
    function(result, value, key) {
      if (a.hasOwnProperty(key)) {
        return result;
      } else {
        result.missing_from_first.push(key);
        return result;
      }
    },
    result
  );

  return result;
};

/*
Adds commas to a float
Input: Float
Output: String
*/
export const toLocaleStringFloat = float => {
  console.log(float);
  const split = String(float).split(".");
  split[0] = split[0].toLocaleString();
  return split.join(".");
};
