// Libraries
import React from "react";
import { withRouter } from "react-router-dom";
import { Storage } from "aws-amplify";
import { Spin, Button } from "antd";
import { isEmpty } from "lodash";

// Constant
import DefaultLogo from "../../assets/your-logo-here.png";

class OrgDisplay extends React.Component {
  state = {
    ready: false,
    url: "",
  };

  componentDidMount = () => {
    const { imgKey } = this.props;
    if (!isEmpty(imgKey)) {
      this.setImageUrl(imgKey);
    } else
      this.setState({
        ready: true,
      });
  };

  /* Listen to change in Logo URL value and change it to update the logo 
  when using the preview org mode */
  componentDidUpdate = previousProps => {
    // Compare previous state value and props if they are not equal to trigger
    // URL change
    if (this.props.imgKey !== previousProps.imgKey) {
      this.setImageUrl(this.props.imgKey);
    }
  };

  setImageUrl = imageKey => {
    if (!imageKey) {
      this.setState({
        ready: true,
        url: null,
      });
      return;
    }

    this._getImage = Storage.get(imageKey)
      .then(result => {
        this._getImage = null;
        this.setState({
          ready: true,
          url: result,
        });
      })
      .catch(err => console.log("ycimage error", err));
  };

  render() {
    if (!this.state.ready) {
      return <Spin size="small" />;
    }

    return (
      <div style={this.props.collapsed ? styles.smallDiv : styles.defaultDiv}>
        <Button
          block
          type="link"
          style={
            this.props.collapsed ? styles.smallButton : styles.defaultButton
          }
          onClick={() => {
            // check if current location is already home. if so, reload page
            const {
              location: { pathname },
            } = this.props.history || {};
            if (pathname === "/home") {
              window.location.reload();
            }

            this.props.history.push("/home");
          }}
        >
          {isEmpty(this.state.url) ? (
            <img
              style={this.props.collapsed ? styles.small : styles.default}
              src={DefaultLogo}
              alt="alternate"
            />
          ) : (
            <img
              style={this.props.collapsed ? styles.small : styles.default}
              src={this.state.url}
              alt="alternate"
            />
          )}
        </Button>
      </div>
    );
  }
}

export default withRouter(OrgDisplay);

const styles = {
  defaultDiv: {
    marginBottom: "10px",
  },
  smallDiv: {
    marginBottom: "0px",
  },
  defaultButton: {
    marginLeft: "auto",
    marginRight: "auto",
    display: "block",
    height: "auto",
  },
  smallButton: {
    display: "initial",
  },
  default: {
    marginTop: 10,
    maxHeight: 80,
    maxWidth: 140,
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
  },
  small: {
    maxHeight: 30,
    maxWidth: 30,
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
  },
};
