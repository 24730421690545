import React, { useState, useEffect } from "react";
import moment from "moment";
import {
  Row,
  Modal,
  Col,
  Select,
  Card,
  Form,
  Input,
  Typography,
  Table,
  Tag,
  DatePicker,
  message,
  Button,
  Space,
} from "antd";
import { CloseOutlined } from "@ant-design/icons";
import {
  PrimaryTableRowText,
  TableColumnTitle,
  StandardTableRowText,
} from "../../../../shared/globalStyling/styledText";
import { formatNumber } from "../../../../../core/utils/campaigns";
// Shared Components from Publisher
import SelectRetailStoresModal from "../../../Editorial/components/SelectRetailStoresModal";
import SelectTagsModal from "../../../Editorial/components/SelectTagsModal";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

const { Title, Text } = Typography;

const LaunchAdvertiserNew = ({
  tags,
  onCancel = () => {},
  currentOrgId,
  onCreate,
  filteredLocations,
  filteredAudiences,
  isSelfService,
  isUpdate = false,
  details,
}) => {
  const formattedTags = tags
    .filter(
      tag =>
        tag.transactions > 0 ||
        tag.pageViews > 0 ||
        tag.signups > 0 ||
        tag.basketItems > 0
    )
    .map(tag => {
      return {
        value: tag.id,
        orgTag: tag.orgs[0].id,
        label: tag.name,
        website: tag.orgs[0].website,
        isOwned: tag.orgs[0].id === currentOrgId,
        isAdvertiser: tag.isAdvertiser === true,
        advertiser: tag.orgs[0].name,
        transactions: tag.transactions,
        pageViews: tag.pageViews,
        basketItems: tag.basketItems,
        signups: tag.signups,
        appId: tag.eventTags[0].appId,
        selectedImpresionsTagOrgId: tag.orgs[0].id,
        measuredEvents: [
          ...(tag.transactions > 0 ? ["TRANSACTIONS"] : []),
          ...(tag.pageViews > 0 ? ["PAGE_VIEWS"] : []),
          ...(tag.basketItems > 0 ? ["BASKET_ITEMS"] : []),
          ...(tag.signups > 0 ? ["SIGN_UPS"] : []),
        ],
      };
    });

  // copy of data with tags with 0 values
  const impressionTags = tags.map(tag => {
    return {
      value: tag.id,
      orgTag: tag.orgs[0].id,
      label: tag.name,
      website: tag.orgs[0].website,
      isOwned: tag.orgs[0].id === currentOrgId,
      isAdvertiser: tag.isAdvertiser === true,
      advertiser: tag.orgs[0].name,
      transactions: tag.transactions,
      pageViews: tag.pageViews,
      basketItems: tag.basketItems,
      signups: tag.signups,
      appId: tag.eventTags[0].appId,
      selectedImpresionsTagOrgId: tag.orgs[0].id,
      measuredEvents: [
        ...(tag.transactions > 0 ? ["TRANSACTIONS"] : []),
        ...(tag.pageViews > 0 ? ["PAGE_VIEWS"] : []),
        ...(tag.basketItems > 0 ? ["BASKET_ITEMS"] : []),
        ...(tag.signups > 0 ? ["SIGN_UPS"] : []),
      ],
    };
  });

  const [form] = Form.useForm();
  const [selectedRowKeys] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [tagSelectionModal, setTagSelectionModal] = useState(false);
  const [retailStoresModal, setRetailStoresModal] = useState(false);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [isLocationGroup, setIsLocationGroup] = useState(false);
  const initialRowSelectedTags = formattedTags.reduce((result, tag) => {
    result[tag.value] = [];
    if (tag.transactions > 0) result[tag.value].push("Transactions");
    if (tag.pageViews > 0) result[tag.value].push("Page Views");
    if (tag.signups > 0) result[tag.value].push("Sign Ups");
    if (tag.basketItems > 0) result[tag.value].push("Basket Items");
    return result;
  }, {});
  const [rowSelectedTags] = useState(initialRowSelectedTags);
  const [selectedEventsByTag, setSelectedEventsByTag] = useState({});

  useEffect(() => {
    if (isUpdate) {
      if (!selectedTags || selectedTags.length === 0) {
        setSelectedTags(details.selectedMeasurementTags);
      }

      if (selectedLocations.length === 0) {
        setSelectedLocations(details.selectedLocations);
        setIsLocationGroup(false);
      }
    }
  }, [details, isUpdate, selectedLocations, selectedTags]);

  let initialValues = {
    campaignFilters: [{ insertionOrderId: null, lineitemIds: [] }],
  };
  if (isUpdate) {
    initialValues = {
      name: details.name,
      impressionsTag: details.impressionsTag,
      selectedImpresionsTagOrgId: details.selectedImpresionsTagOrgId,
      insertionOrderIds: details.insertionOrderIds,
      startDate: moment(details.startDate),
      endDate: moment(details.endDate),
      transactionsTags: details.transactionsTags,
      locations: details.locations,
      campaignFilters: details.campaignFilters
        ? details.campaignFilters
        : [{ insertionOrderId: null, lineitemIds: [] }],
    };
  }

  const handleSelectLocations = (selectedStores, isLocationGroup) => {
    setSelectedLocations(selectedStores);
    setIsLocationGroup(isLocationGroup);
    setRetailStoresModal(false);
  };

  const measurementMapping = {
    Transactions: "TRANSACTIONS",
    "Page Views": "PAGE_VIEWS",
    "Basket Items": "BASKET_ITEMS",
    "Sign Ups": "SIGN_UPS",
  };

  useEffect(() => {
    const formatTrackMeasurements = rowValue => {
      const trackMeasurementsValue = rowSelectedTags[rowValue];
      return trackMeasurementsValue.map(
        measurement => measurementMapping[measurement]
      );
    };

    if (selectedRowKeys.length > 0) {
      const rowValue = selectedRowKeys[0];
      const formatted = formatTrackMeasurements(rowValue);
      form.setFieldsValue({
        trackMesurements: formatted,
      });
    } else {
      form.setFieldsValue({
        trackMesurements: [],
      });
    }
  }, [rowSelectedTags, selectedRowKeys, form, measurementMapping]);

  useEffect(() => {
    if (selectedRowKeys.length > 0) {
      const selectedRowKey = selectedRowKeys[0];
      const selectedTag = tags.find(tag => tag.id === selectedRowKey);
      if (selectedTag && selectedTag.orgs && selectedTag.orgs.length > 0) {
        form.setFieldsValue({ transactionsTagOrgId: selectedTag.orgs[0].id });
      }
    } else {
      form.setFieldsValue({ transactionsTagOrgId: undefined });
    }
  }, [form, selectedRowKeys, tags]);

  const onSelectTags = () => {
    setTagSelectionModal(true);
  };

  const onSelectRetailStores = () => {
    setRetailStoresModal(true);
  };

  const onCloseTagsModal = () => {
    setTagSelectionModal(false);
  };

  const onCloseLocationsModal = () => {
    setRetailStoresModal(false);
  };

  const handleSelectTags = selectedTags => {
    const updatedTags = selectedTags.map(tag => ({
      ...tag,
      measuredEvents: selectedEventsByTag[tag.value] || tag.measuredEvents,
    }));
    setSelectedTags(updatedTags);
    form.setFieldsValue({
      measurementTags: updatedTags,
      transactionsTagOrgId:
        updatedTags.length > 0
          ? updatedTags[0].selectedImpresionsTagOrgId
          : undefined,
      trackMesurements: updatedTags.flatMap(tag => tag.measuredEvents),
    });
    onCloseTagsModal();
  };

  const toggleEvent = (record, event) => {
    setSelectedEventsByTag(prev => {
      const currentEvents = prev[record.value] || record.measuredEvents;
      let updatedEvents;

      if (currentEvents.includes(event)) {
        // prevent empty array of events
        if (currentEvents.length === 1) {
          return prev;
        }
        updatedEvents = currentEvents.filter(e => e !== event);
      } else {
        updatedEvents = [...currentEvents, event];
      }

      return { ...prev, [record.value]: updatedEvents };
    });
  };

  const handleRemoveTag = tagToRemove => {
    setSelectedTags(prevTags =>
      prevTags.filter(tag => tag.value !== tagToRemove.value)
    );
    form.setFieldsValue({
      measurementTags: selectedTags.filter(
        tag => tag.value !== tagToRemove.value
      ),
    });
  };

  const handleRemoveLocation = locationToRemove => {
    setSelectedLocations(prevLocations =>
      prevLocations.filter(location =>
        isLocationGroup
          ? location.id !== locationToRemove.id
          : location.id !== locationToRemove.id
      )
    );
  };

  const tagsColumns = [
    {
      title: <TableColumnTitle text={"Tag Name"} />,
      key: "name",
      render: record => <PrimaryTableRowText text={record.label} />,
    },
    {
      title: <TableColumnTitle text={"Advertiser"} />,
      key: "advertiser",
      render: record => <StandardTableRowText text={record.advertiser} />,
    },
    {
      title: <TableColumnTitle text={"Measured Events"} />,
      key: "measuredEvents",
      render: (text, record) => (
        <Space>
          {record.pageViews > 0 && (
            <Tag
              color={
                (
                  selectedEventsByTag[record.value] || record.measuredEvents
                ).includes("PAGE_VIEWS")
                  ? "success"
                  : "default"
              }
              onClick={() => toggleEvent(record, "PAGE_VIEWS")}
              style={{ cursor: "pointer" }}
            >
              <Space direction="vertical" size={0}>
                <Text>Page Views</Text>
                <Text strong>{formatNumber(record.pageViews)}</Text>
              </Space>
            </Tag>
          )}
          {record.transactions > 0 && (
            <Tag
              color={
                (
                  selectedEventsByTag[record.value] || record.measuredEvents
                ).includes("TRANSACTIONS")
                  ? "success"
                  : "default"
              }
              onClick={() => toggleEvent(record, "TRANSACTIONS")}
              style={{ cursor: "pointer" }}
            >
              <Space direction="vertical" size={0}>
                <Text>Transactions</Text>
                <Text strong>{formatNumber(record.transactions)}</Text>
              </Space>
            </Tag>
          )}
          {record.signups > 0 && (
            <Tag
              color={
                (
                  selectedEventsByTag[record.value] || record.measuredEvents
                ).includes("SIGN_UPS")
                  ? "success"
                  : "default"
              }
              onClick={() => toggleEvent(record, "SIGN_UPS")}
              style={{ cursor: "pointer" }}
            >
              <Space direction="vertical" size={0}>
                <Text>Sign-Ups</Text>
                <Text strong>{formatNumber(record.signups)}</Text>
              </Space>
            </Tag>
          )}
          {record.basketItems > 0 && (
            <Tag
              color={
                (
                  selectedEventsByTag[record.value] || record.measuredEvents
                ).includes("BASKET_ITEMS")
                  ? "success"
                  : "default"
              }
              onClick={() => toggleEvent(record, "BASKET_ITEMS")}
              style={{ cursor: "pointer" }}
            >
              <Space direction="vertical" size={0}>
                <Text>Basket Items</Text>
                <Text strong>{formatNumber(record.basketItems)}</Text>
              </Space>
            </Tag>
          )}
        </Space>
      ),
    },
    {
      title: <TableColumnTitle text={"Tag ID"} />,
      key: "appId",
      render: record => (
        <Typography.Paragraph copyable>{record.appId}</Typography.Paragraph>
      ),
    },
  ];

  const selectedTagsColumns = [
    {
      title: "Tag Name",
      dataIndex: "label",
      key: "label",
    },
    {
      title: "Measured Events",
      key: "measuredEvents",
      render: (_, record) => (
        <Space>
          {record.measuredEvents.map(event => (
            <Tag key={event} color="success">
              {event.replace("_", " ")}
            </Tag>
          ))}
        </Space>
      ),
    },
    {
      title: "Tag ID",
      dataIndex: "appId",
      key: "appId",
      render: text => <Typography.Text copyable>{text}</Typography.Text>,
    },
    {
      title: "",
      key: "delete",
      width: 50,
      render: (_, record) => (
        <Button
          type="text"
          danger
          onClick={() => handleRemoveTag(record)}
          icon={<CloseOutlined />}
          aria-label="Remove tag"
        />
      ),
    },
  ];

  const locationColumns = [
    {
      title: "Location Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Street Address",
      key: "street",
      render: (text, record) =>
        `${record.street}, ${record.city} ${record.state}, ${record.zip}`,
    },
    {
      title: "Created On",
      dataIndex: "createdAt",
      key: "createdAt",
      render: text => new Date(text).toLocaleString(),
    },
    {
      title: "",
      key: "delete",
      width: 50,
      render: (_, record) => (
        <Button
          type="text"
          danger
          onClick={() => handleRemoveLocation(record)}
          icon={<CloseOutlined />}
          aria-label="Remove location"
        />
      ),
    },
  ];

  const locationGroupColumns = [
    {
      title: "Region Group Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Locations",
      dataIndex: "geoTargets",
      key: "locations",
      render: function(geoTargets) {
        if (!geoTargets || !Array.isArray(geoTargets)) {
          return null;
        }
        return (
          <Space wrap>
            {geoTargets.map(gt => (
              <Tag key={gt.id} color="blue">
                {gt.name}
              </Tag>
            ))}
          </Space>
        );
      },
    },
    {
      title: "",
      key: "delete",
      width: 50,
      render: (_, record) => (
        <Button
          type="text"
          danger
          onClick={() => handleRemoveLocation(record)}
          icon={<CloseOutlined />}
          aria-label="Remove location group"
        />
      ),
    },
  ];

  return (
    <>
      <Card title={"Funnel Details"}>
        <Form
          form={form}
          layout="vertical"
          name={"launchAdvertiserForm"}
          initialValues={initialValues}
          onFinish={() => {
            form
              .validateFields()
              .then(async values => {
                const formattedValues = {
                  ...values,
                  startDate: values.startDate.toISOString(),
                  endDate: values.endDate.toISOString(),
                  transactionsTags: selectedTags.map(tag => ({
                    eventsTarget: {
                      connect: { id: tag.value, orgId: tag.orgTag },
                    },
                    trackMesurements: { set: tag.measuredEvents },
                  })),
                  locations: {
                    connect: isLocationGroup
                      ? selectedLocations.flatMap(group =>
                          (group.geoTargets || [])
                            .map(loc => ({
                              id:
                                loc.location && loc.location.id
                                  ? loc.location.id
                                  : null,
                            }))
                            .filter(item => item.id != null)
                        )
                      : (selectedLocations || [])
                          .map(location => ({
                            id: location && location.id ? location.id : null,
                          }))
                          .filter(item => item.id != null),
                  },
                };
                message.loading(
                  `${isUpdate ? "Updating" : "Creating"} Advertiser`
                );
                await onCreate(formattedValues);
                message.destroy();
                message.success(
                  `Advertiser ${isUpdate ? "Updated" : "Created"} Successfully`
                );
                form.resetFields();
                setSelectedTags([]);
                setSelectedLocations([]);
                setIsLocationGroup(false);
                onCancel();
              })
              .catch(() => {
                message.error(
                  "Please ensure all required fields are filled correctly."
                );
              });
          }}
        >
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item
                name={"name"}
                required={false}
                rules={[{ required: true, message: "Funnel Name is Required" }]}
                label={
                  <Row>
                    <Col span={24}>
                      <Title level={5}>Funnel Name</Title>
                    </Col>
                    <Col span={24}>
                      <Text type="secondary">
                        Enter the Name for this funnel
                      </Text>
                    </Col>
                  </Row>
                }
              >
                <Input placeholder="Enter Funnel Name" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Row>
                <Col span={12}>
                  <Form.Item
                    required={false}
                    name={"startDate"}
                    rules={[
                      { required: true, message: "Start Date is Required" },
                    ]}
                    label={
                      <Row>
                        <Col span={24}>
                          <Title level={5}>Start Date</Title>
                        </Col>
                        <Col span={24}>
                          <Text type="secondary">
                            Select the Start Date for this funnel
                          </Text>
                        </Col>
                      </Row>
                    }
                  >
                    <DatePicker
                      className="span-mobile"
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    required={false}
                    name={"endDate"}
                    rules={[
                      { required: true, message: "End Date is Required" },
                    ]}
                    label={
                      <Row>
                        <Col span={24}>
                          <Title level={5}>End Date</Title>
                        </Col>
                        <Col span={24}>
                          <Text type="secondary">
                            Select the End Date for this funnel
                          </Text>
                        </Col>
                      </Row>
                    }
                  >
                    <DatePicker
                      className="span-mobile"
                      style={{ width: "100%" }}
                      disabledDate={current => {
                        const startDate = form.getFieldValue("startDate"); // Retrieve startDate from form values
                        return (
                          current && current < moment(startDate).endOf("day")
                        );
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
          <Form.List
            name="campaignFilters"
            rules={[
              {
                validator: (_, value) => {
                  if (!value || value.length < 1) {
                    return Promise.reject(
                      new Error("Please add at least one campaign order")
                    );
                  }

                  return Promise.resolve();
                },
              },
            ]}
          >
            {(fields, { add, remove }, { errors }) => (
              <Row>
                {fields.map(({ key, name, ...restField }, index) => (
                  <Col span={24}>
                    <Card
                      title={`Line Items ${index + 1}`}
                      extra={
                        fields.length > 1 && index !== 0 ? (
                          <Button
                            type="danger"
                            shape="round"
                            onClick={() => remove(name)}
                            icon={<MinusCircleOutlined />}
                          >
                            Remove
                          </Button>
                        ) : null
                      }
                    >
                      <Row gutter={[20, 20]}>
                        <Col span={10}>
                          <Form.Item
                            name={[name, "lineItemName"]}
                            required={false}
                            rules={[
                              {
                                required: true,
                                message: "Line Item Name is Required",
                              },
                            ]}
                            label={
                              <Row>
                                <Col span={24}>
                                  <Title level={5}>Name</Title>
                                </Col>
                                <Col span={24}>
                                  <Text type="secondary">
                                    Enter the Name for this line item
                                  </Text>
                                </Col>
                              </Row>
                            }
                          >
                            <Input placeholder="Enter Funnel Name" />
                          </Form.Item>
                        </Col>
                        <Col span={10}>
                          <Form.Item
                            {...restField}
                            name={[name, "impressionsTag"]}
                            required={false}
                            rules={[
                              {
                                required: true,
                                message: "Impression Tag is Required",
                              },
                            ]}
                            label={
                              <Row>
                                <Col span={24}>
                                  <Title level={5}>Impression Tag</Title>
                                </Col>
                                <Col span={24}>
                                  <Text type="secondary">
                                    Select the Impression Tag for this funnel
                                  </Text>
                                </Col>
                              </Row>
                            }
                          >
                            <Select
                              style={{ width: "100%" }}
                              placeholder="Select Impression Tag"
                              showSearch={true}
                              filterOption={(input, option) =>
                                option.label
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                              options={impressionTags.filter(
                                tag => tag.isOwned && tag.isAdvertiser
                              )}
                              filterSort={(optionA, optionB) =>
                                optionA.label
                                  .toLowerCase()
                                  .localeCompare(optionB.label.toLowerCase())
                              }
                              onChange={(value, option) => {
                                // Get the current form values
                                const currentCampaigns =
                                  form.getFieldValue("campaignFilters") || [];

                                // Update the specific index
                                currentCampaigns[index] = {
                                  ...currentCampaigns[index],
                                  selectedImpresionsTagOrgId:
                                    option.selectedImpresionsTagOrgId,
                                };

                                form.setFieldsValue({
                                  campaignFilters: currentCampaigns,
                                });

                                form.validateFields();
                              }}
                            />
                          </Form.Item>
                          <Form.Item
                            name={[name, "selectedImpresionsTagOrgId"]}
                            hidden
                            noStyle
                          >
                            <Input type="hidden" />
                          </Form.Item>
                        </Col>
                        <Col span={10}>
                          <Form.Item
                            {...restField}
                            name={[name, "advertiserId"]}
                            label={
                              <Row>
                                <Col span={24}>
                                  <Title level={5}>Advertiser ID</Title>
                                </Col>
                                <Col span={24}>
                                  <Text type="secondary">
                                    Enter the Advertiser ID for this funnel
                                  </Text>
                                </Col>
                              </Row>
                            }
                            rules={[
                              {
                                validator: (_, value) => {
                                  if (!value) return Promise.resolve();
                                  if (
                                    !form.getFieldValue("campaignFilters")[
                                      index
                                    ].impressionsTag &&
                                    value
                                  ) {
                                    return Promise.reject(
                                      new Error(
                                        "Impression Tag is Required to add Advertiser ID"
                                      )
                                    );
                                  }

                                  // Return error meesage when impression tag has value and campaign order and advertiser id is empty
                                  if (
                                    form.getFieldValue("campaignFilters")[index]
                                      .impressionsTag &&
                                    !value &&
                                    !form.getFieldValue("campaignFilters")[
                                      index
                                    ].insertionOrderId
                                  ) {
                                    return Promise.reject(
                                      new Error(
                                        "Advertiser ID or Campaign Order ID is Required"
                                      )
                                    );
                                  }

                                  // Throw error when this field has value and advertiser id has value as well
                                  if (
                                    value &&
                                    form.getFieldValue("campaignFilters")[index]
                                      .insertionOrderId
                                  ) {
                                    return Promise.reject(
                                      new Error(
                                        "There should be only one value between Advertiser ID and Campaign Order ID"
                                      )
                                    );
                                  }

                                  if (value && value.includes(",")) {
                                    return Promise.reject(
                                      new Error(
                                        "Please enter only one Advertiser ID"
                                      )
                                    );
                                  }

                                  return Promise.resolve();
                                },
                              },
                            ]}
                            key={key}
                          >
                            <Input
                              placeholder="Enter Advertiser ID"
                              onChange={() => form.validateFields()}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={10}>
                          <Row>
                            <Col span={24}>
                              <Form.Item
                                {...restField}
                                name={[name, "insertionOrderId"]}
                                label={
                                  <Row>
                                    <Col span={24}>
                                      <Title level={5}>Campaign Order ID</Title>
                                    </Col>
                                    <Col span={24}>
                                      <Text type="secondary">
                                        Enter the Campaign Order ID for this
                                        funnel
                                      </Text>
                                    </Col>
                                  </Row>
                                }
                                rules={[
                                  {
                                    validator: (_, value) => {
                                      if (!value) return Promise.resolve();
                                      if (
                                        !form.getFieldValue("campaignFilters")[
                                          index
                                        ].impressionsTag &&
                                        value
                                      ) {
                                        return Promise.reject(
                                          new Error(
                                            "Impression Tag is Required to add Campaign Order ID"
                                          )
                                        );
                                      }
                                      // Return error meesage when impression tag has value and campaign order and campaign order id is empty
                                      if (
                                        form.getFieldValue("campaignFilters")[
                                          index
                                        ].impressionsTag &&
                                        !value &&
                                        !form.getFieldValue("campaignFilters")[
                                          index
                                        ].advertiserId
                                      ) {
                                        return Promise.reject(
                                          new Error(
                                            "Advertiser ID or Campaign Order ID is Required"
                                          )
                                        );
                                      }

                                      // Throw error when this field has value and advertiser id has value as well
                                      if (
                                        value &&
                                        form.getFieldValue("campaignFilters")[
                                          index
                                        ].advertiserId
                                      ) {
                                        return Promise.reject(
                                          new Error(
                                            "There should be only one value between Advertiser ID and Campaign Order ID"
                                          )
                                        );
                                      }

                                      if (value && value.includes(",")) {
                                        return Promise.reject(
                                          new Error(
                                            "Please enter only one Campaign Order ID"
                                          )
                                        );
                                      }

                                      return Promise.resolve();
                                    },
                                  },
                                ]}
                                key={key}
                              >
                                <Input
                                  placeholder="Enter Campaign Order ID"
                                  onChange={() => form.validateFields()}
                                />
                              </Form.Item>
                            </Col>
                            <Col span={24}>
                              <Form.Item
                                {...restField}
                                name={[name, "lineitemIds"]}
                                label={
                                  <Row>
                                    <Col span={24}>
                                      <Title level={5}>
                                        Line Item IDs (Optional)
                                      </Title>
                                    </Col>
                                    <Col span={24}>
                                      <Text type="secondary">
                                        Enter the Line Item IDs within the
                                        Campaign Order ID
                                      </Text>
                                    </Col>
                                  </Row>
                                }
                                key={key}
                                rules={[
                                  {
                                    validator: (_, value) => {
                                      if (!value) return Promise.resolve();
                                      // Return error meesage when impression tag has value and campaign order and campaign order id is empty
                                      if (
                                        !form.getFieldValue("campaignFilters")[
                                          index
                                        ].insertionOrderId &&
                                        value &&
                                        value.length > 0
                                      ) {
                                        return Promise.reject(
                                          new Error(
                                            "Campaign Order ID is Required to add Line Item IDs"
                                          )
                                        );
                                      }

                                      return Promise.resolve();
                                    },
                                  },
                                ]}
                              >
                                <Select
                                  mode="tags"
                                  style={{ width: "100%" }}
                                  placeholder="Enter Line Item ID"
                                  allowClear={true}
                                  notFoundContent={null}
                                  onChange={() => form.validateFields()}
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                ))}
                <Form.Item>
                  <Button
                    type="primary"
                    shape="round"
                    onClick={() => add()}
                    icon={<PlusOutlined />}
                    style={{ marginTop: "25%" }}
                  >
                    Add Line Items
                  </Button>
                </Form.Item>
                <Col span={24}>
                  <Form.ErrorList errors={errors} />
                </Col>
              </Row>
            )}
          </Form.List>
          <Form.Item name="transactionsTagOrgId" hidden>
            <Input />
          </Form.Item>
          <Form.Item name={"trackMesurements"} hidden>
            <Input />
          </Form.Item>
          <Form.Item
            name="measurementTags"
            rules={[
              {
                validator: (_, value) => {
                  if (!selectedTags || selectedTags.length === 0) {
                    return Promise.reject(
                      new Error("Please select at least one measurement tag")
                    );
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input type="hidden" />
          </Form.Item>
          <Card
            title="MEASUREMENT TAGS"
            style={{ marginBottom: "16px" }}
            extra={
              selectedTags.length > 0 && (
                <Button onClick={onSelectTags}>Edit Tags</Button>
              )
            }
          >
            {selectedTags.length === 0 ? (
              <>
                <div style={{ textAlign: "center", marginBottom: "20px" }}>
                  Select 1 or more measurement tags for this funnel from your
                  library
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <Button type="primary" onClick={onSelectTags}>
                    Select Tags
                  </Button>
                </div>
              </>
            ) : (
              <Table
                dataSource={selectedTags}
                columns={selectedTagsColumns}
                pagination={false}
              />
            )}
          </Card>
          <Card
            title="RETAIL STORES"
            extra={
              selectedLocations.length > 0 && (
                <Button onClick={onSelectRetailStores}>Edit Stores</Button>
              )
            }
          >
            {selectedLocations.length === 0 ? (
              <>
                <div style={{ textAlign: "center", marginBottom: "20px" }}>
                  Select retail stores from your library in order to attribute
                  transactions with store locations
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <Button type="primary" onClick={onSelectRetailStores}>
                    Select Stores
                  </Button>
                </div>
              </>
            ) : (
              <Table
                dataSource={selectedLocations}
                columns={
                  isLocationGroup ? locationGroupColumns : locationColumns
                }
                pagination={false}
              />
            )}
          </Card>
          <Row justify="end" style={{ marginTop: "16px" }}>
            <Button type="primary" htmlType="submit">
              {isUpdate ? "Update" : "Create"}
            </Button>
          </Row>
        </Form>
      </Card>
      {tagSelectionModal && (
        <Modal
          visible={tagSelectionModal}
          onCancel={onCloseTagsModal}
          footer={null}
          width={1200}
          bodyStyle={{ padding: "0" }}
        >
          <SelectTagsModal
            formattedTags={formattedTags.filter(tag => !tag.isOwned)}
            onSelectTags={handleSelectTags}
            tagsColumns={tagsColumns}
          />
        </Modal>
      )}
      {retailStoresModal && isSelfService && (
        <Modal
          visible={retailStoresModal}
          onCancel={onCloseLocationsModal}
          footer={null}
          width={1200}
          bodyStyle={{ padding: "0" }}
        >
          <SelectRetailStoresModal
            visible={retailStoresModal}
            onClose={onCloseLocationsModal}
            filteredLocations={filteredLocations}
            filteredAudiences={filteredAudiences}
            onSelectStores={handleSelectLocations}
          />
        </Modal>
      )}
    </>
  );
};

export default LaunchAdvertiserNew;
