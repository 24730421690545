import React, { useState, useEffect } from "react";
import { Card, Col, Form, Input, Row, Select, Space, Typography } from "antd";
import { useCampaignRequestorContext } from "../../../core/components/campaignRequestor/useCampaignRequestorContext";

const { Text } = Typography;
const CampaignRequestorDetails = () => {
  const {
    childrenOrg,
    loadingChildrenOrgs,
    currentCampaignOrder,
    currentUserOrg,
    selectedAdvertiser,
    fetchChildrenOrgs,
  } = useCampaignRequestorContext();

  const [advertisers, setAdvertisers] = useState([]);

  useEffect(() => {
    if (childrenOrg && childrenOrg.length) {
      setAdvertisers(
        childrenOrg.map(org => ({
          value: org.id,
          label: org.name,
        }))
      );
    } else {
      if (!selectedAdvertiser) return;

      setAdvertisers([
        {
          value: selectedAdvertiser.id,
          label: selectedAdvertiser.name,
        },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [childrenOrg]);

  const handleFetchAdvertisers = (search = "") => {
    fetchChildrenOrgs({
      variables: {
        where: {
          OR: [
            { parentOrg_some: { id: currentUserOrg.id } },
            { id: currentUserOrg.id },
          ],
          name_contains: search,
        },
        orderBy: "name_ASC",
        first: 1500, // TODO: Apply virtual scrolling and pagination
      },
    });
  };

  return (
    <>
      <Card id="requester-details" title="DETAILS">
        <Space direction="vertical" size="middle" style={{ width: "100%" }}>
          <Row gutter={20}>
            <Col span={10}>
              <Space
                direction="vertical"
                size="small"
                style={{ width: "100%" }}
              >
                <Text>Advertiser</Text>
                <Text type="secondary">
                  The one being promoted in this campaign
                </Text>
                <Form.Item
                  name="advertiserId"
                  rules={[
                    {
                      required: true,
                      message: "Please select an advertiser",
                    },
                  ]}
                >
                  <Select
                    style={{ width: "100%" }}
                    showSearch
                    placeholder="Select Advertiser"
                    optionFilterProp="children"
                    loading={loadingChildrenOrgs}
                    onFocus={() => handleFetchAdvertisers()}
                    onSearch={searchText => handleFetchAdvertisers(searchText)}
                    filterOption={(input, option) => {
                      return (
                        option.label
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      );
                    }}
                    options={advertisers}
                    disabled={currentCampaignOrder}
                  ></Select>
                </Form.Item>
              </Space>
            </Col>
            <Col span={14}>
              <Space
                direction="vertical"
                size="small"
                style={{ width: "100%" }}
              >
                <Text>Campaign Name</Text>
                <Text type="secondary">
                  The name you will identify this campaign by
                </Text>
                <Form.Item name="name">
                  <Input placeholder="Enter campaign name" />
                </Form.Item>
              </Space>
            </Col>
          </Row>

          <Row gutter={20}>
            <Col span={24}>
              <Space
                direction="vertical"
                size="small"
                style={{ width: "100%" }}
              >
                <Text>Description</Text>
                <Text type="secondary">
                  Optional: Helps to distiguish your campaign
                </Text>
                <Form.Item name="description">
                  <Input placeholder="Enter optional description for campaign" />
                </Form.Item>
              </Space>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col span={10}>
              <Space
                direction="vertical"
                size="small"
                style={{ width: "100%" }}
              >
                <Text>Campaign Type</Text>
                <Text type="secondary">
                  Media format of ad units for this campaign
                </Text>
                <Form.Item name="type">
                  <Select
                    style={{ width: "100%" }}
                    showSearch
                    placeholder="Display Campaign"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    options={[
                      { label: "Display", value: 1, key: 1 },
                      {
                        label: "External",
                        value: 7,
                        key: 7,
                      },
                      {
                        label: "Video",
                        value: 8,
                        key: 8,
                      },
                    ]}
                  />
                </Form.Item>
              </Space>
            </Col>
          </Row>
        </Space>
      </Card>
    </>
  );
};

export default CampaignRequestorDetails;
