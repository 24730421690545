import React, { useEffect } from "react";
import { Storage } from "aws-amplify";
import { message, Tag } from "antd";
import { PaperClipOutlined } from "@ant-design/icons";

const S3File = ({ file }) => {
  useEffect(() => {
    if (file && file.key) {
      Storage.get(file.key, { expires: 24 * 60 * 60 }) // idle for 24 hours
        .then(result => {
          file.url = result; // Update the file object with the URL
        })
        .catch(err => {
          console.error("Error fetching file from S3", err);
        });
    }
  }, [file]);

  return (
    <Tag
      onClick={async () => {
        try {
          message.loading("Downloading the file...", 0);
          const response = await fetch(file.url, { method: "GET" });
          const blob = await response.blob();
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = file.name; // File name for the downloaded file
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(link.href); // Clean up the object URL
          message.destroy();
          message.success("File downloaded!");
        } catch (error) {
          console.error("Error downloading the file:", error);
        }
      }}
      icon={<PaperClipOutlined />}
      style={{
        marginBottom: "4px",
        display: "inline-flex",
        alignItems: "center",
        whiteSpace: "nowrap",
        overflow: "hidden",
        maxWidth: "100%",
        cursor: "pointer",
      }}
    >
      {file && file.name && file.name.length > 68
        ? `${file.name.slice(0, 68)}...`
        : file.name}
    </Tag>
  );
};

export default S3File;
