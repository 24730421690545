import React from "react";
import moment from "moment";
import { Avatar, Tooltip } from "antd";
import { isEmpty } from "lodash";
import cnnaid from "../../../../../assets/icons/transactions/cnnaid.png";
import cnnaip from "../../../../../assets/icons/transactions/cnnaip.png";
import cnnafingerprint from "../../../../../assets/icons/transactions/cnnafingerprint.png";
import { ReactComponent as GlobePhoneIcon } from "../../../../../assets/icons/transactions/globephone.svg";

// Global Styling
import PaginationTable from "../../../../shared/PaginationTable";
import {
  PrimaryTableRowText,
  TableColumnTitle,
} from "../../../../shared/globalStyling/styledText";
import SignupsImpressionsTimeline from "./SignupsImpressionsTImeline";

const columns = [
  {
    title: <TableColumnTitle text={"Sign Up Id"} />,
    dataIndex: "signupId",
    key: "signupId",
    align: "left",
    width: "25%",
    sorter: (a, b) => a.signupId - b.signupId,
    render: signupId => <PrimaryTableRowText text={signupId} />,
  },

  {
    title: <TableColumnTitle text={"Last Impression"} />,
    dataIndex: "completeLastImpression",
    key: "completeLastImpression",
    align: "left",
    width: "25%",
    sorter: (a, b) => {
      let getA = null;
      let getB = null;

      a.impressions.forEach(impression => {
        const { IMPRESSION_TIME } = impression;

        if (!getA) {
          getA = IMPRESSION_TIME;
        } else if (getA < IMPRESSION_TIME) {
          getA = IMPRESSION_TIME;
        } else {
          //None
        }
      });

      b.impressions.forEach(impression => {
        const { IMPRESSION_TIME } = impression;

        if (!getB) {
          getB = IMPRESSION_TIME;
        } else if (getB < IMPRESSION_TIME) {
          getB = IMPRESSION_TIME;
        } else {
          //None
        }
      });

      return new Date(getA) - new Date(getB);
    },
    render: (item, row) => {
      let latestDateRaw = null;
      if (row && row.impressions) {
        const { impressions } = row;
        impressions.forEach(impression => {
          const { IMPRESSION_TIME } = impression;
          if (!latestDateRaw) {
            latestDateRaw = IMPRESSION_TIME;
          } else if (latestDateRaw < IMPRESSION_TIME) {
            latestDateRaw = IMPRESSION_TIME;
          } else {
            //do nothing
          }
        });
      } else {
        const { latestDate } = row;
        latestDateRaw = latestDate;
      }

      return (
        <PrimaryTableRowText
          text={
            latestDateRaw
              ? moment(latestDateRaw, moment.DATETIME_LOCAL_MS).format(
                  "MM/DD/YY HH:mm"
                )
              : ""
          }
        />
      );
    },
  },

  {
    title: <TableColumnTitle text={"Impressions"} />,
    dataIndex: "impressions",
    key: "impressions",
    align: "left",
    width: "25%",
    sorter: (a, b) => a.impressions.length - b.impressions.length,
    render: (impressions, row) => {
      const { totalImpressions, impressionsLength } = row;
      let count = 0;
      const impressionCount = isEmpty(impressions)
        ? 0
        : impressions.length || impressionsLength;
      if (totalImpressions && totalImpressions > impressionCount) {
        count = totalImpressions;
      } else {
        count = impressionCount;
      }

      return <PrimaryTableRowText text={count} />;
    },
  },
  {
    title: <TableColumnTitle text={"Signed up on"} />,
    dataIndex: "signups_tstamp",

    key: "signups_tstamp",

    align: "left",
    width: "25%",
    render: signups_tstamp => {
      return (
        <PrimaryTableRowText
          text={moment(signups_tstamp, moment.DATETIME_LOCAL_MS).format(
            "MM/DD/YY HH:mm"
          )}
        />
      );
    },
  },

  {
    title: <TableColumnTitle text={""} />,
    dataIndex: "attributionMethod",
    key: "attributionMethod",
    align: "left",
    width: "8%",
    render: col => {
      let record;

      record = col;
      if (typeof col === "string") {
        // check if attribution method data type is string
        record = JSON.parse(col);
      }

      let method = "";
      let attributionIcon = cnnaip;
      let tip = "";
      //Single object with 1 key
      if (record) {
        method = Object.keys(record)[0];
        if (method === "COOKIE") {
          attributionIcon = cnnaid;
          tip =
            "Cookie Match - Deterministic match using browser based cookie / uuid match.";
        } else if (method === "FINGERPRINT") {
          attributionIcon = cnnafingerprint;
          tip =
            "User Match - Probabilistic match using user fingerprint. (User fingerprint - The combination of user agent and IP address to produce a unique hash)";
        } else if (method === "DEVICE_FINGERPRINT") {
          tip =
            "Device Match - Probabilistic match using user device model information (Device model, OS, OS version ) combined with IP address";
        } else if (method === "IP") {
          tip = "IP Match - Probabilistic match using IP address.";
          attributionIcon = cnnaip;
        }
      }

      const attributionIcn = () => {
        if (method === "DEVICE_FINGERPRINT") {
          return (
            <span
              role="img"
              className="anticon"
              style={{ verticalAlign: "-5px" }}
            >
              <GlobePhoneIcon
                height="35"
                width="35"
                style={{
                  opacity: "1.0",
                }}
              />
            </span>
          );
        } else {
          return <Avatar src={attributionIcon} />;
        }
      };

      return <Tooltip title={tip}>{attributionIcn()}</Tooltip>;
    },
  },
];

const SignUpsTable = ({ signUpsData, loading = false }) => {
  try {
    const transactionsWithKeys = signUpsData.map((item, i) => {
      const record = { ...item };
      record.value = i;
      return record;
    });

    return (
      <PaginationTable
        loading={loading}
        bordered={false}
        columns={columns}
        data={transactionsWithKeys}
        rowKey={record => record.value}
        scroll={{ x: 850, y: 400 }}
        showSizeChanger
        size={"default"}
        total={signUpsData.length}
        expandedRowRender={record => {
          const { impressions } = record;

          return (
            <SignupsImpressionsTimeline
              impressions={impressions ? impressions : []}
            />
          );
        }}
        rowClassName={record => {
          return record && record.impressions ? "" : "hide-expand";
        }}
      />
    );
  } catch (err) {}
};

export default SignUpsTable;
