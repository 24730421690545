import React, { useEffect, useState } from "react";
import {
  Card,
  Space,
  Typography,
  Collapse,
  Row,
  Col,
  Divider,
  Table,
  Tag,
} from "antd";
import moment from "moment";
import { calculateImpressions } from "../../CampaignRequestor/CampaignRequestorUtil";
import { formatCurrency2SigFig } from "../../../../core/utils/campaigns";
import { advertiserTypeMap } from "../../../../core/utils/constants/constants";

const kpiDefaultOptions = [
  {
    key: "transactions",
    kpi: "Transactions",
  },
  {
    key: "signups",
    kpi: "Sign-Ups",
  },
];

const { Panel } = Collapse;
const { Title, Text } = Typography;

export const CampaignOrderQueueReviewDetails = ({ currentCampaignOrder }) => {
  const [kpis, setKpis] = useState(kpiDefaultOptions);
  const {
    name,
    description,
    startDate,
    endDate,
    budget,
    isRegulated,
    category,
    advertiserType,
    cpm,
    type,
    notes,
    campaigns,
    transactionsConfig,
    signUpConfig,
    attributionTargets,
    geoTargets,
    measureWalkInTraffic,
    freeImpressions,
    advertiser,
  } = currentCampaignOrder;

  const lineItems = campaigns.length ? campaigns[0].lineItems || [] : [];

  useEffect(() => {
    let updatedKpis = [...kpis];

    if (transactionsConfig) {
      updatedKpis = processTag({
        eventType: "transactions",
        config: transactionsConfig,
        updatedKpis,
      });
    }

    if (signUpConfig) {
      updatedKpis = processTag({
        eventType: "signups",
        config: {
          ...signUpConfig,
          tag: {
            name: signUpConfig.tag
              ? signUpConfig.tag.name
              : signUpConfig.name || "",
          },
        },
        updatedKpis,
      });
    }

    setKpis(updatedKpis);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCampaignOrder]);

  const processTag = ({ eventType, config, updatedKpis }) => {
    const matchedTag = updatedKpis.find(tag => tag.key === eventType);
    const newTag = {
      ...matchedTag,
      ...config,
    };

    // Replace the matched tag with the updated tag
    const updatedTags = updatedKpis.map(tag =>
      tag.key === eventType ? newTag : tag
    );
    return updatedTags;
  };

  return (
    <div>
      <Collapse bordered={false} defaultActiveKey={[0]}>
        <Panel open={true} header={<Title level={5}>CAMPAIGN DETAILS</Title>}>
          <Card>
            <Row gutter={20}>
              <Col span={6}>
                <Space
                  direction="vertical"
                  size="small"
                  style={{ width: "100%" }}
                >
                  <Text type="secondary">Campaign Name</Text>
                  <Text ellipsis>{name}</Text>
                </Space>
              </Col>
              <Col span={6}>
                <Space
                  direction="vertical"
                  size="small"
                  style={{ width: "100%" }}
                >
                  <Text type="secondary">Advertiser</Text>
                  <Text>{advertiser && advertiser.name}</Text>
                </Space>
              </Col>
              <Col span={6}>
                <Space
                  direction="vertical"
                  size="small"
                  style={{ width: "100%" }}
                >
                  <Text type="secondary">Channel Partner</Text>
                  <Text></Text>
                </Space>
              </Col>
              <Col span={6}>
                <Space
                  direction="vertical"
                  size="small"
                  style={{ width: "100%" }}
                >
                  <Text type="secondary">Campaign Type</Text>
                  <Text>{type}</Text>
                </Space>
              </Col>
            </Row>

            <Divider plain></Divider>

            <Row gutter={20}>
              <Col span={6}>
                <Space
                  direction="vertical"
                  size="small"
                  style={{ width: "100%" }}
                >
                  <Text type="secondary">Regulated Industry</Text>
                  <Text>{isRegulated ? "Yes" : "No"}</Text>
                </Space>
              </Col>
              <Col span={6}>
                <Space
                  direction="vertical"
                  size="small"
                  style={{ width: "100%" }}
                >
                  <Text type="secondary">Category</Text>
                  <Text>{category}</Text>
                </Space>
              </Col>
              <Col span={6}>
                <Space
                  direction="vertical"
                  size="small"
                  style={{ width: "100%" }}
                >
                  <Text type="secondary">Advertiser Type</Text>
                  <Text>{advertiserTypeMap[advertiserType]}</Text>
                </Space>
              </Col>
            </Row>

            <Divider plain></Divider>

            <Row gutter={20}>
              <Col span={2}>
                <Space
                  direction="vertical"
                  size="small"
                  style={{ width: "100%" }}
                >
                  <Text type="secondary">CPM</Text>
                  <Text>{formatCurrency2SigFig(cpm)}</Text>
                </Space>
              </Col>
              <Col span={5}>
                <Space
                  direction="vertical"
                  size="small"
                  style={{ width: "100%" }}
                >
                  <Text type="secondary">Campaign Budget</Text>
                  <Text>{formatCurrency2SigFig(budget)}</Text>
                </Space>
              </Col>
              <Col span={5}>
                <Space
                  direction="vertical"
                  size="small"
                  style={{ width: "100%" }}
                >
                  <Text type="secondary">Impression Goal</Text>
                  <Text>
                    {calculateImpressions({
                      budget,
                      cpm,
                    }).toLocaleString()}
                  </Text>
                </Space>
              </Col>
              <Col span={5}>
                <Space
                  direction="vertical"
                  size="small"
                  style={{ width: "100%" }}
                >
                  <Text type="secondary">Free Impression</Text>
                  <Text>{freeImpressions}</Text>
                </Space>
              </Col>
            </Row>

            <Row gutter={20}>
              <Col span={4}>
                <Space
                  direction="vertical"
                  size="small"
                  style={{ width: "100%" }}
                >
                  <Text type="secondary">Start Date</Text>
                  <Text>{moment(startDate).format("MM/DD/YYYY")}</Text>
                </Space>
              </Col>
              <Col span={4}>
                <Space
                  direction="vertical"
                  size="small"
                  style={{ width: "100%" }}
                >
                  <Text type="secondary">End Date</Text>
                  <Text>{moment(endDate).format("MM/DD/YYYY")}</Text>
                </Space>
              </Col>
            </Row>

            <Divider plain></Divider>

            <Row gutter={20}>
              <Col span={12}>
                <Space
                  direction="vertical"
                  size="small"
                  style={{ width: "100%" }}
                >
                  <Text type="secondary">Description</Text>
                  <Text>{description}</Text>
                </Space>
              </Col>
              <Col span={12}>
                <Space
                  direction="vertical"
                  size="small"
                  style={{ width: "100%" }}
                >
                  <Text type="secondary">Additional Details</Text>
                  <Text>{notes}</Text>
                </Space>
              </Col>
            </Row>

            <Space
              direction="vertical"
              size="middle"
              style={{ width: "100%", marginTop: 24 }}
            >
              {/* BUDGET TABLE */}
              <div
                className="requester-table-wrapper"
                style={{ marginTop: 20 }}
              >
                <div className="requester-table-header">
                  <Row>
                    <Col span={12}>
                      <Title level={5}>BUDGET ALLOCATION</Title>
                    </Col>
                  </Row>
                </div>
                <Table
                  rowKey="id"
                  columns={[
                    {
                      title: "#",
                      dataIndex: "index",
                      key: "index",
                      render: (text, record, index) => index + 1,
                    },
                    {
                      title: "Line Item Name",
                      dataIndex: "name",
                      key: "name",
                    },
                    {
                      title: "Duration",
                      dataIndex: "duration",
                      key: "duration",
                      render: (_, record) =>
                        `${moment(record.startDate).format(
                          "MM/DD/YYYY"
                        )} - ${moment(record.endDate).format("MM/DD/YYYY")}`,
                    },
                    {
                      title: "Imps.",
                      dataIndex: "impressions",
                      key: "impressions",
                      render: (_, record) =>
                        calculateImpressions({
                          budget: record.budgetTotal,
                          cpm: record.cpm,
                        }).toLocaleString(),
                    },

                    {
                      title: "Budget",
                      dataIndex: "budgetTotal",
                      key: "budgetTotal",
                      width: "20%",
                    },
                    {
                      title: "Budget %",
                      dataIndex: "budgetTotal",
                      key: "budgetTotal",
                      width: "20%",
                      render: (_, record) =>
                        `${(
                          (parseFloat(record.budgetTotal) / budget) *
                          100
                        ).toFixed(2)}%`,
                    },
                  ]}
                  dataSource={lineItems || []}
                  pagination={false}
                  className="requester-table"
                  summary={() => (
                    <Table.Summary.Row>
                      <Table.Summary.Cell index={0} colSpan={3}>
                        {campaigns.length} Line Items
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={1}>
                        {campaigns
                          .reduce(
                            (acc, campaign) =>
                              acc +
                              calculateImpressions({
                                budget: parseFloat(campaign.budgetTotal),
                                cpm,
                              }),
                            0
                          )
                          .toLocaleString()}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={2}>
                        {formatCurrency2SigFig(
                          Math.round(
                            campaigns.reduce(
                              (acc, campaign) =>
                                acc + parseFloat(campaign.budgetTotal),
                              0
                            )
                          )
                        )}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={3}>
                        {`${Math.round(
                          (campaigns.reduce(
                            (acc, campaign) =>
                              acc + parseFloat(campaign.budgetTotal),
                            0
                          ) /
                            budget) *
                            100
                        ).toFixed(2)}%`}
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  )}
                />
              </div>

              {/* RETAIL TABLE */}
              <div
                className="requester-table-wrapper"
                style={{ marginTop: 20 }}
              >
                <div className="requester-table-header">
                  <Row>
                    <Col span={12}>
                      <Title level={5}>RETAIL STORE LOCATIONS</Title>
                    </Col>
                  </Row>
                </div>
                <Table
                  rowKey="id"
                  columns={[
                    {
                      title: "Location Name",
                      dataIndex: "name",
                      key: "name",
                      ellipsis: true, // Enables ellipsis for text overflow
                      width: 200, // Set a fixed width for the column
                    },
                    {
                      title: "Street Address",
                      dataIndex: "formattedAddress",
                      key: "formattedAddress",
                      render: (_, { location }) =>
                        (location && location.name) || "",
                    },
                  ]}
                  dataSource={attributionTargets || []}
                  pagination={false}
                  className="requester-table"
                  summary={() => (
                    <Table.Summary.Row>
                      <Table.Summary.Cell index={0} colSpan={2}>
                        {`${attributionTargets.length} Retail Stores`}
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  )}
                />
              </div>

              {/* KPI MEASUREMENT TABLE */}
              <div className="requester-table-wrapper">
                <div className="requester-table-header">
                  <Row>
                    <Col span={12}>
                      <Title level={5}>KPI MEASUREMENT</Title>
                    </Col>
                  </Row>
                </div>
                <Table
                  key="kpi"
                  columns={[
                    {
                      title: "KPI",
                      dataIndex: "kpi",
                      key: "kpi",
                      render: (_, { kpi }) => (
                        <Tag color="green" key={kpi}>
                          {kpi}
                        </Tag>
                      ),
                    },
                    {
                      title: "Corresponding Tag",
                      dataIndex: "correspondingTag",
                      key: "correspondingTag",
                      render: (_, { tag, appId, key }) => {
                        if (tag && tag.name) {
                          return tag.name;
                        } else if (appId) {
                          return "";
                        }
                      },
                    },
                    {
                      title: "Tag ID",
                      dataIndex: "tagId",
                      key: "tagId",
                      render: (_, { appId }) => {
                        if (appId) {
                          return appId;
                        }
                        return "";
                      },
                    },
                  ]}
                  dataSource={kpis}
                  pagination={false}
                  className="requester-table"
                  summary={() => (
                    <Table.Summary.Row>
                      <Table.Summary.Cell index={0} colSpan={4}>
                        2 Tags
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  )}
                />
              </div>

              {/* WALK-IN TABLE */}
              <div className="requester-table-wrapper">
                <div className="requester-table-header">
                  <Row>
                    <Col span={12}>
                      <Title level={5}>WALK-IN MEASUREMENT</Title>
                    </Col>
                  </Row>
                </div>
                {attributionTargets.length && measureWalkInTraffic ? (
                  <Table
                    rowKey="id"
                    style={{ borderTop: "1px solid #f0f0f0" }}
                    showHeader={false}
                    columns={[
                      {
                        title: "Location Name",
                        dataIndex: "name",
                        key: "name",
                        ellipsis: true, // Enables ellipsis for text overflow
                        width: 200, // Set a fixed width for the column
                      },
                      {
                        title: "Street Address",
                        dataIndex: "formattedAddress",
                        key: "formattedAddress",
                        render: (_, { location }) =>
                          (location && location.name) || "",
                      },
                    ]}
                    dataSource={attributionTargets}
                    pagination={false}
                    className="requester-table"
                  />
                ) : null}
                {geoTargets.length ? (
                  <Table
                    rowKey="id"
                    style={{ borderTop: "1px solid #f0f0f0" }}
                    showHeader={false}
                    columns={[
                      {
                        title: "Location Name",
                        dataIndex: "name",
                        key: "name",
                      },
                      {
                        title: "Street Address",
                        dataIndex: "formattedAddress",
                        key: "formattedAddress",
                        render: (_, { location }) =>
                          (location && location.name) || "",
                      },
                    ]}
                    dataSource={geoTargets}
                    pagination={false}
                    className="requester-table"
                    summary={() => (
                      <Table.Summary.Row>
                        <Table.Summary.Cell index={0} colSpan={2}>
                          {attributionTargets.length + geoTargets.length}{" "}
                          Locations
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                    )}
                  />
                ) : (
                  <></>
                )}
              </div>
            </Space>
          </Card>
        </Panel>
      </Collapse>
    </div>
  );
};
