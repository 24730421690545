import React from "react";
import TagsManagerLoader from "./TagsManagerLoader";
import TagsManagerController from "./TagsManagerController";
import TagsManagerView from "../../../../platform/ux/Tags/manager/TagsManagerView";
import TagsLoader from "../TagsLoader";
import TagsController from "../TagsController";
import TagsManagerImpressionsLoader from "./TagsManagerImpressionsLoader";
import TagsManagerImpressionsController from "./TagsManagerImpressionsController";
import TagsManagerPublisherController from "./TagsManagerPublisherController";
import TagsManagerPublisherLoader from "./TagsManagerPublisherLoader";
import TagsManagerUpdateLoader from "./TagsManagerUpdateLoader";
import TagsManagerUpdateController from "./TagsManagerUpdateController";

export const TagsManagerMeasurements = ({ headerSubmit = true }) => {
  return (
    <TagsLoader>
      <TagsController>
        <TagsManagerLoader>
          <TagsManagerController>
            <TagsManagerView
              headerSubmit={headerSubmit}
              tagType={"Measurement"}
            />
          </TagsManagerController>
        </TagsManagerLoader>
      </TagsController>
    </TagsLoader>
  );
};

export const TagsManagerImpressions = ({ headerSubmit, onSuccess }) => {
  return (
    <TagsLoader>
      <TagsController>
        <TagsManagerImpressionsLoader>
          <TagsManagerImpressionsController onSuccess={onSuccess}>
            <TagsManagerView
              headerSubmit={headerSubmit}
              tagType={"Impression"}
            />
          </TagsManagerImpressionsController>
        </TagsManagerImpressionsLoader>
      </TagsController>
    </TagsLoader>
  );
};

export const TagsManagerPublisherDomain = ({
  headerSubmit,
  onSuccess,
  onCard,
}) => {
  return (
    <TagsLoader>
      <TagsController>
        <TagsManagerPublisherLoader>
          <TagsManagerPublisherController onSuccess={onSuccess}>
            <TagsManagerView
              headerSubmit={headerSubmit}
              tagType={"Publisher"}
              onCard={onCard}
              isUpdate={false}
            />
          </TagsManagerPublisherController>
        </TagsManagerPublisherLoader>
      </TagsController>
    </TagsLoader>
  );
};

export const TagsManagerUpdateMeasurement = ({ onSuccess, headerSubmit }) => {
  return (
    <TagsLoader>
      <TagsController>
        <TagsManagerUpdateLoader>
          <TagsManagerUpdateController onSuccess={onSuccess}>
            <TagsManagerView
              headerSubmit={headerSubmit}
              tagType={"Measurement"}
              isUpdate={true}
            />
          </TagsManagerUpdateController>
        </TagsManagerUpdateLoader>
      </TagsController>
    </TagsLoader>
  );
};
