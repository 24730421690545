import React from "react";

const AdvertiserDetailsController = ({
  advertiser,
  signups,
  eventsTargets,
  permission,
  currentOrgId,
  locationData,
  attributionAudiences,
  updateAdvertiser,
  deleteAdvertiser,
  ...props
}) => {
  const { isSelfService, isTrafficker } = permission;
  const hasTransactions = advertiser.reports.length > 0;
  const hasSignups = signups.length > 0;

  const filteredLocations =
    locationData && locationData.locations
      ? locationData.locations.filter(
          location =>
            location.audienceType === "ATTRIBUTION" &&
            location.org.id === currentOrgId
        )
      : [];

  const edges =
    attributionAudiences && attributionAudiences.audiencesConnection
      ? attributionAudiences.audiencesConnection.edges
      : undefined;
  const filteredAudiences = edges
    ? edges.filter(
        edge =>
          edge.node && edge.node.geoTargets && edge.node.geoTargets.length > 0
      )
    : [];

  const onUpdateAdvertiser = async values => {
    await updateAdvertiser({
      variables: {
        where: { id: advertiser.id },
        dataRemove: {
          transactionsTags: {
            delete: advertiser.transactionsTags.map(t => {
              return { id: t.id };
            }),
          },
          locations: {
            disconnect: advertiser.locations.map(l => {
              return { id: l.id };
            }),
          },
          campaigns: {
            delete: advertiser.campaigns.map(c => {
              return { id: c.id };
            }),
          },
        },
        data: {
          name: values.name,
          startDate: values.startDate,
          endDate: values.endDate,
          impressionsTag: {
            connect: { id: values.campaignFilters[0].impressionsTag },
          },
          transactionsTags: {
            create: values.transactionsTags.map(tag => ({
              eventsTarget: {
                connect: {
                  id: tag.eventsTarget.connect.id,
                },
              },
              trackMesurements: tag.trackMesurements,
            })),
          },
          sharedOrgs: {
            create: [
              ...values.campaignFilters.map(campaign => ({
                isAdmin: false,
                isImpressionsOrg: true,
                isMeasurementOrg: false,
                org: {
                  connect: {
                    id: campaign.selectedImpresionsTagOrgId,
                  },
                },
              })),
              ...values.transactionsTags.map(tag => ({
                isAdmin: false,
                isImpressionsOrg: false,
                isMeasurementOrg: true,
                org: {
                  connect: {
                    id: tag.eventsTarget.connect.orgId,
                  },
                },
              })),
            ],
          },
          locations: values.locations,
          campaigns: {
            create: values.campaignFilters.map(campaign => {
              return {
                insertionOrderId: campaign.insertionOrderId
                  ? campaign.insertionOrderId
                  : null,
                lineItemIds: { set: campaign.lineitemIds },
                name: campaign.lineItemName,
                advertiserId: campaign.advertiserId,
                impressionsTag: {
                  connect: {
                    id: campaign.impressionsTag,
                  },
                },
              };
            }),
          },
        },
      },
    });
  };

  const onUpdateCreatives = async values => {
    const toCreate = values.filter(v => v.action === "create");
    const toUpdate = values.filter(v => v.action === "update");

    const toCreateQuery = toCreate.map(c => {
      return {
        creativeID: c.creativeID,
        name: c.name,
        url: c.url,
      };
    });

    const toUpdateQuery = toUpdate.map(c => {
      return {
        where: { id: c.id },
        data: {
          name: c.name,
          url: c.url,
        },
      };
    });

    await updateAdvertiser({
      variables: {
        where: { id: advertiser.id },
        dataRemove: {
          creatives: {
            update: toUpdateQuery,
          },
        },
        data: {
          creatives: {
            create: toCreateQuery,
          },
        },
      },
    });
  };

  return React.cloneElement(props.children, {
    ...props.children.props,
    advertiser,
    tags: eventsTargets,
    isSelfService,
    isTrafficker,
    currentOrgId,
    filteredLocations,
    filteredAudiences,
    onUpdateAdvertiser,
    hasSignups,
    hasTransactions,
    signups: signups && signups.length > 0 ? signups[0] : null,
    deleteAdvertiser,
    onUpdateCreatives,
  });
};

export default AdvertiserDetailsController;
