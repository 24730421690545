// TODO: Remove eslint disable after fixing publisher backend
/* eslint-disable */
import React, { useEffect, useState } from "react";
import {
  Row,
  Modal,
  Col,
  Select,
  Card,
  Form,
  Input,
  Typography,
  Table,
  Tag,
  Tooltip,
  DatePicker,
  Button,
  Space,
  message,
} from "antd";
import { CloseOutlined } from "@ant-design/icons";
import moment from "moment";
import {
  PrimaryTableRowText,
  TableColumnTitle,
  StandardTableRowText,
} from "../../../shared/globalStyling/styledText";
import { formatNumber } from "../../../../core/utils/campaigns";
import SelectRetailStoresModal from "./SelectRetailStoresModal";
import SelectTagsModal from "./SelectTagsModal";

const { Text, Title } = Typography;

const LaunchEditorial = ({
  tags,
  open,
  onCreate,
  onCancel,
  currentOrgId,
  filteredLocations,
  filteredAudiences,
  google,
  isSelfService,
  isUpdate = false,
  details,
}) => {
  const formattedTags = tags
    .filter(
      tag =>
        tag.transactions > 0 ||
        tag.pageViews > 0 ||
        tag.signups > 0 ||
        tag.basketItems > 0
    )
    .map(tag => ({
      value: tag.id,
      label: tag.name,
      orgTag: tag.orgs[0].id,
      website: tag.orgs[0].website,
      isOwned: tag.orgs[0].id === currentOrgId,
      advertiser: tag.orgs[0].name,
      transactions: tag.transactions,
      pageViews: tag.pageViews,
      basketItems: tag.basketItems,
      signups: tag.signups,
      appId: tag.eventTags[0].appId,
      selectedPublisherDomainOrgId: tag.orgs[0].id,
      measuredEvents: [
        ...(tag.transactions > 0 ? ["TRANSACTIONS"] : []),
        ...(tag.pageViews > 0 ? ["PAGE_VIEWS"] : []),
        ...(tag.basketItems > 0 ? ["BASKET_ITEMS"] : []),
        ...(tag.signups > 0 ? ["SIGN_UPS"] : []),
      ],
    }));

  // tags for publisher domains
  const publisherDomains = tags.map(tag => {
    return {
      value: tag.id,
      label: tag.name,
      website: tag.orgs[0].website,
      orgTag: tag.orgs[0].id,
      isOwned: tag.orgs[0].id === currentOrgId,
      advertiser: tag.orgs[0].name,
      transactions: tag.transactions,
      pageViews: tag.pageViews,
      basketItems: tag.basketItems,
      signups: tag.signups,
      selectedPublisherDomainOrgId: tag.orgs[0].id,
    };
  });

  const [form] = Form.useForm();
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [tagSelectionModal, setTagSelectionModal] = useState(false);
  const [retailStoresModal, setRetailStoresModal] = useState(false);
  const [selectedEventsByTag, setSelectedEventsByTag] = useState({});
  const [isLocationGroup, setIsLocationGroup] = useState(false);

  useEffect(() => {
    if (isUpdate) {
      if (!selectedTags || selectedTags.length === 0) {
        setSelectedTags(details.measurementTags);
      }
      if (selectedLocations.length === 0) {
        setSelectedLocations(details.selectedLocations);
        setIsLocationGroup(false);
      }

    }
  }, [details, isUpdate, selectedLocations, selectedTags]);

  let initialValues = {};
  if (isUpdate) {
    initialValues = details;
  }

  const onChangeClicks = (value, option) => {
    const selectedDomain = publisherDomains.find(
      domain => domain.value === value
    );
    if (selectedDomain) {
      form.setFieldsValue({
        domain: selectedDomain.website,
        selectedPublisherDomainOrgId:
          selectedDomain.selectedPublisherDomainOrgId,
      });
    }
  };

  const onSelectTags = () => {
    setTagSelectionModal(true);
  };

  const onSelectRetailStores = () => {
    setRetailStoresModal(true);
  };

  const onCloseTagsModal = () => {
    setTagSelectionModal(false);
  };

  const onCloseLocationsModal = () => {
    setRetailStoresModal(false);
  };

  const handleSelectTags = selectedTags => {
    const updatedTags = selectedTags.map(tag => ({
      ...tag,
      measuredEvents: selectedEventsByTag[tag.value] || tag.measuredEvents,
    }));
    setSelectedTags(updatedTags);
    form.setFieldsValue({
      measurementTags: updatedTags,
      trackMesurements: updatedTags.flatMap(tag => tag.measuredEvents),
    });
    onCloseTagsModal();
  };

  const handleSelectLocations = (selectedStores, isLocationGroup) => {
    setSelectedLocations(selectedStores);
    setIsLocationGroup(isLocationGroup);
    setRetailStoresModal(false);
  };

  const toggleEvent = (record, event) => {
    setSelectedEventsByTag(prev => {
      const currentEvents = prev[record.value] || record.measuredEvents;
      let updatedEvents;

      if (currentEvents.includes(event)) {
        if (currentEvents.length === 1) {
          return prev;
        }
        updatedEvents = currentEvents.filter(e => e !== event);
      } else {
        updatedEvents = [...currentEvents, event];
      }

      return { ...prev, [record.value]: updatedEvents };
    });
  };

  const handleRemoveTag = tagToRemove => {
    setSelectedTags(prevTags =>
      prevTags.filter(tag => tag.value !== tagToRemove.value)
    );
    form.setFieldsValue({
      measurementTags: selectedTags.filter(
        tag => tag.value !== tagToRemove.value
      ),
    });
  };

  const handleRemoveLocation = (locationToRemove) => {
    setSelectedLocations(prevLocations => 
      prevLocations.filter(location => 
        isLocationGroup 
          ? location.id !== locationToRemove.id
          : location.id !== locationToRemove.id
      )
    );
  };

  const tagsColumns = [
    {
      title: <TableColumnTitle text={"Tag Name"} />,
      key: "name",
      render: record => <PrimaryTableRowText text={record.label} />,
    },
    {
      title: <TableColumnTitle text={"Advertiser"} />,
      key: "advertiser",
      render: record => <StandardTableRowText text={record.advertiser} />,
    },
    {
      title: <TableColumnTitle text={"Measured Events"} />,
      key: "measuredEvents",
      render: (text, record) => (
        <Space>
          {record.pageViews > 0 && (
            <Tag
              color={
                (
                  selectedEventsByTag[record.value] || record.measuredEvents
                ).includes("PAGE_VIEWS")
                  ? "success"
                  : "default"
              }
              onClick={() => toggleEvent(record, "PAGE_VIEWS")}
              style={{ cursor: "pointer" }}
            >
              <Space direction="vertical" size={0}>
                <Text>Page Views</Text>
                <Text strong>{formatNumber(record.pageViews)}</Text>
              </Space>
            </Tag>
          )}
          {record.transactions > 0 && (
            <Tag
              color={
                (
                  selectedEventsByTag[record.value] || record.measuredEvents
                ).includes("TRANSACTIONS")
                  ? "success"
                  : "default"
              }
              onClick={() => toggleEvent(record, "TRANSACTIONS")}
              style={{ cursor: "pointer" }}
            >
              <Space direction="vertical" size={0}>
                <Text>Transactions</Text>
                <Text strong>{formatNumber(record.transactions)}</Text>
              </Space>
            </Tag>
          )}
          {record.signups > 0 && (
            <Tag
              color={
                (
                  selectedEventsByTag[record.value] || record.measuredEvents
                ).includes("SIGN_UPS")
                  ? "success"
                  : "default"
              }
              onClick={() => toggleEvent(record, "SIGN_UPS")}
              style={{ cursor: "pointer" }}
            >
              <Space direction="vertical" size={0}>
                <Text>Sign-Ups</Text>
                <Text strong>{formatNumber(record.signups)}</Text>
              </Space>
            </Tag>
          )}
          {record.basketItems > 0 && (
            <Tag
              color={
                (
                  selectedEventsByTag[record.value] || record.measuredEvents
                ).includes("BASKET_ITEMS")
                  ? "success"
                  : "default"
              }
              onClick={() => toggleEvent(record, "BASKET_ITEMS")}
              style={{ cursor: "pointer" }}
            >
              <Space direction="vertical" size={0}>
                <Text>Basket Items</Text>
                <Text strong>{formatNumber(record.basketItems)}</Text>
              </Space>
            </Tag>
          )}
        </Space>
      ),
    },
    {
      title: <TableColumnTitle text={"Tag ID"} />,
      key: "appId",
      render: record => (
        <Typography.Paragraph copyable>{record.appId}</Typography.Paragraph>
      ),
    },
  ];

const selectedTagsColumns = [
  {
    title: "Tag Name",
    dataIndex: "label",
    key: "label",
  },
  {
    title: "Measured Events",
    key: "measuredEvents",
    render: (_, record) => (
      <Space>
        {record.measuredEvents.map(event => (
          <Tag key={event} color="success">
            {event.replace("_", " ")}
          </Tag>
        ))}
      </Space>
    ),
  },
  {
    title: "Tag ID",
    dataIndex: "appId",
    key: "appId",
    render: text => <Typography.Text copyable>{text}</Typography.Text>,
  },
  {
    title: "",
    key: "delete",
    width: 50,
    render: (_, record) => (
      <Button
        type="text"
        danger
        onClick={() => handleRemoveTag(record)}
        icon={<CloseOutlined />}
        aria-label="Remove tag"
      />
    ),
  }
];

const locationColumns = [
  {
    title: "Location Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Street Address",
    key: "street",
    render: (text, record) =>
      `${record.street}, ${record.city} ${record.state}, ${record.zip}`,
  },
  {
    title: "Created On",
    dataIndex: "createdAt",
    key: "createdAt",
    render: text => new Date(text).toLocaleString(),
  },
  {
    title: "",
    key: "delete",
    width: 50,
    render: (_, record) => (
      <Button
        type="text"
        danger
        onClick={() => handleRemoveLocation(record)}
        icon={<CloseOutlined />}
        aria-label="Remove location"
      />
    ),
  }
];

const locationGroupColumns = [
  {
    title: "Region Group Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Locations",
    dataIndex: "geoTargets",
    key: "locations",
    render: function(geoTargets) {
      if (!geoTargets || !Array.isArray(geoTargets)) {
        return null;
      }
      return (
        <Space wrap>
          {geoTargets.map(gt => (
            <Tag key={gt.id} color="blue">
              {gt.name}
            </Tag>
          ))}
        </Space>
      );
    },
  },
  {
    title: "",
    key: "delete",
    width: 50,
    render: (_, record) => (
      <Button
        type="text"
        danger
        onClick={() => handleRemoveLocation(record)}
        icon={<CloseOutlined />}
        aria-label="Remove location group"
      />
    ),
  }
];

  return (
    <Modal
      title={`${isUpdate ? "Update" : "Add"} Publisher Funnel`}
      visible={open}
      okText={isUpdate ? "Update" : "Launch"}
      cancelText="Cancel"
      width={"75%"}
      onOk={() => {
        form
          .validateFields()
          .then(values => {
            const formattedValues = {
              ...values,
              startDate: values.startDate.toISOString(),
              endDate: values.endDate.toISOString(),
              transactionsTags: selectedTags.map(tag => ({
                eventsTarget: { connect: { id: tag.value, orgId: tag.orgTag } },
                trackMesurements: { set: tag.measuredEvents },
              })),
              locations: {
                connect: isLocationGroup
                  ? selectedLocations.flatMap(group =>
                      (group.geoTargets || [])
                        .map(loc => ({
                          id:
                            loc.location && loc.location.id
                              ? loc.location.id
                              : null,
                        }))
                        .filter(item => item.id != null)
                    )
                  : (selectedLocations || [])
                      .map(location => ({
                        id: location && location.id ? location.id : null,
                      }))
                      .filter(item => item.id != null),
              },
            };
            onCreate(formattedValues);
            form.resetFields();
            setSelectedTags([]);
            setSelectedLocations([]);
            setIsLocationGroup(false);
            onCancel();
          })
          .catch(() => {
            message.error("Please ensure all required fields are filled correctly.");
          });
      }}  
      onCancel={onCancel}
    >
      <Card title={"Funnel Details"}>
        <Form form={form} layout="vertical" name={"launchEditorialForm"} initialValues={initialValues}>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item
                name={"name"}
                label={<Text strong>Funnel Name</Text>}
                rules={[{ required: true, message: "Funnel Name Required" }]}
              >
                <Input placeholder="Enter Funnel Name" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item
                name={"clicksTag"}
                label={
                  <Row>
                    <Col span={24}>
                      <Title level={5}>Publisher Domain</Title>
                    </Col>
                    <Col span={24}>
                      <Text type="secondary">
                        Select Publisher Domain Tag for this funnel
                      </Text>
                    </Col>
                  </Row>
                }
                rules={[{ required: true, message: "Please select a tag" }]}
              >
                <Select
                  style={{ width: "100%" }}
                  placeholder="Select Clicks Tag"
                  showSearch={true}
                  filterOption={(input, option) => {
                    if (
                      option.label.toLowerCase().includes(input.toLowerCase())
                    )
                      return true;
                  }}
                  options={publisherDomains.filter(tag => tag.isOwned)}
                  filterSort={(optionA, optionB) =>
                    optionA.label
                      .toLowerCase()
                      .localeCompare(optionB.label.toLowerCase())
                  }
                  onChange={onChangeClicks}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name={"url"}
                label={
                  <Row>
                    <Col span={24}>
                      <Title level={5}>Funnel URL (Optional)</Title>
                    </Col>
                    <Col span={24}>
                      <Text type="secondary">
                        Enter one or more specific sub pages to measure. Leave blank to measure the entire domain
                      </Text>
                    </Col>
                  </Row>
                }
                rules={[
                  {
                    validator: (_, value) => {
                      // Check if value has '/' at the start
                      const urlArrays = value;
                      if (urlArrays) {
                        for (let i = 0; i < urlArrays.length; i++) {
                          if (urlArrays[i].charAt(0) !== "/") {
                            return Promise.reject(
                              new Error(`URL ${urlArrays[i]} must start with /`)
                            );
                          }
                        }
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Select
                  mode="tags"
                  style={{ width: "100%" }}
                  placeholder="/page.html"
                  allowClear={true}
                  notFoundContent={null}
                />
              </Form.Item>
              <Form.Item name="selectedPublisherDomainOrgId" hidden>
                <Input />
              </Form.Item>
              <Form.Item name="domain" hidden>
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={6}>
              <Form.Item
                name={"startDate"}
                label={<Text strong>Start Date</Text>}
                rules={[{ required: true, message: "Start Date is Required" }]}
              >
                <DatePicker style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name={"endDate"}
                label={<Text strong>End Date</Text>}
                rules={[{ required: true, message: "End Date is Required" }]}
              >
                <DatePicker
                  style={{ width: "100%" }}
                  disabledDate={current =>
                    current && current < moment().endOf("day")
                  }
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            name="measurementTags"
            rules={[
              {
                validator: (_, value) => {
                  if (!selectedTags || selectedTags.length === 0) {
                    return Promise.reject(
                      new Error("Please select at least one measurement tag")
                    );
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input type="hidden" />
          </Form.Item>
          <Card
            title="MEASUREMENT TAGS"
            style={{ marginBottom: "16px" }}
            extra={
              selectedTags.length > 0 && (
                <Button onClick={onSelectTags}>Edit Tags</Button>
              )
            }
          >
            {selectedTags.length === 0 ? (
              <>
                <div style={{ textAlign: "center", marginBottom: "20px" }}>
                Select 1 or more measurement tags for this funnel from your
                library
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <Button type="primary" onClick={onSelectTags}>
                    Select Tags
                  </Button>
                </div>
              </>
            ) : (
              <Table
                dataSource={selectedTags}
                columns={selectedTagsColumns}
                pagination={false}
              />
            )}
          </Card>
          <Card
            title="RETAIL STORES"
            extra={
              selectedLocations.length > 0 && (
                <Button onClick={onSelectRetailStores}>Edit Stores</Button>
              )
            }
          >
            {selectedLocations.length === 0 ? (
              <>
                <div style={{ textAlign: "center", marginBottom: "20px" }}>
                  Select retail stores from your library in order to attribute
                  transactions with store locations
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <Button type="primary" onClick={onSelectRetailStores}>
                    Select Stores
                  </Button>
                </div>
              </>
            ) : (
              <Table
                dataSource={selectedLocations}
                columns={
                  isLocationGroup ? locationGroupColumns : locationColumns
                }
                pagination={false}
              />
            )}
          </Card>
        </Form>
      </Card>
      {tagSelectionModal && (
        <Modal
          visible={tagSelectionModal}
          onCancel={onCloseTagsModal}
          footer={null}
          width={1200}
          bodyStyle={{ padding: "0" }}
        >
          <SelectTagsModal
            formattedTags={formattedTags}
            onSelectTags={handleSelectTags}
            tagsColumns={tagsColumns}
            
          />
        </Modal>
      )}
      {retailStoresModal && isSelfService && (
        <Modal
          visible={retailStoresModal}
          onCancel={onCloseLocationsModal}
          footer={null}
          width={1200}
          bodyStyle={{ padding: "0" }}
        >
          <SelectRetailStoresModal
            visible={retailStoresModal}
            onClose={onCloseLocationsModal}
            filteredLocations={filteredLocations}
            filteredAudiences={filteredAudiences}
            onSelectStores={handleSelectLocations}
            google={google}
          />
        </Modal>
      )}
    </Modal>
  );
};

export default LaunchEditorial;
