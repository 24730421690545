import gql from "graphql-tag";

const LIST_ORGS_SHARING = gql`
  query listOrgsSharing($parentid: ID!) {
    orgs(
      where: { OR: [{ parentOrg_some: { id: $parentid } }, { id: $parentid }] }
      first: 2000 # This is a temporary fix to get all orgs. This should be paginated
      orderBy: name_ASC
    ) {
      id
      name
      level
      tagDomain
      parentOrg {
        id
        name
        level
        tagDomain
      }
      dataConfig {
        dspAdvertiserId
      }
    }
  }
`;

export default LIST_ORGS_SHARING;
