import React from "react";
import PropTypes from "prop-types";
import { Storage } from "aws-amplify";
import { Avatar, Spin } from "antd";

class AvatarS3 extends React.Component {
  constructor(props) {
    super();
    this.state = {
      ready: false,
      url: "",
    };
  }

  componentDidMount = () => {
    const { imgKey, placeholderSrc } = this.props;
    if (imgKey) {
      Storage.get(imgKey)
        .then(result =>
          this.setState({
            ready: true,
            url: result,
          })
        )
        .catch(err => {
          console.log(err);
          this.setState({
            ready: true,
            url: placeholderSrc,
          });
        });
    } else {
      this.setState({
        ready: true,
        url: placeholderSrc,
      });
    }
  };

  componentDidUpdate(prevProps) {
    const { imgKey, placeholderSrc } = this.props;
    if (imgKey !== prevProps.imgKey) {
      this.setState({ ready: false });
      if (imgKey) {
        Storage.get(imgKey)
          .then(result =>
            this.setState({
              ready: true,
              url: result,
            })
          )
          .catch(err => {
            console.log(err);
            this.setState({
              ready: true,
              url: placeholderSrc,
            });
          });
      } else {
        this.setState({
          ready: true,
          url: placeholderSrc,
        });
      }
    }
  }

  render() {
    if (!this.state.ready) {
      return <Spin size="small" />;
    }

    // New fallback logic
    if (this.state.url) {
      return <Avatar {...this.props.avatarProps} src={this.state.url} />;
    }

    if (this.props.username) {
      return (
        <Avatar {...this.props.avatarProps}>
          {this.props.username.substring(0, 1).toUpperCase()}
        </Avatar>
      );
    }

    // Final fallback to default avatar
    return <Avatar {...this.props.avatarProps} />;
  }
}

export default AvatarS3;

AvatarS3.propTypes = {
  imgKey: PropTypes.string,
  avatarProps: PropTypes.object,
  placeholderSrc: PropTypes.string,
  username: PropTypes.string,
};

AvatarS3.defaultProps = {
  placeholderSrc: "",
  username: "",
};
