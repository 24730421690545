/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { PageContentLayout, PageHeaderLayout } from "../../../shared/layout";
import { AreaChartOutlined } from "@ant-design/icons";
import { ButtonText } from "../../../shared/globalStyling/styledText";
import DetailBar from "./components/DetailBar";
import { Card, Col, Dropdown, Empty, Menu, Row, Space, Tabs } from "antd";
import TransactionsTable from "./components/TransactionsTable";
import Button from "../../../shared/globalStyling/Button";
import { LoadingPage } from "../../../shared/LoadingPage";
import ErrorPage from "../../../shared/ErrorPage";
import { withRouter } from "react-router-dom";
import moment from "moment";
import DualAxisChart from "../../../shared/charts/DualAxisChart";
import { summaryScale } from "../utils/summaryScale";
import TopPageViewed from "./components/TopPageViewed";
import AdminModal from "./components/AdminModal";
import EditorialPageViewDetailsView from "./tabs/pageViews/EditorialPageViewDetailsView";
import EditorialSignUpsDetailsView from "./tabs/signUps/EditorialSignUpsDetailsViews";
import { HasAccess } from "@permify/react-role";
import GlobalButton from "../../../shared/globalStyling/Button";
import LaunchEditorial from "../components/LaunchEditorial";
import { Tooltip } from "antd";

const EditorialDetailsView = ({
  data,
  formattedByDate,
  formattedTransactions,
  name,
  startDate,
  endDate,
  filterByDate,
  filterByDropdown,
  transactionLoading,
  loading,
  error,
  transactionsError,
  totalTransactions,
  allTransactions,
  allTransactionLoading,
  allTransactionsError,
  location,
  history,
  deleteEditorial,
  userPermission,
  domainViewClick,
  domainData,
  domainDataLoading,
  pageViewsReport,
  signUpsSummaryReport,
  signUpsListReport,
  transactionsReportKey,
  hasReport,
  pausePlayEditorial,
  status,
  reprocessPublisher,
  tags,
  currentOrgId,
  filteredLocations,
  google,
  filteredAudiences,
  details,
  onUpdatePublisher,
}) => {
  const [bySummaryRangeDate, setBySummaryRangeDate] = useState(); //Selected date range for summary data
  const [summaryData, setSummaryData] = useState(null); //Summary data for the detail bar
  const [byDate, setByDate] = useState([]); //By Date data for the chart
  const [byDateReport, setByDateReport] = useState([]); //By Date data for the CSV report
  const [transactionList, setTransactionList] = useState([]); //Transactions data for the table
  const [transactionsReport, setTransactionsReport] = useState([]); //Transactions data for the CSV report
  const [topPageReport, setTopReport] = useState([]); //Top Pages for the CSV report
  const [impressionsReport, setImpressionsReport] = useState([]); //Impressions data for the CSV report
  const [usingDropdown, setUsingDropdown] = useState(false); //Flag to monitor if dropdown filter is used
  const [customLoading, setCustomLoading] = useState(false); //Flag to show a loading on demand
  const [chartId, setChartId] = useState(`defaultChart`);
  const [openLaunch, setOpenLaunch] = useState(false);

  //URL Params to monitor if filters are applied
  const urlParams = new URLSearchParams(location.search);
  const method = urlParams.get("method") ? urlParams.get("method") : null;
  const pageViews = urlParams.get("pageViews")
    ? urlParams.get("pageViews")
    : null;
  const url = urlParams.get("url") ? urlParams.get("url") : null;
  const startDateFilter = urlParams.get("startDate")
    ? urlParams.get("startDate")
    : null;
  const endDateFilter = urlParams.get("endDate")
    ? urlParams.get("endDate")
    : null;

  useEffect(() => {
    if (domainData && domainData.editorials.length > 0) {
      const editorial = domainData.editorials[0];
      history.push(`/editorial/details/${editorial.id}/1`);
    }
  }, [domainData, history]);

  useEffect(() => {
    if (method || pageViews) {
      setChartId(`${method}-${pageViews}-chart-${moment().format()}`);
    } else {
      setChartId(`defaultChart`);
    }
  }, [method, pageViews]);
  //Update displayed data when GQL Fetch is complete and data is available
  useEffect(() => {
    if (formattedByDate) {
      setByDate(formattedByDate);
      //Format the by Date to a user friendly report
      const reportData = formattedByDate.map(byDate => {
        return {
          Date: moment(byDate.xAxis)
            .utc(true)
            .format("YYYY-MM-DD"),
          Transactions: byDate.transactions,
          Revenue: byDate.revenue,
          Customers: byDate.customers,
          "Page Views": byDate.clicks,
        };
      });
      setByDateReport(reportData);
    }
  }, [formattedByDate]);

  //Update the detail bar data when GQL Fetch is complete and data is available
  useEffect(() => {
    if (data) {
      setSummaryData({
        transactions: data.transactions,
        revenue: data.revenue,
        customers: data.customers,
        clicks: data.clicks,
      });
    }
  }, [data, usingDropdown]);

  //Update the transaction table data when GQL Fetch is complete and data is available
  useEffect(() => {
    if (formattedTransactions) {
      setTransactionList(formattedTransactions);
    }
  }, [formattedTransactions]);

  //Updatye transaction CSV Report data when GQL Fetch is complete and data is available
  useEffect(() => {
    if (allTransactions) {
      let transactionsToReport = allTransactions;
      if (bySummaryRangeDate) {
        transactionsToReport = allTransactions.filter(transaction => {
          return (
            moment(transaction.orderTime) >= bySummaryRangeDate[0] &&
            moment(transaction.orderTime) <= bySummaryRangeDate[1]
          );
        });
      }
      let impressionsReport = [];
      //Format the transactions to a user friendly report
      const reportData = transactionsToReport.map(transaction => {
        transaction.clicksList.forEach(click => {
          impressionsReport.push({
            "Order ID": transaction.orderId,
            "Page URL": click.pageURL,
            "View Time": click.viewTime,
          });
        });
        return {
          "Order ID": transaction.orderId,
          "Order Time": transaction.orderTime,
          "Page URL": transaction.pageURL,
          Total: transaction.total,
          "Page View": transaction.clicks,
          "Last Page View Time": transaction.clicksList[0].viewTime,
          "Last Page View URL": transaction.clicksList[0].pageURL,
          "Attribution Method": transaction.attributionMethod,
        };
      });
      setTransactionsReport(reportData);
      setImpressionsReport(impressionsReport);
    }
  }, [allTransactions, bySummaryRangeDate]);

  //If the transaction filter has been changed by either the dropdown or URL update the display counts
  useEffect(() => {
    if ((pageViews || method || url) && allTransactions) {
      //Due to time it takes to create a new by date report, show a loading overlay
      setCustomLoading(true);
      const filtered = filterByDropdown();
      setByDate(filtered.byDates);
      setSummaryData(filtered.summary);

      setUsingDropdown(true);
      setCustomLoading(false);
    } else {
      setUsingDropdown(false);
    }
  }, [allTransactions, filterByDropdown, method, pageViews, url]);

  //If the startDate and endDate has been change by either the datepicker or URL update the display counts
  useEffect(() => {
    if (
      startDateFilter &&
      endDateFilter &&
      filterByDate &&
      !pageViews &&
      !method &&
      !url
    ) {
      const filtered = filterByDate([
        moment(startDateFilter, "YYYY-MM-DD"),
        moment(endDateFilter, "YYYY-MM-DD"),
      ]);

      setBySummaryRangeDate(filtered.dates);
      setSummaryData(filtered.filteredData);
      setByDateReport(filtered.reportData);
      setByDate(filtered.filteredByDate);
    }
  }, [startDateFilter, endDateFilter, filterByDate, pageViews, method, url]);

  //Early return for loading and error displays
  if (loading)
    return (
      <PageContentLayout>
        <LoadingPage />
      </PageContentLayout>
    );

  if (error || transactionsError || allTransactionsError)
    return (
      <PageContentLayout>
        <ErrorPage
          errorMessage={error || transactionsError || allTransactionsError}
        />
      </PageContentLayout>
    );

  //Dropdown menu for CSV download
  const hasEmptyReports =
    !byDateReport.length &&
    !transactionsReport.length &&
    !topPageReport.length &&
    !impressionsReport.length;
  const hasDateReport = byDateReport.length > 0;
  const hasTransactionsReport = transactionsReport.length > 0;
  const hasTopPageReport = topPageReport.length > 0;
  const hasImpressionsReport = impressionsReport.length > 0;

  const menu = hasEmptyReports ? (
    <Menu>
      <Menu.Item>No reports available to download</Menu.Item>
    </Menu>
  ) : (
    <Menu>
      {hasDateReport && (
        <Menu.Item>
          <CSVLink
            filename={`${name}-editorial-by-date.csv`}
            data={byDateReport}
          >
            By Date Report
          </CSVLink>
        </Menu.Item>
      )}
      {hasTransactionsReport && (
        <Menu.Item>
          <CSVLink
            filename={`${name}-editorial-transactions.csv`}
            data={transactionsReport}
          >
            Transactions Report
          </CSVLink>
        </Menu.Item>
      )}
      {hasTopPageReport && (
        <Menu.Item>
          <CSVLink
            filename={`${name}-editorial-top-pages.csv`}
            data={topPageReport}
          >
            Top Pages Report
          </CSVLink>
        </Menu.Item>
      )}
      {hasImpressionsReport && (
        <Menu.Item>
          <CSVLink
            filename={`${name}-editorial-impressions.csv`}
            data={impressionsReport}
          >
            Impressions Report
          </CSVLink>
        </Menu.Item>
      )}
    </Menu>
  );

  //Dropdown options for the Dual Axis Chart
  const dropDownOptions = [
    { key: "clicks", display: "Page Views" },
    { key: "transactions", display: "Transactions" },
    { key: "revenue", display: "Revenue" },
    { key: "customers", display: "Customers" },
  ];

  //Generate url text for publisher
  const getDomain = () => {
    if (data && data.url && data.domain) {
      return data.url.split(",").map(url => data.domain.endsWith('/') ? data.domain.slice(0, -1) : data.domain + url).join(", ");
    } else if (data && data.clicksTag && data.clicksTag.orgs.length > 0) {
      return data.clicksTag.orgs[0].website;
    } else {
      return "";
    }
  };

  const getDefaultKey = () => {
    if (transactionsReportKey && transactionsReportKey.reports.length > 0) {
      return "1";
    } else if (
      pageViewsReport &&
      pageViewsReport.reports &&
      pageViewsReport.reports.length > 0
    ) {
      return "2";
    } else if (
      signUpsSummaryReport &&
      signUpsSummaryReport.reports &&
      signUpsSummaryReport.reports.length > 0
    ) {
      return "3";
    }
    return "1";
  };

  const DomainView = () => {
    if (data && !data.url) return null;
    return (
      <Button
        type="primary"
        loading={domainDataLoading}
        onClick={async () => {
          await domainViewClick();
        }}
      >
        <ButtonText text={"Domain View"} />
      </Button>
    );
  };

  //If no report is found show a empty display with notification
  if (!hasReport) {
    return (
      <React.Fragment>
        <LaunchEditorial
          tags={tags}
          open={openLaunch}
          onCreate={values => {
            setOpenLaunch(false);
            onUpdatePublisher(values);
          }}
          onCancel={() => {
            setOpenLaunch(false);
          }}
          currentOrgId={currentOrgId}
          filteredLocations={filteredLocations}
          filteredAudiences={filteredAudiences}
          google={google}
          isSelfService={userPermission.isSelfService}
          isUpdate={true}
          details={details}
        />
        <PageHeaderLayout
          title={name}
          subtitle={
          <Tooltip title={getDomain()} placement="bottomLeft">
            <div className="urllHover">Publisher Urls</div>
          </Tooltip>
          }
          titleIcon={
            <AreaChartOutlined
              style={{
                marginRight: "10px",
                color: "lightgrey",
                fontSize: 20,
              }}
            />
          }
          rightContent={
            <>
              <HasAccess permissions={["default", "defaultSSO"]}>
                {userPermission && userPermission.isAdmin ? (
                  <AdminModal
                    deleteEditorial={deleteEditorial}
                    pausePlayEditorial={pausePlayEditorial}
                    status={status}
                    reprocessPublisher={reprocessPublisher}
                  />
                ) : null}
                {userPermission && userPermission.isTrafficker ? (
                  <GlobalButton type="primary" onClick={() => setOpenLaunch(!openLaunch)} >Update Funnel</GlobalButton>

                ) : null}
              </HasAccess>
            </>
          }
        />
        <PageContentLayout>
          <Card>
            <Empty description="Editorial Data Unavailable" />
          </Card>
        </PageContentLayout>
      </React.Fragment>
    );
  }
  return (
    <React.Fragment>
      <LaunchEditorial
        tags={tags}
        open={openLaunch}
        onCreate={values => {
          setOpenLaunch(false);
          onUpdatePublisher(values);
        }}
        onCancel={() => {
          setOpenLaunch(false);
        }}
        currentOrgId={currentOrgId}
        filteredLocations={filteredLocations}
        filteredAudiences={filteredAudiences}
        google={google}
        isSelfService={userPermission.isSelfService}
        isUpdate={true}
        details={details}
      />
      <PageHeaderLayout
        title={name}
        subtitle={
          <Tooltip title={getDomain()} placement="bottomLeft">
            <div className="urllHover">Publisher Urls</div>
          </Tooltip>
        }
        titleIcon={
          <AreaChartOutlined
            style={{
              marginRight: "10px",
              color: "lightgrey",
              fontSize: 20,
            }}
          />
        }
        rightContent={
          <HasAccess permissions={["default", "defaultSSO"]}>
            <Space>
              <Dropdown placement="bottomCenter" overlay={menu}>
                <Button type="primary">
                  <ButtonText text={"Download CSV"} />
                </Button>
              </Dropdown>
              {userPermission && userPermission.isAdmin ? (
                <AdminModal
                  deleteEditorial={deleteEditorial}
                  pausePlayEditorial={pausePlayEditorial}
                  status={status}
                  reprocessPublisher={reprocessPublisher}
                />
              ) : null}
              {userPermission && userPermission.isTrafficker ? (
                <GlobalButton type="primary" onClick={() => setOpenLaunch(!openLaunch)} >Update Funnel</GlobalButton>
              ) : null}
              <DomainView />
            </Space>
          </HasAccess>
        }
      />
      <PageContentLayout>
        <HasAccess permissions={["default", "defaultSSO", "publisherSSO"]}>
          <Tabs defaultActiveKey={getDefaultKey()}>
            {transactionsReportKey &&
              transactionsReportKey.reports.length > 0 ? (
              <Tabs.TabPane tab="Transactions" key="1">
                <DetailBar
                  startDate={startDate}
                  endDate={endDate}
                  loading={customLoading}
                  {...summaryData}
                />
                <Row gutter={[16, 16]}>
                  <Col xs={24}>
                    <DualAxisChart
                      id={chartId}
                      byDate={byDate}
                      xAxis="xAxis"
                      options={dropDownOptions}
                      defaultLeftYValue={dropDownOptions[0]}
                      defaultRightYValue={dropDownOptions[1]}
                      defaultSummaryDates={bySummaryRangeDate}
                      customScale={summaryScale}
                      onDatePickerChange={({ data, dates, rangeKey }) => {
                        //Update URL with new date range
                        const datePickerStartDate = dates[0].format(
                          "YYYY-MM-DD"
                        );
                        const datePickerEndDate = dates[1].format("YYYY-MM-DD");
                        const nextPage = location.pathname.split("/");
                        nextPage[4] = 1;
                        const searchParams = new URLSearchParams(
                          location.search
                        );
                        if (searchParams.get("startDate"))
                          searchParams.delete("startDate");
                        if (searchParams.get("endDate"))
                          searchParams.delete("endDate");

                        searchParams.append("startDate", datePickerStartDate);
                        searchParams.append("endDate", datePickerEndDate);

                        history.push({
                          pathname: nextPage.join("/"),
                          search: `?${searchParams.toString()}`,
                        });
                      }}
                      loading={transactionLoading}
                      //showEmpty={isEmpty(formattedTransactions)}
                      has6MonDisables={false}
                      allTimeDateRange={[
                        moment(startDate),
                        moment(endDate),
                      ]}
                    />
                  </Col>
                  <Col xs={24}>
                    <TransactionsTable
                      data={transactionList}
                      loading={transactionLoading}
                      filterLoading={allTransactionLoading}
                      total={totalTransactions}
                    />
                  </Col>
                  <Col xs={24}>
                    <TopPageViewed
                      data={allTransactions}
                      loading={allTransactionLoading}
                      setTopReport={setTopReport}
                    />
                  </Col>
                </Row>
              </Tabs.TabPane>
            ) : null}
            {pageViewsReport &&
              pageViewsReport.reports &&
              pageViewsReport.reports.length > 0 ? (
              <Tabs.TabPane tab="Page Views" key="2">
                <EditorialPageViewDetailsView
                  reportKey={pageViewsReport.reports[0].key}
                  startDate={startDate}
                  endDate={endDate}
                />
              </Tabs.TabPane>
            ) : null}
            {signUpsSummaryReport &&
              signUpsSummaryReport.reports &&
              signUpsSummaryReport.reports.length > 0 ? (
              <Tabs.TabPane tab="Sign Ups" key="3">
                <EditorialSignUpsDetailsView
                  reportKeySummary={signUpsSummaryReport.reports[0].key}
                  reportKeyList={signUpsListReport.reports[0].key}
                />
              </Tabs.TabPane>
            ) : null}
          </Tabs>
        </HasAccess>
      </PageContentLayout>
    </React.Fragment>
  );
};

export default withRouter(EditorialDetailsView);
