import React, { useState } from "react";
import {
  Card,
  Col,
  Row,
  Spin,
  Empty,
  Tag,
  Typography,
  Input,
  Popconfirm,
  message,
} from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import Button from "../../../shared/globalStyling/Button";
import { cardMargin, cardBody } from "../../../shared/globalStyling/style";
import PaginationTable from "../../../shared/PaginationTable";
import moment from "moment";

const { Text } = Typography;

const LocationAttributionAudiences = ({
  style,
  attributionAudiences,
  currentuser,
  deleteAudience,
}) => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const isLoading = false; // Set loading state based on your logic
  const [searchText, setSearchText] = useState("");
  const [deletedAudienceIds, setDeletedAudienceIds] = useState([]);

  const getAttributionAudiences =
    attributionAudiences &&
    attributionAudiences.audiencesConnection &&
    attributionAudiences.audiencesConnection.edges &&
    attributionAudiences.audiencesConnection.edges.filter(
      val => val.node.orgs.length === 1
    );
  const remapAttributionAudiences = (getAttributionAudiences || []).map(val => {
    return val.node;
  });

  let columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "300px",
    },
    {
      title: "Locations",
      dataIndex: "geoTargets",
      key: "geoTargets",
      align: "left",
      render: (text, record) => {
        return record.geoTargets.map(target => {
          const location = target.name;

          return target.location ? (
            <Tag color="blue" key={target.id}>
              {location}
            </Tag>
          ) : null;
        });
      },
    },
    {
      title: "Date Created",
      dataIndex: "createdAt",
      key: "createdAt",
      render: text => {
        return moment(text).format("MM/DD/YYYY - hh:mma");
      },
      sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
    },
    {
      title: "Date Updated",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: text => {
        return moment(text).format("MM/DD/YYYY - hh:mma");
      },
      sorter: (a, b) => moment(a.updatedAt).unix() - moment(b.updatedAt).unix(),
    },
  ];

  const filteredRemapAttributionAudiences = remapAttributionAudiences
    ? remapAttributionAudiences.filter(
        audience => !deletedAudienceIds.includes(audience.id)
      )
    : [];

  // TODO: This filter should happen on the BACKEND.
  // This improvement will be handle on a separate ticket
  const filteredData =
    filteredRemapAttributionAudiences &&
    filteredRemapAttributionAudiences.filter(
      audience =>
        audience.orgs.length &&
        audience.orgs[0].id === currentuser.role.org.id &&
        audience.name.toLowerCase().includes(searchText.toLowerCase())
    );

  const onDeleteAudience = async () => {
    const selectedIds = selectedRows.filter(row => row.id).map(row => row.id);

    if (selectedIds.length === 0) {
      message.warning("Please select at least one audience to delete");
      return;
    }

    const result = await deleteAudience(selectedIds);

    if (result.successCount > 0) {
      // Update the local state to reflect the deletions
      setDeletedAudienceIds(prevIds => [...prevIds, ...selectedIds]);
      setSelectedRows([]);
    }
  };

  return (
    <div>
      <Row gutter={[18, 18]}>
        <Col xs={24} lg={24}>
          <Card
            style={style ? { ...cardMargin, ...style.card } : { ...cardMargin }}
            bodyStyle={
              style ? { ...cardBody, ...style.cardBody } : { ...cardBody }
            }
            title="LOCATIONS AUDIENCE"
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: 16,
              }}
            >
              <Input
                placeholder="Search by name"
                value={searchText}
                allowClear
                onChange={e => setSearchText(e.target.value)}
                style={{
                  width: 300, // Adjust the width as needed
                }}
              />
              <div>
                <Popconfirm
                  title="Are you sure to delete this audience location?"
                  onConfirm={onDeleteAudience}
                  okText="Yes"
                  cancelText="No"
                  disabled={selectedRows.length === 0}
                >
                  <Button
                    icon={<DeleteOutlined />}
                    type="danger"
                    disabled={selectedRows.length === 0}
                  >
                    Delete Audience
                  </Button>
                </Popconfirm>
              </div>
            </div>
            {filteredRemapAttributionAudiences &&
            filteredRemapAttributionAudiences.length ? (
              <div>
                <Spin tip="Loading..." spinning={isLoading}>
                  <PaginationTable
                    columns={columns}
                    data={filteredData.sort(
                      (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
                    )}
                    bordered={false}
                    rowSelection={{
                      type: "checkbox",
                      getCheckboxProps: record => ({}),
                      onChange: (selectedRowKeys, selectedRows) => {
                        setSelectedRows(selectedRows);
                      },
                    }}
                    paginationSize={"default"}
                    scroll={{ x: 1200 }}
                    size={"default"}
                    current={currentPage}
                    pageSize={10}
                    onChange={paginationObject => {
                      setCurrentPage(paginationObject.current);
                    }}
                    rowKey="id"
                  />
                </Spin>
              </div>
            ) : (
              <Empty />
            )}
            <Text type="secondary" style={{ marginLeft: "0.4em" }}>
              Number of Rows: {remapAttributionAudiences.length}
            </Text>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default LocationAttributionAudiences;
