import gql from "graphql-tag";

const GET_ORG_TAGS = gql`
  query getOrgTags($orgId: ID) {
    org(where: { id: $orgId }) {
      id
      name
      description
      website
      zone
      level
      roles {
        id
        isMaster
      }
      dataConfig {
        menuId
        dspAdvertiserId
        roasFormat
        defaultDisplayAttributionWindow
        defaultPaidSearchAttributionWindow
        defaultSEOAttributionWindow
        currency {
          currency
          conversionValue
        }
        semrushEnable
        semrushConfig {
          projectId
          rootKeyword
          rootDomainUrl
          competitor1stRootDomainUrl
          competitor2ndRootDomainUrl
        }
        seo
        seoDomainRankings
        googleAnalytics {
          viewId
          isGA4
          keyFileName
        }
        tagProcessingConfig {
          processTag
        }
        paidSearchDataConfig {
          isEnabled
        }
        bing {
          accountId
          customerId
        }
      }
      eventsTarget {
        id
        name
        retailId
        cart
        test
        tag
        transactions
        pageViews
        total
        tagUpdateDate
        signups
        isAmplifyDefault
        segmentId
        segmentName
        eventTags {
          id
          appId
        }
      }
    }
  }
`;

export default GET_ORG_TAGS;
