import gql from "graphql-tag";

const UPDATE_ADVERTISER = gql`
  mutation UpdateAdvertiser(
    $data: AdvertiserUpdateInput!
    $where: AdvertiserWhereUniqueInput!
    $dataRemove: AdvertiserUpdateInput!
  ) {
    removeOldValues: updateAdvertiser(data: $dataRemove, where: $where) {
      id
      name
    }
    updateAdvertiser(data: $data, where: $where) {
      id
      name
    }
  }
`;

export default UPDATE_ADVERTISER;
