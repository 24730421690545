import React from "react";
import { withRouter, useHistory } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { graphql } from "react-apollo";
import compose from "lodash/flowRight";
import OverlaySpinner from "../../../../platform/shared/OverlaySpinner";
import ADVERTISER from "../../../GraphQl/Queries/ADVERTISER";
import DELETE_ADVERTISER from "../../../GraphQl/Mutations/DELETE_ADVERTISER";
import CURRENT_USER from "../../../GraphQl/Queries/CURRENT_USER";
import TAG_ORGS from "../../../GraphQl/Queries/TAG_ORGS";
import GET_LOCATIONS from "../../../GraphQl/Queries/GET_LOCATIONS";
import ATTRIBUTION_AUDIENCES_ORG_CONFIG from "../../../GraphQl/Queries/ATTRIBUTION_AUDIENCES_ORG_CONFIG";
import UPDATE_ADVERTISER from "../../../GraphQl/Mutations/UPDATE_ADVERTISER";

const AdvertiserDetailsLoader = props => {
  const history = useHistory();
  const { match, currentUser } = props;
  const { params } = match;
  const { id } = params;
  const { permission } = currentUser;
  const currentOrgId = currentUser.role.org.id;

  const { data, loading } = useQuery(ADVERTISER, {
    variables: { id },
  });

  const { data: eventTags, loading: eventTagsLoading } = useQuery(TAG_ORGS, {
    variables: {
      orgId: currentOrgId,
    },
  });

  const [deleteAdvertiser] = useMutation(DELETE_ADVERTISER, {
    variables: {
      where: { id },
    },
    onCompleted: () => {
      history.push("/advertiser/main/1");
    },
  });

  const { loading: loadingLocation, data: locationData } = useQuery(
    GET_LOCATIONS,
    {
      variables: { id: currentOrgId },
    }
  );

  const { loading: loadingAudiences, data: attributionAudiences } = useQuery(
    ATTRIBUTION_AUDIENCES_ORG_CONFIG,
    {
      variables: { TYPE: "ATTRIBUTION", ORGID: currentOrgId },
    }
  );

  const [updateAdvertiser] = useMutation(UPDATE_ADVERTISER, {
    onCompleted: () => {
      // reload page after update
      history.go(0);
    },
  });

  if (loading || eventTagsLoading || loadingLocation || loadingAudiences)
    return <OverlaySpinner />;

  return React.cloneElement(props.children, {
    ...props.children.props,
    advertiser: data ? data.advertiser : null,
    signups: data ? data.signupReports.reports : null,
    deleteAdvertiser,
    permission,
    eventsTargets: eventTags.eventsTargets,
    currentOrgId,
    locationData,
    attributionAudiences,
    updateAdvertiser,
  });
};

export default compose(
  graphql(CURRENT_USER, {
    props: ({ data: { currentUser }, ownProps }) => {
      return ownProps.currentUser || { currentUser };
    },
  })
)(withRouter(AdvertiserDetailsLoader));
