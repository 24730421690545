import { CloseOutlined } from "@ant-design/icons";
import { Button, Col, Input, Row } from "antd";
import React, { useRef } from "react";
import { withRouter } from "react-router-dom";

const FilterBar = ({ location, history }) => {
  const searchRef = useRef(null);
  const params = new URLSearchParams(location.search);

  return (
    <Row
      className="filter-bar-container"
      align={"middle"}
      justify={"end"}
      gutter={[16, 16]}
    >
      <Col
        xs={{ span: 24, order: 1 }}
        md={{ span: 7, order: 2, offset: 7 }}
        xl={{ span: 4, offset: 15 }}
      >
        <Input.Search
          placeholder={"Search"}
          ref={searchRef}
          style={{ width: "100%", height: "36px", fontSize: "14px" }}
          allowClear
          onSearch={search => {
            if (params.get("search")) params.delete("search");
            if (search) params.append("search", search);
            history.push(`/editorial/main/1?${params.toString()}`);
          }}
          defaultValue={params.get("search")}
        />
      </Col>
      <Col
        xs={{ span: 24, order: 3 }}
        md={{ span: 4, order: 3 }}
        xl={{ span: 2 }}
      >
        <Button
          style={{ width: "100%", height: "36px", fontSize: "14px" }}
          shape="round"
          type={"default"}
          onClick={() => {
            searchRef.current.input.value = "";
            history.push(`/editorial/main/1`);
          }}
          disabled={!params.toString()}
          icon={<CloseOutlined />}
        >
          Clear
        </Button>
      </Col>
    </Row>
  );
};

export default withRouter(FilterBar);
