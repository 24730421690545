import React, { useState } from "react";
import {
  Modal,
  Button,
  Upload,
  message,
  Table,
  Row,
  Col,
  Space,
  Select,
  Tooltip,
  Typography,
} from "antd";
import {
  DownloadOutlined,
  InboxOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import Papa from "papaparse";
import { useSEOLinkMaintenanceContext } from "../../../../../core/components/seoRefactored/seoDetails/context/SEOLinkMaintenanceContext";
import { contentTypeOptions } from "../../../../../core/utils/constants/constants";

const UploadLinksModal = ({ visible, onCancel }) => {
  const [fileData, setFileData] = useState([]);
  const [uploading, setUploading] = useState(false);

  const { createAcquiredLink } = useSEOLinkMaintenanceContext();

  // URL validation
  const validateUrl = url => {
    const cleanUrl = url.replace(/^https?:\/\//, "");
    const urlPattern = /^([a-zA-Z0-9][a-zA-Z0-9-]*\.)+[a-zA-Z]{2,}$/;

    return urlPattern.test(cleanUrl);
  };

  const validateCSVData = data => {
    const errors = [];

    data.forEach((row, index) => {
      const rowNumber = index + 1;
      const sourceUrl = row["Source URL"] ? row["Source URL"].trim() : "";
      const destinationUrl = row["Destination URL"]
        ? row["Destination URL"].trim()
        : "";

      if (!sourceUrl || !destinationUrl) {
        errors.push(`Row ${rowNumber}: Missing URL(s)`);
        return;
      }

      if (!validateUrl(sourceUrl)) {
        errors.push(
          `Row ${rowNumber}: Invalid Source URL format (${sourceUrl})`
        );
      }

      if (!validateUrl(destinationUrl)) {
        errors.push(
          `Row ${rowNumber}: Invalid Destination URL format (${destinationUrl})`
        );
      }
    });

    return errors;
  };

  const handleContentTypeChange = (value, record) => {
    const newData = fileData.map(item => {
      if (item.key === record.key) {
        return { ...item, contentType: value };
      }
      return item;
    });
    setFileData(newData);
  };

  const handleRemoveRow = record => {
    const newData = fileData.filter(item => item.key !== record.key);
    setFileData(newData);
    if (newData.length === 0) {
      message.info("All rows removed. You can upload a new file.");
    }
  };

  const columns = [
    {
      title: "Source URL",
      dataIndex: "sourceUrl",
      key: "sourceUrl",
    },
    {
      title: "Destination URL",
      dataIndex: "destinationUrl",
      key: "destinationUrl",
    },
    {
      title: "Content Type",
      dataIndex: "contentType",
      key: "contentType",
      render: (_, record) => (
        <Select
          style={{ width: "100%" }}
          placeholder="Select content type"
          value={record.contentType || undefined}
          onChange={value => handleContentTypeChange(value, record)}
        >
          {contentTypeOptions.map(option => (
            <Select.Option key={option} value={option}>
              {option}
            </Select.Option>
          ))}
        </Select>
      ),
    },
    {
      title: "",
      key: "actions",
      width: 50,
      render: (_, record) => (
        <Tooltip title="Remove Row">
          <Button
            type="text"
            icon={<CloseCircleOutlined style={{ color: "#ff4d4f" }} />}
            onClick={() => handleRemoveRow(record)}
          />
        </Tooltip>
      ),
    },
  ];

  const handleDownloadTemplate = () => {
    const headers = ["Source URL", "Destination URL"];
    const csvContent = [
      headers.join(","),
      "sampleSource.com,sampleDestination.com",
    ].join("\n");

    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "Acquired_Links_Template.csv";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  };

  const processCSV = file => {
    return new Promise((resolve, reject) => {
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: results => {
          const validationErrors = validateCSVData(results.data);
          if (validationErrors.length > 0) {
            reject(new Error(validationErrors.join("\n")));
            return;
          }
          const processedData = results.data.map((row, index) => ({
            key: index,
            sourceUrl: row["Source URL"].trim(),
            destinationUrl: row["Destination URL"].trim(),
            contentType: undefined,
          }));
          resolve(processedData);
        },
        error: error => {
          reject(error);
        },
      });
    });
  };

  const handleUpload = async () => {
    setUploading(true);
    message.loading("Uploading links...");

    try {
      for (const row of fileData) {
        const sourceUrl = row.sourceUrl.startsWith("http")
          ? row.sourceUrl
          : `https://${row.sourceUrl}`;

        const destinationUrl = row.destinationUrl.startsWith("http")
          ? row.destinationUrl
          : `https://${row.destinationUrl}`;

        await createAcquiredLink(sourceUrl, destinationUrl, row.contentType);
      }

      message.success(`Successfully uploaded ${fileData.length} links`);
      setFileData([]);
      onCancel();
    } catch (error) {
      message.error("Failed to upload file. Please try again.");
    } finally {
      setUploading(false);
    }
  };

  const uploadProps = {
    name: "file",
    multiple: false,
    accept: ".csv",
    maxCount: 1,
    showUploadList: false,
    beforeUpload: async file => {
      const isCSV = file.type === "text/csv" || file.name.endsWith(".csv");
      if (!isCSV) {
        message.error("You can only upload CSV files. Please try again");
        return false;
      }

      try {
        const data = await processCSV(file);
        setFileData(data);
        message.success(`${file.name} file processed successfully`);
      } catch (error) {
        if (error.message.includes("Row")) {
          Modal.error({
            title: "The following errors were found in your CSV file:",
            content: (
              <Space direction="vertical" style={{ width: "100%" }}>
                <div
                  style={{
                    background: "#fafafa",
                    padding: 16,
                    borderRadius: 6,
                    border: "1px solid #f0f0f0",
                  }}
                >
                  {error.message.split("\n").map((line, index) => (
                    <Typography.Text
                      key={index}
                      type="danger"
                      style={{ display: "block" }}
                    >
                      {line}
                    </Typography.Text>
                  ))}
                </div>
                <Typography.Text type="secondary">
                  Please correct these errors and try again.
                </Typography.Text>
              </Space>
            ),
            width: 500,
          });
        } else {
          message.error("Error processing CSV file");
        }
      }
      return false;
    },
  };

  const handleModalCancel = () => {
    setFileData([]);
    onCancel();
  };

  const isDataValid =
    fileData.length > 0 && fileData.every(row => row.contentType);

  return (
    <Modal
      visible={visible}
      title="UPLOAD ACQUIRED LINKS"
      onCancel={handleModalCancel}
      width={850}
      footer={
        <Row justify="space-between" align="middle">
          <Col>
            <Space>
              <Button
                icon={<DownloadOutlined />}
                onClick={handleDownloadTemplate}
              >
                Download Template
              </Button>
            </Space>
          </Col>
          <Col>
            <Space>
              <Button onClick={handleModalCancel}>Cancel</Button>
              <Button
                type="primary"
                onClick={handleUpload}
                disabled={!isDataValid}
                loading={uploading}
              >
                Upload
              </Button>
            </Space>
          </Col>
        </Row>
      }
    >
      {fileData.length === 0 ? (
        <Upload.Dragger {...uploadProps}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            Click or drag CSV file to this area to upload
          </p>
          <p className="ant-upload-hint">Please use the provided template.</p>
        </Upload.Dragger>
      ) : (
        <div>
          <Row style={{ marginBottom: 16 }}>
            <Col span={12}>
              <span>Preview of data to be uploaded:</span>
            </Col>
            <Col span={12} style={{ textAlign: "right" }}>
              <Button onClick={() => setFileData([])}>
                Upload Different File
              </Button>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Table
                columns={columns}
                dataSource={fileData}
                size="small"
                scroll={{ y: 300 }}
                pagination={fileData.length > 10 ? { pageSize: 5 } : false}
              />
            </Col>
          </Row>
        </div>
      )}
    </Modal>
  );
};

export default UploadLinksModal;
