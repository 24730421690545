import React, { useState } from "react";
import { Modal, Button } from "antd";
import TagsManagerView from "../../../Tags/manager/TagsManagerView";

const CreateImpressionsTag = ({
  onSubmit = () => {}, //Function to be called when the modal is submitted
  advertisers,
}) => {
  const [visible, setVisible] = useState(false); //Modal Pop up

  return (
    <React.Fragment>
      <Button
        type="primary"
        shape="round"
        onClick={() => {
          setVisible(true);
        }}
      >
        Create Impression Tag
      </Button>

      <Modal
        title="Create Impression Tag"
        visible={visible}
        onCancel={() => {
          setVisible(false);
        }}
        footer={null}
      >
        <TagsManagerView
          headerSubmit={false}
          tagType={"Impression"}
          platforms={advertisers}
          onCard={false}
          onSubmit={async value => {
            await onSubmit(value);
            setVisible(false);
          }}
        />
      </Modal>
    </React.Fragment>
  );
};

export default CreateImpressionsTag;
