import React, { useState } from "react";
import { PageContentLayout, PageHeaderLayout } from "../../../shared/layout";
import { Space, Popconfirm, message, Tabs, Modal } from "antd";
import { withRouter } from "react-router-dom/cjs/react-router-dom";
import GlobalButton from "../../../shared/globalStyling/Button";
import JSZip from "jszip";
import Papa from "papaparse";
import { cloneDeep } from "lodash";
import { saveAs } from "file-saver";

import TransactionsDetailsView from "./tabs/TransactionsDetailsView";
import SignupsDetailsView from "./tabs/SignupsDetailsView";
import LaunchAdvertiser from "../main/components/LaunchAdvertiser";
import UpdateCreatives from "./components/UpdateCreatives";

const AdvertiserDetailsView = props => {
  const {
    advertiser,
    deleteAdvertiser,
    isSelfService,
    currentOrgId,
    tags,
    filteredLocations,
    filteredAudiences,
    onUpdateAdvertiser,
    hasSignups,
    hasTransactions,
    onUpdateCreatives,
  } = props;
  const [openLaunch, setOpenLaunch] = useState(false);
  const [csvReport, setCsvReport] = useState(null);
  const [creatives, setCreatives] = useState([]);
  const [creativesSignups, setCreativesSignups] = useState([]);

  const advertiserDetailSummary = {
    name: advertiser.name,
    startDate: advertiser.startDate,
    endDate: advertiser.endDate,
    impressionsTag: advertiser.impressionsTag.id,
    selectedImpresionsTagOrgId: advertiser.impressionsTag.orgs[0].id,
    insertionOrderIds: advertiser.insertionOrderIds,
    transactionsTags: advertiser.transactionsTags.map(t => {
      const { eventsTarget, trackMesurements } = t;
      return {
        eventsTarget: {
          connect: {
            id: eventsTarget.id,
            orgId: eventsTarget.orgs[0].id,
          },
        },
        trackMesurements: {
          set: trackMesurements,
        },
      };
    }),
    locations: advertiser.locations.map(l => {
      const { id } = l;
      return {
        connect: [
          {
            id,
          },
        ],
      };
    }),
    selectedMeasurementTags: advertiser.transactionsTags.map(t => {
      const { eventsTarget, trackMesurements } = t;
      return {
        value: eventsTarget.id,
        orgTag: eventsTarget.orgs[0].id,
        label: eventsTarget.name,
        website: eventsTarget.orgs[0].website,
        isOwned: eventsTarget.orgs[0].id === currentOrgId,
        isAdvertiser: eventsTarget.isAdvertiser,
        advertiser: eventsTarget.orgs[0].name,
        transactions: eventsTarget.transactions,
        pageViews: eventsTarget.pageViews,
        basketItems: eventsTarget.basketItems,
        signups: eventsTarget.signups,
        appId: eventsTarget.eventTags[0].appId,
        selectedImpresionsTagOrgId: eventsTarget.orgs[0].id,
        measuredEvents: trackMesurements,
      };
    }),
    selectedLocations: advertiser.locations,
    campaignFilters: advertiser.campaigns.map(c => ({
      insertionOrderId: c.insertionOrderId ? c.insertionOrderId : null,
      lineitemIds: c.lineItemIds ? c.lineItemIds : [],
      lineItemName: c.name ? c.name : null,
      advertiserId: c.advertiserId ? c.advertiserId : null,
      impressionsTag: c.impressionsTag ? c.impressionsTag.id : null,
      selectedImpresionsTagOrgId: c.impressionsTag
        ? c.impressionsTag.orgs[0].id
        : null,
    })),
  };

  const onDeleteAdvertiser = async () => {
    try {
      await deleteAdvertiser();
      message.success("Advertiser successfully deleted");
    } catch (err) {
      message.error(`Error deleting advertiser: ${err.message}`);
    }
  };

  return (
    <React.Fragment>
      <Modal
        title={`Update Advertiser Funnel`}
        visible={openLaunch}
        footer={null}
        width={"75%"}
        onCancel={() => {
          setOpenLaunch(false);
        }}
      >
        <Tabs>
          <Tabs.TabPane key={"details"} tab={"Funnel Details"}>
            <LaunchAdvertiser
              currentOrgId={currentOrgId}
              tags={tags}
              open={openLaunch}
              onCreate={async values => {
                setOpenLaunch(false);
                await onUpdateAdvertiser(values);
              }}
              filteredLocations={filteredLocations}
              filteredAudiences={filteredAudiences}
              isSelfService={isSelfService}
              details={advertiserDetailSummary}
              isUpdate={true}
            />
          </Tabs.TabPane>
          <Tabs.TabPane key={"creatives"} tab={"Creatives"}>
            <UpdateCreatives
              creatives={creatives.concat(creativesSignups)}
              maskedCreatives={advertiser.creatives}
              onUpdate={onUpdateCreatives}
            />
          </Tabs.TabPane>
        </Tabs>
      </Modal>
      <PageHeaderLayout
        title={advertiser.name}
        rightContent={
          <Space>
            <GlobalButton
              type="primary"
              onClick={() => {
                try {
                  const zip = new JSZip();
                  csvReport.forEach(report => {
                    const folder = zip.folder(
                      `Advertiser ${advertiser.name} Reports`
                    );
                    let dupFileData = cloneDeep(report.value);
                    folder.file(
                      `${report.name} Transactions Report.csv`,
                      Papa.unparse(dupFileData)
                    );
                  });

                  zip.generateAsync({ type: "blob" }).then(
                    blob => {
                      saveAs(blob, "Advertiser");
                      return true;
                    },
                    function(e) {
                      console.log("error", e);
                      return false;
                    }
                  );
                } catch (err) {
                  console.log(err);
                }
              }}
              disabled={!csvReport || csvReport.length === 0}
            >
              Export CSV
            </GlobalButton>
            {isSelfService && (
              <Popconfirm
                title="Are you sure you want to permanently delete this funnel?"
                onConfirm={onDeleteAdvertiser}
                okText="Yes"
                cancelText="No"
              >
                <GlobalButton type="primary">Delete Funnel</GlobalButton>
              </Popconfirm>
            )}
            <GlobalButton
              onClick={() => setOpenLaunch(!openLaunch)}
              type="primary"
            >
              Update Funnel
            </GlobalButton>
          </Space>
        }
      />
      <PageContentLayout>
        <Tabs>
          {hasTransactions ? (
            <Tabs.TabPane key="transaction" tab="Transactions">
              <TransactionsDetailsView
                setReport={setCsvReport}
                setCreatives={setCreatives}
                creatives={advertiser.creatives}
                {...props}
              />
            </Tabs.TabPane>
          ) : null}

          {hasSignups ? (
            <Tabs.TabPane key="signups" tab="Sign Ups">
              <SignupsDetailsView
                setReport={setCsvReport}
                creatives={advertiser.creatives}
                setCreatives={setCreativesSignups}
                {...props}
              />
            </Tabs.TabPane>
          ) : null}
        </Tabs>
      </PageContentLayout>
    </React.Fragment>
  );
};

export default withRouter(AdvertiserDetailsView);
